import React from 'react';
import { withRouter } from 'react-router-dom';
import Constants from '../utils/Constants';
import NoDataFound from './NoDataFound';
import { msToHMS } from '../utils/validation';
class ViewSubscriptionScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const { data } = this.props;
        return (
            <div className="content-part px-5 py-4 list">
                <div className="agent-details">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Subscription Name
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.planName ? data.planName : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Subscription Price
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.planPrice ? data.planPrice : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Agent Limit
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.agentLimit ? data.agentLimit : "-"}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Cart Generation (Screen Sharing, Age verification)
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.cartGeneration === 1 ? 'Yes' : 'No'}</p>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {"Availability"}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.availability === 1 ? 'Yes' : 'No'}</p>
                                </div>
                            </div> */}
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {"Additional Charges"}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.additionalCharges ? data.additionalCharges : "-"}</p>
                                </div>
                            </div> */}
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {"Customer Feedback"}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.customerFeedback === 1 ? 'Yes' : 'No'}</p>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-md-6">
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Live Stream
                                </div>
                                <div className='col-7'>
                                    <p>{data.totalSec ? msToHMS(data && data.totalSec) : '-'}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Argumented Reality
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.argumentedReality === 1 ? 'Yes' : 'No'}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Description
                                </div>
                                <div className='col-7'>
                                    <p>{data.description ? data.description : '-'}</p>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {"LiveStreamMinutes"}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.liveStreamMins}</p>
                                </div>
                            </div> */}
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {"livechat"}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.livechat === 1 ? 'Yes' : 'No'}</p>
                                </div>
                            </div> */}
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {"paymentIntegration"}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.paymentIntegration === 1 ? 'Yes' : 'No'}</p>
                                </div>
                            </div> */}
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {"Screen Sharing"}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.screenSharing === 1 ? 'Yes' : 'No'}</p>
                                </div>
                            </div> */}
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {"usageReporting"}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.usageReporting === 1 ? 'Yes' : 'No'}</p>
                                </div>
                            </div> */}
                        </div>
                        {!data && <NoDataFound />}
                    </div>
                    <div className=" mt-5 me-5 pb-3 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.goBack()} >{Constants.BACK}</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(ViewSubscriptionScreen);