import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import NoDataFound from '../component/NoDataFound';
import SideBar from '../component/SideBar';
import Constants from '../utils/Constants';
import Actions from '../redux/actions';
import { getstatusHookUrlDetails } from '../redux/reducer/VendorListReducer';
import AppLoader from '../utils/AppLoader';
import { toast } from 'react-toastify';
import moment from 'moment';


class VendorWeebhook extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isHookAdded: false,
            endPoint: '',
            description: '',
            error: {},
            message: {},
            statusIsActive: false,
            isEdit: false,
            editableId: null,
        }
    }

    handleEndpoint = (e) => {
        let endPoint = e.target.value

        if (!endPoint || endPoint.length === 0) {
            this.setState({ endPoint: endPoint, error: { endPoint: true }, message: { endPoint: 'Please Enter End Point' } })
        } else {
            this.setState({ endPoint: endPoint, error: { endPoint: false }, message: { endPoint: '' } })
        }
        this.setState({ endPoint: endPoint })
    }

    handleDescription = (e) => {
        let description = e.target.value
        this.setState({ description: description })
    }

    handleAddEndPoint = () => {
        const { vendorWeBhookConfig, enablevendorWeBhookConfig } = this.props;
        const { endPoint, description, isEdit, editableId } = this.state;


        if (!endPoint || endPoint.length < 20) {
            return false
        }
        if (isEdit) {
            let params = {
                id: editableId,
                statusUrl: endPoint,
                description: description
            }

            const callback = (response) => {
                const data = response.response;
                if (data) {
                    toast.success(Constants.UPDATED_SUCCESSFULLY)
                    this.setState({ isHookAdded: false })
                }
            }
            enablevendorWeBhookConfig(params, callback, false)
            this.setState({ isEdit: false })
        } else {
            this.setState({ endPoint: '', description: '' })
            let params = {
                statusUrl: endPoint,
                description: description
            }
            const callback = (Response) => {
                const { response } = Response;
                const { data } = response
                if (Response.response_message === 'SUCCESS') {
                    toast.success(Constants.UPDATED_SUCCESSFULLY)
                    this.setState({ isHookAdded: false })
                }
                else if (data.response_message === 'Diasbale The Active StatusURL To Add New StatusURL') {
                    toast.error("kindly disable the active statusURl to add new statusUrl")
                    this.setState({ isHookAdded: false })
                    window.location.reload(false)
                }
            }
            vendorWeBhookConfig(params, callback, false)
        }
    }

    handleEnable = async (id) => {
        const { enablevendorWeBhookConfig } = this.props;
        const { getstatusUrlDetails } = this.props;
        const { webhook } = getstatusUrlDetails;
        const { response } = webhook;
        this.setState({ isEdit: false })
        await response?.find((item) => {
            if (item?.id === id) {
                if (item?.isActive === true) {
                    this.setState({ statusIsActive: false })
                } else {
                    this.setState({ statusIsActive: true })
                }
            }

        })
        let params = {
            isActive: this.state.statusIsActive ? 1 : 0,
            id: id
        }

        const callback = (response) => {
            const data = response?.response;
            if (data) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.setState({ isHookAdded: false })
            }
        }
        enablevendorWeBhookConfig(params, callback, false)
    }

    handleEditUpdateUrl = async (id) => {
        const { getstatusUrlDetails } = this.props;
        const { webhook } = getstatusUrlDetails;
        const { response = [] } = webhook;
        const { isHookAdded } = this.state;
        this.setState({ isHookAdded: !isHookAdded, isEdit: true })
        await response?.find((item) => {
            if (item.id === id) {
                this.setState({ endPoint: item?.statusUrl, description: item?.description, editableId: id })
            }
        })
    }

    componentDidMount() {
        const { getVendorWebhookList } = this.props;
        getVendorWebhookList();

    }

    render() {
        const { isHookAdded, description, endPoint, error, message, isEdit } = this.state
        const { getstatusUrlDetails } = this.props;
        const { isLoading } = getstatusUrlDetails;
        const { webhook } = getstatusUrlDetails;
        const { response = [] } = webhook;
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <div className="content-part">
                            {!isHookAdded && <div className="addButton">
                                <div className="add-btn">
                                    <button className="btn add-item" type="button" onClick={() => this.setState({ isHookAdded: !isHookAdded, isEdit: false, endPoint: '', description: '' })} ><i className="fa-solid fa-plus me-3"
                                    ></i>{"Add New Webhook"}</button>
                                </div>
                            </div>
                            }
                            {isHookAdded && <div className='m-5 p3'>
                                <div className="card cardShadow">
                                    <div className="card-body">
                                        <div className='modal-header'>
                                            <h3 className="card-title">Set up your webhook endpoint to receive live events from hooks.</h3>
                                            <button type="button" className="btn-close" onClick={() => this.setState({ isHookAdded: false })}></button>
                                        </div>
                                        <div className='card-body'>
                                            <div className="mt-3">
                                                <p className="text-bold">{"Endpoint URL"}</p>
                                                <input autoComplete="off" type="text" name='planPrice' value={endPoint} onChange={(e) => this.handleEndpoint(e)}
                                                    className="form-control w-75" placeholder={"https://"}
                                                />
                                            </div>
                                            <div>
                                                {error?.endPoint && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.endPoint}</h5>}
                                            </div>
                                            <div className="mt-3">
                                                <p className="text-bold">{Constants.DESCRIPTION}</p>
                                                <textarea type="text" name='description' placeholder={"An optional description of what this webhook endpoint is used for..."} rows={5}
                                                    className="form-control w-75" value={description} onChange={(e) => this.handleDescription(e)} />
                                            </div>
                                        </div>
                                        <div>
                                            <button className='btn btn-primary buttonHook mx-3' onClick={() => this.handleAddEndPoint()}>{!isEdit ? "Add Endpoint" : "Update Endpoint"}</button>
                                            <button className='btn btn-primary buttonHook' onClick={() => this.setState({ isHookAdded: false, endPoint: '', description: '', isEdit: false })}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {!isHookAdded && response.length > 0 && <div className="listView subscriptionList">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{"Id"}</th>
                                            <th>{"URL"}</th>
                                            <th>{"Status"}</th>
                                            <th>{"Created Date"}</th>
                                            <th>{"Last Updated"}</th>
                                            <th>{"Edit"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {response && response?.length > 0 && response?.map((item) => {
                                            return < tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td className="">{item.statusUrl}</td>
                                                <td><i className={item.isActive ? "fa-solid fa-toggle-on toggle-icon" : "fa-solid fa-toggle-off toggle-icon"} onClick={() => this.handleEnable(item.id)}
                                                /></td>
                                                <td className="">{moment(item.createdAt).format("DD/MM/YYYY hh:mm:ss A")}</td>
                                                <td className="">{item.updatedAt ? moment(item.updatedAt).format("DD/MM/YYYY hh:mm:ss A") : "-"}</td>
                                                <td>
                                                    <i className="fa-solid fa-pen-to-square edit-icon" onClick={() => this.handleEditUpdateUrl(item.id)} />
                                                </td>
                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>}
                            {
                                response && response.length <= 0 && !isHookAdded && <NoDataFound />
                            }
                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>

        )
    }
}

export const mapStateToProps = (state) => {
    return {
        getstatusUrlDetails: getstatusHookUrlDetails(state)
    }
};

export default withRouter(connect(mapStateToProps, {
    vendorWeBhookConfig: Actions.vendorWeBhookConfig,
    enablevendorWeBhookConfig: Actions.updateVendorWeBhookConfig,
    getVendorWebhookList: Actions.getVendorWeBhookConfig
})(VendorWeebhook));