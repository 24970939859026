import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../redux/actions/index";
import AppLoader from "../utils/AppLoader";
import stringTamil from "../utils/stringTamil";
import stringEnglish from "../utils/stringEnglish";
import SRM_GLOBAL from "../assets/img/srm-global.jpeg";
import Constants from "../utils/Constants";
import { toast } from "react-toastify";
class SRM_IP_Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      message: {},
      vendorLogo: null,
      language: parseInt(localStorage.getItem("language")) || 1,
      category: "SRM_HOSPITAL",
      roomId: '',
      RoomServiceList: [],
      encryptRoomId: ""
    };
  }
  async componentDidMount() {
    localStorage?.clear();
    this.setState({ roomId: this.props.match.params.roomId });
    localStorage.setItem("srm_ip_room", this.props.match.params.roomId)
    this.getRoomService(this.props.match.params.roomId)
    this.setState({ encryptRoomId: this.props.match.params.roomId })
  }
  getRoomService = (encryptRoomId) => {
    let params = {
      encryptRoomId
    }
    const callback = (response) => {
      this.setState({ isLoading: false })
      if (response?.response_code === 0) {
        this.setState({ RoomServiceList: response?.response?.SrmIpRoomServiceMappings })
      }
    }
    this.setState({ isLoading: true })
    this.props.getSrmIpRoomService(params, callback, true)
  }

  join = (item) => {
    this.setState({ isLoading: true })
    const params = {
      encryptRoomId: this.state.encryptRoomId,
      srmIpServiceId: item?.srmIpServiceId,
      srmIpRoomId: item?.srmIpRoomId,
      userId: item?.userId,
    }
    localStorage.setItem("srmIpServiceId", item?.srmIpServiceId)
    this.props.customerListAction(params, (res) => {
      this.setState({ isLoading: false })
      if (res?.response_code === 0) {
        const roomId = res?.response?.roomId
        this.props.history.push('/customer/' + roomId)
      } else if (res?.response_code === 5) {
        toast.error(res?.response)
      } else {
        this.props.history.push('/pageNotFound')
      }
    })
  }
  render() {
    const { vendorLogo, language, isLoading, RoomServiceList } = this.state;
    let MESSAGE = language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
    return (
      <div className="customer-care overflow-auto" style={{ backgroundImage: `url(${SRM_GLOBAL})`, backgroundSize: "auto 100%", backgroundPosition: "center" }}>
        {isLoading && <AppLoader />}
        <div className="row call-row mx-0">
          <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
            <div className="text-center">
              <img className="logo" src={vendorLogo ? vendorLogo : require("../assets/img/srm-logo.png")} alt="logo" />
            </div>
            <div className="d-flex flex-column gap-3 width_value pb-sm-0 pb-5">
              <div className="d-flex gap-3 flex-column p-sm-5 p-3 rounded-4" style={{ backgroundColor: "rgb(255 255 255 / 80%)" }}>
                <div>
                  <h1 className="text-center">{MESSAGE?.HOW_CAN_I_HELP}</h1>
                  {/* <p className="text-center">{MESSAGE?.TEXT_CONTENT}</p> */}
                </div>
                <React.Fragment>
                  <div className="d-flex flex-column">
                    <label htmlFor="exampleFormControlInput1" className="form-label form-text">
                      {MESSAGE?.SERVICES}
                    </label>
                    {/* <div className="row g-2">
                      {MESSAGE?.LIST_OF_CATEGORY.map((item) => (
                        <div
                          className="col-6"
                          key={item?.id}
                          onClick={(e) => this.handleChangeCategory(item?.string)}>
                          <button className={"cat-btn btn-gradient"}>
                            <h1>
                              <i className={item?.icon}></i>
                            </h1>
                            {item?.value}
                          </button>
                        </div>
                      ))}
                    </div> */}
                    <div className="row g-2">
                      {RoomServiceList?.map((item) => (
                        <div
                          className="col-6"
                          key={item?.id}
                          onClick={(e) => this.join(item)}>
                          <button className={"cat-btn btn-gradient"}>
                            <h1>
                              {/* <i className={item?.icon}></i> */}
                            </h1>
                            {item?.SrmIpServiceMaster?.serviceName}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    customerListLoader: state?.CUSTOMER_LIST,
  };
};

export default withRouter(connect(mapStateToProps, {
  customerListAction: Actions.cutomerListAction,
  getSrmIpRoomService: Actions.srmIpRoomServiceListAction,
})(SRM_IP_Customer))



