import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import ViewSubscriptionScreen from '../component/ViewSubscriptionScreen';
import Actions from '../redux/actions';
import { subscriptionListLoader } from '../redux/reducer/SubscriptionReducer';
import AppLoader from '../utils/AppLoader';


class AdminViewSubscription extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            viewData:[]
        }
    }
    componentDidMount() {
        this.getSubscription()
    }

    getSubscription = () => {
        let params = {
           id:this.props.match.params.id
        }
        const callback = (response) => {
            if (response) {
                this.setState({ viewData: response})
            }
        }
        this.props.getSubscriptionDetails(params, callback, true)
    }
    render() {
        const {  viewData } = this.state;
        const { isLoading } = this.props;
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <ViewSubscriptionScreen data={viewData} />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        isLoading: subscriptionListLoader(state)
    }
};

export default withRouter(connect(mapStateToProps, {
    getSubscriptionDetails: Actions.getSubscriptionDetails,
})(AdminViewSubscription));