import { doAdminGET, doAdminPost, doGET } from "../../utils/service";
import { put, takeLatest, all } from "redux-saga/effects";
import { END_POINTS } from "../../utils/service/EndPoints";
import Actions from "../actions";
import { ADD_SUBSCRIPTION, GET_PAYMENT_LIST, GET_SUBSCRIPTION_ACTIVE_LIST, GET_SUBSCRIPTION_DETAILS, GET_SUBSCRIPTION_LIST, UPDATE_SUBSCRIPTION } from "../actions/SubscriptionAction";


export function* subscriptionListSaga({ params, callback, isAdmin = true }) {
    try {
        const response = yield doAdminGET(END_POINTS.GET_SUBSCRIPTION_LIST, params, isAdmin);
        if (response) {
            yield put(Actions.getSubscriptionListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getSubscriptionListFailure(e));
        callback && callback(e);
    }
}
export function* subscriptionActiveListSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(isAdmin ? END_POINTS.GET_SUBSCRIPTION_ACTIVE_LIST: END_POINTS.VENDOR_SUBSCRIPTION_LIST, params, isAdmin);
        if (response) {
            yield put(Actions.getSubscriptionActiveListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getSubscriptionActiveListFailure(e));
        callback && callback(e);
    }
}
export function* addSubscriptionListSaga({ params, callback, isAdmin = true }) {
    try {
        const response = yield doAdminPost(END_POINTS.ADD_SUBSCRIPTION, params, isAdmin);
        if (response) {
            yield put(Actions.addSubscriptionSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.addSubscriptionFailure(e));
        callback && callback(e);
    }
}
export function* updateSubscriptionListSaga({ params, callback, isAdmin = true }) {
    try {
        const response = yield doAdminPost(END_POINTS.UPDATE_SUBSCRIPTION, params, isAdmin);
        if (response) {
            callback && callback(response);
        }
    } catch (e) {
        callback && callback(e);
    }
}
export function* getSubscriptionDetailsSaga({ params, callback, isAdmin = true }) {
    try {
        const response = yield doAdminGET(END_POINTS.GET_SUBSCRIPTION_DETAILS, params, isAdmin);
        if (response) {
            yield put(Actions.getSubscriptionDetailsSuccess(response.response));
            callback && callback(response?.response);
        }
    } catch (e) {
        yield put(Actions.getSubscriptionDetailsFailure(e));
        callback && callback(e);
    }
}

export function* paymentListSaga({ params, callback}) {
    try {
        const response = yield doGET(END_POINTS.GET_PAYMENT_LIST_DETAILS, params);
        if (response) {
            yield put(Actions.getPaymentListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getPaymentListFailure(e));
        callback && callback(e);
    }
}

export default function* adminSubscriptionListener() {
    yield all([
        takeLatest(GET_SUBSCRIPTION_LIST, subscriptionListSaga),
        takeLatest(ADD_SUBSCRIPTION, addSubscriptionListSaga),
        takeLatest(UPDATE_SUBSCRIPTION, updateSubscriptionListSaga),
        takeLatest(GET_SUBSCRIPTION_DETAILS, getSubscriptionDetailsSaga),
        takeLatest(GET_SUBSCRIPTION_ACTIVE_LIST, subscriptionActiveListSaga),
        takeLatest(GET_PAYMENT_LIST, paymentListSaga),
    ]);
}