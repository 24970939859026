import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import ViewAddOnScreen from '../component/ViewAddOnScreen ';
import Actions from '../redux/actions';
import { addOnListLoader } from '../redux/reducer/AddOnReducer ';
import AppLoader from '../utils/AppLoader';
import ViewProductScreen from '../component/ViewProductScreen';
import { productLoading } from '../redux/reducer/ARProductReducer';
import ViewVBScreen from '../component/ViewVBScreen';


class VendorViewVBImage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            viewData: [],
            isLoading: false
        }
    }
    componentDidMount() {
        this.getVbImageDetails()
    }
    getVbImageDetails = () => {
        const route = window.location.href.split('/')
        const isAdmin = route[3] === 'admin' ? true : false
        let params = {
            id: this.props.match.params.id
        }
        const callback = (response) => {
            if (response) {
                this.setState({ viewData: response?.response, isLoading: false })
            }
        }
        this.setState({ isLoading: true })
        this.props.getvBimage(params, callback, isAdmin)
    }
    render() {
        const { viewData, isLoading } = this.state;
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <ViewVBScreen data={viewData} />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        isLoading: productLoading(state)
    }
};

export default withRouter(connect(mapStateToProps, {
    getvBimage: Actions.vbImageDetails,
})(VendorViewVBImage));