import { doAction, doResponseAction } from ".";

const GET_VB_IMAGE_LIST = 'GET_VB_IMAGE_LIST';
const GET_VB_IMAGE_LIST_SUCCESS = 'GET_VB_IMAGE_LIST_SUCCESS';
const GET_VB_IMAGE_LIST_FAILURE = 'GET_VB_IMAGE_LIST_FAILURE';

const CREATE_VB_IMAGE = 'CREATE_VB_IMAGE';
const CREATE_VB_IMAGE_SUCCESS = 'CREATE_VB_IMAGE_SUCCESS';
const CREATE_VB_IMAGE_FAILURE = 'CREATE_VB_IMAGE_FAILURE';

const UPDATE_VB_IMAGE = 'UPDATE_VB_IMAGE';
const UPDATE_VB_IMAGE_SUCCESS = 'UPDATE_VB_IMAGE_SUCCESS';
const UPDATE_VB_IMAGE_FAILURE = 'UPDATE_VB_IMAGE_FAILURE';

const VB_IMAGE_DETAILS = 'VB_IMAGE_DETAILS';
const VB_IMAGE_DETAILS_SUCCESS = 'VB_IMAGE_DETAILS_SUCCESS';
const VB_IMAGE_DETAILS_FAILURE = 'VB_IMAGE_DETAILS_FAILURE';

const GET_AGENT_VB_IMAGE_LIST = 'GET_AGENT_VB_IMAGE_LIST';
const GET_AGENT_VB_IMAGE_LIST_SUCCESS = 'GET_AGENT_VB_IMAGE_LIST_SUCCESS';
const GET_AGENT_VB_IMAGE_LIST_FAILURE = 'GET_AGENT_VB_IMAGE_LIST_FAILURE';
export {
    GET_VB_IMAGE_LIST,
    GET_VB_IMAGE_LIST_SUCCESS,
    GET_VB_IMAGE_LIST_FAILURE,

    CREATE_VB_IMAGE,
    CREATE_VB_IMAGE_SUCCESS,
    CREATE_VB_IMAGE_FAILURE,
    
    UPDATE_VB_IMAGE,
    UPDATE_VB_IMAGE_SUCCESS,
    UPDATE_VB_IMAGE_FAILURE,

    VB_IMAGE_DETAILS,
    VB_IMAGE_DETAILS_SUCCESS,
    VB_IMAGE_DETAILS_FAILURE,

    GET_AGENT_VB_IMAGE_LIST,
    GET_AGENT_VB_IMAGE_LIST_SUCCESS,
    GET_AGENT_VB_IMAGE_LIST_FAILURE,
}

export const getVbImage = store => store['VIRTUAL_BACKGROUND_IMAGE']


export const getVbImageList = (params, callback, isAdmin) => doAction(GET_VB_IMAGE_LIST, params, callback, isAdmin);
export const getVbImageListSuccess = data => doResponseAction(GET_VB_IMAGE_LIST_SUCCESS, data);
export const getVbImageListFailure = data => doResponseAction(GET_VB_IMAGE_LIST_FAILURE, data);

export const createVbImage = (params, callback, isAdmin) => doAction(CREATE_VB_IMAGE, params, callback, isAdmin);
export const createVbImageSuccess = data => doResponseAction(CREATE_VB_IMAGE_SUCCESS, data);
export const createVbImageFailure = data => doResponseAction(CREATE_VB_IMAGE_FAILURE, data);

export const updateVbImage = (params, callback, isAdmin) => doAction(UPDATE_VB_IMAGE, params, callback, isAdmin);
export const updateVbImageSuccess = data => doResponseAction(UPDATE_VB_IMAGE_SUCCESS, data);
export const updateVbImageFailure = data => doResponseAction(UPDATE_VB_IMAGE_FAILURE, data);

export const vbImageDetails = (params, callback, isAdmin) => doAction(VB_IMAGE_DETAILS, params, callback, isAdmin);
export const vbImageDetailsSuccess = data => doResponseAction(VB_IMAGE_DETAILS_SUCCESS, data);
export const vbImageDetailsFailure = data => doResponseAction(VB_IMAGE_DETAILS_FAILURE, data);

export const getAgentVbImageList = (params, callback, isAdmin) => doAction(GET_AGENT_VB_IMAGE_LIST, params, callback, isAdmin);
export const getAgentVbImageListSuccess = data => doResponseAction(GET_AGENT_VB_IMAGE_LIST_SUCCESS, data);
export const getAgentVbImageListFailure = data => doResponseAction(GET_AGENT_VB_IMAGE_LIST_FAILURE, data);
