import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import RoutePath from '../utils/routes/RoutePath';
import { toast } from 'react-toastify';
import Constants from '../utils/Constants';
import AddVendorRoom from '../component/AddVendorRoom'
import { accountNumberOnly, isCharacterReg } from '../utils/validation';
import AppLoader from '../utils/AppLoader';

class VendorRoomAdd extends Component {
    constructor(props) {
        super(props)
        this.state = {
            roomName: '',
            roomId: '',
            id: '',
            error: {},
            message: {}
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({ isLoading: true })
            this.props?.getVendorRoomDetails({ id: this.props.match.params.id }, (response) => {
                const data = response || {}
                this.setState({
                    roomId: data?.response?.patientRoomId,
                    roomName: data?.response?.patientRoomName,
                    isLoading: false,
                })
            }, false)
        }
    }

    addVendorRoom = () => {
        const callback = (res) => {
            this.setState({ isLoading: false })
            const { response } = res
            if (res?.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.props.history.push(RoutePath.VENDOR_ROOM)
            } else {
                toast.error(response?.data?.response_message)
            }
        }
        let params = {
            patientRoomName: this.state?.roomName,
            patientRoomId: this.state?.roomId

        };
        if (this.props.match.params?.id) {
            params.id = this.props.match.params.id
        }

        if (!params?.patientRoomId || params?.patientRoomId.length === 0) {
            this.setState({ error: { roomId: true }, message: { roomId: "Please Enter Room Number" } })
        }
        else if (!accountNumberOnly(params?.patientRoomId)) {
            this.setState({ error: { roomId: true }, message: { roomId: Constants.ONLY_NUMBER_ACCEPT } })
        }
        else if (!params?.patientRoomName || params?.patientRoomName.trim().length === 0) {
            this.setState({ error: { roomName: true }, message: { roomName: "Please Enter Room Name" } })
        }
        else if (!isCharacterReg(params?.patientRoomName)) {
            this.setState({ error: { roomName: true }, message: { roomName: Constants.NAME_REGX } })
        }
        else {
            this.props.addVendorRoom(params, callback)
        }
    }

    handleVendorRoomName = (e) => {
        let vendorRoom = e.target.value;
        if (!vendorRoom || vendorRoom.length === 0) {
            this.setState({ roomName: vendorRoom, error: { roomName: true }, message: { roomName: "Please Enter Room Name" } })
        }
        else {
            this.setState({ roomName: vendorRoom, error: { roomName: false }, message: { roomName: ' ' } })
        }
    }

    handleVendorRoomId = (e) => {
        let vendorRoomId = e.target.value;
        if (!vendorRoomId || vendorRoomId.length === 0) {
            this.setState({ roomId: vendorRoomId, error: { roomId: true }, message: { roomId: "Please Enter Room Id" } })
        }
        else {
            this.setState({ roomId: vendorRoomId, error: { roomId: false }, message: { roomId: ' ' } })
        }
    }

    render() {
        const { isLoading } = this.state;
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <AddVendorRoom
                            value={this.state}
                            isEdit={this.props.match.params.id ? true : false}
                            handleVendorRoomName={(e) => this.handleVendorRoomName(e)}
                            handleVendorRoomId={(e) => this.handleVendorRoomId(e)}
                            handleSubmit={() => this.addVendorRoom()}
                            message={this.state.message}
                            isClick={this.state.isClick}
                            error={this.state.error} />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
    }
};
export default withRouter(connect(mapStateToProps, {
    addVendorRoom: Actions.addVendorRoom,
    getVendorRoomDetails: Actions.getVendorRoomDetails
})(VendorRoomAdd));