import { doAction, doResponseAction } from ".";

const CUSTOMER_DECLINE = "CUSTOMER_DECLINE";
const CUSTOMER_DECLINE_SUCCESS = "CUSTOMER_DECLINE_SUCCESS";
const CUSTOMER_DECLINE_FAILURE = "CUSTOMER_DECLINE_FAILURE";

export { CUSTOMER_DECLINE, CUSTOMER_DECLINE_SUCCESS, CUSTOMER_DECLINE_FAILURE };

export const customerDeclineAction = (params, callback) => doAction(CUSTOMER_DECLINE, params, callback);
export const customerDeclineSuccess = (data) => doResponseAction(CUSTOMER_DECLINE_SUCCESS, data);
export const customerDeclineFailure = (data) => doResponseAction(CUSTOMER_DECLINE_FAILURE, data);
