import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../redux/actions";
import Constants, { LIMIT } from "../utils/Constants";
import RoutePath from '../utils/routes/RoutePath'
import NoDataFound from "./NoDataFound";
class VendorListScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    selectedListHandler = (id) => {
        this.props.history.push(RoutePath.VIEW_ADMIN_VENDOR)
        this.props.selectVendorList(id)
    }
    render() {
        const { vendors = [], updateActive } = this.props
        return (
            <div className="admin_table">
                <div role="region" aria-label="data table" tabIndex="0" className="candidate_primary">
                    <table className="dashboard_table">
                        <thead>
                            <tr>
                                <th className="pin">{Constants.ID}</th>
                                <th>{Constants.NAME}</th>
                                <th>{Constants.COMPANY_NAME}</th>
                                <th>{Constants.AGENT_COUNT}</th>
                                <th>{Constants.AGENT_TYPE}</th>
                                <th>{Constants.PAYMENT_STATUS}</th>
                                <th>{Constants.ACTION}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vendors?.length > 0 ?
                                vendors?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="pin">{((this.props.currentPage - 1) * LIMIT) + (index + 1)}</td>
                                            <td >{item?.fullName.replace("_", " ")}</td>
                                            <td>{item?.Vendor?.companyName}</td>
                                            <td>
                                                {item?.Vendor?.VendorAgents?.length}
                                            </td>
                                            <td>
                                                {
                                                    item?.Vendor?.agentTypeId === 1 ? Constants.SHARED : Constants.OWNED
                                                }
                                            </td>
                                            <td>{
                                                <p className={`status_text ${item?.Vendor?.paymentStatus === 0 ? "pending_status" : "accepted_status"}`}>{item?.Vendor?.paymentStatus === 0 ? "Pending" : "Done"}</p>
                                            }</td>
                                            <td >
                                                <i className="fa-solid fa-pen-to-square edit-icon ms-2 updateIcon" onClick={() => {
                                                    this.props.history.push(RoutePath.ADMIN_VENDOR_UPDATE_ + item?.id)
                                                }} />
                                                <span onClick={() => updateActive(item.isActive && item.isActive === 1 ? 0 : 1, item)}>
                                                    <i style={{ fontSize: '20px' }} className={item?.isActive && item?.isActive === 1 ? "fa-solid fa-toggle-on edit-icon toggle-icon ms-2 activeIcon" : "fa-solid fa-toggle-off edit-icon toggle-icon ms-2 activeIcon"} />
                                                </span>
                                                <i className="fa-solid edit-icon fa-eye ms-2 viewIcon" onClick={() => this.selectedListHandler(item?.id)}></i>
                                            </td>
                                        </tr>
                                    )
                                })
                                : <NoDataFound />
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
export const mapStateToProps = (state) => {
    return {

    }
};
export default withRouter(connect(mapStateToProps, {
    selectVendorList: Actions.selectVendorListById
})(VendorListScreen));
