import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import RoutePath from '../utils/routes/RoutePath';
import { toast } from 'react-toastify';
import Constants from '../utils/Constants';
import { convertToMilliSeconds, isCharacterReg, minutesToHours, minutesToSec, msToHMS } from '../utils/validation';
import AddAddOn from '../component/AddAddOn';
import AddARProduct from '../component/AddARProduct';
import { convertToBase64 } from '../utils/ConvertBase64';
const onlyNumberRegex = new RegExp(`^[0-9]+$`);

class AddVendorARProducts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productName: '',
            productSku: '',
            pageURL: '',
            productGLB: '',
            base64file: '',
            isShoe: "",
            error: {},
            message: {},
            isActive: false,
            isClick: false,
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({ isLoading: true })
            this.props?.prodcutDetails({ id: this.props.match.params.id }, (response) => {
                const data = response || {}
                this.setState({
                    isActive: data?.response?.isActive,
                    productName: data?.response?.productName,
                    productSku: data?.response?.productSku,
                    pageURL: data?.response?.pageURL,
                    base64file: data?.response?.productGLB,
                    productGLB: data?.response?.productGLB,
                    isShoe: data?.response?.isShoe,
                    isLoading: false
                })
            }, false)
        }
    }
    updateProduct = () => {
        const callback = (res) => {
            this.setState({ isLoading: false })
            const { response } = res
            if (res?.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.props.history.push(RoutePath.VENDOR_AR_PRODUCTS)
            } else {
                toast.error(response?.data?.response_message)
            }
        }
        let params = {
            productName: this.state?.productName,
            productSku: this.state?.productSku,
            pageURL: this.state?.pageURL,
            isShoe: this.state?.isShoe,
            productGLBfile: this.state?.productGLB,
            id: this.props.match.params.id,
        }
        if (!params?.productSku || params?.productSku.length === 0) {
            this.setState({ error: { productSku: true }, message: { productSku: "Please Enter Product SKU" } })
        } else if (!onlyNumberRegex.test(params?.productSku)) {
            this.setState({ error: { productSku: true }, message: { productSku: "Special Characters & Alphabet are not allowed" } })
        } else if (!params?.productName || params?.productName.length === 0) {
            this.setState({ error: { productName: true }, message: { productName: Constants.PLEASE_ENTER_THE_NAME } })
        } else if (!isCharacterReg(params?.productName)) {
            this.setState({ error: { productName: true }, message: { productName: Constants.NAME_REGX } })
        } else if (params?.productName.trim().length < 3) {
            this.setState({ error: { productName: true }, message: { productName: Constants.NAME_LENGTH } })
        } else if (!params?.pageURL || params?.pageURL.length === 0) {
            this.setState({ error: { pageURL: true }, message: { pageURL: "Please Enter Page URL" } })
        } else if (params.isShoe !== 0 && !params?.isShoe) {
            this.setState({ error: { isShoe: true }, message: { isShoe: "Please Select product type." } })
        } else if (!params?.productGLBfile || params?.productGLBfile.length === 0) {
            this.setState({ error: { productGLB: true }, message: { productGLB: "Please Upload .glb file." } })
        } else {
            params.productGLBfile = this.state.isClick ? this.state?.productGLB : null
            let formData = new FormData()
            formData.append('productSku', params?.productSku)
            formData.append('productName', params?.productName)
            formData.append('pageURL', params?.pageURL)
            formData.append('isShoe', params?.isShoe)
            formData.append('productGLBfile', params?.productGLBfile)
            if (params?.id) {
                formData.append('id', params?.id)
            }
            if (params.id) {
                this.setState({ isLoading: true })
                this.props.updateProduct(formData, callback, false)
            } else {
                this.setState({ isLoading: true })
                this.props.addProduct(formData, (res) => {
                    this.setState({ isLoading: false })
                    const { response } = res
                    if (res.response_code === 0) {
                        toast.success(Constants.ADDED_SUCCESSFULLY)
                        this.props.history.push(RoutePath.VENDOR_AR_PRODUCTS)
                    } else {
                        toast.error(response?.data?.response_message)
                    }
                }, false)
            }
        }
    }
    removeProducyImage = () => {
        if (this.props.match.params.id) {
            this.setState({ isLoading: true })
            let params = {
                id: this.props.match.params.id,
                removeS3: true
            }
            this.props.updateProduct(params, (res) => {
                const { response } = res
                this.setState({ isLoading: false })
                if (res.response_code === 0) {
                    toast.success(Constants.DELETED_SUCCESSFULLY)
                    this.componentDidMount()
                    document.getElementById('exampleFormControlFile2').value = "";
                } else {
                    toast.error(response?.data?.response_message)
                }
            }, false)
        } else {
            this.setState({ productGLB: "", base64file: "" })
            document.getElementById('exampleFormControlFile2').value = "";
        }
    }
    handleProductName = (e) => {
        let productName = e.target.value;
        if (!productName || productName.length === 0) {
            this.setState({ productName: productName, error: { productName: true }, message: { productName: Constants.PLEASE_ENTER_THE_NAME } })
        } else if (!isCharacterReg(productName)) {
            this.setState({ productName: productName, error: { productName: true }, message: { productName: Constants.NAME_REGX } })
        } else if (productName.trim().length < 3) {
            this.setState({ productName: productName, error: { productName: true }, message: { productName: Constants.NAME_LENGTH } })
        } else {
            this.setState({ productName: productName, error: { productName: false }, message: { productName: ' ' } })
        }
        this.setState({ productName: productName })
    }
    handleProductSKU = (e) => {
        let productSku = e.target.value;
        if (!productSku || productSku.length === 0) {
            this.setState({ productSku: productSku, error: { productSku: true }, message: { productSku: "Please Enter Product SKU" } })
        } else if (!onlyNumberRegex.test(productSku)) {
            this.setState({ productSku: productSku, error: { productSku: true }, message: { productSku: "Special Characters & Alphabet are not allowed" } })
        } else {
            this.setState({ productSku: productSku, error: { productSku: false }, message: { productSku: ' ' } })
        }
        this.setState({ productSku: productSku })
    }
    handlePageURL = (e) => {
        let pageURL = e.target.value;
        if (!pageURL || pageURL.length === 0) {
            this.setState({ pageURL: pageURL, error: { pageURL: true }, message: { pageURL: "Please Enter Page URL" } })
        } else {
            this.setState({ pageURL: pageURL, error: { pageURL: false }, message: { pageURL: ' ' } })
        }
        this.setState({ pageURL: pageURL })
    }
    handleIsShoe = (e) => {
        let isShoe = e.target.value;
        if (!isShoe || isShoe.length === 0) {
            this.setState({ isShoe: isShoe, error: { isShoe: true }, message: { isShoe: "Please select product type." } })
        } else {
            this.setState({ isShoe: isShoe, error: { isShoe: false }, message: { isShoe: ' ' } })
        }
        this.setState({ isShoe: isShoe })
    }
    uploadProductglb = async (e) => {
        let productGLB = e.target.value;
        if (!productGLB || productGLB.length === 0) {
            this.setState({ productGLB: productGLB, error: { productGLB: true }, message: { productGLB: "Please Upload .glb file." } })
        } else {
            this.setState({ error: { productGLB: false }, message: { productGLB: ' ' } })
        }
        let file = e.target.files[0];
        if (typeof file !== "undefined") {
            this.setState({ isLoading: true })
            let imageBase64 = await convertToBase64(file)
            this.setState({ productGLB: file, isClick: true, base64file: imageBase64, isLoading: false })
        } else {
            this.setState({ isClick: false })
        }
    }
    render() {
        const { isLoading } = this.state
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <AddARProduct
                            value={this.state}
                            handleProductName={(e) => { this.handleProductName(e) }}
                            handleProductSKU={(e) => this.handleProductSKU(e)}
                            handlePageURL={(e) => { this.handlePageURL(e) }}
                            uploadProductglb={(e) => { this.uploadProductglb(e) }}
                            isShoe={(e) => { this.handleIsShoe(e) }}
                            handleSubmit={() => this.updateProduct()}
                            removeProducyImage={() => this.removeProducyImage()}
                            message={this.state.message}
                            error={this.state.error}
                            isEdit={this.props.match.params.id}
                        />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {

    }
};
export default withRouter(connect(mapStateToProps, {
    addProduct: Actions.createProduct,
    updateProduct: Actions.updateProduct,
    prodcutDetails: Actions.productDetails
})(AddVendorARProducts));