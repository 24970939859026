import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import Actions from '../redux/actions/index';
import ReactStars from "react-rating-stars-component";
import Constants from '../utils/Constants';
import AppLoader from '../utils/AppLoader';
class Feedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            feedBack: '',
            customerRating: 0,
            encryptId: '',
            srm_ip_room: null
        }
    }
    componentDidMount() {
        const { getcutomerList } = this.props
        let encryptId = localStorage.getItem("vendorId")
        let srm_ip_room = localStorage.getItem("srm_ip_room")
        this.setState({ encryptId: encryptId, srm_ip_room: srm_ip_room })
    }
    submitHandler = () => {
        const { feedBack, customerRating, srm_ip_room } = this.state;
        const customerRoomId = this.props.getCustomerToken;
        let params = {
            roomId: customerRoomId,
            ratings: customerRating,
            feedback: feedBack,
            srm_ip_room: customerRoomId
        }
        const feedBackCallBack = (response) => {
            let navigation = srm_ip_room ? `/SRM_IP/custom/${srm_ip_room}` : `/custom/${this.state.encryptId}`
            this.props.history.push(navigation)
        }

        this.props.feedBack(params, feedBackCallBack)

    }
    ratingChanged = (newRating) => {
        this.setState({ customerRating: newRating })
    };
    render() {
        const { srm_ip_room } = this.state
        const { isLoading } = this.props.feedBackLoader
        let navigation = srm_ip_room ? `/SRM_IP/custom/${srm_ip_room}` : `/custom/${this.state.encryptId}`
        return (
            <div className='feedback-form'>
                {isLoading && <AppLoader />}
                <div className='feed-form-inputs'>
                    <div className="skipDiv">
                        <Link to={navigation} className="skip">
                            {Constants.SKIP}
                        </Link>
                    </div>
                    <h1 className='feed-head'>{Constants.FEEDBACK}</h1>
                    <p className='feed-para'>
                        {Constants.FEEDBACK_HEADERS}
                    </p>
                    <h4 className='rate-text'>{Constants.RATE_YOUR_EXPERIENCE}</h4>
                    <div className='rating-stars'>
                        <ReactStars
                            count={5}
                            onChange={this.ratingChanged}
                            size={50}
                            isHalf={true}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#ffd700"
                        />
                    </div>
                    <p className='commentsHeader'>{Constants.COMMMENT_OPTIONAL}</p>
                    <div className='d-grid gap-4 mt-4'>
                        <textarea className='form-control textarea-width' placeholder={Constants.YOUR_COMMENTS} onChange={(e) => this.setState({ feedBack: e.target.value })}></textarea>
                        <button className='btn send-btn' type='button' onClick={() => this.submitHandler()}>{Constants.SEND_FEEDBACK}</button>
                    </div>
                </div>
            </div>
        )
    }
}
export const mapStateToProps = (state) => {
    return {
        getCustomerToken: state?.CUSTOMER_LIST?.customerList?.response?.roomId,
        feedBackLoader: state?.FEEDBACK,
        getcutomerList: Actions.getcutomerList(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    feedBack: Actions.feedBackAction
})(Feedback))