import { doAction, doResponseAction } from ".";

const SCREEN_SHARE = "SCREEN_SHARE";
const SCREEN_SHARE_SUCCESS = "SCREEN_SHARE_SUCCESS";
const SCREEN_SHARE_FAILURE = "SCREEN_SHARE_FAILURE";

const UPDATE_SCREEN_SHARE = "UPDATE_SCREEN_SHARE";
const UPDATE_SCREEN_SHARE_SUCCESS = "UPDATE_SCREEN_SHARE_SUCCESS";
const UPDATE_SCREEN_SHARE_FAILURE = "UPDATE_SCREEN_SHARE_FAILURE";

export {
  SCREEN_SHARE,
  SCREEN_SHARE_SUCCESS,
  SCREEN_SHARE_FAILURE,
  UPDATE_SCREEN_SHARE_SUCCESS,
  UPDATE_SCREEN_SHARE,
  UPDATE_SCREEN_SHARE_FAILURE
};

export const screenShareAction = (params, callback) => doAction(SCREEN_SHARE, params, callback);
export const screenShareSuccessAction = (data) => doResponseAction(SCREEN_SHARE_SUCCESS, data);
export const screenShareFailureAction = (data) => doResponseAction(SCREEN_SHARE_FAILURE, data);

export const updateScreenShareAction = (params, callback) => doAction(UPDATE_SCREEN_SHARE, params, callback);
export const updateScreenShareSuccessAction = (data) => doResponseAction(UPDATE_SCREEN_SHARE_SUCCESS, data);
export const updateScreenShareFailureAction = (data) => doResponseAction(UPDATE_SCREEN_SHARE_FAILURE, data);
