import Actions from "../actions";

const initialState = {
    isLoading: false,
    error: false,
    agentList: {},
    getDetails: {},
    addAgentDetails: {},
    vendorAgentList: {},
    vendorGetDetails: {},
    vendorAddAgentDetails: {},
    adminAgentDetails: {},
    agentSelectedId:{}
};

export const agentDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.AGENT_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.AGENT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                agentList: !action.data.response?.isVendor ? (!!action.data ? action.data.response : {}) : (!!action.data ? state.agentList : {}),
                vendorAgentList: action.data.response?.isVendor ? (!!action.data ? action.data.response : {}) : (!!action.data ? state.vendorAgentList : {}),
                error: false,
            };
        }
        case Actions.AGENT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.ADD_AGENT_DETAILS: {
            return {
                ...state,
                addAgentDetails: !action.data?.isVendor ? (!!action.data ? action.data : {}) : (!!action.data ? state.agentList : {}),
                vendorAddAgentDetails: action.data?.isVendor ? (!!action.data ? action.data : {}) : (!!action.data ? state.vendorAddAgentDetails : {})
            };
        }
        case Actions.ADD_AGENT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ADD_AGENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false,
            };
        }
        case Actions.ADD_AGENT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.UPDATE_AGENT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UPDATE_AGENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                // agentList:action.data,
                error: false,
            };
        }
        case Actions.UPDATE_AGENT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.GET_AGENT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_AGENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                getDetails: !action.data.response?.isVendor ? (!!action.data ? action.data.response : {}) : (!!action.data ? state.getDetails : {}),
                vendorGetDetails: action.data.response?.isVendor ? (!!action.data ? action.data.response : {}) : (!!action.data ? state.vendorGetDetails : {}),
                error: false,
            };
        }
        case Actions.GET_AGENT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.DELETE_AGENT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DELETE_AGENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false,
            };
        }
        case Actions.DELETE_AGENT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.ADMIN_AGENT_DETAILS: {
            return {
                ...state,
                isLoading:true
            }
        }
        case Actions.ADMIN_AGENT_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                adminAgentDetails:action.data
            }
        }
        case Actions.ADMIN_AGENT_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error:true,
                adminAgentDetails: action.error
            }
        }
        case Actions.AGENT_GET_SELECTED_BY_ID: {
            return {
                ...state,
                agentSelectedId:action.params
            }
        }
        default: {
            return state
        }
    }
};

export const agentDetailsLoader=(state)=>state?.AGENR_DETAILS?.isLoading;

