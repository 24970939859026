import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import VendorListScreen from '../component/VendorListScreen';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import RoutePath from '../utils/routes/RoutePath';
import PaginationComponenent from '../component/Pagination';
import Constants, { LIMIT } from '../utils/Constants';
import { toast } from 'react-toastify';
import NoDataFound from '../component/NoDataFound';

class VendorList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageNumber: 1,
            totalItem: ''
        }
    }
    componentDidMount() {
        this.getVendorList()
    }

    getVendorList = (isUpdate) => {
        let params = {
            limit: LIMIT,
            pageNumber: isUpdate ? this.state.pageNumber - 1 : 0
        }
        const callback = (response) => {
            if (response) {
                this.setState({ totalItem: response.response.totalItems })
            }
        }
        this.props.vendorList(params, callback)
    }

    updateActive = (e, obj) => {
        const callback = (response) => {
            if (response.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.getVendorList(true)
            }
            else {
                toast.error(response.response_message)
            }
        }
        let params = {
            id: obj.id,
            isActive: e
        }
        this.props.updateVendor(params, callback, (this.props.location.pathname === RoutePath.ADMIN_VENDOR_LIST))
    }
    onChangePagination = (pageNumber) => {
        let params = {
            pageNumber: (pageNumber - 1),
            limit: LIMIT
        }
        this.props.vendorList(params, '', "")
        this.setState({ pageNumber: pageNumber })
    }
    render() {
        const { pageNumber } = this.state
        const { getVendorList = {} } = this.props
        const { vendorList = {}, isLoading } = getVendorList
        const { vendors = [], totalItems = 0 } = vendorList;
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <div className='addButton'>
                            <div className="add-btn">
                                <button className="btn add-item" onClick={() => this.props.history.push(RoutePath.ADD_ADMIN_VENDOR)} type="button"><i className="fa-solid fa-plus me-3"></i>{Constants.ADD_NEW_VENDOR}</button>
                            </div>
                        </div>
                        <VendorListScreen
                            vendors={vendors}
                            updateActive={(value, index) => this.updateActive(value, index)}
                            currentPage={pageNumber}
                        />
                        <div className='page-content'>
                            {
                                totalItems > (LIMIT - 1) && <PaginationComponenent currentPage={pageNumber} countValue={totalItems} onChangeHandle={this.onChangePagination} />
                            }
                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}

            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getVendorList: Actions.getCustomerCallList(state)
    }
};

export default withRouter(connect(mapStateToProps, {
    vendorList: Actions.vendorListAction,
    updateVendor: Actions.updateVendorAction,
})(VendorList))