import Actions from "../actions";

const initialState = {
    isLoading: false,
    verificationData: {},
    vbImageData:{},
    error: false,
};

export const AgeVerificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.AGE_VERIFICATION: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.AGE_VERIFICATION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                verificationData: action.data?.isVendor ? action.data : state.verificationData,
            };
        }
        case Actions.AGE_VERIFICATION_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }

        //Virtual Background
        case Actions.VBIMAGE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.VBIMAGE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vbImageData: action.data?.isVendor ? action.data : state.vbImageData,
            };
        }
        case Actions.VBIMAGE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        default: {
            return state
        }
    }
};
