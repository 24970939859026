import { doResponseAction } from ".";



const PROFILE = "PROFILE";
const PROFILE_SUCCESS = "PROFILE_SUCCESS";
const PROFILE_FAILURE = "PROFILE_FAILURE";

export {
    PROFILE,
    PROFILE_SUCCESS,
    PROFILE_FAILURE,
};
export const getProfileDetails = store => store["VENDOR_PROFILE"]

export const getProfileAction = (params, callback, isAdmin) => {
    return {
        type: PROFILE,
        params,
        callback,
        isAdmin
    }
}
export const getProfileSuccessAction = (data) => doResponseAction(PROFILE_SUCCESS, data);
export const getProfileFailureAction = (data) => doResponseAction(PROFILE_FAILURE, data);

