import React from 'react';
import { withRouter } from 'react-router-dom';


class PageNotFound extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="access-content">
                <div className="access-denied" style={{}}>
                    <img src={require('../assets/img/empty.png')} className="access-denied-img" alt='image-not-found' />
                    <div className='m-3 p-2'>
                        <h5>404 Page Not Found</h5>
                        <p className="access-text">The page you are looking for can't be found.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PageNotFound)