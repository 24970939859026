import React from 'react';
import { withRouter } from 'react-router-dom';
import Constants from '../utils/Constants';
import NoDataFound from './NoDataFound';
import PlaceHolderImage from '../assets/img/no_image.png'
class ViewVBImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }
    render() {
        const { data } = this.props;
        return (
            <div className="content-part px-5 py-4 list">
                <div className="agent-details">
                    <div className='row'>
                        <div className="col-md-8">
                            <div className='row'>
                                <div className='col-6 text-bold'>
                                    Virtual Background Image Name
                                </div>
                                <div className='col-6'>
                                    <p>{data && data.imageName ? data.imageName : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-bold'>
                                    Virtual Background Image
                                </div>
                                <div className='col-6'>
                                    <img style={{ width: "90px", height: "60px", marginBottom: '4px' }} alt="Product" src={data && data?.imageURL ? data?.imageURL : (PlaceHolderImage)} />
                                </div>
                            </div>
                        </div>
                        {!data && <NoDataFound />}
                    </div>
                    <div className=" mt-5 me-5 pb-3 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.goBack()} >{Constants.BACK}</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(ViewVBImage);