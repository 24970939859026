import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Constants, { LIMIT } from "../utils/Constants";
import NoDataFound from "./NoDataFound";
import { connect } from "react-redux";
import Actions from "../redux/actions";
class VendorRoomList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomId: '',
            isLoading: false,
            stylesLoaded: false
        }
    }

    render() {
        const { vendorRoomList, updateStatus, isVendor, viewRoom, editRoom } = this.props
        return (
            <React.Fragment>
                <div className="admin_table">
                    <div role="region" aria-label="data table" tabIndex="0" className="candidate_primary">
                        <table className="dashboard_table">
                            <thead>
                                <tr>
                                    <th className="pin">{Constants.ID}</th>
                                    <th>Room Number</th>
                                    <th>Room Name</th>
                                    <th>{Constants.ACTION}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vendorRoomList?.length > 0 ?
                                    vendorRoomList?.map((item, index) => {
                                        return <tr key={index}>
                                            <td>{((this.props.currentPage - 1) * LIMIT) + (index + 1)}</td>
                                            <td>{item?.patientRoomId}</td>
                                            <td>{item?.patientRoomName}</td>
                                            <td>
                                                <i className="fa-solid fa-pen-to-square updateIcon edit-icon" onClick={() => editRoom(item?.id)} />
                                                <i className={item?.isActive === 1 ? "fa-solid fa-toggle-on toggle-icon ms-2 edit-icon" : "fa-solid fa-toggle-off toggle-icon ms-2 edit-icon"} onClick={() => updateStatus(item?.id, item.isActive)} />
                                                <i className="fa-solid fa-eye ms-2 edit-icon" onClick={() => viewRoom(item?.id)}></i>
                                            </td>
                                        </tr>
                                    }) : <NoDataFound />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export const mapStateToProps = (state) => {
    return {
        getRoom: Actions.getRoom(state)
    }
};
export default withRouter(connect(mapStateToProps, {
})(VendorRoomList));