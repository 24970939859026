import { Component } from "react";
import { withRouter } from "react-router-dom";
import Constants from "../utils/Constants";
import RoutePath from "../utils/routes/RoutePath";

class AddAddOn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomId: ''
        }
    }


    render() {
        const { isEdit, error, message, handleDescriptions, handlesubscriptionPrice,handleTotalMinutes, handleUserName, handleSubmit,value } = this.props
        return (
            <div className="content-part px-5 py-4">
                <div className="agent-details">
                    <div className="row m-0">
                        <div className="col-md-6" >
                            <div className="mt-3">
                                <p className="text-bold">AddOn Name</p>
                                <input autoComplete="off" type="text" name='addonPlanName' maxLength="100" className="form-control w-75" value={value?.addonPlanName} placeholder="Enter AddOn Name" onChange={(e) => handleUserName(e)} />
                                {error?.addonPlanName && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.addonPlanName}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">AddOn Price</p>
                                <input autoComplete="off" type="text" maxLength="7" pattern="[+-]?\d+(?:[.,]\d+)?" name='addonPlanPrice' value={value?.addonPlanPrice}
                                    className="form-control w-75" placeholder="Enter AddOn Price"
                                    onChange={(e) => handlesubscriptionPrice(e)} />
                                {error?.addonPlanPrice && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.addonPlanPrice}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Total Stream Minutes</p>
                                <input autoComplete="off" type="text" maxLength="9" name='totalMins' value={value?.totalMins}
                                    className="form-control w-75" placeholder="Enter Total Stream Minutes"
                                    onChange={(e) => handleTotalMinutes(e)} />
                                {error?.totalMins && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.totalMins}</span>}
                            </div>
                            <div className="mt-3">
                                {error?.time && <span style={{ color: 'black', fontSize: '16px', marginTop: '10px' }}>{message.time}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Description</p>
                                <textarea type="text" name='description' placeholder="Enter Description" value={value?.description}
                                    className="form-control w-75" onChange={(e) => handleDescriptions(e)} />
                                {error?.description && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.description}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 me-5 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.push(RoutePath.ADDON_LIST)}>{Constants.BACK}</button>
                        <button type="button" className="btn input-btn mx-3" onClick={() => handleSubmit()}>{isEdit ? Constants.UPDATE : Constants.CREATE}</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(AddAddOn)
