import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import PaginationComponenent from '../component/Pagination';
import Constants, { LIMIT, THREEDSCRIPT } from '../utils/Constants';
import { toast } from 'react-toastify';
import RoutePath from '../utils/routes/RoutePath';
import ARProductsList from '../component/ARProductsList';
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import stringEnglish from '../utils/stringEnglish';
const EXCEL_EXTENSION = '.xlsx'
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

function mergeStrings(str) {
    return str.replace(/\s+/g, '')
        .replace(/(\b[a-z](?!\s)|\([^)]*\)|'[a-z])/gi, (x) => x.toUpperCase())
        .replace(/&/g, 'AND')
        .replace(/[()']/g, '');
}
class VendorFeedbackList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageNumber: 1,
            totalItem: '',
            isVendor: false,
            isCopied: false,
            error: {},
            fromDate: null,
            toDate: null,
            isLoading: false,
            errorMsg1: null,
            errorMsg2: null
        }
    }
    componentDidMount() {
    }

    onChangePagination = (pageNumber) => {
        let params = {
            pageNumber: (pageNumber - 1),
            limit: LIMIT
        }
        this.props.getProductList(params, '', false)
        this.setState({ pageNumber: pageNumber })
    }
    updateStatus = (id, active) => {
        const updateParams = {
            id,
            isActive: active ? 0 : 1
        }
    }
    downloadReportFunction = (report) => {
        let fileName = 'Report';
        let count1 = 0
        let arr = []
        let textCount = 0
        let voiceCount = 0
        let Excellent_count = 0;
        let Good_count = 0;
        let Vgood_count = 0;
        let Average_count = 0;
        let Needsimprovement_count = 0;
        let Specific_userrating = 0;
        let Total_rate = 0;
        let Average_rating = 0;
        let Not_applicable = 0;
        let obj = {}
        let data = report && report.map((o) => {
            Specific_userrating = 0;
            if (o && o.type === "text") {
                textCount = textCount + 1
            }
            if (o && o.type === "voice") {
                voiceCount = voiceCount + 1
            }
            count1 = count1 + 1
            obj = {
                S_No: count1,
                date: o.createdAt ? moment(o?.createdAt).format("DD/MM/YYYY hh:mm:ss:A") : null,
                type: o && o.type,
                fullNameAudioUrl: o && o.fullNameAudioUrl,
                feedbackAudioUrl: o && o.feedbackAudioUrl,
                fullName: o && o.fullName,
                comments: o && o.comments,
                phone: o && o.phone,
                recommend: o && o.recommend
            }
            // Extracting answers for each question
            let feedbackData = {};
            if (o && o.feedback) {
                try {
                    feedbackData = JSON.parse(o.feedback);
                } catch (error) {
                    console.error("Error parsing feedback JSON:", error);
                }
            }
            stringEnglish && stringEnglish.MESSAGE && stringEnglish.MESSAGE.FEEDBACK_QUESTIONS && stringEnglish.MESSAGE.FEEDBACK_QUESTIONS.SRM_HOSPITAL.forEach((question, index) => {
                const answer = feedbackData && feedbackData[index];
                obj[mergeStrings(question && question.question)] = answer !== undefined ? answer : null; // Set answer based on the index
            });
            let numericValues = {}
            if (o && o.language === 1) {
                numericValues = {
                    "Not applicable": 0,
                    "Needs Improvement": 1,
                    "Average": 2,
                    "Good": 3,
                    "V Good": 4,
                    "Excellent": 5,
                };
            }
            if (o && o.language === 2) {
                numericValues = {
                    "பொருந்தாது": 0,
                    "முன்னேற்றம் தேவை": 1,
                    "நடுத்தரம்": 2,
                    "நல்லது": 3,
                    "மிகவும் நல்லது": 4,
                    "சிறப்பானது": 5
                };
            }
            for (let rate_value of Object.values(feedbackData)) {
                Specific_userrating += numericValues[rate_value];
                if (rate_value === "Excellent" || rate_value === "சிறப்பானது") {
                    Excellent_count++;
                }
                else if (rate_value === "V Good" || rate_value === "மிகவும் நல்லது") {
                    Vgood_count++;
                }
                else if (rate_value === "Good" || rate_value === "நல்லது") {
                    Good_count++;
                }
                else if (rate_value === "Needs Improvement" || rate_value === "முன்னேற்றம் தேவை") {
                    Needsimprovement_count++;
                }
                else if (rate_value === "Average" || rate_value === "நடுத்தரம்") {
                    Average_count++;

                }
                else if (rate_value === "Not applicable" || rate_value === "பொருந்தாது") {
                    Not_applicable++;
                }
            }

            Total_rate += Specific_userrating;

            Average_rating += (Specific_userrating) / (Object.values(feedbackData).length);
            arr.push(obj)
        })
        this.exportAsExcelFile(arr, fileName)
    }
    exportAsExcelFile = (json, excelFileName) => {
        // Initialize object to store maximum lengths for headers and content
        const columnLengths = {};
        // Iterate through headers to initialize columnLengths with header lengths
        Object.keys(json[0]).forEach(key => {
            columnLengths[key] = key.length;
        });
        // Iterate through json to calculate maximum lengths for each column
        json.forEach(row => {
            Object.keys(row).forEach(key => {
                const columnLength = (row[key] || '').toString().length;
                columnLengths[key] = Math.max(columnLengths[key] || 0, columnLength);
            });
        });
        // Convert json to worksheet
        const worksheet = XLSX.utils.json_to_sheet(json);
        // Apply column widths based on maximum lengths
        worksheet['!cols'] = Object.keys(columnLengths).map(key => ({ wch: columnLengths[key] + 1 }));
        // Create workbook
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        // Convert workbook to Excel file buffer
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // Save Excel file
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    saveAsExcelFile = (buffer, fileName) => {
        const data = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
    }
    todayReportDownload = () => {
        let currentDate = new Date();
        let day = currentDate.getDate().toString().padStart(2, '0');
        let month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so we add 1
        let year = currentDate.getFullYear();
        let today = `${year}-${month}-${day}`
        this.fetchReportList(today, today)
    }
    fetchReportList = async (fromDate, toDate) => {
        this.setState({ isLoading: true })
        if (!fromDate) {
            this.setState({ isLoading: false, errorMsg1: "Please select the from date" })
        } else if (!toDate) {
            this.setState({ isLoading: false, errorMsg2: "Please select the to date" })
        }
        else {
            let params = {
                fromDate: fromDate,
                toDate: toDate
            }
            const callback = (res) => {
                if (res?.response_code === 0) {
                    this.setState({ isLoading: false })
                    this.downloadReportFunction(res?.response)
                    toast.success("Successfully Downloaded", {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                } else if (res?.response_code === -2) {
                    this.setState({ isLoading: false })
                    toast.error(res?.response_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                } else {
                    this.setState({ isLoading: false })
                    toast.error(res?.response_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    })
                }
            }
            this.props.downloadReport(params, callback, false)
            // await doGet(constants.API_BASE_URL + constants.GET_REPORT, params)
            //     .then(res => {
            //         if (res?.data?.response_code === 0) {
            //             setLoading(false)
            //             downloadReport(res.data?.response)
            //             toast.success(languageFile.MESSAGE.REPORT.SUCCESSFULLY_DOWNLOADED, {
            //                 position: toast.POSITION.TOP_RIGHT,
            //             })
            //         } else if (res?.data?.response_code === -2) {
            //             setLoading(false)
            //             toast.error(res?.data?.response_message, {
            //                 position: toast.POSITION.TOP_RIGHT,
            //             })
            //         } else {
            //             setLoading(false)
            //             toast.error(res?.data?.response_message, {
            //                 position: toast.POSITION.TOP_RIGHT,
            //             })
            //         }
            //     })
        }
    }
    render() {
        const { error, fromDate, toDate, isLoading, errorMsg1, errorMsg2 } = this.state;
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <div className="JS_script_card">
                            <h3 className="my-3">Report Download</h3>
                            <div className="row m-0">
                                <div className="script_cards col-12 p-0">
                                    <div className="card">
                                        <div className="fixTableHead table-responsive">
                                            <div className="sub-nv">
                                                <div className="col-sm-4">
                                                    <h6>Today's Report</h6>
                                                    <button type="button" className="btn add-item" onClick={() => { this.todayReportDownload() }}>&nbsp;Download today's report</button>
                                                </div>
                                                <hr />
                                                <div className="col-sm-4">
                                                    <h6>For Custom Report's</h6>
                                                    <div className="mb-1">
                                                        <label for="fromDate">From Date</label>
                                                        <input type="date" id="fromDate" max={moment(new Date()).format("YYYY-MM-DD")} className="form-control" placeholder="From Date" aria-label="From Date" aria-describedby="basic-addon1"
                                                            onChange={(event) => { this.setState({ fromDate: event.target.value, errorMsg1: null }) }} />
                                                        <span className="text-danger">{errorMsg1}</span>
                                                    </div>
                                                    <div className="mb-1">
                                                        <label for="toDate">To Date</label>
                                                        <input type="date" id="toDate" max={moment(new Date()).format("YYYY-MM-DD")} min={fromDate} className="form-control" placeholder="To Date" aria-label="To Date" aria-describedby="basic-addon1"
                                                            onChange={(event) => { this.setState({ toDate: event.target.value, errorMsg2: null }) }} />
                                                        <span className="text-danger">{errorMsg2}</span>
                                                    </div>
                                                    <button type="button" className="btn add-item mt-2" onClick={() => { this.fetchReportList(fromDate, toDate) }}>&nbsp;Download custom report</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='addButton'>
                            <div className="add-btn">
                                <button className="btn add-item" type="button" onClick={() => this.addProduct()}><i className="fa-solid fa-plus me-3"></i>Add Product</button>
                            </div>
                        </div>
                        <ARProductsList productList={productListData?.products} updateStatus={(id, active) => this.updateStatus(id, active)}
                            isVendor={isVendor} 
                            currentPage={pageNumber} />
                        <div className='page-content'>
                            {
                                productListData?.totalItems > (LIMIT - 1)  ? <PaginationComponenent currentPage={pageNumber} countValue={productListData?.totalItems} onChangeHandle={this.onChangePagination} /> : ''
                            }
                        </div> */}
                    </div>
                </div>

                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
    }
};
export default withRouter(connect(mapStateToProps, {
    downloadReport: Actions.downloadFeedbackAction,
})(VendorFeedbackList
));