import { doAction, doResponseAction } from ".";

const RENEW_SUBSCRIPTION = "RENEW_SUBSCRIPTION";
const RENEW_SUBSCRIPTION_SUCCESS = "RENEW_SUBSCRIPTION_SUCCESS";
const RENEW_SUBSCRIPTION_FAILURE = "RENEW_SUBSCRIPTION_FAILURE";
const ATTEND_CALL = "ATTEND_CALL";

export {
    RENEW_SUBSCRIPTION,
    RENEW_SUBSCRIPTION_SUCCESS,
    RENEW_SUBSCRIPTION_FAILURE,
    ATTEND_CALL
};

export const renewSubscriptionAction = (params, callback) => doAction(RENEW_SUBSCRIPTION, params, callback);
export const renewSubscriptionSuccessAction = (data) => doResponseAction(RENEW_SUBSCRIPTION_SUCCESS, data);
export const renewSubscriptionFailureAction = (data) => doResponseAction(RENEW_SUBSCRIPTION_FAILURE, data);
