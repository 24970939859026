import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import RoutePath from '../utils/routes/RoutePath';
import { toast } from 'react-toastify';
import Constants from '../utils/Constants';
import AddRoomService from '../component/AddRoomService';

const onlyNumberRegex = new RegExp(`^[0-9]+$`);

class AddVendorRoomService extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: {},
            message: {},
            isActive: false,
            isClick: false,
            isLoading: false,
            srmIpRoomId: null,
            srmIpServiceId: null,
            userId: null
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({ isLoading: true })
            this.props?.getVendorRoomServiceDetail({ id: this.props.match.params.id }, (response) => {
                const data = response || {}
                this.setState({
                    isActive: data?.response?.isActive,
                    srmIpRoomId: data?.response?.SrmIpRoom?.id,
                    srmIpServiceId: data?.response?.SrmIpServiceMaster?.id,
                    userId: data?.response?.User?.id,
                    isLoading: false
                })
            }, false)
        }
    }
    updateRoomService = () => {
        const callback = (res) => {
            this.setState({ isLoading: false })
            const { response } = res
            if (res?.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.props.history.push(RoutePath.VENDOR_ROOM_SERVICE)
            } else {
                toast.error(res?.response_message)
            }
        }
        let params = {
            id: this.props.match.params.id,
            srmIpRoomId: this.state?.srmIpRoomId,
            srmIpServiceId: this.state?.srmIpServiceId,
            userId: this.state?.userId

        }
        if (params?.srmIpRoomId == null || params?.srmIpRoomId == 'ALL') {
            this.setState({ error: { srmIpRoomId: true }, message: { srmIpRoomId: "Please Select the Room" } })
        } else if (params?.srmIpServiceId == null || params?.srmIpServiceId == 'ALL') {
            this.setState({ error: { srmIpServiceId: true }, message: { srmIpServiceId: "Please Select the Service" } })
        } else if (params?.userId == null || params?.userId == 'ALL') {
            this.setState({ error: { userId: true }, message: { userId: "Please Select the Agent" } })
        } else {
            if (params.id) {
                console.log("params", params);
                this.setState({ isLoading: true })
                this.props.updateVendorServiceService(params, callback, false)
            } else {
                this.setState({ isLoading: true })
                this.props.createVendorRoomService(params, (res) => {
                    this.setState({ isLoading: false })
                    if (res.response_code === 0) {
                        toast.success(Constants.ADDED_SUCCESSFULLY)
                        this.props.history.push(RoutePath.VENDOR_ROOM_SERVICE)
                    } else {
                        toast.error(res?.response_message)
                    }
                }, false)
            }
        }
    }

    handleRoomName = (e) => {
        let srmIpRoomId = e.target.value;
        if (!srmIpRoomId || srmIpRoomId == 'ALL') {
            this.setState({ srmIpRoomId: srmIpRoomId, error: { srmIpRoomId: true }, message: { srmIpRoomId: 'Please Select the Room' } })
        } else {
            this.setState({ srmIpRoomId: srmIpRoomId, error: { srmIpRoomId: false }, message: { srmIpRoomId: ' ' } })
        }
        this.setState({ srmIpRoomId: srmIpRoomId })
    }

    handleServiceName = (e) => {
        let srmIpServiceId = e.target.value;
        if (!srmIpServiceId || srmIpServiceId == 'ALL') {
            this.setState({ srmIpServiceId: srmIpServiceId, error: { srmIpServiceId: true }, message: { srmIpServiceId: 'Please Select the Service' } })
        } else {
            this.setState({ srmIpServiceId: srmIpServiceId, error: { srmIpServiceId: false }, message: { srmIpServiceId: ' ' } })
        }
        this.setState({ srmIpServiceId: srmIpServiceId })
    }

    handleAgentName = (e) => {
        let userId = e.target.value;
        if (!userId) {
            this.setState({ userId: userId, error: { userId: true }, message: { userId: 'Please Select the Agent' } })
        } else {
            this.setState({ userId: userId, error: { userId: false }, message: { userId: ' ' } })
        }
        this.setState({ userId: userId })
    }



    render() {
        const { isLoading } = this.state
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <AddRoomService
                            value={this.state}
                            handleSubmit={() => this.updateRoomService()}
                            handleRoomName={(e) => { this.handleRoomName(e) }}
                            handleServiceName={(e) => { this.handleServiceName(e) }}
                            handleAgentName={(e) => { this.handleAgentName(e) }}
                            message={this.state.message}
                            isClick={this.state.isClick}
                            error={this.state.error}
                            isEdit={this.props.match.params.id}
                        />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {

    }
};
export default withRouter(connect(mapStateToProps, {
    createVendorRoomService: Actions.createVendorRoomService,
    updateVendorServiceService: Actions.updateVendorRoomService,
    getVendorRoomServiceDetail: Actions.getVendorRoomServiceDetail
})(AddVendorRoomService));