export const isValidEmail = (email) => {
    const validEmailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return validEmailRegex.test(email);
}
export const isValidMobile = (field) => {
    var phoneNo = /^\d{10}$/;
    return phoneNo.test(field)
}

export const isValidOtp = (field) => {
    var otp = /^\d{1}$/;
    return otp.test(field)
}
export const isUserName = (field) => {
    var name = /^[a-zA-Z ]+$/;
    return name.test(field)
}


// export const isUserName = (name) => {
//     var name = /^(?=^[^_]+_?[^_]+$)\w{3,20}$/;
//     return name.test(name)
// }

export const isValidPassword = (field) => {
    var pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
    return pass.test(field)
}

export const isValidMobileNumber = (field) => {
    var number = /^\d{10}$/
    return number.test(field)
}
export const isCharacterReg = (name) => {
    const onlyCharactersRegex = new RegExp(`^[a-zA-Z -]+$`);
    return onlyCharactersRegex.test(name)
}

export const isOnlyCharacterReg = (name) => {
    const onlyCharactersRegex = new RegExp(`^[a-zA-Z0-9]+$`);
    return onlyCharactersRegex.test(name)
}

export const accountNumberOnly = (name) => {
    const accountonlyCharactersRegex = new RegExp(`^[0-9-]+$`);
    return accountonlyCharactersRegex.test(name)
}

export const timeConvert = (n) => {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    var seconds = (minutes - rminutes) * 10
    var rseconds = Math.round(seconds)
    return rhours + " hour(s) and " + rminutes + " minute(s) " + rseconds + " seconds";
}

export const secondsToTime = (e) => {
    const h = Math.floor(e / 3600).toString().padStart(2, '0'),
        m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
        s = Math.floor(e % 60).toString().padStart(2, '0');

    return h + " hours and " + m + " minutes " + s + " seconds";
}

export const convertToSeconds = (minutes) => {
    return minutes * 60;
}

export const convertToMilliSeconds = (minutes) => {
    return minutes * 60000;
}

export const msToHMS = (ms) => {
    // 1- Convert to seconds:
    var seconds = ms / 1000;
    // 2- Extract hours:
    var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;
    return hours.toFixed(0) + " hours and " + minutes.toFixed(0) + " minutes and " + seconds.toFixed(0) + " seconds";
}
export const msToHMSShort = (ms) => {
    // 1- Convert to seconds:
    var seconds = ms / 1000;
    // 2- Extract hours:
    var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;
    return hours.toFixed(0) + "h " + minutes.toFixed(0) + "m " + seconds.toFixed(0) + "s";
}
export const minutesToHours = (e) => {
    return Math.floor(e / 3600).toString().padStart(2, '0')
}
export const minutesToSec = (e) => {
    return Math.floor(e % 3600 / 60).toString().padStart(2, '0')
}