import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import PaginationComponenent from '../component/Pagination';
import Constants, { LIMIT } from '../utils/Constants';
import { toast } from 'react-toastify';
import RoutePath from '../utils/routes/RoutePath';
import VendorRoomList from '../component/VendorRoomList';

class VendorRoom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageNumber: 1,
            totalItem: ''
        }
    }
    componentDidMount() {
        this.getRoomList()
    }

    getRoomList = (currentPage) => {
        let params = {
            limit: LIMIT,
            pageNumber: currentPage > 0 ? currentPage-1 : 0
        }
        const callback = (response) => {
            if (response) {
                this.setState({ totalItem: response.response.totalItems })
            }
        }
        this.props.getRoomList(params, callback)
    }
    onChangePagination = (pageNumber) => {
        let params = {
            pageNumber: (pageNumber - 1),
            limit: LIMIT
        }
        this.setState({ pageNumber: pageNumber });
        this.props.getRoomList(params, '')
    }
    updateVendorRoom = (id, active) => {
        const updateParams = {
            id,
            isActive: active ? 0 : 1
        }
        const callback = (response) => {
            if (response.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.getRoomList(this.state.pageNumber)
            }
            else {
                toast.error(response.response_message)
            }
        }
        this.props.updateVendorRoom(updateParams, callback)
    }
    addProduct = () => {
        this.props.history.push(RoutePath.VENDOR_ROOM_ADD)
    }

    viewRoom = (id) => {
            this.props.history.push("/vendor/room/view/" + id)
    }

    editRoom = (id) => {
        this.props.history.push("/vendor/room/edit/" + id);
    }

    render() {
        const { pageNumber, isVendor } = this.state;
        const { getRoom = {} } = this.props
        const { isLoading, vendorRoomListData } = getRoom
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <div className='addButton'>
                            <div className="add-btn">
                                <button className="btn add-item" type="button" onClick={() => this.addProduct()}><i className="fa-solid fa-plus me-3"></i>Add Room</button>
                            </div>
                        </div>
                        <VendorRoomList vendorRoomList={vendorRoomListData?.rooms} 
                            updateStatus={(id, active) => this.updateVendorRoom(id, active)}
                            isVendor={isVendor}
                            currentPage={pageNumber}
                            viewRoom={(id) => this.viewRoom(id)}
                            editRoom={(id) => this.editRoom(id)} />
                        <div className='page-content'>
                            {
                                vendorRoomListData?.totalItems > (LIMIT - 1) ? 
                                <PaginationComponenent 
                                currentPage={pageNumber} 
                                countValue={vendorRoomListData?.totalItems} 
                                onChangeHandle={this.onChangePagination} /> : ''
                            }
                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getRoom: Actions.getRoom(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    getRoomList: Actions.getRoomList,
    updateVendorRoom: Actions.updateVendorRoom
})(VendorRoom));