import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import ViewAddOnScreen from '../component/ViewAddOnScreen ';
import Actions from '../redux/actions';
import { addOnListLoader } from '../redux/reducer/AddOnReducer ';
import AppLoader from '../utils/AppLoader';
import ViewProductScreen from '../component/ViewProductScreen';
import { productLoading } from '../redux/reducer/ARProductReducer';
import RoutePath from '../utils/routes/RoutePath';


class AdminViewARProduct extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            viewData: []
        }
    }
    componentDidMount() {
        this.getProductDetails()
    }
    getProductDetails = () => {
        const route = this.props.match.path.split('/')
        const isAdmin = (route[2] === "products" && route[1] === "admin") ? true : false
        let params = {
            id: this.props.match.params.id
        }
        const callback = (response) => {
            if (response) {
                this.setState({ viewData: response?.response })
            }
        }
        this.props.getProduct(params, callback, isAdmin)
    }
    render() {
        const { viewData } = this.state;
        const { isLoading } = this.props;
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <ViewProductScreen data={viewData} />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        isLoading: productLoading(state)
    }
};

export default withRouter(connect(mapStateToProps, {
    getProduct: Actions.productDetails,
})(AdminViewARProduct));