import { doAction, doResponseAction } from ".";

const CALL_HISTROY = "CALL_HISTROY";
const CALL_HISTROY_SUCCESS = "CALL_HISTROY_SUCCESS";
const CALL_HISTROY_FAILURE = "CALL_HISTROY_FAILURE";
const CALL_HISTORY_LIST = "CALL_HISTORY_LIST";

const ADMIN_AGENT_CALL_HISTROY = "ADMIN_AGENT_CALL_HISTROY";
const ADMIN_AGENT_CALL_HISTROY_SUCCESS = "ADMIN_AGENT_CALL_HISTROY_SUCCESS";
const ADMIN_AGENT_CALL_HISTROY_FAILURE = "ADMIN_AGENT_CALL_HISTROY_FAILURE";

const VENDOR_AGENT_CALL_HISTROY = "VENDOR_AGENT_CALL_HISTROY";
const VENDOR_AGENT_CALL_HISTROY_SUCCESS = "VENDOR_AGENT_CALL_HISTROY_SUCCESS";
const VENDOR_AGENT_CALL_HISTROY_FAILURE = "VENDOR_AGENT_CALL_HISTROY_FAILURE";
export {
    CALL_HISTROY,
    CALL_HISTROY_SUCCESS,
    CALL_HISTROY_FAILURE,
    CALL_HISTORY_LIST,

    ADMIN_AGENT_CALL_HISTROY,
    ADMIN_AGENT_CALL_HISTROY_SUCCESS,
    ADMIN_AGENT_CALL_HISTROY_FAILURE,

    VENDOR_AGENT_CALL_HISTROY,
    VENDOR_AGENT_CALL_HISTROY_SUCCESS,
    VENDOR_AGENT_CALL_HISTROY_FAILURE,
};
export const getHistotyDetails = store => store['CALL_HISTORY_REDUCER']
export const callHistoryAction = (params, callback) => doAction(CALL_HISTROY, params, callback);
export const callHistorySuccessAction = (data) => doResponseAction(CALL_HISTROY_SUCCESS, data);
export const callHistoryFailureAction = (data) => doResponseAction(CALL_HISTROY_FAILURE, data);
export const callHistoryListAction = (data) => doResponseAction(CALL_HISTORY_LIST, data);

export const adminAgentCallHistoryAction = (params, callback) => doAction(ADMIN_AGENT_CALL_HISTROY, params, callback);
export const adminAgentCallHistorySuccessAction = (data) => doResponseAction(ADMIN_AGENT_CALL_HISTROY_SUCCESS, data);
export const adminAgentCallHistoryFailureAction = (data) => doResponseAction(ADMIN_AGENT_CALL_HISTROY_FAILURE, data);

export const vendorAgentCallHistoryAction = (params, callback) => doAction(VENDOR_AGENT_CALL_HISTROY, params, callback);
export const vendorAgentCallHistorySuccessAction = (data) => doResponseAction(VENDOR_AGENT_CALL_HISTROY_SUCCESS, data);
export const vendorAgentCallHistoryFailureAction = (data) => doResponseAction(VENDOR_AGENT_CALL_HISTROY_FAILURE, data);