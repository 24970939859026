import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import ViewVendorProfileScreen from '../component/ViewVendorProfileScreen';
import AppLoader from '../utils/AppLoader';


class ViewVendorProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            isLoading: false
        }
    }
    componentDidMount() {
        this.setState({ isLoading: true })
        this.props.getProfileDetails("", (res) => {
            this.setState({ isLoading: false })
            if (res) {
                this.setState({ data: res })
            }
        }, false)
    }

    render() {
        const { isLoading } = this.state
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <ViewVendorProfileScreen data={this.state.data} />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getProfile: Actions.getProfileDetails(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    getProfileDetails: Actions.getProfileAction
})(ViewVendorProfile));