import Actions from "../actions";
import { LOGIN, LOGIN_VERIFY_EMAIL, LOGIN_VERIFY_OTP, LOGIN_RESET_PASSWORD, FORGET_PASSWORD, SET_NEW_PASSWORD } from "../actions/LoginActions";
import { END_POINTS } from '../../utils/service/EndPoints'
import { doForgetPost, doLogin, doForgetPassword } from "../../utils/service";
import { put, takeLatest, all } from "redux-saga/effects";

export function* login({ params, callback, isAdmin, isVendor }) {
  try {
    const response = yield doLogin(END_POINTS.LOGIN_API, params, isAdmin, isVendor);
    if (response) {
      yield put(Actions.loginSuccessAction(response.response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.loginFailureAction(e));
    callback && callback(e);
  }
}

export function* verifyEmail({ params, callback }) {
  try {
    const response = yield doForgetPost('auth/forget_password', params);
    if (response) {
      yield put(Actions.loginVerifyEmailSuccess(response.response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.loginVerifyEmailFailure(e));
    callback && callback(e);
  }
}

export function* verifyOtp({ params, callback }) {
  try {
    const response = yield doForgetPost('auth/verify_otp', params);
    if (response) {
      yield put(Actions.loginVerifyOtpSuccess(response.response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.loginVerifyOtpFailure(e));
    callback && callback(e);
  }
}

export function* verifyPassword({ params, callback }) {
  try {
    const response = yield doForgetPost('auth/reset_password', params);
    if (response) {
      yield put(Actions.loginVerifyPasswordSuccess(response.response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.loginVerifyPasswordFailure(e));
    callback && callback(e);
  }
}

export function* forgetPasswordSaga({ params, callback }) {
  try {
    const response = yield doForgetPassword(END_POINTS.FORGET_PASSWORD_API, params);
    if (response) {
      yield put(Actions.forgetPasswordSuccess(response.response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.forgetPasswordfailure(e));
    callback && callback(e);
  }
}
export function* setNewPassword({ params, callback }) {
  try {
    const response = yield doForgetPost(END_POINTS.SET_NEW_PASSWORD, params);
    if (response) {
      yield put(Actions.setNewPasswordSuccess(response.response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.setNewPasswordFailure(e));
    callback && callback(e);
  }
}
export default function* loginSagaListener() {
  yield all([
    yield takeLatest(LOGIN, login),
    yield takeLatest(LOGIN_VERIFY_EMAIL, verifyEmail),
    yield takeLatest(LOGIN_VERIFY_OTP, verifyOtp),
    yield takeLatest(LOGIN_RESET_PASSWORD, verifyPassword),
    yield takeLatest(FORGET_PASSWORD, forgetPasswordSaga),
    yield takeLatest(SET_NEW_PASSWORD, setNewPassword),
  ]);
}

