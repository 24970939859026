import { doAction, doResponseAction } from ".";

const LOGIN = 'LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';

const LOGIN_VERIFY_EMAIL = 'LOGIN_VERIFY_EMAIL';
const LOGIN_VERIFY_EMAIL_SUCCESS = 'LOGIN_VERIFY_EMAIL_SUCCESS';
const LOGIN_VERIFY_EMAIL_FAILURE = 'LOGIN_VERIFY_EMAIL_FAILURE';

const LOGIN_VERIFY_OTP = 'LOGIN_VERIFY_OTP';
const LOGIN_VERIFY_OTP_SUCCESS = 'LOGIN_VERIFY_OTP_SUCCESS';
const LOGIN_VERIFY_OTP_FAILURE = 'LOGIN_VERIFY_OTP_FAILURE';

const LOGIN_RESET_PASSWORD = 'LOGIN_RESET_PASSWORD';
const LOGIN_RESET_PASSWORD_SUCCESS = 'LOGIN_RESET_PASSWORD_SUCCESS';
const LOGIN_RESET_PASSWORD_FAILURE = 'LOGIN_REST_PASSWORD_FAILURE';

const FORGET_PASSWORD = 'FORGET_PASSWORD';
const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
const FORGET_PASSWORD_FAILURE = 'FORGET_PASSWORD_FAILURE';

const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
const SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE';

const SOCKET_VENDOR_NAME_UPDATE = 'SOCKET_VENDOR_NAME_UPDATE';
const SOCKET_AGENT_NAME_UPDATE = 'SOCKET_AGENT_NAME_UPDATE';
const SOCKET_PAYMENT_UPDATE = 'SOCKET_PAYMENT_UPDATE';
export {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,

    LOGIN_VERIFY_EMAIL,
    LOGIN_VERIFY_EMAIL_SUCCESS,
    LOGIN_VERIFY_EMAIL_FAILURE,

    LOGIN_VERIFY_OTP,
    LOGIN_VERIFY_OTP_SUCCESS,
    LOGIN_VERIFY_OTP_FAILURE,

    LOGIN_RESET_PASSWORD,
    LOGIN_RESET_PASSWORD_SUCCESS,
    LOGIN_RESET_PASSWORD_FAILURE,

    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILURE,

    SET_NEW_PASSWORD,
    SET_NEW_PASSWORD_SUCCESS,
    SET_NEW_PASSWORD_FAILURE,

    SOCKET_VENDOR_NAME_UPDATE,
    SOCKET_AGENT_NAME_UPDATE,
    SOCKET_PAYMENT_UPDATE,
}

export const getLoginDetails = store => store['LOGIN_CREDS']
export const loginAction = (params, callback, isAdmin, isVendor) => {
    return {
        type: LOGIN,
        params,
        callback,
        isAdmin,
        isVendor
    }
}
export const loginSuccessAction = data => doResponseAction(LOGIN_SUCCESS, data);
export const loginFailureAction = data => doResponseAction(LOGIN_FAILURE, data);

export const loginVerifyEmail = (params, callback) => doAction(LOGIN_VERIFY_EMAIL, params, callback);
export const loginVerifyEmailSuccess = (data) => doResponseAction(LOGIN_VERIFY_EMAIL_SUCCESS, data);
export const loginVerifyEmailFailure = (data) => doResponseAction(LOGIN_VERIFY_EMAIL_FAILURE, data);

export const loginVerifyOtp = (params, callback) => doAction(LOGIN_VERIFY_OTP, params, callback);
export const loginVerifyOtpSuccess = (data) => doResponseAction(LOGIN_VERIFY_OTP_SUCCESS, data);
export const loginVerifyOtpFailure = (data) => doResponseAction(LOGIN_VERIFY_OTP_FAILURE, data);

export const loginVerifyPassword = (params, callback) => doAction(LOGIN_RESET_PASSWORD, params, callback);
export const loginVerifyPasswordSuccess = (data) => doResponseAction(LOGIN_RESET_PASSWORD_SUCCESS, data);
export const loginVerifyPasswordFailure = (data) => doResponseAction(LOGIN_RESET_PASSWORD_FAILURE, data);

export const forgetPassword = (params, callback) => doAction(FORGET_PASSWORD, params, callback);
export const forgetPasswordSuccess = (params, callback) => doAction(FORGET_PASSWORD_SUCCESS, params, callback);
export const forgetPasswordfailure = (params, callback) => doAction(FORGET_PASSWORD_FAILURE, params, callback);

export const setNewPassword = (params, callback) => doAction(SET_NEW_PASSWORD, params, callback);
export const setNewPasswordSuccess = (data) => doResponseAction(SET_NEW_PASSWORD_SUCCESS, data);
export const setNewPasswordFailure = (data) => doResponseAction(SET_NEW_PASSWORD_FAILURE, data);

export const socketVendorNameUpdate = data => doResponseAction(SOCKET_VENDOR_NAME_UPDATE, data); // Once admin update vendor profile name will update.
export const socketAgentNameUpdate = data => doResponseAction(SOCKET_AGENT_NAME_UPDATE, data); // Once admin and vendor update agent profile name will update.
export const socketPaymentUpdate = data => doResponseAction(SOCKET_PAYMENT_UPDATE, data); // Once payment is done socket will trigger.
