import { takeLatest, put } from "redux-saga/effects";
import Actions from "../actions";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doGET } from "../../utils/service/index";
import { VOICE_RECORDING_LIST } from "../actions/VoiceRecordingAction";

export function* voiceRecordingListSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doGET(END_POINTS.BABY_CRY_LIST, params, isAdmin);
        if (response) {
            yield put(Actions.getVoiceRecordingListSuccessAction(response?.response));
            callback && callback(response?.response);
        }
    } catch (e) {
        yield put(Actions.getVoiceRecordingListFailureAction(e));
        callback && callback(e);
    }
}

export default function* voiceRecordingListener() {
    yield takeLatest(VOICE_RECORDING_LIST, voiceRecordingListSaga);
}