import { ADD_FEEDBACK, ADD_FEEDBACK_VOICE, CUSTOMER_LIST, GET_VENDOR_DETAILS, SRM_IP_ROOM_SRVICES_LIST } from "../actions/CustomerListAction";
import { takeLatest, put } from "redux-saga/effects";
import Actions from "../actions";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doCustomerPostFormData, doGET, doPost } from "../../utils/service/index";
export function* customerListSaga({ params, callback }) {
  try {
    const response = yield doPost(END_POINTS.CUSTOMER_LIST_API, params);
    if (response) {
      yield put(Actions.customerListSuccessAction(response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.customerListFailureAction(e));
    callback && callback(e);
  }
}
export function* getVendorDetailsSaga({ params, callback }) {
  try {
    const response = yield doGET(END_POINTS.GET_VENDOR_DETAILS, params);
    if (response) {
      yield put(Actions.getVendorDeailsSuccessAction(response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.getVendorDeailsFailureAction(e));
    callback && callback(e);
  }
}
export function* feedbackSaga({ params, callback }) {
  try {
    const response = yield doPost(END_POINTS.VENDOR_FEEDBACK_TEXT, params);
    if (response) {
      yield put(Actions.addFeedbackSuccessAction(response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.addFeedbackFailureAction(e));
    callback && callback(e);
  }
}

export function* feedbackVoiceSaga({ params, callback }) {
  try {
    const response = yield doCustomerPostFormData(END_POINTS.VENDOR_FEEDBACK_VOICE, params);
    if (response) {
      yield put(Actions.addFeedbackVoiceSuccessAction(response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.addFeedbackVoiceFailureAction(e));
    callback && callback(e);
  }
}
export function* getSrmIpRoomServiceList({ params, callback }) {
  try {
    const response = yield doGET(END_POINTS.GET_SRM_IP_ROOM_SERVICE, params);
    if (response) {
      yield put(Actions.srmIpRoomServiceListSuccessAction(response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.srmIpRoomServiceListFailureAction(e));
    callback && callback(e);
  }
}
export default function* customerListSagaListener() {
  yield takeLatest(CUSTOMER_LIST, customerListSaga);
  yield takeLatest(GET_VENDOR_DETAILS, getVendorDetailsSaga);
  yield takeLatest(ADD_FEEDBACK, feedbackSaga);
  yield takeLatest(ADD_FEEDBACK_VOICE, feedbackVoiceSaga);
  yield takeLatest(SRM_IP_ROOM_SRVICES_LIST, getSrmIpRoomServiceList);
}
