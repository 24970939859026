import Actions from "../actions";
const initialState = {
    isChatLoading: false,
    chatList: [],
    currentChatValue: {},
    currentArrayList: [],
    socketcurrentArrayList: [],
    error: false,
    queueList:[]
}

export const chatListReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.CHAT_LIST: {
            return {
                ...state,
                isChatLoading: true,
            };
        }
        case Actions.CHAT_LIST_SUCCESS: {
            return {
                ...state,
                isChatLoading: false,
                chatList: action?.data?.response?.ChatMessages,
                currentArrayList: action?.data?.response?.ChatMessages,
                socketcurrentArrayList: action?.data?.response?.ChatMessages,
                error: false,
            };
        }
        case Actions.CHAT_LIST_FAILURE: {
            return {
                ...state,
                isChatLoading: false,
                chatList: [],
                error: action.error,
            };
        }
        case Actions.ADD_CHAT_LIST: {
            return {
                ...state,
                isChatLoading: true,
            };
        }
        case Actions.ADD_CHAT_LIST_SUCCESS: {
            return {
                ...state,
                isChatLoading: false,
                currentChatValue: action?.data?.response?.ChatMessages,
                error: false,
            };
        }
        case Actions.ADD_CHAT_LIST_FAILURE: {
            return {
                ...state,
                isChatLoading: false,
                currentChatValue: {},
                error: action.error,
            };
        }
        case Actions.APPEND_CHAT: {
            return {
                ...state,
                currentArrayList: action.data,
            }
        }
        case Actions.SOCKET_APPEND_CHAT: {
            return {
                ...state,
                socketcurrentArrayList: action.data
            }
        }
        case Actions.ADD_CHAT_QUEUE_LIST: {
            return {
                ...state,
                queueList:action.data
            }
        }
        default: {
            return state;
        }
    }
};
export const chatListLoader = (state) => state?.CHAT_LIST;
