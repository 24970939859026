import React from 'react'
// import { withRouter } from 'react-router-dom'
import Close from '../../assets/img/modal-close.svg'
class AddonPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { title, description, onFailure, searchField, data } = this.props
        let count = 0
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabIndex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-100">
                            <div className="modal-body">
                                <div style={{ float: 'right', cursor: 'pointer' }}>
                                    <img
                                        style={{ height: '35px', width: '35px' }}
                                        id='close'
                                        src={Close}
                                        alt="X"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        className="modal-close "
                                        onClick={() => onFailure()} />
                                </div>
                                <form>
                                    <div className="row mb-1">
                                        <div
                                            className="col">
                                            {
                                                title && <h3
                                                    className="font18 fw-bold mb-5">{title}</h3>
                                            }
                                            <p className='mt-3'>{description}</p>
                                            {searchField && <div className="col-12 col-lg pe-lg-3">
                                                <div className="input-group mb-3 mb-lg-0">
                                                    <input type="text" className="form-control " placeholder="Search by order no" />
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="table-responsive addon_table">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="width20">S.No</th>
                                                        <th className="width50">Name</th>
                                                        <th className="width20">Amount</th>
                                                        <th className="width20">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.map((o, i) => {
                                                            count = count + 1
                                                            return (
                                                                <React.Fragment index={i}>
                                                                    <tr>
                                                                        <td className="width20">{count}</td>
                                                                        <td className="width20">{o?.Addon?.addonPlanName}</td>
                                                                        <td className="width50">${o?.Addon?.addonPlanPrice}</td>
                                                                        <td className="width20">{o?.status === 1 ? "Active" : "Deactive"}</td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {/* <div
                                            id='onClickSuccess' className="col pe-0" onClick={() => onSuccess()}>
                                            <span className="btn buttonSuccess font14 w-100 p-3">{proceed}</span> </div>
                                        <div
                                            id="onClickFailure" className="col text-end" onClick={() => onFailure()}>
                                            <span className="btn buttonFailure font14 w-100 p-3">{cancel}</span> </div> */}

                                </form>
                            </div>

                        </div>
                    </div>
                </div >
                <div className="modal-backdrop show"></div>
            </React.Fragment >
        )
    }
}

export default AddonPopup;