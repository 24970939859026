import { Component } from "react";
import { withRouter } from "react-router-dom";
import Constants from "../utils/Constants";
import RoutePath from "../utils/routes/RoutePath";
import '@google/model-viewer/dist/model-viewer'
import PlaceHolderImage from '../assets/img/no_image.png'
class AddVBImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        const { isEdit, error, message, isClick, handleImageURL, handleImageName, handleSubmit, value, removeProducyImage } = this.props
        return (
            <div className="content-part px-5 py-4">
                <div className="agent-details">
                    <div className="row m-0">
                        <div className="col-md-6" >
                            <div className="mt-3">
                                <p className="text-bold">Virtual Background Image Name</p>
                                <input autoComplete="off" type="text" name='imageName' maxLength="20" className="form-control w-75" value={value?.imageName} placeholder="Enter Virtual Background Image Name" onChange={(e) => handleImageName(e)} />
                                {error?.imageName && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.imageName}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Virtual Background Image</p>
                                <div className="custom-file custom-file-naked d-block mb-1">
                                    <div className="img-wraps_logo">
                                        <img style={{ width: '90px', height: '70px', marginBottom: '4px' }} alt="Store" src={value?.imageURL ? value?.imageURL : (PlaceHolderImage)} />
                                        {value?.imageURL &&
                                            <span className="closes" title="Delete" onClick={(e) => removeProducyImage(e)}>×</span>
                                        }
                                    </div><br />
                                    <input type="file" className={`form-control-file${!isClick && isEdit ? 'removeNoFile' : ''}`} id="exampleFormControlVbImage" onChange={(e) => handleImageURL(e)} name="imageURL" accept="image/*" />
                                </div>
                                {error?.imageURL && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message?.imageURL}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 me-5 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.push(RoutePath.VENDOR_VB_LIST)}>{Constants.BACK}</button>
                        <button type="button" className="btn input-btn mx-3" onClick={() => handleSubmit()}>{isEdit ? Constants.UPDATE : Constants.CREATE}</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(AddVBImage)
