import React from 'react';
import { withRouter } from 'react-router-dom';
import Constants from '../utils/Constants';
import NoDataFound from './NoDataFound';
class ViewService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }
    render() {
        const { data } = this.props;
        return (
            <div className="content-part px-5 py-4 list">
                <div className="agent-details">
                    <div className='row'>
                        <div className="col-md-8">
                            <div className='row'>
                                <div className='col-6 text-bold'>
                                    Service Id
                                </div>
                                <div className='col-6'>
                                    <p>{data && data.id ? data.id : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-bold'>
                                    Service Name
                                </div>
                                <div className='col-6'>
                                    <p>{data && data.serviceName ? data.serviceName : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-bold'>
                                    Service Head Name
                                </div>
                                <div className='col-6'>
                                    <p>{data && data.User?.fullName ? data?.User?.fullName : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-bold'>
                                    Service Head Email
                                </div>
                                <div className='col-6'>
                                    <p>{data && data?.User?.email ? data?.User?.email : "-"}</p>
                                </div>
                            </div>
                        </div>
                        {!data && <NoDataFound />}
                    </div>
                    <div className=" mt-5 me-5 pb-3 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.goBack()} >{Constants.BACK}</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(ViewService);