import React, { Component } from "react";
import io from "socket.io-client";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../redux/actions/index";
import Alert from '../component/alert/alert';
import Constants, { CONSTANTS, SOCKET_IO_PATH, SOCKET_URL } from "../utils/Constants";
import { isUserName } from "../utils/validation";
import AppLoader from "../utils/AppLoader";
import ReactStars from "react-rating-stars-component";
import { toast } from "react-toastify";
import EMERGENCY_CALL from "../assets/img/emergency-call.svg";
import stringTamil from "../utils/stringTamil";
import stringEnglish from "../utils/stringEnglish";
import AudioPlayer from "../component/AudioPlayer";
import VoiceRecorder from "../component/VoiceRecoder";
import AudioJoiner from "../component/AudioJoiner";
import SRM_GLOBAL from "../assets/img/srm-global.jpeg";
class KioskGeneralCustomerFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: "",
      phone: '',
      error: {},
      message: {},
      vendorId: "",
      vendorLogo: null,
      tab: "",
      language: parseInt(localStorage.getItem("language")) || 1,
      recordedNameAudioUrl: "",
      recordedCommentsAudioUrl: "",
      selectedQuestion: parseInt(localStorage.getItem("language")) === 2 ? stringTamil?.MESSAGE?.SELECTED_QUESTION : stringEnglish?.MESSAGE?.SELECTED_QUESTION,
      feedBack: "",
      nameDuration: 5000,
      feedbackDuration: 30000,
      initialVoiceDuration: 2000,
      feedbackTab: 1,
      Name_Blob: null,
      Answer_MP3: null,
      category: "SRM_HOSPITAL",
      textFeedbackTab: 2,
      mergeUrl: null,
      feedbackTextCancelModal: false,
      feedbackVoiceCancelModal: false,
      feedbackTextSubmitModal: false,
      feedbackVioceSubmitModal: false,
      recommendFlag: ''
    };
    this.recorderNameRef = React.createRef();
    this.audioNamePlayerRef = React.createRef();
    this.audioCommentsPlayerRef = React.createRef();
    this.recorderCommentsRef = React.createRef();
    this.audioCtx = new AudioContext();
    this.questionRefs = [];
  }
  async componentDidMount() {
    let encryptId = localStorage.getItem("vendorId");
    const tab = this.props.location.state;
    this.setState({ vendorId: encryptId, tab: tab });
    this.startAudioPlayer(tab);
  }
  startAudioPlayer = (tab) => {
    if (tab === "feedbackNameAudio") {
      setTimeout(() => {
        this.audioCommentsPlayerRef?.current?.startPlayback();
      }, this.state.initialVoiceDuration);
    }
  };
  handleChangeName = (e) => {
    let fullname = e.target.value;
    let MESSAGE = this.state?.language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
    this.setState({ error: { isFullName: false }, fullname: e.target.value, message: { isFullName: "" } });

    // if (!fullname || fullname.length === 0) {
    //   this.setState({ error: { isFullName: true }, fullname: e.target.value, message: { isFullName: MESSAGE?.PLEASE_ENTER_THE_NAME } });
    //   return false;
    // } else if (fullname.trim().length < 3) {
    //   this.setState({ error: { isFullName: true }, fullname: e.target.value, message: { isFullName: MESSAGE?.FULL_NAME_MINIMUM_CHARACTER } });
    // } else if (!isUserName(fullname)) {
    //   this.setState({ error: { isFullName: true }, fullname: e.target.value, message: { isFullName: MESSAGE?.NAME_REGX } });
    // } else {
    //   this.setState({ error: { isFullName: false }, fullname: e.target.value, message: { isFullName: "" } });
    // }
  };
  handleChangeMobileNumber = (e) => {
    // this.setState({ error: { isMobileNumber: false }, phone: e.target.value, message: { isMobileNumber: "" } });
    e.target.value = e.target.value.slice(0, 10).trim()
    this.setState({ phone: e.target.value })
    let number = e.target.value
    if (!number || number.length === 0) {
      this.setState({ error: { isMobileNumber: true }, message: { isMobileNumber: this.state.language === 2 ? stringTamil?.MESSAGE?.PLEASE_ENTER_THE_MOBLIE_NUMBER : stringEnglish?.MESSAGE?.PLEASE_ENTER_THE_MOBLIE_NUMBER } }, () => ({ ...this.state }))
      return false;
    }
    else if (number.length < 10) {
      this.setState({ error: { isMobileNumber: true }, message: { isMobileNumber: this.state.language === 2 ? stringTamil?.MESSAGE?.MOBILE_NUMBER_MUST_BE_10_CHARCTER : stringEnglish?.MESSAGE?.MOBILE_NUMBER_MUST_BE_10_CHARCTER } }, () => ({ ...this.state }))
      return false;
    }
    else {
      this.setState({ error: { isMobileNumber: false }, message: { isMobileNumber: '' } }, () => ({ ...this.state }))
    }
  }
  // handleChangeQuestion = (optionIndex, option) => {
  //   const updatedQuestion = { ...this.state.selectedQuestion };
  //   updatedQuestion["question" + (optionIndex + 1)] = option;
  //   this.setState({
  //     error: { ...this.state.error, [optionIndex]: false },
  //     selectedQuestion: updatedQuestion,
  //     message: { ...this.state.message, [optionIndex]: "" },
  //   });
  // };
  handleChangeQuestion = (questionIndex, selectedOption) => {
    this.setState(prevState => ({
      selectedQuestion: {
        ...prevState.selectedQuestion,
        [questionIndex]: selectedOption
      }
    }), () => {
      // Scroll to the next question after state has been updated
      if (questionIndex > 5 && this.questionRefs?.[questionIndex + 1]) {
        this.questionRefs?.[questionIndex + 1]?.current?.scrollIntoView({ behavior: 'smooth' });
      } else {
        this.questionRefs?.[questionIndex - 1]?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }
  handleChangeFeedback = () => {
    this.setState({ error: {}, selectedQuestion: {}, fullname: "", feedBack: "", recorderCommentsRef: "", recorderNameRef: "", feedbackTab: 1, tab: "feedbackNameAudio" });
    this.startAudioPlayer("feedbackNameAudio");
  };
  handleChangeToHomeScreen = (showToaster) => {
    if (showToaster) {
      toast.success(this.state.language === 2 ? stringTamil?.MESSAGE?.SUCCESSFULLY_SUBMITTED : stringEnglish?.MESSAGE?.SUCCESSFULLY_SUBMITED);
    }
    this.props.history.push("/kiosk_srm/" + this.state.vendorId);
  };
  handleSaveNameRecordedAudio = (audioUrl) => {
    this.setState({ recordedNameAudioUrl: audioUrl, feedbackTab: 5 });
  };
  handleSaveCommentsRecordedAudio = (audioUrl) => {
    this.setState({ recordedCommentsAudioUrl: audioUrl, feedbackTab: 3 });
    setTimeout(() => {
      this.audioNamePlayerRef?.current?.startPlayback();
    }, this.state.initialVoiceDuration);
  };
  handleQuestionNameAudioEnd = () => {
    this.setState({ feedbackTab: 4 });
    this.recorderNameRef?.current?.startRecording();
  };
  componentWillUnmount = () => {
    this.stopRecording();
  };
  stopRecording = () => {
    // Stop recording logic
    if (this.recorderNameRef && this.recorderNameRef.current) {
      this.recorderNameRef?.current?.stopRecording();
    }
    if (this.recorderCommentsRef && this.recorderCommentsRef.current) {
      this.recorderCommentsRef?.current?.stopRecording();
    }
  };
  handleQuestionCommentsAudioEnd = () => {
    this.setState({ recordedNameAudioUrl: "", feedbackTab: 2 });
    this.recorderCommentsRef?.current?.startRecording(); // Start recording automatically
    setTimeout(() => {
      this.recorderCommentsRef?.current?.stopRecording();
    }, this.state.feedbackDuration); // Stop recording after 30 seconds
  };
  voiceFeedbackSubmit = () => {
    const feedBackBack = (res) => {
      if (res?.response_code === 0) {
        this.setState({ feedbackVioceSubmitModal: true })
        setTimeout(() => this.handleChangeToHomeScreen(false), 3000)
      } else {
        toast.error(res?.response_message)
      }
      this.setState({ isLoading: false });
    };
    let formData = new FormData();
    formData.append('type', "voice");
    formData.append('encryptId', this.state?.vendorId);
    formData.append('language', this.state?.language);
    formData.append('recorderName', this.state?.recordedNameAudioUrl?.blob, 'voiceRecordName.wav');
    formData.append('recorderFeedback', this.state?.recordedCommentsAudioUrl?.blob, 'voiceRecordFeedback.wav');
    this.setState({ isLoading: true });
    this.props.feedBackVoice(formData, feedBackBack);
  };
  textFeedbackSubmitOne = () => {
    let MESSAGE = this.state?.language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
    if (!this.state.fullname || this.state.fullname.length === 0) {
      this.setState({ error: { isFullName: true }, message: { isFullName: MESSAGE?.PLEASE_ENTER_THE_NAME } });
      return false;
    } else if (this.state.fullname.trim().length < 3) {
      this.setState({ error: { isFullName: true }, message: { isFullName: MESSAGE?.FULL_NAME_MINIMUM_CHARACTER } });
    } else if (!this.state.category || this.state.category.length === 0) {
      this.setState({ error: { isCategory: true }, message: { isCategory: MESSAGE?.PLEASE_SELECT_THE_CATEGORY } });
      return false;
    } else {
      this.setState({ textFeedbackTab: 2 });
    }
  };
  textFeedbackSubmitTwo = () => {
    let MESSAGE = this.state?.language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
    if (MESSAGE?.FEEDBACK_QUESTIONS?.[this.state.category]?.length !== Object.keys(this.state.selectedQuestion).length) {
      this.setState({ error: { feedbackQuestion: true }, message: { feedbackQuestion: MESSAGE?.PLEASE_SELECT_THE_FEEDBACK } });
    } else if (this.state.recommendFlag.length === 0) {
      this.setState({ error: { recommend: true }, message: { recommend: this.state.language === 2 ? stringTamil?.MESSAGE?.PLEASE_SELECT_THE_OPTION : stringEnglish?.MESSAGE?.PLEASE_SELECT_THE_OPTION } }, () => ({ ...this.state }))
      return false;
    } else if (!this.state.phone || this.state.phone.length === 0) {
      this.setState({ error: { isMobileNumber: true }, message: { isMobileNumber: this.state.language === 2 ? stringTamil?.MESSAGE?.PLEASE_ENTER_THE_MOBLIE_NUMBER : stringEnglish?.MESSAGE?.PLEASE_ENTER_THE_MOBLIE_NUMBER } }, () => ({ ...this.state }))
      return false;
    } else if (this.state.phone.length < 10) {
      this.setState({ error: { isMobileNumber: true }, message: { isMobileNumber: this.state.language === 2 ? stringTamil?.MESSAGE?.MOBILE_NUMBER_MUST_BE_10_CHARCTER : stringEnglish?.MESSAGE?.MOBILE_NUMBER_MUST_BE_10_CHARCTER } }, () => ({ ...this.state }))
      return false;
    } else {
      const feedBackBack = (res) => {
        if (res?.response_code === 0) {
          this.setState({ feedbackTextSubmitModal: true })
          setTimeout(() => this.handleChangeToHomeScreen(false), 3000)
        } else {
          toast.error(res?.response_message)
        }
        this.setState({ isLoading: false });
      };
      let params = {
        type: "text",
        encryptId: this.state?.vendorId,
        fullName: this.state?.fullname,
        comments: this.state?.feedBack,
        feedback: this.state?.selectedQuestion,
        language: this.state?.language,
        category: this.state?.category,
        phone: this.state?.phone,
        recommend: this.state.recommendFlag
      };
      this.setState({ isLoading: true });
      this.props.feedBack(params, feedBackBack);
    }
  };
  fetchMp3AndConvertToBlob = async () => {
    let MESSAGE = this.state?.language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
    try {
      const response = await fetch(MESSAGE?.NAME_AUDIO);
      const arrayBuffer = await response.arrayBuffer();
      const blobData = new Blob([arrayBuffer], { type: "audio/mp3" });
      const d = URL.createObjectURL(blobData);
      this.setState({ Name_Blob: d });
    } catch (error) {
      console.error("Error fetching MP3 file:", error);
    }
  };
  convertBlobUrlToMp3 = async (blobUrl) => {
    try {
      // Fetch the Blob data from the Blob URL
      const response = await fetch(blobUrl);
      const blobData = await response.blob();

      // Convert Blob data to a base64 string
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];

        // Create a Blob from the base64 string with MIME type audio/mp3
        const mp3Blob = new Blob([atob(base64Data)], { type: "audio/mp3" });

        // Create a URL for the MP3 Blob
        const mp3BlobUrl = URL.createObjectURL(mp3Blob);
        this.setState({ Answer_MP3: mp3BlobUrl });
      };
      reader.readAsDataURL(blobData);
    } catch (error) {
      console.error("Error converting Blob URL to MP3:", error);
    }
  };
  handleChangeCategory = (e) => {
    let category = e;
    let MESSAGE = this.state?.language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
    if (!category) {
      this.setState({ error: { isCategory: true }, category: e, message: { isCategory: MESSAGE?.PLEASE_SELECT_THE_CATEGORY } });
      return false;
    } else {
      this.setState({ error: { isCategory: false }, category: e, message: { isCategory: "" }, textFeedbackTab: 2 });
    }
  };
  handleClick = (value) => {
    let MESSAGE = this.state?.language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
    if (value?.length === 0) {
      this.setState({ error: { recommend: true }, recommendFlag: value, message: { recommend: MESSAGE?.PLEASE_SELECT_THE_OPTION } });
    } else {
      this.setState({ error: { recommend: false }, recommendFlag: value, message: { recommend: "" } })
    }
  };
  render() {
    const { message, error, vendorLogo, tab, language, recordedNameAudioUrl, recordedCommentsAudioUrl, feedbackTab, Name_Blob, category, textFeedbackTab, selectedQuestion } = this.state;
    const { isLoading } = this.props.customerListLoader;
    let MESSAGE = language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
    return (
      <div className="customer-care overflow-auto" style={{ backgroundImage: `url(${SRM_GLOBAL})`, backgroundSize: "auto 100%", backgroundPosition: "center" }}>
        {isLoading && <AppLoader />}
        <div className="row call-row mx-0">
          <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
            <div className="text-center">
              <img className="logo" src={vendorLogo ? vendorLogo : require("../assets/img/srm-logo.png")} alt="logo" />
            </div>
            {tab === "feedbackNameAudio" && (
              <div className="d-flex flex-column width_value pb-sm-0 pb-5">
                <div className="d-flex flex-column rounded-4" style={{ backgroundColor: "rgb(255 255 255 )" }}>
                  <div className="p-4">
                    <h1 className="text-center">{MESSAGE?.AUDIO}</h1>
                    <p className="text-center">{MESSAGE?.AUDIO_CONTENT}</p>
                  </div>
                  <div>
                    {feedbackTab === 1 && (
                      <div>
                        <img src={require("../assets/img/voice.jpg")} alt="logo" className="w-100" />
                        <div className="p-4">
                          <h2>{MESSAGE?.SECOND_QUESTION}</h2>
                          <AudioPlayer
                            audioUrl={MESSAGE?.FEEDBACK_AUDIO}
                            onEnded={this.handleQuestionCommentsAudioEnd}
                            ref={this.audioCommentsPlayerRef}
                            showControls={false}
                            waveform={true}
                          />
                        </div>
                      </div>
                    )}
                    {feedbackTab === 2 && (
                      <>
                        <img src={require("../assets/img/recording.gif")} alt="logo" className="w-100" />
                        <div className="p-4">
                          <h2>{MESSAGE?.AUTO_RECORD}</h2>
                          <VoiceRecorder
                            ref={this.recorderCommentsRef}
                            onSave={this.handleSaveCommentsRecordedAudio}
                            duration={this.state.feedbackDuration}
                          />
                        </div>
                      </>
                    )}
                    {feedbackTab === 3 && (
                      <div>
                        <img src={require("../assets/img/voice.jpg")} alt="logo" className="w-100" />
                        <div className="p-4">
                          <h2>{MESSAGE?.FIRST_QUESTION}</h2>
                          <AudioPlayer
                            audioUrl={MESSAGE?.NAME_AUDIO}
                            onEnded={this.handleQuestionNameAudioEnd}
                            ref={this.audioNamePlayerRef}
                            showControls={false}
                            waveform={true}
                          />
                        </div>
                      </div>
                    )}
                    {feedbackTab === 4 && (
                      <div>
                        <img src={require("../assets/img/recording.gif")} alt="logo" className="w-100" />
                        <div className="p-4">
                          <h2>{MESSAGE?.AUTO_RECORD}</h2>
                          <VoiceRecorder
                            ref={this.recorderNameRef}
                            onSave={this.handleSaveNameRecordedAudio}
                            duration={this.state.nameDuration}
                          />
                        </div>
                      </div>
                    )}
                    {feedbackTab === 5 && (
                      <div>
                        <img src={require("../assets/img/voice.jpg")} alt="logo" className="w-100" />
                        <div className="p-4">
                          <h2>{MESSAGE?.RECORDER_ANSWER}</h2>
                          <h6>{MESSAGE?.CUSTOMER_FEEDBACK}</h6>
                          <AudioPlayer
                            audioUrl={recordedCommentsAudioUrl?.blobURL}
                            showControls={true}
                            waveform={false}
                          />
                          <h6>{MESSAGE?.NAME}</h6>
                          <AudioPlayer
                            audioUrl={recordedNameAudioUrl?.blobURL}
                            showControls={true}
                            waveform={false}
                          />
                          {/* <AudioJoiner showControls={true} audioUrl1={recordedNameAudioUrl?.blobURL} audioUrl2={recordedCommentsAudioUrl?.blobURL}
                          /> */}
                        </div>
                      </div>
                    )}
                  </div>

                  {feedbackTab === 5 && (
                    <div className="pb-4 px-4 pt-0 gap-3 justify-content-between d-flex">
                      <button className="btn w-50 cancel_btn custom-form-control p-3" onClick={() => this.setState({ feedbackVoiceCancelModal: true })}>
                        {MESSAGE?.CANCEL}
                      </button>
                      <button className="btn btn-gradient custom-form-control w-50 p-3" onClick={() => this.voiceFeedbackSubmit(true)}>
                        {MESSAGE?.SUBMIT}
                      </button>
                    </div>
                  )}
                </div>
                {this.state.feedbackVoiceCancelModal && <Alert
                  id="Alert"
                  screen="feedback"
                  description={this.state.language === 2 ? stringTamil?.MESSAGE?.FEEDBACK_CANCEL?.desc : stringEnglish?.MESSAGE?.FEEDBACK_CANCEL?.desc}
                  proceed={this.state.language === 2 ? stringTamil?.MESSAGE?.FEEDBACK_CANCEL?.proceed : stringEnglish?.MESSAGE?.FEEDBACK_CANCEL?.proceed}
                  cancel={this.state.language === 2 ? stringTamil?.MESSAGE?.FEEDBACK_CANCEL?.cancel : stringEnglish?.MESSAGE?.FEEDBACK_CANCEL?.cancel}
                  onSuccess={() => this.handleChangeToHomeScreen(false)}
                  onFailure={() => this.setState({ feedbackVoiceCancelModal: false })}
                />}
                {this.state.feedbackVioceSubmitModal && <Alert
                  id="Alert"
                  screen="submittedFeedback"
                  title={this.state.language === 2 ? stringTamil?.MESSAGE?.SUCCESSFULLY_SUBMITTED : stringEnglish?.MESSAGE?.SUCCESSFULLY_SUBMITED}
                />}
              </div>
            )}

            {tab === "feedbackText" && (
              <div className="d-flex flex-column gap-3 width_value pb-sm-0 pb-5">
                <div className="d-flex gap-3 flex-column p-sm-5 p-3 rounded-4" style={{ backgroundColor: "rgb(255 255 255 / 80%)" }}>
                  <div>
                    <h1 className="text-center">{MESSAGE?.TEXT}</h1>
                    <p className="text-center">{MESSAGE?.TEXT_CONTENT}</p>
                  </div>
                  {textFeedbackTab === 1 && (
                    <React.Fragment>
                      <div className="d-flex flex-column">
                        <label htmlFor="exampleFormControlInput1" className="form-label form-text">
                          {MESSAGE?.CATEGORY}
                        </label>
                        <div className="row g-2">
                          {MESSAGE?.LIST_OF_CATEGORY.map((item) => (
                            <div
                              className="col-6"
                              key={item?.id}
                              onClick={(e) => this.handleChangeCategory(item?.string)}>
                              <button className={"cat-btn btn-gradient"}>
                                <h1>
                                  <i className={item?.icon}></i>
                                </h1>
                                {item?.value}
                              </button>
                            </div>
                          ))}
                        </div>
                        {error.isCategory && (
                          <span style={{ color: "red", fontSize: "16px", marginTop: "10px" }}>{message?.isCategory}</span>
                        )}
                      </div>
                      <button className="btn" onClick={() => this.handleChangeToHomeScreen()}>
                        {MESSAGE?.BACK}
                      </button>
                    </React.Fragment>
                  )}
                  {textFeedbackTab === 2 && (
                    <React.Fragment>
                      <div className="dummy-questions">
                        <label className="form-label form-text">
                          {MESSAGE?.FEEDBACK_FOR_HOSPITAL}
                          :
                        </label>
                        <p>{MESSAGE?.FEEDBACK_QUESTIONS?.SRM_HOSPITAL_CONTENT}</p>
                        <div className="d-flex flex-column gap-3">
                          {MESSAGE?.FEEDBACK_QUESTIONS[category]?.map((question, questionIndex) => {
                            if (!this.questionRefs?.[questionIndex]) {
                              this.questionRefs[questionIndex] = React.createRef();
                            }
                            return (
                              <div key={questionIndex}
                                className="feedback-question d-md-flex align-items-center"
                                ref={this.questionRefs?.[questionIndex]}
                              >
                                <div className="feedback-rating" style={{ marginBottom: "-16px", marginRight: "30px" }}>
                                  {questionIndex + 1}. {question?.question + " "} {questionIndex < 7 && <span style={{ color: questionIndex < 7 ? "red" : "inherit" }}>*</span>} {questionIndex < 7 ? "" : MESSAGE?.CALL_FEEDBACK?.OPTIONAL}
                                </div>
                                <div className="options d-flex flex-wrap justify-content-md-center align-items-center gap-sm-5 gap-2">
                                  <ReactStars
                                    count={5}
                                    onChange={(newRating) => {
                                      const optionValue = question?.options?.find(
                                        (option) => option.rate === newRating
                                      )?.value;
                                      this.handleChangeQuestion(questionIndex, optionValue);
                                    }}
                                    size={50}
                                    isHalf={false}
                                    emptyIcon={<i className="far fa-star" style={{ fontSize: '32px' }}></i>}
                                    fullIcon={<i className="fa fa-star" style={{ fontSize: '32px' }} ></i>}
                                    activeColor="#ffc600"
                                    className="rating-star"
                                  />
                                  <label
                                    htmlFor={`${category}-${questionIndex}`}
                                  >
                                    <span className={language === 2 ? "text_value tamil_text_value text-center" : "text_value text-center"}>{this.state.selectedQuestion[questionIndex] !== "Not applicable" && this.state.selectedQuestion[questionIndex] !== "பொருந்தாது" && this.state.selectedQuestion[questionIndex]}
                                    </span>
                                  </label>
                                  {/* {question?.options?.map((option, optionIndex) => (
                                  <div key={optionIndex} className="cc-selector">
                                    <input
                                      type="radio"
                                      id={`${category}-${questionIndex}-${option?.id}`}
                                      name={`${category}-${questionIndex}`}
                                      value={option.value}
                                      checked={selectedQuestion[questionIndex] === option.value} // Check if this option is selected for this question
                                      onChange={(e) => this.handleChangeQuestion(questionIndex, option.value)}
                                    />
                                    <label
                                      className="d-flex flex-column align-items-center"
                                      htmlFor={`${category}-${questionIndex}-${option?.id}`}
                                    >
                                      <div className="display-3 mb-0 ">
                                        <i className={option.value === selectedQuestion[questionIndex] ? option.icon_active : option.icon}></i> 
                                  
                                      </div>
                                      <span className={language === 2 ? "text_value tamil_text_value text-center" : "text_value text-center"}>{option.value}</span>
                                    </label>
                                  </div>
                                ))} */}
                                </div>
                              </div>)
                          })}
                        </div>
                      </div>
                      <div>
                        {error.feedbackQuestion && (
                          <h5 style={{ color: "red", fontSize: "16px", marginTop: "10px" }}>
                            {message?.feedbackQuestion}
                          </h5>
                        )}
                      </div>
                      <div className="label-text recomend">
                        <label htmlFor="exampleFormControlInput1" className="form-label form-text reference-rating" style={{ marginRight: '50px' }}>
                          {MESSAGE?.RECOMMEND_QUESTION} <span style={{ color: "red" }}>*</span>
                        </label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <button
                            type="button"
                            onClick={() => this.handleClick(MESSAGE?.YES)}
                            className={`${this.state.recommendFlag === MESSAGE?.YES ? "btn-gradient" : "cancel_btn"}`}
                            style={{
                              marginRight: '10px',
                              padding: '10px 20px', // Adjusted padding for increased button length
                              border: '1px solid black',
                              cursor: 'pointer',
                              minWidth: '100px' // Set minimum width for the button
                            }}
                          >
                            {MESSAGE?.YES}
                          </button>
                          <button
                            type="button"
                            onClick={() => this.handleClick(MESSAGE.NO)}
                            className={`${this.state.recommendFlag === MESSAGE.NO ? "btn-gradient" : "cancel_btn"}`}
                            style={{
                              padding: '10px 20px', // Adjusted padding for increased button length
                              border: '1px solid black',
                              cursor: 'pointer',
                              minWidth: '100px' // Set minimum width for the button
                            }}
                          >
                            {MESSAGE.NO}
                          </button>
                        </div>
                      </div>
                      {error.recommend && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message?.recommend}</h5>}
                      <div>
                        <label htmlFor="exampleFormControlInput1" className="form-label form-text">{MESSAGE?.FULLNAME} {MESSAGE?.CALL_FEEDBACK?.OPTIONAL}</label>
                        <input autoComplete="off" type="text" className="form-control custom-form-control w-100" id="exampleFormControlInput1"
                          placeholder={MESSAGE?.ENTER_NAME} style={{ height: '46px' }} maxLength="30" value={this.state.fullname} onChange={(e) => this.handleChangeName(e)} />
                        {error.isFullName && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message?.isFullName}</h5>}
                      </div>
                      <div className="label-text">
                        <label htmlFor="exampleFormControlInput1" className="form-label form-text">{MESSAGE?.MOBILE_NUMBER}  <span style={{ color: "red" }}>*</span></label>
                        <input autoComplete="off" type="number" maxLength={13} minLength={9} className="form-control input-box-size" id="exampleFormControlInput1"
                          placeholder={MESSAGE?.ENTER_MOBILE_NUMBER} style={{ height: '46px' }} onChange={(e) => this.handleChangeMobileNumber(e)} />
                        <div>
                          {error.isMobileNumber && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isMobileNumber}</h5>}
                        </div>
                      </div>
                      <div>
                        <label className="form-label form-text">{MESSAGE?.CALL_FEEDBACK?.COMMMENT_OPTIONAL} {MESSAGE?.CALL_FEEDBACK?.OPTIONAL}</label>
                        <textarea
                          className="form-control textarea-width"
                          placeholder={MESSAGE?.CALL_FEEDBACK?.YOUR_COMMENTS}
                          onChange={(e) => this.setState({ feedBack: e.target.value })}></textarea>
                      </div>
                      <div className="d-flex justify-content-between align-items-center gap-3">
                        <button className="btn w-50 cancel_btn custom-form-control p-3" onClick={() => this.setState({ feedbackTextCancelModal: true })}>
                          {MESSAGE?.CANCEL}
                        </button>
                        <button className="btn w-50 btn-gradient custom-form-control p-3" onClick={() => this.textFeedbackSubmitTwo()}>
                          {MESSAGE?.SUBMIT}
                        </button>
                      </div>
                      {this.state.feedbackTextCancelModal && <Alert
                        id="Alert"
                        screen="feedback"
                        description={this.state.language === 2 ? stringTamil?.MESSAGE?.FEEDBACK_CANCEL?.desc : stringEnglish?.MESSAGE?.FEEDBACK_CANCEL?.desc}
                        proceed={this.state.language === 2 ? stringTamil?.MESSAGE?.FEEDBACK_CANCEL?.proceed : stringEnglish?.MESSAGE?.FEEDBACK_CANCEL?.proceed}
                        cancel={this.state.language === 2 ? stringTamil?.MESSAGE?.FEEDBACK_CANCEL?.cancel : stringEnglish?.MESSAGE?.FEEDBACK_CANCEL?.cancel}
                        onSuccess={() => this.handleChangeToHomeScreen(false)}
                        onFailure={() => this.setState({ feedbackTextCancelModal: false })}
                      />}
                      {this.state.feedbackTextSubmitModal && <Alert
                        id="Alert"
                        screen="submittedFeedback"
                        title={this.state.language === 2 ? stringTamil?.MESSAGE?.SUCCESSFULLY_SUBMITTED : stringEnglish?.MESSAGE?.SUCCESSFULLY_SUBMITED}
                      />}
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    getQueueList: state?.CHAT_LIST?.queueList,
    customerListLoader: state?.CUSTOMER_LIST,
  };
};

export default withRouter(connect(mapStateToProps, {
  getVendorDetails: Actions.getVendorDetailsAction,
  customerListAction: Actions.cutomerListAction,
  customerIdAction: Actions.customerID,
  appendQueueList: Actions.addChatQueueList,
  feedBack: Actions.addFeedbackAction,
  feedBackVoice: Actions.addFeedbackVoiceAction
})(KioskGeneralCustomerFeedback))



