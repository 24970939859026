import { doAction, doResponseAction } from ".";

const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
const UPDATE_APPOINTMENT_SUCCESS = "UPDATE_APPOINTMENT_SUCCESS";
const UPDATE_APPOINTMENT_FAILURE = "UPDATE_APPOINTMENT_FAILURE";

export { UPDATE_APPOINTMENT, UPDATE_APPOINTMENT_SUCCESS, UPDATE_APPOINTMENT_FAILURE };

export const updateAppointmentAction = (params, callback) => doAction(UPDATE_APPOINTMENT, params, callback);
export const updateAppointmentSuccess = (data) => doResponseAction(UPDATE_APPOINTMENT_SUCCESS, data);
export const updateAppointmentFailure = (data) => doResponseAction(UPDATE_APPOINTMENT_FAILURE, data);
