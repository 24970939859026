import { doAction, doResponseAction } from ".";

const GET_VENDOR_SERVICE_LIST = 'GET_VENDOR_SERVICE_LIST';
const GET_VENDOR_SERVICE_LIST_SUCCESS = 'GET_VENDOR_SERVICE_LIST_SUCCESS';
const GET_VENDOR_SERVICE_LIST_FAILURE = 'GET_VENDOR_SERVICE_LIST_FAILURE';

const CREATE_VENDOR_SERVICE = 'CREATE_VENDOR_SERVICE';
const CREATE_VENDOR_SERVICE_SUCCESS = 'CREATE_VENDOR_SERVICE_SUCCESS';
const CREATE_VENDOR_SERVICE_FAILURE = 'CREATE_VENDOR_SERVICE_FAILURE';

const UPDATE_VENDOR_SERVICE = ' UPDATE_VENDOR_SERVICE';
const UPDATE_VENDOR_SERVICE_SUCCESS = ' UPDATE_VENDOR_SERVICE_SUCCESS';
const UPDATE_VENDOR_SERVICE_FAILURE = ' UPDATE_VENDOR_SERVICE_FAILURE';

const VENDOR_SERVICE_DETAIL = 'VENDOR_SERVICE_DETAIL';
const VENDOR_SERVICE_DETAIL_SUCCESS = 'VENDOR_SERVICE_DETAIL_SUCCESS';
const VENDOR_SERVICE_DETAIL_FAILURE = 'VENDOR_SERVICE_DETAIL_FAILURE';

const GET_VENDOR_ROOM_SERVICE_LIST = 'GET_VENDOR_ROOM_SERVICE_LIST';
const GET_VENDOR_ROOM_SERVICE_LIST_SUCCESS = 'GET_VENDOR_ROOM_SERVICE_LIST_SUCCESS';
const GET_VENDOR_ROOM_SERVICE_LIST_FAILURE = 'GET_VENDOR_ROOM_SERVICE_LIST_FAILURE';

const CREATE_VENDOR_ROOM_SERVICE = 'CREATE_VENDOR_ROOM_SERVICE';
const CREATE_VENDOR_ROOM_SERVICE_SUCCESS = 'CREATE_VENDOR_ROOM_SERVICE_SUCCESS';
const CREATE_VENDOR_ROOM_SERVICE_FAILURE = 'CREATE_VENDOR_ROOM_SERVICE_FAILURE';

const UPDATE_VENDOR_ROOM_SERVICE = 'UPDATE_VENDOR_ROOM_SERVICE';
const UPDATE_VENDOR_ROOM_SERVICE_SUCCESS = 'UPDATE_VENDOR_ROOM_SERVICE_SUCCESS';
const UPDATE_VENDOR_ROOM_SERVICE_FAILURE = 'UPDATE_VENDOR_ROOM_SERVICE_FAILURE';

const VENDOR_ROOM_SERVICE_DETAIL = 'VENDOR_ROOM_SERVICE_DETAIL';
const VENDOR_ROOM_SERVICE_DETAIL_SUCCESS = 'VENDOR_ROOM_SERVICE_DETAIL_SUCCESS';
const VENDOR_ROOM_SERVICE_DETAIL_FAILURE = 'VENDOR_ROOM_SERVICE_DETAIL_FAILURE';
const GET_VENDOR_SRM_IP_AGENT_LIST = 'GET_VENDOR_SRM_IP_AGENT_LIST';
const GET_VENDOR_SRM_IP_AGENT_LIST_SUCCESS = 'GET_VENDOR_SRM_IP_AGENT_LIST_SUCCESS';
const GET_VENDOR_SRM_IP_AGENT_LIST_FAILURE = 'GET_VENDOR_SRM_IP_AGENT_LIST_FAILURE';

export {
    GET_VENDOR_SERVICE_LIST,
    GET_VENDOR_SERVICE_LIST_SUCCESS,
    GET_VENDOR_SERVICE_LIST_FAILURE,

    CREATE_VENDOR_SERVICE,
    CREATE_VENDOR_SERVICE_SUCCESS,
    CREATE_VENDOR_SERVICE_FAILURE,

    UPDATE_VENDOR_SERVICE,
    UPDATE_VENDOR_SERVICE_SUCCESS,
    UPDATE_VENDOR_SERVICE_FAILURE,

    VENDOR_SERVICE_DETAIL,
    VENDOR_SERVICE_DETAIL_SUCCESS,
    VENDOR_SERVICE_DETAIL_FAILURE,

    GET_VENDOR_ROOM_SERVICE_LIST,
    GET_VENDOR_ROOM_SERVICE_LIST_SUCCESS,
    GET_VENDOR_ROOM_SERVICE_LIST_FAILURE,

    CREATE_VENDOR_ROOM_SERVICE,
    CREATE_VENDOR_ROOM_SERVICE_SUCCESS,
    CREATE_VENDOR_ROOM_SERVICE_FAILURE,

    UPDATE_VENDOR_ROOM_SERVICE,
    UPDATE_VENDOR_ROOM_SERVICE_SUCCESS,
    UPDATE_VENDOR_ROOM_SERVICE_FAILURE,

    VENDOR_ROOM_SERVICE_DETAIL,
    VENDOR_ROOM_SERVICE_DETAIL_SUCCESS,
    VENDOR_ROOM_SERVICE_DETAIL_FAILURE,

    GET_VENDOR_SRM_IP_AGENT_LIST,
    GET_VENDOR_SRM_IP_AGENT_LIST_SUCCESS,
    GET_VENDOR_SRM_IP_AGENT_LIST_FAILURE,
}

export const getVendorRoomService = store => store['VENDOR_ROOM_SERVICES']


export const getVendorServiceList = (params, callback) => doAction(GET_VENDOR_SERVICE_LIST, params, callback);
export const getVendorServiceListSuccess = data => doResponseAction(GET_VENDOR_SERVICE_LIST_SUCCESS, data);
export const getVendorServiceListFailure = data => doResponseAction(GET_VENDOR_SERVICE_LIST_FAILURE, data);

export const createVendorService = (params, callback) => doAction(CREATE_VENDOR_SERVICE, params, callback);
export const createVendorServiceSuccess = data => doResponseAction(CREATE_VENDOR_SERVICE_SUCCESS, data);
export const createVendorServiceFailure = data => doResponseAction(CREATE_VENDOR_SERVICE_FAILURE, data);

export const updateVendorService = (params, callback) => doAction(UPDATE_VENDOR_SERVICE, params, callback);
export const updateVendorServiceSuccess = data => doResponseAction(UPDATE_VENDOR_SERVICE_SUCCESS, data);
export const updateVendorServiceFailure = data => doResponseAction(UPDATE_VENDOR_SERVICE_FAILURE, data);

export const getVendorServiceDetail = (params, callback) => doAction(VENDOR_SERVICE_DETAIL, params, callback);
export const getVendorServiceDetailSuccess = data => doResponseAction(VENDOR_SERVICE_DETAIL_SUCCESS, data);
export const getVendorServiceDetailFailure = data => doResponseAction(VENDOR_SERVICE_DETAIL_FAILURE, data);

export const getVendorRoomServiceList = (params, callback) => doAction(GET_VENDOR_ROOM_SERVICE_LIST, params, callback);
export const getVendorRoomServiceListSuccess = data => doResponseAction(GET_VENDOR_ROOM_SERVICE_LIST_SUCCESS, data);
export const getVendorRoomServiceListFailure = data => doResponseAction(GET_VENDOR_ROOM_SERVICE_LIST_FAILURE, data);

export const createVendorRoomService = (params, callback) => doAction(CREATE_VENDOR_ROOM_SERVICE, params, callback);
export const createVendorRoomServiceSuccess = data => doResponseAction(CREATE_VENDOR_ROOM_SERVICE_SUCCESS, data);
export const createVendorRoomServiceFailure = data => doResponseAction(CREATE_VENDOR_ROOM_SERVICE_FAILURE, data);

export const updateVendorRoomService = (params, callback) => doAction(UPDATE_VENDOR_ROOM_SERVICE, params, callback);
export const updateVendorRoomServiceSuccess = data => doResponseAction(UPDATE_VENDOR_ROOM_SERVICE_SUCCESS, data);
export const updateVendorRoomServiceFailure = data => doResponseAction(UPDATE_VENDOR_ROOM_SERVICE_FAILURE, data);

export const getVendorRoomServiceDetail = (params, callback) => doAction(VENDOR_ROOM_SERVICE_DETAIL, params, callback);
export const getVendorRoomServiceDetailSuccess = data => doResponseAction(VENDOR_ROOM_SERVICE_DETAIL_SUCCESS, data);
export const getVendorRoomServiceDetailFailure = data => doResponseAction(VENDOR_ROOM_SERVICE_DETAIL_FAILURE, data);

export const getVendorSrmIpAgentList = (callback) => doAction(GET_VENDOR_SRM_IP_AGENT_LIST, callback);
export const getVendorSrmIpAgentListSuccess = data => doResponseAction(GET_VENDOR_SRM_IP_AGENT_LIST_SUCCESS, data);
export const getVendorSrmIpAgentListFailure = data => doResponseAction(GET_VENDOR_SRM_IP_AGENT_LIST_FAILURE, data);