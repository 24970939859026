import React from 'react'
import { S3_URL } from '../utils/Constants';
import '@google/model-viewer/dist/model-viewer'

class ModalViewer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { sku } = this.props.match.params || {}
        const { location } = this.props
        const Arr = location?.pathname.split("/")
        const productId = Arr[Arr.length - 2]
        return (
            <React.Fragment>
                <model-viewer
                    src={`${S3_URL}${productId}/${sku}.glb`}
                    // ios-src={`${S3_URL}${productId}/${sku}.usdz`}
                    poster={require("../assets/img/ar.gif")}
                    shadow-intensity="1" ar camera-controls touch-action="pan-y"
                    ar-modes="scene-viewer webxr quick-look"
                    style={{ height: "500px" }}
                    alt="A 3D model carousel">
                    <button slot="ar-button" id="ar-button">
                        View in your space
                    </button>
                    <div id="ar-prompt">
                        <img src="https://modelviewer.dev/shared-assets/icons/hand.png" />
                    </div>
                    <button id="ar-failure">
                        AR is not tracking!
                    </button>
                </model-viewer>
            </React.Fragment>
        )
    }
}

export default ModalViewer