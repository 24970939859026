import { doAdminGET, doAdminPost } from "../../utils/service";
import { put, takeLatest, all } from "redux-saga/effects";
import { END_POINTS } from "../../utils/service/EndPoints";
import Actions from "../actions";
import { ADD_ADDON, GET_ADDON_ACTIVE_LIST, GET_ADDON_DETAILS, GET_ADDON_LIST, PAYMENT, UPDATE_ADDON } from "../actions/AddOnAction";


export function* addOnListSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(isAdmin ? END_POINTS.GET_ADDON_LIST : END_POINTS.GET_VENDOR_ADDON_LIST, params, isAdmin);
        if (response) {
            yield put(Actions.getAddOnListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getAddOnListFailure(e));
        callback && callback(e);
    }
}
export function* addOnActiveListSaga({ params, callback, isAdmin = true }) {
    try {
        const response = yield doAdminGET(END_POINTS.GET_ADDON_ACTIVE_LIST, params, isAdmin);
        if (response) {
            yield put(Actions.getAddOnActiveListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getAddOnActiveListFailure(e));
        callback && callback(e);
    }
}
export function* addAddOnListSaga({ params, callback, isAdmin = true }) {
    try {
        const response = yield doAdminPost(END_POINTS.ADD_ADDON, params, isAdmin);
        if (response) {
            yield put(Actions.addAddOnSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.addAddOnFailure(e));
        callback && callback(e);
    }
}
export function* paymentAddOnSaga({ params, callback, isAdmin}) {
    try {
        const response = yield doAdminPost(END_POINTS.PAYMENT_ADDON, params, isAdmin);
        if (response) {
            yield put(Actions.paymentSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.paymentFailure(e));
        callback && callback(e);
    }
}
export function* updateAddOnListSaga({ params, callback, isAdmin = true }) {
    try {
        const response = yield doAdminPost(END_POINTS.UPDATE_ADDON, params, isAdmin);
        if (response) {
            callback && callback(response);
        }
    } catch (e) {
        callback && callback(e);
    }
}
export function* getAddOnDetailsSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(isAdmin?END_POINTS.GET_ADDON_DETAILS:END_POINTS.GET_VENDOR_ADDON_DETAILS, params, isAdmin);
        if (response) {
            yield put(Actions.getAddOnDetailsSuccess(response.response));
            callback && callback(response?.response);
        }
    } catch (e) {
        yield put(Actions.getAddOnDetailsFailure(e));
        callback && callback(e);
    }
}

export default function* adminAddOnListener() {
    yield all([
        takeLatest(GET_ADDON_LIST, addOnListSaga),
        takeLatest(ADD_ADDON, addAddOnListSaga),
        takeLatest(PAYMENT, paymentAddOnSaga),
        takeLatest(UPDATE_ADDON, updateAddOnListSaga),
        takeLatest(GET_ADDON_DETAILS, getAddOnDetailsSaga),
        takeLatest(GET_ADDON_ACTIVE_LIST, addOnActiveListSaga),
    ]);
}