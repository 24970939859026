import React from 'react'
import Close from '../../assets/img/modal-close.svg'
import Actions from '../../redux/actions'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppLoader from '../../utils/AppLoader';
import Constants from '../../utils/Constants';
import { msToHMS } from '../../utils/validation';
import { toast } from 'react-toastify';
import RoutePath from '../../utils/routes/RoutePath';
class RenewSubscripton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            subscriptionlist: '',
            isLoading: false,
            fields: {},
            error: {}
        }
    }
    componentDidMount = () => {
        this.setState({ isLoading: true })
        this.props.getSubscriptionAciveList('', (res) => {
            this.setState({ isLoading: false })
            if (res?.response) {
                this.setState({ subscriptionlist: res?.response })
            }
        }, false)
    }
    validateForm = (validateArray) => {
        let { error, fields } = this.state
        let isFailed = false;
        validateArray?.forEach((a) => {
            switch (a) {
                case 'subscriptionId':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = "Please select one subscription."
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'isAgree':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = "Please select the I agree condition."
                    } else {
                        error[a] = ''
                    }
                    break;
                default:
                    break;
            }
        })
        this.setState({ error })
        return isFailed
    }
    handleChange = ({ target }, o) => {
        const { name, value } = target || {}
        const { fields } = this.state;
        this.setState({
            fields: {
                ...fields,
                [name]: value
            }
        }, () => this.validateForm([name]))
    }
    updateSubscription = (id) => {
        const { fields } = this.state
        const { subscriptionId } = fields
        if (this.validateForm(['subscriptionId', 'isAgree'])) {
            return false
        }
        let params = {
            subscriptionId: subscriptionId
        }
        const callback = (res) => {
            this.setState({ isLoading: false })
            if (res?.response_code === 0) {
                toast.success("Successfully Updated")
                this.props.history.push(RoutePath.VENDOR_DASHBOARD_SCREEN)
            }
        }
        this.setState({ isLoading: true })
        this.props.postSubscription(params, callback,)
    }
    clickAgree = (e) => {
        const { fields } = this.state;
        let name = "isAgree"
        this.setState({
            fields: {
                ...fields,
                [name]: e.target.checked
            }
        }, () => this.validateForm([name]))
    }
    render() {
        const { title, proceed, cancel, onFailure, textField, vendorId } = this.props
        const { isLoading, subscriptionlist, fields, error } = this.state
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabIndex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-100">
                            <div className="modal-body">
                                <div style={{ float: 'right', cursor: 'pointer' }}>
                                    <img
                                        style={{ height: '35px', width: '35px' }}
                                        id='close'
                                        src={Close}
                                        alt="X"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        className="modal-close "
                                        onClick={() => onFailure()} />
                                </div>
                                <form>
                                    <div
                                        className="row mb-4">
                                        <div
                                            className="col">
                                            {
                                                title && <h3
                                                    className="font18 fw-bold mb-5">{title}</h3>
                                            }
                                            <p className="text-bold">{Constants.SUBSCRIPTION_ID}</p>
                                            <select name='subscriptionId' className="form-select" value={fields['subscriptionId']} onChange={(e) => this.handleChange(e)}>
                                                <option hidden> {Constants.SELECT_SUBSCRIPTION_ID} </option>
                                                {
                                                    subscriptionlist?.length > 0 && subscriptionlist?.map((o) => {
                                                        return (
                                                            <option key={o?.id} value={o?.id} totalSec={o?.totalSec}>{o?.planName} ({(msToHMS(o?.totalSec))})</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {error['subscriptionId'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['subscriptionId']}</span>}
                                            <br />
                                            <div className="tacbox">
                                                <p style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}><span className="fa fa-exclamation-triangle"> </span> Existing subscriptions and add-ons will expire once this subscription is renewed.</p>
                                                <input id="checkbox" type="checkbox" name="isAgree" onClick={(e) => this.clickAgree(e)} />
                                                <label htmlFor="checkbox"> I agree to proceed</label>
                                            </div>
                                            {error['isAgree'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['isAgree']}</span>}
                                            {textField && <div className="col-12 col-lg pe-lg-3">
                                                <div className="input-group mb-3 mb-lg-0">
                                                    <textarea type="textarea" className="form-control " placeholder="Search by order no" />
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        {/* <button type="button" disabled="true" className="btn " id='onClickSuccess'>
                                            <span className="btn buttonSuccess font14 w-100 p-3">{proceed}</span>
                                        </button> */}
                                        <div
                                            id='onClickSuccess' className="col pe-0" onClick={(e) => this.updateSubscription(vendorId)}>
                                            <span className="btn buttonSuccess font14 w-100 p-3">{proceed}</span> </div>
                                        <div
                                            id="onClickFailure" className="col text-end" onClick={() => onFailure()}>
                                            <span className="btn buttonFailure font14 w-100 p-3">{cancel}</span> </div>

                                        {/* <button type="button" id="onClickFailure" className="btn col text-end">
                                            <span className="btn buttonFailure font14 w-100 p-3">{cancel}</span>
                                        </button> */}
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                {!isLoading && <div className="modal-backdrop show"></div>}
                {isLoading && <AppLoader />}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        getSubscriptionListData: Actions.getSubscription(state)
    }
}

export default withRouter(connect(mapStateToProps, {
    getSubscriptionAciveList: Actions.getSubscriptionActiveList,
    postSubscription: Actions.renewSubscriptionAction
})(RenewSubscripton))