import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import SubscriptionList from '../component/SubscriptionList';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import PaginationComponenent from '../component/Pagination';
import Constants, { LIMIT } from '../utils/Constants';
import { toast } from 'react-toastify';

class AdminSubscriptionList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageNumber: 1,
            totalItem: '',
            isLoading: false
        }
    }
    componentDidMount() {
        this.getSubscription()
    }

    getSubscription = () => {
        const {pageNumber}=this.state
        let params = {
            limit: LIMIT,
            pageNumber: pageNumber-1
        }
        const callback = (response) => {
            this.setState({ isLoading: false })
            if (response) {
                this.setState({ totalItem: response.response.totalItems })
            }
        }
        this.setState({ isLoading: true })
        this.props.getSubscriptionList(params, callback, true)
    }
    onChangePagination = (pageNumber) => {
        this.setState({ isLoading: true })
        let params = {
            pageNumber: (pageNumber - 1),
            limit: LIMIT
        }
        const callback = (response) => {
            this.setState({ isLoading: false })
        }
        this.props.getSubscriptionList(params, callback, true)
        this.setState({ pageNumber: pageNumber })
    }
    updateStatus = (id, active) => {
        const updateParams = {
            id,
            isActive: active ? 0 : 1
        }
        const callback = (response) => {
            this.setState({ isLoading: false })
            if (response.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.getSubscription()
            }
            else {
                toast.error(response.response_message)
            }
        }
        this.setState({ isLoading: true })
        this.props.updateSubscriptionDetails(updateParams, callback, true)
    }
    render() {
        const { pageNumber, totalItem, isLoading } = this.state;
        const { getSubscription = {} } = this.props
        const { data } = getSubscription
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <SubscriptionList subscriptionList={data?.subscriptions} updateStatus={(id, active) => this.updateStatus(id, active)} currentPage={pageNumber} />
                        <div className='page-content'>
                            {
                                data?.totalItems > (LIMIT - 1) ? <PaginationComponenent currentPage={pageNumber} countValue={data?.totalItems} onChangeHandle={this.onChangePagination} /> : ''
                            }
                        </div>
                    </div>
                </div>

                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getSubscription: Actions.getSubscription(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    getSubscriptionList: Actions.getSubscriptionList,
    updateSubscriptionDetails: Actions.updateSubscription,
})(AdminSubscriptionList));