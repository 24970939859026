import { DOWNLOAD_FEEDBACK, FEEDBACK } from "../actions/FeedBackAction";
import { takeLatest, put } from "redux-saga/effects";
import Actions from "../actions";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doPost, doVendorPost } from "../../utils/service/index";
export function* feedBackSaga({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.FEEDBACK_API, params);
        if (response) {
            yield put(Actions.feedBackSuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.feedBackFailureAction(e));
        callback && callback(e);
    }
}

export function* downloadFeedBackSaga({ params, callback }) {
    try {
        const response = yield doVendorPost(END_POINTS.VENDOR_DOWNLOAD_FEEDBACK, params);
        if (response) {
            yield put(Actions.downloadFeedbackSuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.downloadFeedbackFailureAction(e));
        callback && callback(e);
    }
}

export default function* feedBackListener() {
    yield takeLatest(FEEDBACK, feedBackSaga);
    yield takeLatest(DOWNLOAD_FEEDBACK, downloadFeedBackSaga);
}