import * as loginAction from "./LoginActions";
import * as customerList from "./CustomerListAction";
import * as customerdecline from "./CustomerDeclineAction";
import * as customerCallList from './CustomerCallListAction';
import * as updateAppointment from './UpdateAppointment';
import * as chatList from './ChatListAction';
import * as feedback from './FeedBackAction';
import * as callHistory from './CallHistory'
import * as screenShareAction from "./ScreenShareAction";
import * as agentDetailsAction from "./AgentDetails";
import * as subscriptionAction from './SubscriptionAction';
import * as DashboardAction from './DashboardAction';
import * as VendorList from './VendorList'
import * as AddOnAction from './AddOnAction'
import * as ARProductAction from './ARProductAction'
import * as VendorProfile from './VendorProfileAction'
import * as RenewSubscription from './RenewSubscriptionAction'
import * as AgeVerification from './AgeVerification'
import * as VbImageAction from './VBImageAction'
import * as vendorRoomAction from './VendorRoomAction'
import * as VendorRoomServiceAction from './VendorRoomServiceAction'
import * as VoiceRecorder from './VoiceRecordingAction'
const Actions = {
  ...loginAction,
  ...customerList,
  ...customerdecline,
  ...customerCallList,
  ...updateAppointment,
  ...chatList,
  ...feedback,
  ...callHistory,
  ...screenShareAction,
  ...agentDetailsAction,
  ...subscriptionAction,
  ...DashboardAction,
  ...VendorList,
  ...AddOnAction,
  ...ARProductAction,
  ...VendorProfile,
  ...RenewSubscription,
  ...AgeVerification,
  ...VbImageAction,
  ...vendorRoomAction,
  ...VendorRoomServiceAction,
  ...VoiceRecorder,
};

export default Actions;
export const doAction = (type, params = {}, callback, isAdmin) => ({ type, params, callback, isAdmin });
export const doResponseAction = (type, data = {}) => ({ type, data });
