import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import RoutePath from '../utils/routes/RoutePath';
import { toast } from 'react-toastify';
import Constants from '../utils/Constants';
import AddService from '../component/AddService';
import { isCharacterReg } from '../utils/validation';

const onlyNumberRegex = new RegExp(`^[0-9]+$`);

class AddVendorService extends Component {
    constructor(props) {
        super(props)
        this.state = {
            imageName: '',
            imageURL: '',
            error: {},
            message: {},
            isActive: false,
            isClick: false,
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({ isLoading: true })
            this.props?.getVendorServiceDetail({ id: this.props.match.params.id }, (response) => {
                const data = response || {}
                this.setState({
                    isActive: data?.response?.isActive,
                    serviceName: data?.response?.serviceName,
                    userId: data?.response?.userId,
                    isLoading: false
                })
            }, false)
        }
    }
    updateService = () => {
        const callback = (res) => {
            this.setState({ isLoading: false })
            const { response } = res
            if (res?.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.props.history.push(RoutePath.VENDOR_SERVICE)
            } else {
                toast.error(response?.data?.response_message)
            }
        }
        let params = {
            serviceName: this.state?.serviceName,
            id: this.props.match.params.id,
            userId: this.state?.userId
        }
        if (!params?.serviceName || params?.serviceName.trim().length === 0) {
            this.setState({ error: { serviceName: true }, message: { serviceName:"Please Enter Service Name" } })
        } else if (!isCharacterReg(params?.serviceName)) {
            this.setState({ error: { serviceName: true }, message: { serviceName: Constants.NAME_REGX } })
        } else if (!params.userId || params.userId == 'ALL') {
            this.setState({ error: { userId: true }, message: { userId: 'Please Select Service Head'} })
        } else {
            if (params.id) {
                this.setState({ isLoading: true })
                this.props.updateVendorService(params, callback, false)
            } else {
                this.setState({ isLoading: true })
                this.props.createVendorService(params, (res) => {
                    this.setState({ isLoading: false })
                    const { response } = res
                    if (res.response_code === 0) {
                        toast.success(Constants.ADDED_SUCCESSFULLY)
                        this.props.history.push(RoutePath.VENDOR_SERVICE)
                    } else {
                        toast.error(response?.data?.response_message)
                    }
                }, false)
            }
        }
    }

    handleServiceName = (e) => {
        let serviceName = e.target.value;
        if (!serviceName || serviceName.length === 0) {
            this.setState({ serviceName: serviceName, error: { serviceName: true }, message: { serviceName: Constants.PLEASE_ENTER_THE_NAME } })
        } else {
            this.setState({ serviceName: serviceName, error: { serviceName: false }, message: { serviceName: ' ' } })
        }
        this.setState({ serviceName: serviceName })
    }

    handleSrmServiceHead = (e) => {
        let userId = e.target.value;
        if (!userId) {
            this.setState({ userId: userId, error: { userId: true }, message: { userId: 'Please Select Service Head' } })
        } else {
            this.setState({ userId: userId, error: { userId: false }, message: { userId: ' ' } })
        }
        this.setState({ userId: userId })
    }
   

   
    render() {
        const { isLoading } = this.state
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <AddService
                            value={this.state}
                            handleSubmit={() => this.updateService()}
                            handleServiceName={(e) => { this.handleServiceName(e) }}
                            handleSrmServiceHead={(e) => { this.handleSrmServiceHead(e) }}
                            message={this.state.message}
                            isClick={this.state.isClick}
                            error={this.state.error}
                            isEdit={this.props.match.params.id}
                        />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {

    }
};
export default withRouter(connect(mapStateToProps, {
    createVendorService: Actions.createVendorService,
    updateVendorService: Actions.updateVendorService,
    getVendorServiceDetail: Actions.getVendorServiceDetail
})(AddVendorService));