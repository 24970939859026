import { doAdminGET, doAdminPost, doAdminPostFormData } from "../../utils/service";
import { put, takeLatest, all } from "redux-saga/effects";
import { END_POINTS } from "../../utils/service/EndPoints";
import Actions from "../actions";
import { CREATE_PRODUCT, GET_ALL_VENDOR_LIST, GET_AR_PRODUCT_LIST, GET_PRODUCT_LIST, PRODUCT_DETAILS, UPDATE_PRODUCT } from "../actions/ARProductAction";


export function* productListSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(isAdmin ? END_POINTS.GET_ADMIN_AR_PRODUCT_LIST : END_POINTS.GET_VENDOR_AR_PRODUCT_LIST, params, isAdmin);
        if (response) {
            yield put(Actions.getProductListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getProductListFailure(e));
        callback && callback(e);
    }
}

export function* createProductSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminPostFormData(isAdmin ? END_POINTS.POST_ADMIN_AR_PRODUCT_ADD : END_POINTS.POST_VENDOR_AR_PRODUCT_ADD, params, isAdmin);
        if (response) {
            yield put(Actions.createProductSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.createProductFailure(e));
        callback && callback(e);
    }
}

export function* updateProductSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminPostFormData(isAdmin ? END_POINTS.POST_ADMIN_AR_PRODUCT_UPDATE : END_POINTS.POST_VENDOR_AR_PRODUCT_UPDATE, params, isAdmin);
        if (response) {
            yield put(Actions.updateProductSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.updateProductFailure(e));
        callback && callback(e);
    }
}

export function* productDetailsSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(isAdmin ? END_POINTS.GET_ADMIN_AR_PRODUCT_DETAILS : END_POINTS.GET_VENDOR_AR_PRODUCT_DETAILS, params, isAdmin);
        if (response) {
            yield put(Actions.productDetailsSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.productDetailsFailure(e));
        callback && callback(e);
    }
}
export function* arProductListSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminPost(END_POINTS.GET_AR_PRODUCT_VALIDATION, params, isAdmin);
        if (response) {
            yield put(Actions.getArProductListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getArProductListFailure(e));
        callback && callback(e);
    }
}

export function* allVendorListSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(END_POINTS.GET_ALL_VENDOR_LIST, params, isAdmin);
        if (response) {
            yield put(Actions.getArProductListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getArProductListFailure(e));
        callback && callback(e);
    }
}
export default function* arProductListener() {
    yield all([
        takeLatest(GET_PRODUCT_LIST, productListSaga),
        takeLatest(CREATE_PRODUCT, createProductSaga),
        takeLatest(UPDATE_PRODUCT, updateProductSaga),
        takeLatest(PRODUCT_DETAILS, productDetailsSaga),
        takeLatest(GET_AR_PRODUCT_LIST, arProductListSaga),
        takeLatest(GET_ALL_VENDOR_LIST, allVendorListSaga),

    ]);
}