import { put, takeLatest, all } from "redux-saga/effects";
import { END_POINTS } from "../../utils/service/EndPoints";
import Actions from "../actions";
import { doSrmIpAgentGET, doVendorGET, doVendorPost } from "../../utils/service";
import { CREATE_VENDOR_ROOM_SERVICE, CREATE_VENDOR_SERVICE, GET_VENDOR_ROOM_SERVICE_LIST, GET_VENDOR_SERVICE_LIST, GET_VENDOR_SRM_IP_AGENT_LIST, UPDATE_VENDOR_ROOM_SERVICE, UPDATE_VENDOR_SERVICE, VENDOR_ROOM_SERVICE_DETAIL, VENDOR_SERVICE_DETAIL } from "../actions/VendorRoomServiceAction";

export function* vendorServiceListSaga({ params, callback}) {
    try {
        const response = yield doVendorGET( END_POINTS.GET_VENDOR_SERVICE_LIST,params);
        if (response) {
            yield put(Actions.getVendorServiceListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getVendorServiceListFailure(e));
        callback && callback(e);
    }
}

export function* createVendorServiceSaga({ params, callback}) {
    try {
        const response = yield doVendorPost(END_POINTS.POST_VENDOR_SERVICE_ADD, params);
        if (response) {
            yield put(Actions.createVendorServiceSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.createVendorServiceFailure(e));
        callback && callback(e);
    }
} 

export function* updateVendorServiceSaga({ params, callback}) {
    try {
        const response = yield doVendorPost(END_POINTS.POST_VENDOR_SERVICE_UPDATE, params);
        if (response) {
            yield put(Actions.updateVendorServiceSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.updateVendorServiceFailure(e));
        callback && callback(e);
    }
}

export function* vendorServiceDetailSaga({ params, callback}) {
    try {
        const response = yield doVendorGET(END_POINTS.GET_VENDOR_SERVICE_DETAIL, params);
        if (response) {
            yield put(Actions.getVendorServiceDetailSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getVendorServiceDetailFailure(e));
        callback && callback(e);
    }
}

export function* vendorRoomServiceListSaga({ params, callback}) {
    try {
        const response = yield doVendorGET( END_POINTS.GET_VENDOR_ROOM_SERVICE_LIST,params);
        if (response) {
            yield put(Actions.getVendorRoomServiceListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getVendorRoomServiceListFailure(e));
        callback && callback(e);
    }
}

export function* vendorRoomDetailSaga({ params, callback}) {
    try {
        const response = yield doVendorGET(END_POINTS.GET_VENDOR_ROOM_DETAILS, params);
        if (response) {
            yield put(Actions.getVendorRoomDetailSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getVendorRoomDetailFailure(e));
        callback && callback(e);
    }
}

export function* createVendorRoomServiceSaga({ params, callback}) {
    try {
        const response = yield doVendorPost(END_POINTS.POST_VENDOR_ROOM_SERVICE_ADD, params);
        if (response) {
            yield put(Actions.createVendorRoomServiceSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.createVendorRoomServiceFailure(e));
        callback && callback(e);
    }
}

export function* updateVendorRoomServiceSaga({ params, callback}) {
    try {
        const response = yield doVendorPost(END_POINTS.POST_VENDOR_ROOM_SERVICE_UPDATE, params);
        if (response) {
            yield put(Actions.updateVendorRoomServiceSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.updateVendorRoomServiceFailure(e));
        callback && callback(e);
    }
}

export function* vendorRoomServiceDetailSaga({ params, callback}) {
    try {
        const response = yield doVendorGET(END_POINTS.GET_VENDOR_ROOM_SERVICE_DETAIL, params);
        if (response) {
            yield put(Actions.getVendorRoomServiceDetailSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getVendorRoomServiceDetailFailure(e));
        callback && callback(e);
    }
}

export function* vendorSrmIpagentListSaga({params,callback}) {
    try {
        const response = yield doVendorGET( END_POINTS.GET_VENDOR_SRM_IP_AGENT_LIST,params);
        if (response) {
            yield put(Actions.getVendorSrmIpAgentListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getVendorSrmIpAgentListFailure(e));
        callback && callback(e);
    }
}

export default function* vendorRoomServiceListener() {
    yield all([
        takeLatest(GET_VENDOR_SERVICE_LIST, vendorServiceListSaga),
        takeLatest(CREATE_VENDOR_SERVICE, createVendorServiceSaga),
        takeLatest(UPDATE_VENDOR_SERVICE, updateVendorServiceSaga),
        takeLatest(VENDOR_SERVICE_DETAIL, vendorServiceDetailSaga),
        takeLatest(GET_VENDOR_ROOM_SERVICE_LIST, vendorRoomServiceListSaga),
        takeLatest(CREATE_VENDOR_ROOM_SERVICE, createVendorRoomServiceSaga),
        takeLatest(UPDATE_VENDOR_ROOM_SERVICE, updateVendorRoomServiceSaga),
        takeLatest(VENDOR_ROOM_SERVICE_DETAIL, vendorRoomServiceDetailSaga),
        takeLatest(GET_VENDOR_SRM_IP_AGENT_LIST, vendorSrmIpagentListSaga),
        


    ]);
}