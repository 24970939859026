import { takeLatest, put, all } from "redux-saga/effects";
import Actions from "../actions";
import { GET_VENDOR_CONFIG_WEBHOOK, GET_VENDOR_ONBOARD, UPDATE_VENDOR, UPDATE_VENDOR_CONFIG_WEBHOOK, VENDOR_ACTIVE_LIST, VENDOR_AGENT_DELETE, VENDOR_CONFIG_WEBHOOK, VENDOR_DETAILS, VENDOR_LIST, VENDOR_ONBOARD } from "../actions/VendorList";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doAdminGET, doAdminPost, doVendorGET, doVendorPost } from "../../utils/service/index";
export function* vendorListSaga({ params, callback, isAdmin = true }) {
    try {
        const response = yield doAdminGET(END_POINTS.ADMIN_VENDOR_LIST, params, isAdmin);
        if (response) {
            yield put(Actions.vendorListSuccess(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.vendorListFailure(e));
        callback && callback(e);
    }
}

export function* vendorActiveListSaga({ params, callback, isAdmin = true }) {
    try {
        const response = yield doAdminGET(END_POINTS.ADMIN_VENDOR_ACTIVE_LIST, params, isAdmin);
        if (response) {
            yield put(Actions.vendorActiveListSuccess(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.vendorActiveListFailure(e));
        callback && callback(e);
    }
}

export function* vendorAgentDeleteSaga({ params, callback, isAdmin = true }) {
    try {
        const response = yield doAdminPost(END_POINTS.ADMIN_VENDOR_DELETE_AGENT, params, isAdmin);
        if (response) {
            yield put(Actions.vendorAgentDeleteSuccess(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.vendorAgentDeleteFailure(e));
        callback && callback(e);
    }
}

export function* updateVendorListSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminPost(isAdmin ? END_POINTS.ADMIN_VENDOR_UPDATE : END_POINTS.UPDATE_VENDOR_PROFILE, params, isAdmin);
        if (response) {
            yield put(Actions.updateVendorSuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.updateVendorFailureAction(e));
        callback && callback(e?.response?.data);
    }
}

export function* selectedVendorDetails({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(`${END_POINTS.ADMIN_VENDOR_DETAILS}?id=${params.id}`, {}, isAdmin);
        if (response) {
            yield put(Actions.vendorDetailsSuccess(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.vendorDetailsFailure(e));
        callback && callback(e);
    }
}

export function* getAdminVendorDetails({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(END_POINTS.ADMIN_VENDOR_DETAILS, params, callback, isAdmin);
        if (response) {
            yield put(Actions.getVendorOnboardDetailsSuccessAction(response?.response));
            callback && callback(response?.response);
        }
    } catch (err) {
        yield put(Actions.getVendorOnboardDetailsFailureAction(err));
        callback && callback(err);
    }
}
export function* registerAdminVendor({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminPost('admin/vendor/register', params, callback, isAdmin);
        if (response) {
            yield put(Actions.vendorOnBoardSuccessAction(response));
            callback && callback(response);
        }
    } catch (err) {
        yield put(Actions.vendorOnBoardFailureAction(err));
        callback && callback(err);
    }
}



export function* getWebhookConfig({ params, callback, isAdmin }) {
    try {
        const response = yield doVendorGET(END_POINTS.GET_VENDOR_STATUS_URL, params);
        if (response) {
            yield put(Actions.getVendorWeBhookConfigSuccess(response));
            callback && callback(response);
        }
    } catch (err) {
        yield put(Actions.getVendorWeBhookConfigFailure(err));
        callback && callback(err);
    }
}

export function* webhookConfig({ params, callback, isAdmin }) {
    try {
        const response = yield doVendorPost(END_POINTS.VENDOR_STATUS_URL, params, isAdmin);
        if (response) {
            yield put(Actions.vendorWeBhookConfigSuccess(response));
            callback && callback(response);
        }
    } catch (err) {
        yield put(Actions.vendorWeBhookConfigFailure(err));
        callback && callback(err);
    }
}

export function* updateWebhookConfig({ params, callback, isAdmin }) {
    try {
        const response = yield doVendorPost(END_POINTS.VENDOR_STATUS_URL_UPDATE, params, isAdmin);
        if (response) {
            yield put(Actions.updateVendorWeBhookConfigSuccess(response));
            callback && callback(response);
        }
    } catch (err) {
        yield put(Actions.updateVendorWeBhookConfigFailure(err));
        callback && callback(err);
    }
}

export default function* vendorListSagaListener() {
    yield all([
        takeLatest(VENDOR_LIST, vendorListSaga),
        takeLatest(VENDOR_ONBOARD, registerAdminVendor),
        takeLatest(UPDATE_VENDOR, updateVendorListSaga),
        takeLatest(GET_VENDOR_ONBOARD, getAdminVendorDetails),
        takeLatest(VENDOR_DETAILS, selectedVendorDetails),
        takeLatest(GET_VENDOR_CONFIG_WEBHOOK, getWebhookConfig),
        takeLatest(VENDOR_CONFIG_WEBHOOK, webhookConfig),
        takeLatest(UPDATE_VENDOR_CONFIG_WEBHOOK, updateWebhookConfig),
        takeLatest(VENDOR_ACTIVE_LIST, vendorActiveListSaga),
        takeLatest(VENDOR_AGENT_DELETE, vendorAgentDeleteSaga)
    ]);
}
