import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import RoutePath from '../utils/routes/RoutePath';
import { toast } from 'react-toastify';
import Constants from '../utils/Constants';
import { convertToMilliSeconds, isCharacterReg, minutesToHours, minutesToSec, msToHMS } from '../utils/validation';
import AddAddOn from '../component/AddAddOn';
import AddARProduct from '../component/AddARProduct';
import { convertToBase64 } from '../utils/ConvertBase64';
import AddVBImage from '../component/AddVBImage';
const onlyNumberRegex = new RegExp(`^[0-9]+$`);

class AddVendorVBImage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            imageName: '',
            imageURL: '',
            error: {},
            message: {},
            isActive: false,
            isClick: false,
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({ isLoading: true })
            this.props?.vbImageDetails({ id: this.props.match.params.id }, (response) => {
                const data = response || {}
                this.setState({
                    isActive: data?.response?.isActive,
                    imageName: data?.response?.imageName,
                    imageURL: data?.response?.imageURL,
                    isLoading: false
                })
            }, false)
        }
    }
    updateImage = () => {
        const callback = (res) => {
            this.setState({ isLoading: false })
            const { response } = res
            if (res?.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.props.history.push(RoutePath.VENDOR_VB_LIST)
            } else {
                toast.error(response?.data?.response_message)
            }
        }
        let params = {
            imageName: this.state?.imageName,
            vbImageURL: this.state?.imageURL,
            id: this.props.match.params.id,
        }
        if (!params?.imageName || params?.imageName.length === 0) {
            this.setState({ error: { imageName: true }, message: { imageName: Constants.PLEASE_ENTER_THE_NAME } })
        } else if (!isCharacterReg(params?.imageName)) {
            this.setState({ error: { imageName: true }, message: { imageName: Constants.NAME_REGX } })
        } else if (params?.imageName.trim().length < 3) {
            this.setState({ error: { imageName: true }, message: { imageName: Constants.NAME_LENGTH } })
        } else if (!params?.vbImageURL || params?.vbImageURL.length === 0) {
            this.setState({ error: { imageURL: true }, message: { imageURL: "Please Select One Image" } })
        } else {
            if (this.state.isClick) {
                params.imageURL = params.vbImageURL
            }
            if (params.id) {
                this.setState({ isLoading: true })
                this.props.updateImage(params, callback, false)
            } else {
                this.setState({ isLoading: true })
                this.props.addImage(params, (res) => {
                    this.setState({ isLoading: false })
                    const { response } = res
                    if (res.response_code === 0) {
                        toast.success(Constants.ADDED_SUCCESSFULLY)
                        this.props.history.push(RoutePath.VENDOR_VB_LIST)
                    } else {
                        toast.error(response?.data?.response_message)
                    }
                }, false)
            }
        }
    }
    removeProducyImage = () => {
        if (this.props.match.params.id) {
            this.setState({ isLoading: true })
            let params = {
                id: this.props.match.params.id,
                removeS3: true
            }
            this.props.updateImage(params, (res) => {
                const { response } = res
                this.setState({ isLoading: false })
                if (res.response_code === 0) {
                    toast.success(Constants.DELETED_SUCCESSFULLY)
                    this.componentDidMount()
                    document.getElementById('exampleFormControlVbImage').value = "";
                } else {
                    toast.error(response?.data?.response_message)
                }
            }, false)
        } else {
            this.setState({ imageURL: "" })
            document.getElementById('exampleFormControlVbImage').value = "";
        }
    }
    handleImageName = (e) => {
        let imageName = e.target.value;
        if (!imageName || imageName.length === 0) {
            this.setState({ imageName: imageName, error: { imageName: true }, message: { imageName: Constants.PLEASE_ENTER_THE_NAME } })
        } else if (!isCharacterReg(imageName)) {
            this.setState({ imageName: imageName, error: { imageName: true }, message: { imageName: Constants.NAME_REGX } })
        } else if (imageName.trim().length < 3) {
            this.setState({ imageName: imageName, error: { imageName: true }, message: { imageName: Constants.NAME_LENGTH } })
        } else {
            this.setState({ imageName: imageName, error: { imageName: false }, message: { imageName: ' ' } })
        }
        this.setState({ imageName: imageName })
    }

    handleImageURL = async (e) => {
        let imageURL = e.target.value;
        if (!imageURL || imageURL.length === 0) {
            this.setState({ imageURL: imageURL, error: { imageURL: true }, message: { imageURL: "Please Upload .jpeg or png file." } })
        } else {
            this.setState({ error: { imageURL: false }, message: { imageURL: ' ' } })
        }
        let file = e.target.files[0];
        let arrary = file && file.type.split('/')
        let type = arrary[arrary.length - 2]
        if (type !== 'image') {
            this.setState({ imageURL: imageURL, error: { imageURL: true }, message: { imageURL: "Please Upload jpeg or png file only." } })
        } else {
            if (typeof file !== "undefined") {
                this.setState({ isLoading: true })
                let imageBase64 = await convertToBase64(file)
                this.setState({
                    isLoading: false,
                    isClick: true,
                    error: { imageURL: false }, message: { imageURL: ' ' },
                    imageURL: imageBase64
                })
            } else {
                this.setState({ isClick: false })
            }
        }
    }
    render() {
        const { isLoading } = this.state
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <AddVBImage
                            value={this.state}
                            handleImageName={(e) => { this.handleImageName(e) }}
                            handleImageURL={(e) => { this.handleImageURL(e) }}
                            handleSubmit={() => this.updateImage()}
                            removeProducyImage={() => this.removeProducyImage()}
                            message={this.state.message}
                            isClick={this.state.isClick}
                            error={this.state.error}
                            isEdit={this.props.match.params.id}
                        />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {

    }
};
export default withRouter(connect(mapStateToProps, {
    addImage: Actions.createVbImage,
    updateImage: Actions.updateVbImage,
    vbImageDetails: Actions.vbImageDetails
})(AddVendorVBImage));