import { Component } from "react";
import { withRouter } from "react-router-dom";
import Constants from "../utils/Constants";
import RoutePath from "../utils/routes/RoutePath";
import '@google/model-viewer/dist/model-viewer'

class AddVendorRoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: ''
        }
    }

    render() {
        const { isEdit, error, message, handleVendorRoomName, handleVendorRoomId, handleSubmit, value } = this.props
        return (
            <div className="content-part px-5 py-4">
                <div className="agent-details">
                    <div className="row m-0">
                        <div className="col-md-6" >
                            <div className="mt-3">
                                <p className="text-bold">Room Number</p>
                                <input autoComplete="off" type="text" name='roomId' maxLength="20" className="form-control w-75" value={value?.roomId} placeholder="Enter Room Number" onChange={(e) => handleVendorRoomId(e)} />
                                {error?.roomId && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.roomId}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Room Name</p>
                                <input autoComplete="off" type="text" name='roomName' maxLength="20" className="form-control w-75" value={value?.roomName} placeholder="Enter Room Name" onChange={(e) => handleVendorRoomName(e)} />
                                {error?.roomName && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.roomName}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 me-5 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.push(RoutePath.VENDOR_ROOM)}
                        >{Constants.BACK}</button>
                        <button type="button" className="btn input-btn mx-3" onClick={() => handleSubmit()}>{isEdit ? Constants.UPDATE : Constants.CREATE}</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(AddVendorRoom)
