import { Component } from "react";
import { withRouter } from "react-router-dom";
import Constants from "../utils/Constants";
import RoutePath from "../utils/routes/RoutePath";

class AddSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomId: ''
        }
    }


    render() {
        const { isEdit, error, message, handleDescriptions, handlesubscriptionPrice, handleAgentLimit, handleCartGeneration,
            handleArgumentedReality, handleTotalMinutes, handleUserName, handleSubmit, value,
            // handleCustomerFeedback, handleFullyCustomBranding, handleAdditionalCharges, handleUsageReporting,
            // handleLivechat, handlePaymentIntegration, handleScreenSharing, handleliveStreamHours, handleAvailability,
            // handleliveStreamMinutes,
        } = this.props
        return (
            <div className="content-part px-5 py-4">
                <div className="agent-details">
                    <div className="row m-0">
                        <div className="col-md-6" >
                            <div className="mt-3">
                                <p className="text-bold">Subscription Name</p>
                                <input autoComplete="off" type="text" name='planName' maxLength="35" className="form-control w-75" value={value?.planName} placeholder="Enter Subscription Name" onChange={(e) => handleUserName(e)} />
                                {error?.planName && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.planName}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Subscription Price</p>
                                <input autoComplete="off" type="text" maxLength="7" pattern="[+-]?\d+(?:[.,]\d+)?" name='planPrice' value={value?.planPrice}
                                    className="form-control w-75" placeholder="Enter Subscription Price"
                                    onChange={(e) => handlesubscriptionPrice(e)} />
                                {error?.planPrice && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.planPrice}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Agent Limit</p>
                                <input autoComplete="off" type="text" maxLength="3" pattern="[+-]?\d+(?:[.,]\d+)?" name='agentLimit' placeholder="Enter Agent Limit" value={value?.agentLimit}
                                    className="form-control w-75" onChange={(e) => handleAgentLimit(e)} />
                                {error?.agentLimit && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message?.agentLimit}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Total Stream Minutes</p>
                                <input autoComplete="off" type="number" name='totalMins' value={value?.totalMins}
                                    className="form-control w-75" placeholder="Enter Total Stream Minutes"
                                    onChange={(e) => handleTotalMinutes(e)} />
                                {error?.totalMins && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.totalMins}</span>}
                            </div>
                            <div className="mt-3">
                                {error?.time && <span style={{ color: 'black', fontSize: '16px', marginTop: '10px' }}>{message.time}</span>}
                            </div>
                            {/* <div className="mt-3">
                                <p className="text-bold">Availability</p>
                                <select className="form-select w-75" value={value?.availability} onChange={(e) => handleAvailability(e)}>
                                    <option hidden>-- Select Options --</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {error?.availability && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.availability}</span>}
                            </div> */}
                            {/* <div className="mt-3">
                                <p className="text-bold">Customer Feedback</p>
                                <select className="form-select w-75" value={value?.customerFeedback} onChange={(e) => handleCustomerFeedback(e)}>
                                    <option hidden>-- Select Options --</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {error?.customerFeedback && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.customerFeedback}</span>}
                            </div> */}
                            {/* <div className="mt-3">
                                <p className="text-bold">Usage Report</p>
                                <select className="form-select w-75" value={value?.usageReporting} onChange={(e) => handleUsageReporting(e)}>
                                    <option hidden>-- Select Options --</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {error?.usageReporting && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.usageReporting}</span>}
                            </div> */}
                            {/* <div className="mt-3">
                                <p className="text-bold">Fully Custom Branding</p>
                                <select className="form-select w-75" value={value?.fullyCustomBranding} onChange={(e) => handleFullyCustomBranding(e)}>
                                    <option hidden>-- Select Options --</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {error?.fullyCustomBranding && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.fullyCustomBranding}</span>}
                            </div> */}
                        </div>
                        <div className="col-md-6">
                            <div className="mt-3">
                                <p className="text-bold">Cart Generation (Screen Sharing, Age verification)</p>
                                <select className="form-select w-75" value={value?.cartGeneration} onChange={(e) => handleCartGeneration(e)}>
                                    <option hidden>-- Select Options --</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {error?.cartGeneration && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.cartGeneration}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Argumented Reality</p>
                                <select className="form-select w-75" value={value?.argumentedReality} onChange={(e) => handleArgumentedReality(e)}>
                                    <option hidden>-- Select Options --</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {error?.argumentedReality && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.argumentedReality}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Description</p>
                                <textarea type="text" name='description' placeholder="Enter Description" value={value?.description}
                                    className="form-control w-75" onChange={(e) => handleDescriptions(e)} />
                                {error?.description && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.description}</span>}
                            </div>
                            {/* <div className="mt-3">
                                <p className="text-bold">Live Stream Hours</p>
                                <input autoComplete="off" type="text" maxLength="9" pattern="[+-]?\d+(?:[.,]\d+)?" name='liveStreamHours' value={value?.liveStreamHours}
                                    className="form-control w-75" placeholder="Enter Live Stream Hours"
                                    onChange={(e) => handleliveStreamHours(e)} />
                                {error?.liveStreamHours && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.liveStreamHours}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Live Stream Minutes</p>
                                <input autoComplete="off" type="text" maxLength="9" name='liveStreamMinutes' value={value?.liveStreamMins}
                                    className="form-control w-75" placeholder="Enter Live Stream Minutes"
                                    onChange={(e) => handleliveStreamMinutes(e)} />
                                {error?.liveStreamMins && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.liveStreamMins}</span>}
                            </div> */}
                            {/* <div className="mt-3">
                                <p className="text-bold">Live Chat</p>
                                <select className="form-select w-75" value={value?.livechat} onChange={(e) => handleLivechat(e)}>
                                    <option hidden>-- Select Options --</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {error?.livechat && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.livechat}</span>}
                            </div> */}
                            {/* <div className="mt-3">
                                <p className="text-bold">Payment Integration</p>
                                <select className="form-select w-75" value={value?.paymentIntegration} onChange={(e) => handlePaymentIntegration(e)}>
                                    <option hidden>-- Select Options --</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {error?.paymentIntegration && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.paymentIntegration}</span>}
                            </div> */}
                            {/* <div className="mt-3">
                                <p className="text-bold">Screen Sharing</p>
                                <select className="form-select w-75" value={value?.screenSharing} onChange={(e) => handleScreenSharing(e)}>
                                    <option hidden>-- Select Options --</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {error?.screenSharing && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.screenSharing}</span>}
                            </div> */}
                            {/* <div className="mt-3">
                                <p className="text-bold">Additional Charges</p>
                                <input autoComplete="off" type="number" name='Additional Charges' value={value?.additionalCharges}
                                    className="form-control w-75" placeholder="Enter Additional Charges"
                                    onChange={(e) => handleAdditionalCharges(e)} />
                                {error?.additionalCharges && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.additionalCharges}</span>}
                            </div> */}
                        </div>
                    </div>
                    <div className="mt-5 me-5 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.push(RoutePath.SUBSCRIPTION_LIST)}>{Constants.BACK}</button>
                        <button type="button" className="btn input-btn mx-3" onClick={() => handleSubmit()}>{isEdit ? Constants.UPDATE : Constants.CREATE}</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(AddSubscription)
