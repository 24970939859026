import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import VendorRoomViewScreen from '../component/VendorRoomViewScreen';
import { productLoading } from '../redux/reducer/ARProductReducer';


class VendorRoomView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            viewData: []
        }
    }
    componentDidMount() {
        this.getVendorRoomDetails()
    }
    getVendorRoomDetails = () => {
        let params = {
            id: this.props.match.params.id
        }
        this.setState({ isLoading: true })
        const callback = (response) => {
            if (response) {
                this.setState({ viewData: response?.response, isLoading: false })
            }
        }
        this.props.getVendorRoomDetails(params, callback)
    }
    render() {
        const { viewData, isLoading } = this.state;
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <VendorRoomViewScreen data={viewData} />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        isLoading: productLoading(state)
    }
};

export default withRouter(connect(mapStateToProps, {
    getVendorRoomDetails: Actions.getVendorRoomDetails,
})(VendorRoomView));