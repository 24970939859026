import { CUSTOMER_DECLINE } from "../actions/CustomerDeclineAction";
import { takeLatest, put } from "redux-saga/effects";
import Actions from "../actions";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doPost } from "../../utils/service/index";
export function* customerDeclineSaga({ params, callback }) {
  try {
    const response = yield doPost(END_POINTS.CUSTOMER_DECLINE_API, params);
    if (response) {
      yield put(Actions.customerDeclineSuccess(response));
      callback && callback(response);
    }
  } catch (e) {
    yield put(Actions.customerDeclineFailure(e));
    callback && callback(e);
  }
}

export default function* customerDeclineSagaListener() {
  yield takeLatest(CUSTOMER_DECLINE, customerDeclineSaga);
}
