import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button, Form, Pagination } from "react-bootstrap";
import "../assets/css/voice.css";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../redux/actions';
import { toast } from "react-toastify";
import { BASE_URL } from "../utils/Constants";
import NoDataFound from "../component/NoDataFound";
import moment from "moment";

class VoiceRecorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showModal: false,
      selectedData: null,
      currentPage: 1,
      itemsPerPage: 5,
      searchName: "",
      fromDate: "",
      toDate: "",
    };
    this.timer = null; // Timer for debounce
  }

  componentDidMount() {
    this.getRecordingVoiceList();
  }

  // Debounce function for delaying API call
  debounceSearch = (func, delay) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(func, delay);
  };

  // Function to fetch voice list
  getRecordingVoiceList = () => {
    const { searchName, fromDate, toDate } = this.state;

    const params = {
      name: searchName,
      startDate: fromDate,
      endDate: toDate
    };

    const callback = (res) => {
      if (res) {
        res?.reverse()
        let updatedData = res?.map((o) => {
          const relativePath = o?.filePath.split('recordings/')[1];
          let data = {
            id: o?.id,
            name: o?.name,
            startDate: o?.startDate,
            endDate: o?.endDate,
            fileName: o?.fileName,
            filePath: relativePath
          };
          return data;
        });
        this.setState({ data: updatedData });
      } else {
        toast.error(res?.response_message || "Sorry something went wrong");
      }
    };

    this.props.getVoiceList(params, callback, false);
  };

  // Update state and trigger delayed API call on input change
  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ searchName: value });
    this.debounceSearch(this.getRecordingVoiceList, 3000); // 3 second debounce
  };

  // Update state and trigger delayed API call on from date change
  handleFromDateChange = (e) => {
    const { value } = e.target;
    this.setState({ fromDate: value }, () => {
      // Automatically trigger API call if both fromDate and toDate are selected
      if (this.state.fromDate && this.state.toDate) {
        this.getRecordingVoiceList();
      }
    });
  };

  // Update state and trigger delayed API call on to date change
  handleToDateChange = (e) => {
    const { value } = e.target;
    this.setState({ toDate: value }, () => {
      // Automatically trigger API call if both fromDate and toDate are selected
      if (this.state.fromDate && this.state.toDate) {
        this.getRecordingVoiceList();
      }
    });
  };

  // Handle clearing filters
  handleClearFilters = () => {
    this.setState({
      searchName: "",
      fromDate: "",
      toDate: ""
    }, () => {
      // Trigger API call after clearing filters
      this.getRecordingVoiceList();
    });
  };

  // Handle pagination page change
  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  // Handle view button click to show modal
  handleViewClick = (data) => {
    this.setState({ showModal: true, selectedData: data });
  };

  // Close modal
  handleClose = () => {
    this.setState({ showModal: false, selectedData: null });
  };

  render() {
    const { data, currentPage, itemsPerPage, searchName, fromDate, toDate } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = data?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(data?.length / itemsPerPage);

    return (
      <div className="container mt-5 search-box">
        <Form className="row gy-2 gx-3 align-items-center">
          {/* <div className="col-auto">
            <Form.Group controlId="searchName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={searchName}
                onChange={this.handleSearchChange}
              />
            </Form.Group>
          </div> */}
          <div className="col-sm-3">
            <Form.Group controlId="fromDate">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                value={fromDate}
                onChange={this.handleFromDateChange}
              />
            </Form.Group>
          </div>
          <div className="col-sm-3">
            <Form.Group controlId="toDate">
              <Form.Label>To Date</Form.Label>
              <Form.Control
                type="date"
                value={toDate}
                min={fromDate}
                onChange={this.handleToDateChange}
              />
            </Form.Group>
          </div>
          <div className="col-auto mt-4">
            <Button variant="secondary" onClick={this.handleClearFilters}>Clear</Button>
          </div>
        </Form>
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <th>S.NO</th>
                {/* <th>NAME</th> */}
                <th>START DATE</th>
                <th>END DATE</th>
                <th>VIEWS</th>
              </tr>
            </thead>
            <tbody>
              {currentData?.length > 0 ? currentData?.map((value, index) => {
                const serialNumber = (currentPage - 1) * itemsPerPage + index + 1;
                return <tr key={index}>
                  <td>{serialNumber}</td>
                  {/* <td>{value?.name}</td> */}
                  <td>{moment(value.startDate).format("DD/MM/YYYY hh:mm:ss:A")}</td>
                  <td>{moment(value.endDate).format("DD/MM/YYYY hh:mm:ss:A")}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.handleViewClick(value)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              }) : <NoDataFound />}
            </tbody>
          </table>
        </div>

        {currentData?.length > 0 && <Pagination>
          <Pagination.First onClick={() => this.handlePageChange(1)} />
          <Pagination.Prev
            onClick={() =>
              this.handlePageChange(
                currentPage > 1 ? currentPage - 1 : currentPage
              )
            }
          />
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => this.handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}

          <Pagination.Next
            onClick={() =>
              this.handlePageChange(
                currentPage < totalPages ? currentPage + 1 : currentPage
              )
            }
          />
          <Pagination.Last onClick={() => this.handlePageChange(totalPages)} />
        </Pagination>}

        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.selectedData && (
              <div className="details">
                <div className="detail-item">
                  <strong>Id:</strong>
                  <span>{this.state.selectedData?.id}</span>
                </div>
                {/* <div className="detail-item">
                  <strong>Name:</strong>
                  <span>{this.state.selectedData?.name}</span>
                </div> */}
                <div className="detail-item">
                  <strong>Start date and time:</strong>
                  <span>{moment(this.state.selectedData?.startDate).format("DD/MM/YYYY hh:mm:ss:A")}</span>
                </div>
                <div className="detail-item">
                  <strong>End date and time:</strong>
                  <span>{moment(this.state.selectedData?.endDate).format("DD/MM/YYYY hh:mm:ss:A")}</span>
                </div>
                <div className="detail-item">
                  <strong>Audio:</strong>
                  <audio controls>
                    <source
                      src={`${BASE_URL}/recordings/${this.state.selectedData?.filePath}`}
                      type="audio/wav"
                    />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {};
}


export default withRouter(connect(mapStateToProps, {
  getVoiceList: Actions.getVoiceRecordingListAction,
})(VoiceRecorder));
