import React, { Component } from 'react';
import Constants from '../utils/Constants';

class NoDataFound extends Component {
    render() {
        return (
            <tr className="tr-shadow">
                <td colSpan="10" className="text-center">
                    {Constants.NO_DATA_FOUND}
                </td>
            </tr>
        );
    }
}

export default NoDataFound;