import Actions from "../actions";

const initialState = {
  isLoading: false,
  tokenDetails: {},
  adminTokenDetails: {},
  vendorTokenDetails: {},
  error: false,
  otp: {},
  publicToken: {},
  setPassword: {},
  vendorLogo: "",
  vendorName: "",
  agentName: "",
  isAR: "",
  argumentedReality: "",
  cartGeneration: "",
  paymentStatus: ""
};

export const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        tokenDetails: action?.data?.user?.roleId === 3 ? action.data : state.tokenDetails,
        adminTokenDetails: action?.data?.user?.roleId === 1 ? action.data : state.adminTokenDetails,
        vendorTokenDetails: action?.data?.user?.roleId === 2 ? action.data : state.vendorTokenDetails,
        vendorLogo: action?.data?.user?.roleId === 2 ? action?.data?.userDetails?.Vendor?.vendorLogo : action?.data?.user?.roleId === 3 ? action?.data?.userDetails?.VendorAgent?.Vendor?.vendorLogo : state.vendorTokenDetails?.userDetails?.Vendor?.vendorLogo,
        vendorName: action?.data?.user?.roleId === 2 ? action?.data?.user?.fullName : state.vendorTokenDetails?.user?.fullName,
        agentName: action?.data?.user?.roleId === 3 ? action?.data?.user?.fullName : state.tokenDetails?.user?.fullName,
        isAR: action?.data?.user?.roleId === 2 ? action?.data?.userDetails?.Vendor?.isAR : state.vendorTokenDetails?.userDetails?.Vendor?.isAR,
        argumentedReality: action?.data?.user?.roleId === 2 ? action?.data?.userDetails?.Vendor?.Subscription?.argumentedReality : state.vendorTokenDetails?.userDetails?.Vendor?.Subscription?.argumentedReality,
        cartGeneration: action?.data?.user?.roleId === 3 ? action?.data?.userDetails?.VendorAgent?.Vendor?.Subscription?.cartGeneration : state.tokenDetails?.userDetails?.VendorAgent?.Vendor?.Subscription?.cartGeneration,
        paymentStatus: action?.data?.user?.roleId === 2 ? action?.data?.userDetails?.Vendor?.paymentStatus : state.vendorTokenDetails?.userDetails?.Vendor?.paymentStatus,
        error: false,
      };
    }

    case Actions.LOGIN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.LOGIN_VERIFY_EMAIL: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.LOGIN_VERIFY_EMAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        otp: action.data
      }
    }
    case Actions.LOGIN_VERIFY_EMAIL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }
    case Actions.LOGIN_VERIFY_OTP: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.LOGIN_VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.LOGIN_VERIFY_OTP_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true
      }
    }
    case Actions.LOGIN_RESET_PASSWORD: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.LOGIN_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.LOGIN_RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true
      }
    }
    case Actions.FORGET_PASSWORD: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.FORGET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        publicToken: action.params
      }
    }
    case Actions.FORGET_PASSWORD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true
      }
    }
    case Actions.SET_NEW_PASSWORD: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.SET_NEW_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        setPassword: action.data
      }
    }
    case Actions.SET_NEW_PASSWORD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true
      }
    }

    case Actions.SOCKET_VENDOR_NAME_UPDATE: {
      return {
        ...state,
        isLoading: false,
        vendorLogo: action?.data?.roleId === 2 ? action?.data?.vendorLogo : state.vendorTokenDetails?.userDetails?.Vendor?.vendorLogo,
        vendorName: action?.data?.roleId === 2 ? action?.data?.fullName : state.vendorTokenDetails?.user?.fullName,
        isAR: action?.data?.roleId === 2 ? action?.data?.isAR : state.vendorTokenDetails?.userDetails?.Vendor?.isAR,
        error: false,
      };
    }
    case Actions.SOCKET_AGENT_NAME_UPDATE: {
      return {
        ...state,
        isLoading: false,
        agentName: action?.data?.roleId === 3 ? action?.data?.fullName : state.tokenDetails?.user?.fullName,
        error: false,
      };
    }
    case Actions.SOCKET_PAYMENT_UPDATE: {
      return {
        ...state,
        isLoading: false,
        paymentStatus: action?.data?.roleId === 2 ? action?.data?.paymentStatus : state.vendorTokenDetails?.userDetails?.Vendor?.paymentStatus,
        argumentedReality: action?.data?.roleId === 2 ? action?.data?.argumentedReality : state.vendorTokenDetails?.userDetails?.Vendor?.Subscription?.argumentedReality,
        cartGeneration: action?.data?.roleId === 2 ? action?.data?.cartGeneration : action?.data?.roleId === 3 && action?.data?.cartGeneration,
        error: false,
      };
    }
    default: {
      return state;
    }
  }
};
export const getToken = (state) => state?.LOGIN_CRED?.tokenDetails;