import { takeLatest, put, all } from "redux-saga/effects";
import Actions from "../actions";
import { CUSTOMER_CALL_LIST } from "../actions/CustomerCallListAction";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doAgentGET } from "../../utils/service/index";
export function* customerCallListSaga({ params, callback }) {
    try {
        const response = yield doAgentGET(END_POINTS.CUSTOMER_CALL_LIST_API, params);
        if (response) {
            yield put(Actions.customerCallListSuccess(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.customerCallListFailure(e));
        callback && callback(e);
    }
}
export default function* customerCallListListener() {
    yield all([
        takeLatest(CUSTOMER_CALL_LIST, customerCallListSaga),
    ]);
}
