import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import ViewAgentScreen from '../component/ViewAgentScreen';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import { LIMIT } from '../utils/Constants';

class ViewAgent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isLoading: false,
            isSrmIpAgent: 0,
            pageNumber: 1,
            totalItems: '',
            list: []
        }
    }
    componentDidMount() {
        const { agentSelectedId, isAgent, agentState = {} } = this.props;
        let param = {
            id: agentSelectedId
        }
        const callback = (response) => {
            this.setState({ data: response?.response, isLoading: false })
            let isSrmIpAgent = response?.response?.vendorAgentDetails?.length > 0 ? response?.response?.vendorAgentDetails[0]?.isSrmIpAgent : response?.response?.VendorAgent?.isSrmIpAgent
            this.getCallHistory(isSrmIpAgent)
        }
        this.setState({ isLoading: true })
        if (!isAgent) {
            this.props.adminAgentDetails(param, callback, isAgent)
        } else {
            this.props.adminAgentDetails(param, callback, isAgent)
        }
    }
    setLoading = (state) => {
        this.setState({ isLoading: state })
    }
    getCallHistory(isSrmIpAgent) {
        const { agentSelectedId, isAgent } = this.props
        let param = {
            id: agentSelectedId,
            limit: LIMIT,
            pageNumber: 0,
            isSrmIpAgent: isSrmIpAgent
        }
        const callback = (response) => {
            this.setState({ list: response?.response?.historys, totalItems: response?.response?.totalItems, isLoading: false, isSrmIpAgent: isSrmIpAgent })
        }
        this.setState({ isLoading: true })
        if (!isAgent) {
            this.props.vendorAgentCall(param, callback)
        } else {
            this.props.adminAgentCall(param, callback)
        }
    }
    onChangePagination = (pageNumber) => {
        const { agentSelectedId, isAgent } = this.props
        const { isSrmIpAgent } = this.state
        let params = {
            id: agentSelectedId,
            pageNumber: (pageNumber - 1),
            limit: LIMIT,
            isSrmIpAgent: isSrmIpAgent
        }
        const callBack = (response) => {
            this.setState({ isLoading: false })
            this.setState({ list: response?.response?.historys, totalItems: response?.response?.totalItems, isLoading: false })
        }
        this.setState({ isLoading: true })
        if (!isAgent) {
            this.props.vendorAgentCall(params, callBack)
        } else {
            this.props.adminAgentCall(params, callBack)
        }
        this.setState({ pageNumber: pageNumber })
    }
    render() {
        const { data, isLoading, isSrmIpAgent, list, pageNumber, totalItems } = this.state;
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <div>
                            <ViewAgentScreen
                                agentData={data}
                                list={list}
                                pageNumber={pageNumber}
                                totalItems={totalItems}
                                onChangePagination={(e) => this.onChangePagination(e)}
                            />
                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        agentSelectedId: state?.AGENR_DETAILS?.agentSelectedId?.id,
        isAgent: state?.AGENR_DETAILS?.agentSelectedId?.agent,
        agentState: state?.AGENR_DETAILS
    }
};
export default withRouter(connect(mapStateToProps, {
    adminAgentDetails: Actions.getAdminAgentDetails,
    adminAgentCall: Actions.adminAgentCallHistoryAction,
    vendorAgentCall: Actions.vendorAgentCallHistoryAction
})(ViewAgent));