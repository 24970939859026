import Actions from "../actions";

const initialState = {
    isLoading: false,
    callHistory: undefined,
    error: false,
    callHistoryList: undefined,
    adminAgentCallHistory: undefined,
    vendorAgentCallHistory: undefined
};

export const callHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.CALL_HISTROY: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CALL_HISTROY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                callHistory: action.data.response,
                error: false,
            };
        }
        case Actions.CALL_HISTROY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.CALL_HISTORY_LIST: {
            return {
                ...state,
                isLoading: false,
                callHistoryList: action.data.response
            }
        }
        case Actions.ADMIN_AGENT_CALL_HISTROY: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ADMIN_AGENT_CALL_HISTROY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                adminAgentCallHistory: action.data.response,
                error: false,
            };
        }
        case Actions.ADMIN_AGENT_CALL_HISTROY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.VENDOR_AGENT_CALL_HISTROY: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.VENDOR_AGENT_CALL_HISTROY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorAgentCallHistory: action.data.response,
                error: false,
            };
        }
        case Actions.VENDOR_AGENT_CALL_HISTROY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default: {
            return state
        }
    }
};
