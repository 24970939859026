import React from 'react';
import { withRouter } from 'react-router-dom';
import Constants from '../utils/Constants';
import NoDataFound from './NoDataFound';
import { msToHMS } from '../utils/validation';
class ViewAddOnScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const { data } = this.props;
        return (
            <div className="content-part px-5 py-4 list">
                <div className="agent-details">
                    <div className='row'>

                        <div className="col-md-8">
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Addon Plan Name
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.addonPlanName ? data.addonPlanName : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Addon Plan Price
                                </div>
                                <div className='col-7'>
                                    <p>${data && data.addonPlanPrice ? data.addonPlanPrice : '-'}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Total Mins
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.totalMins ? data.totalMins : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Total Live Stream
                                </div>
                                <div className='col-7'>
                                    <p>{data.totalSec ? msToHMS(data && data.totalSec) : '-'}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Description
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.description ? data.description : '-'}</p>
                                </div>
                            </div>
                        </div>
                        {!data && <NoDataFound />}
                    </div>
                    <div className=" mt-5 me-5 pb-3 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.goBack()} >{Constants.BACK}</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(ViewAddOnScreen);