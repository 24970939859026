import React, { Component } from 'react'
import Multiselect from 'multiselect-react-dropdown';
import { connect } from 'react-redux'
import Actions from '../redux/actions'
class MultiSelectVendor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: [],
      isLoading: false
    }
  }
  componentDidMount = () => {
    this.props.vendorList('', (response) => {
      let Arr = []
      response?.response?.forEach(obj => {
        if (obj?.Vendor?.agentTypeId == 1) {
          if (obj?.Vendor?.VendorAgents.length < obj?.Vendor?.Subscription?.agentLimit) {
            return Arr.push(obj)
          }
        }
      })
      const options = Arr?.map(obj => ({
        label: obj?.fullName + " - (" + obj?.Vendor?.companyName + ")",
        value: obj.id,
        data: obj
      })
      )
      this.setState({ options })
    }, true)
  }
  render() {
    return (
      <Multiselect
        options={this.state.options} // Options to display in the dropdown
        onRemove={(value) => this.props.onRemove(value || {})} // Function will trigger on remove event
        displayValue="label" // Property name to display in the dropdown options
        isObject={true}
        placeholder={this.props.placeholder}
        selectedValues={this.props.value}
        onSelect={(value) => this.props.onSelect(value || {})}
      />
    )
  }
}
const mapStateToProps = (state) => {
  return {

  }
}
export default connect(mapStateToProps, {
  vendorList: Actions.vendorActiveListAction
})(MultiSelectVendor)


