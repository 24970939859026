import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AddSubscription from '../component/AddSubscription';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import RoutePath from '../utils/routes/RoutePath';
import { toast } from 'react-toastify';
import Constants from '../utils/Constants';
import {
    convertToMilliSeconds, isCharacterReg, minutesToHours, minutesToSec, msToHMS,
    // convertToSeconds, secondsToTime, timeConvert
} from '../utils/validation';
const onlyNumberRegex = new RegExp(`^[A-Z0-9.]+$`);

class AdminAddSubscription extends Component {
    constructor(props) {
        super(props)
        this.state = {
            planName: undefined,
            planPrice: undefined,
            description: undefined,
            error: {},
            message: {},
            isActive: false,
            agentLimit: undefined,
            availability: undefined,
            cartGeneration: undefined,
            argumentedReality: undefined,
            customerFeedback: undefined,
            fullyCustomBranding: undefined,
            totalMins: undefined,
            totalSec: undefined,
            livechat: undefined,
            paymentIntegration: undefined,
            planImage: undefined,
            screenSharing: undefined,
            additionalCharges: undefined,
            usageReporting: undefined,
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({ isLoading: true })
            this.props.getSubscriptionDetails({ id: this.props.match.params.id }, (response) => {
                const data = response || {}
                this.setState({
                    isActive: data.isActive ? data.isActive : false,
                    planName: data?.planName,
                    planPrice: data?.planPrice,
                    description: data?.description,
                    agentLimit: data?.agentLimit,
                    availability: data?.availability?.toString(),
                    cartGeneration: data?.cartGeneration?.toString(),
                    argumentedReality: data?.argumentedReality?.toString(),
                    customerFeedback: data?.customerFeedback?.toString(),
                    fullyCustomBranding: data?.fullyCustomBranding?.toString(),
                    // liveStreamHours: data?.liveStreamHrs,
                    // liveStreamMins: data?.liveStreamMins,
                    totalMins: data?.totalMins,
                    totalSec: convertToMilliSeconds(data?.totalMins),
                    livechat: data?.livechat?.toString(),
                    paymentIntegration: data?.paymentIntegration?.toString(),
                    planImage: data?.planImage,
                    screenSharing: data?.screenSharing?.toString(),
                    additionalCharges: data?.additionalCharges,
                    usageReporting: data?.usageReporting?.toString(),
                    isLoading: false
                })
            }, true)
        }
    }


    editAgent = (id) => {
        this.props.history.push("/admin/agent/" + id,)
    }


    updateSubscription = () => {
        const callback = (res) => {
            this.setState({ isLoading: false })
            const { response } = res
            if (res?.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.props.history.push(RoutePath.SUBSCRIPTION_LIST)
            } else {
                toast.error(response?.data?.response_message)
            }
        }
        let params = {
            planName: this.state?.planName,
            planPrice: this.state?.planPrice,
            description: this.state?.description,
            id: this.props.match.params.id,
            agentLimit: this.state?.agentLimit,
            availability: this.state?.availability,
            cartGeneration: this.state?.cartGeneration,
            argumentedReality: this.state?.argumentedReality,
            customerFeedback: this.state?.customerFeedback,
            fullyCustomBranding: this.state?.fullyCustomBranding,
            totalMins: this.state?.totalMins,
            liveStreamHrs: minutesToHours(this.state?.totalMins),
            liveStreamMins: minutesToSec(this.state?.totalMins),
            totalSec: convertToMilliSeconds(this.state?.totalMins),
            livechat: this.state?.livechat,
            paymentIntegration: this.state?.paymentIntegration,
            planImage: this.state?.planImage,
            screenSharing: this.state?.screenSharing,
            additionalCharges: this.state?.additionalCharges,
            usageReporting: this.state?.usageReporting
        }
        if (!params.planName) {
            this.setState({ error: { planName: true }, message: { planName: Constants.PLEASE_ENTER_THE_NAME } })
        } else if (!isCharacterReg(params.planName)) {
            this.setState({ error: { planName: true }, message: { planName: Constants.NAME_REGX } })
        } else if (params.planName.trim().length < 3) {
            this.setState({ error: { planName: true }, message: { planName: Constants.NAME_LENGTH } })
        } else if (!params.planPrice || params.planPrice.length === 0) {
            this.setState({ error: { planPrice: true }, message: { planPrice: Constants.ENTER_PRICE } })
        } else if (params.planPrice == 0) {
            this.setState({ error: { planPrice: true }, message: { planPrice: Constants.PRICE_AMOUNT_ACCEPT } })
        } else if (!onlyNumberRegex.test(params.planPrice)) {
            this.setState({ error: { planPrice: true }, message: { planPrice: Constants.PRICE_ACCEPT } })
        } else if (!params.agentLimit) {
            this.setState({ error: { agentLimit: true }, message: { agentLimit: Constants.AGENT_LIMIT } })
        } else if (!onlyNumberRegex.test(params.agentLimit)) {
            this.setState({ error: { agentLimit: true }, message: { agentLimit: Constants.AGENT_LIMIT_ACCEPT } })
        } else if (params.agentLimit <= 0) {
            this.setState({ error: { agentLimit: true }, message: { agentLimit: Constants.AGENT_LIMIT_VALUE } })
        } else if (!params.totalMins) {
            this.setState({ error: { totalMins: true }, message: { totalMins: Constants.TOTAL_MINS } })
        } else if (!onlyNumberRegex.test(params.totalMins)) {
            this.setState({ error: { totalMins: true }, message: { totalMins: Constants.TOTAL_MINS_ACCEPT } })
        } else if (!params.cartGeneration) {
            this.setState({ error: { cartGeneration: true }, message: { cartGeneration: Constants.CART_GENERATION } })
        } else if (!params.argumentedReality) {
            this.setState({ error: { argumentedReality: true }, message: { argumentedReality: Constants.ARGUMENTED_REALITY } })
        }
        // else if (!params.availability) {
        //     this.setState({ error: { availability: true }, message: { availability: Constants.AVAILABILITY } })
        // } else if (!params.customerFeedback) {
        //     this.setState({ error: { customerFeedback: true }, message: { customerFeedback: Constants.CUSTOMER_FEEDBACK } })
        // } else if (!params.usageReporting) {
        //     this.setState({ error: { usageReporting: true }, message: { usageReporting: Constants.USEAGE_REPORT } })
        // } else if (!params.fullyCustomBranding) {
        //     this.setState({ error: { fullyCustomBranding: true }, message: { fullyCustomBranding: Constants.FULLY_CUSTOM_BRANDING } })
        // } else if (!params.liveStreamHrs) {
        //     this.setState({ error: { liveStreamHours: true }, message: { liveStreamHours: Constants.LIVE_STREAM_HOURS } })
        // } else if (!onlyNumberRegex.test(params.liveStreamHrs)) {
        //     this.setState({ error: { liveStreamHours: true }, message: { liveStreamHours: Constants.LIVE_STREAM_HOURS_ACCEPT } })
        // } else if (!params.liveStreamMins) {
        //     this.setState({ error: { liveStreamMins: true }, message: { liveStreamMins: Constants.LIVE_STREAM_MINIS } })
        // } else if (!onlyNumberRegex.test(params.liveStreamMins)) {
        //     this.setState({ error: { liveStreamMins: true }, message: { liveStreamMins: Constants.LIVE_STREAM_MINIS_ACCEPT } })
        // } else if (!params.livechat) {
        //     this.setState({ error: { livechat: true }, message: { livechat: Constants.LIVE_CHAT } })
        // } else if (!params.paymentIntegration) {
        //     this.setState({ error: { paymentIntegration: true }, message: { paymentIntegration: Constants.PAYMENT_INTEGRATION } })
        // } else if (!params.screenSharing) {
        //     this.setState({ error: { screenSharing: true }, message: { screenSharing: Constants.SCREEN_SHARING } })
        // } else if (!params.additionalCharges) {
        //     this.setState({ error: { additionalCharges: true }, message: { additionalCharges: "Please enter additional charges" } })
        // }
        else {
            this.setState({ isLoading: true })
            if (params.id) {
                this.props.updateSubscriptionDetails(params, callback, true)
            } else {
                this.props.addSubscription(params, (res) => {
                    const { response } = res
                    this.setState({ isLoading: false })
                    if (res.response_code === 0) {
                        toast.success(Constants.UPDATED_SUCCESSFULLY)
                        this.props.history.push(RoutePath.SUBSCRIPTION_LIST)
                    } else {
                        toast.error(response?.data?.response_message)
                    }
                }, true)
            }
        }
    }

    handleUserName = (e) => {
        let fullname = e.target.value;
        if (!fullname || fullname.length === 0) {
            this.setState({ planName: fullname, error: { planName: true }, message: { planName: Constants.PLEASE_ENTER_THE_NAME } })
        } else if (!isCharacterReg(fullname)) {
            this.setState({ planName: fullname, error: { planName: true }, message: { planName: Constants.NAME_REGX } })
        } else if (fullname.trim().length < 3) {
            this.setState({ planName: fullname, error: { planName: true }, message: { planName: Constants.NAME_LENGTH } })
        } else {
            this.setState({ planName: fullname, error: { planName: false }, message: { planName: ' ' } })
        }
        this.setState({ planName: fullname })
    }
    handleDescriptions = (e) => {
        let description = e.target.value;
        // if (!description || description.length === 0) {
        //     this.setState({ description, error: { description: true }, message: { description: Constants.ENTER_DESCRIPTION } })
        // } else {
        //     this.setState({ description, error: { description: true }, message: { description: '' } })
        // }
        this.setState({ description: description })
    }

    handleAgentLimit = (e) => {
        let agentLimit = e.target.value;
        if (!agentLimit || agentLimit.length === 0) {
            this.setState({ agentLimit: agentLimit, error: { agentLimit: true }, message: { agentLimit: Constants.AGENT_LIMIT } })
        } else if (!onlyNumberRegex.test(agentLimit)) {
            this.setState({ agentLimit: agentLimit, error: { agentLimit: true }, message: { agentLimit: Constants.AGENT_LIMIT_ACCEPT } })
        } else if (agentLimit <= 0) {
            this.setState({ agentLimit: agentLimit, error: { agentLimit: true }, message: { agentLimit: Constants.AGENT_LIMIT_VALUE } })
        } else {
            this.setState({ agentLimit: agentLimit, error: { agentLimit: false }, message: { agentLimit: '' } })
        }
        this.setState({ agentLimit: agentLimit })

    }

    handleAvailability = (e) => {
        let availability = e.target.value;
        if (!availability) {
            this.setState({ availability: availability, error: { availability: true }, message: { availability: Constants.AVAILABILITY } })
        } else {
            this.setState({ availability: availability, error: { availability: false }, message: { availability: '' } })
        }
        this.setState({ availability: availability })
    }

    handleCustomerFeedback = (e) => {
        let customerFeedback = e.target.value;
        if (!customerFeedback) {
            this.setState({ customerFeedback: customerFeedback, error: { customerFeedback: true }, message: { customerFeedback: Constants.CUSTOMER_FEEDBACK } })
        } else {
            this.setState({ customerFeedback: customerFeedback, error: { customerFeedback: false }, message: { customerFeedback: '' } })
        }
        this.setState({ customerFeedback: customerFeedback })
    }

    handleUsageReporting = (e) => {
        let usageReporting = e.target.value;
        if (!usageReporting) {
            this.setState({ usageReporting: usageReporting, error: { usageReporting: true }, message: { usageReporting: Constants.USEAGE_REPORT } })
        } else {
            this.setState({ usageReporting: usageReporting, error: { usageReporting: false }, message: { usageReporting: '' } })
        }
        this.setState({ usageReporting: usageReporting })
    }

    handleFullyCustomBranding = (e) => {
        let fullyCustomBranding = e.target.value;
        if (!fullyCustomBranding) {
            this.setState({ fullyCustomBranding: fullyCustomBranding, error: { fullyCustomBranding: true }, message: { fullyCustomBranding: 'ENTER FULLY BRANDING' } })
        } else {
            this.setState({ fullyCustomBranding: fullyCustomBranding, error: { fullyCustomBranding: false }, message: { fullyCustomBranding: '' } })
        }
        this.setState({ fullyCustomBranding: fullyCustomBranding })
    }

    handleliveStreamHours = (e) => {
        let liveStreamHours = e.target.value;
        if (!liveStreamHours || liveStreamHours.length === 0) {
            this.setState({ liveStreamHours: liveStreamHours, error: { liveStreamHours: true }, message: { liveStreamHours: Constants.LIVE_STREAM_HOURS } })
        } else if (!onlyNumberRegex.test(liveStreamHours)) {
            this.setState({ liveStreamHours: liveStreamHours, error: { liveStreamHours: true }, message: { liveStreamHours: Constants.LIVE_STREAM_HOURS_ACCEPT } })
        } else {
            this.setState({ liveStreamHours: liveStreamHours, error: { liveStreamHours: false }, message: { liveStreamHours: '' } })
        }
        this.setState({ liveStreamHours: liveStreamHours })

    }

    handleliveStreamMinutes = (e) => {
        let liveStreamMins = e.target.value;
        if (!liveStreamMins) {
            this.setState({ liveStreamMins: liveStreamMins, error: { liveStreamMins: true }, message: { liveStreamMins: Constants.LIVE_STREAM_MINIS } })
        } else if (!onlyNumberRegex.test(liveStreamMins)) {
            this.setState({ liveStreamMins: liveStreamMins, error: { liveStreamMins: true }, message: { liveStreamMins: Constants.LIVE_STREAM_MINIS_ACCEPT } })
        } else {
            this.setState({ liveStreamMins: liveStreamMins, error: { liveStreamMins: false }, message: { liveStreamMins: '' } })
        }
        this.setState({ liveStreamMins: liveStreamMins })
    }
    handleTotalMinutes = (e) => {
        let totalMins = e.target.value.slice(0, 7).trim();
        if (!totalMins) {
            this.setState({ totalMins: totalMins, error: { totalMins: true, time: false }, message: { totalMins: Constants.TOTAL_MINS } })
        } else if (!onlyNumberRegex.test(totalMins)) {
            this.setState({ totalMins: totalMins, error: { totalMins: true, time: false }, message: { totalMins: Constants.TOTAL_MINS_ACCEPT } })
        } else {
            this.setState({ totalMins: totalMins, error: { totalMins: false }, message: { totalMins: '' } })
        }
        let convertToSec = convertToMilliSeconds(totalMins)
        let convertToTime = msToHMS(convertToSec)
        this.setState({ totalMins: totalMins, error: { time: true }, message: { time: convertToTime } })
    }

    handleLivechat = (e) => {
        let livechat = e.target.value;
        if (!livechat) {
            this.setState({ livechat: livechat, error: { livechat: true }, message: { livechat: Constants.LIVE_CHAT } })
        } else {
            this.setState({ livechat: livechat, error: { livechat: false }, message: { livechat: '' } })
        }
        this.setState({ livechat: livechat })
    }

    handlePaymentIntegration = (e) => {
        let paymentIntegration = e.target.value;
        if (!paymentIntegration || paymentIntegration.length === 0) {
            this.setState({ paymentIntegration: paymentIntegration, error: { paymentIntegration: true }, message: { paymentIntegration: Constants.PAYMENT_INTEGRATION } })
        } else {
            this.setState({ paymentIntegration: paymentIntegration, error: { paymentIntegration: false }, message: { paymentIntegration: '' } })
        }
        this.setState({ paymentIntegration: paymentIntegration })
    }

    handleCartGeneration = (e) => {
        let cartGeneration = e.target.value;
        if (!cartGeneration) {
            this.setState({ cartGeneration: cartGeneration, error: { cartGeneration: true }, message: { cartGeneration: Constants.CART_GENERATION } })
        } else {
            this.setState({ cartGeneration: cartGeneration, error: { cartGeneration: false }, message: { cartGeneration: '' } })
        }
        this.setState({ cartGeneration: cartGeneration })
    }

    handleArgumentedReality = (e) => {
        let argumentedReality = e.target.value;
        if (!argumentedReality) {
            this.setState({ argumentedReality: argumentedReality, error: { argumentedReality: true }, message: { argumentedReality: Constants.ARGUMENTED_REALITY } })
        } else {
            this.setState({ argumentedReality: argumentedReality, error: { argumentedReality: false }, message: { argumentedReality: '' } })
        }
        this.setState({ argumentedReality: argumentedReality })
    }

    handleAdditionalCharges = (e) => {
        let additionalCharges = e.target.value;
        if (!additionalCharges || additionalCharges.length === 0) {
            this.setState({ additionalCharges: additionalCharges, error: { additionalCharges: true }, message: { additionalCharges: "Please enter additional charges" } })
        } else {
            this.setState({ additionalCharges: additionalCharges, error: { additionalCharges: false }, message: { additionalCharges: '' } })
        }
        this.setState({ additionalCharges: additionalCharges })
    }



    handleScreenSharing = (e) => {
        let screenSharing = e.target.value;
        if (!screenSharing) {
            this.setState({ screenSharing: screenSharing, error: { screenSharing: true }, message: { screenSharing: Constants.SCREEN_SHARING } })
        } else {
            this.setState({ screenSharing: screenSharing, error: { screenSharing: false }, message: { screenSharing: '' } })
        }
        this.setState({ screenSharing: screenSharing })
    }

    handlesubscriptionPrice = (e) => {
        let planPrice = e.target.value;
        if (!planPrice || planPrice.length === 0) {
            this.setState({ planPrice: planPrice, error: { planPrice: true }, message: { planPrice: Constants.ENTER_PRICE } })
        } else if (planPrice == 0) {
            this.setState({ planPrice: planPrice, error: { planPrice: true }, message: { planPrice: Constants.PRICE_AMOUNT_ACCEPT } })
        } else if (!onlyNumberRegex.test(planPrice)) {
            this.setState({ planPrice: planPrice, error: { planPrice: true }, message: { planPrice: Constants.PRICE_ACCEPT } })
        } else {
            this.setState({ planPrice: planPrice, error: { planPrice: false }, message: { planPrice: '' } })
        }
        this.setState({ planPrice: planPrice })

    }
    handleStatus = (e) => {
        this.setState({ isActive: e.target.value })
    }

    render() {
        const { isLoading } = this.state
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <AddSubscription
                            value={this.state}
                            handleUserName={(e) => { this.handleUserName(e) }}
                            handlesubscriptionPrice={(e) => { this.handlesubscriptionPrice(e) }}
                            handleDescriptions={(e) => { this.handleDescriptions(e) }}
                            handleAgentLimit={(e) => { this.handleAgentLimit(e) }}
                            handleTotalMinutes={(e) => this.handleTotalMinutes(e)}
                            handleCartGeneration={(e) => this.handleCartGeneration(e)}
                            handleArgumentedReality={(e) => this.handleArgumentedReality(e)}
                            handleAdditionalCharges={(e) => this.handleAdditionalCharges(e)}
                            // handleAvailability={(e) => this.handleAvailability(e)}
                            // handleCustomerFeedback={(e) => this.handleCustomerFeedback(e)}
                            // handleFullyCustomBranding={(e) => this.handleFullyCustomBranding(e)}
                            // handleliveStreamHours={(e) => this.handleliveStreamHours(e)}
                            // handleliveStreamMinutes={(e) => this.handleliveStreamMinutes(e)}
                            // handleLivechat={(e) => this.handleLivechat(e)}
                            // handlePaymentIntegration={(e) => this.handlePaymentIntegration(e)}
                            // handleScreenSharing={(e) => this.handleScreenSharing(e)}
                            // handleUsageReporting={(e) => this.handleUsageReporting(e)}
                            error={this.state.error}
                            message={this.state.message}
                            handleSubmit={() => this.updateSubscription()}
                            isEdit={this.props.match.params.id}
                        />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getSubscription: Actions.getSubscription(state),
    }
};
export default withRouter(connect(mapStateToProps, {
    addAgent: Actions.addAgentAction,
    updateAgent: Actions.updateAgentAction,
    getAgent: Actions.getAgentAction,
    addAgentDetails: Actions.addAgentDetails,
    addSubscription: Actions.addSubscription,
    updateSubscriptionDetails: Actions.updateSubscription,
    getSubscriptionDetails: Actions.getSubscriptionDetails
})(AdminAddSubscription));