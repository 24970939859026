import { doAdminGET, doAdminPost, doAdminPostFormData, doAgentGET } from "../../utils/service";
import { put, takeLatest, all } from "redux-saga/effects";
import { END_POINTS } from "../../utils/service/EndPoints";
import Actions from "../actions";
import { CREATE_VB_IMAGE, GET_AGENT_VB_IMAGE_LIST, GET_VB_IMAGE_LIST, UPDATE_VB_IMAGE, VB_IMAGE_DETAILS } from "../actions/VBImageAction";


export function* vbImageListSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(isAdmin ? END_POINTS.GET_ADMIN_AR_PRODUCT_LIST : END_POINTS.GET_VENDOR_VB_IMAGE_LIST, params, isAdmin);
        if (response) {
            yield put(Actions.getVbImageListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getVbImageListFailure(e));
        callback && callback(e);
    }
}

export function* createVbImageSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminPost(isAdmin ? END_POINTS.POST_ADMIN_AR_PRODUCT_ADD : END_POINTS.POST_VENDOR_VB_IMAGE_ADD, params, isAdmin);
        if (response) {
            yield put(Actions.createVbImageSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.createVbImageFailure(e));
        callback && callback(e);
    }
}

export function* updateVbImageSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminPost(isAdmin ? END_POINTS.POST_ADMIN_AR_PRODUCT_UPDATE : END_POINTS.POST_VENDOR_VB_IMAGE_UPDATE, params, isAdmin);
        if (response) {
            yield put(Actions.updateVbImageSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.updateVbImageFailure(e));
        callback && callback(e);
    }
}

export function* vbImageDetailsSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(isAdmin ? END_POINTS.GET_ADMIN_AR_PRODUCT_DETAILS : END_POINTS.GET_VENDOR_VB_IMAGE_DETAILS, params, isAdmin);
        if (response) {
            yield put(Actions.vbImageDetailsSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.vbImageDetailsFailure(e));
        callback && callback(e);
    }
}

export function* agentVbImageListSaga({ params, callback }) {
    try {
        const response = yield doAgentGET(END_POINTS.AGENT_VIRTUAL_BACKGROUND, params);
        if (response) {
            yield put(Actions.getAgentVbImageListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getAgentVbImageListFailure(e));
        callback && callback(e);
    }
}
export default function* vBImageListener() {
    yield all([
        takeLatest(GET_VB_IMAGE_LIST, vbImageListSaga),
        takeLatest(CREATE_VB_IMAGE, createVbImageSaga),
        takeLatest(UPDATE_VB_IMAGE, updateVbImageSaga),
        takeLatest(VB_IMAGE_DETAILS, vbImageDetailsSaga),
        takeLatest(GET_AGENT_VB_IMAGE_LIST, agentVbImageListSaga),
    ]);
}