import { Component } from "react";
import { withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { LIMIT } from "../utils/Constants";

class PaginationComponenent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: ""
        }
    }
    render() {
        return (
            <>
                <Pagination
                    activePage={this.props.currentPage}
                    itemsCountPerPage={LIMIT}
                    totalItemsCount={Math.ceil(this.props.countValue / LIMIT) * LIMIT}
                    pageRangeDisplayed={5}
                    onChange={this.props.onChangeHandle}
                    activeLinkClass='active'
                    itemClass='page-item'
                    linkClass="page-link"
                    prevPageText='prev'
                    nextPageText='next'
                />
            </>
        )
    }
}
export default withRouter(PaginationComponenent);
