import React from 'react'
import Close from '../../assets/img/modal-close.svg'
import Actions from '../../redux/actions'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppLoader from '../../utils/AppLoader';
import Constants from '../../utils/Constants';
import { msToHMS } from '../../utils/validation';
import { toast } from 'react-toastify';
import RoutePath from '../../utils/routes/RoutePath';
class EmergencyCallAlert extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            fields: {},
            error: {},
            validOtp1: true,
            validOtp2: true,
            validOtp3: true,
            validOtp4: true,
        }
        this.otpRef1 = React.createRef();
        this.otpRef2 = React.createRef();
        this.otpRef3 = React.createRef();
        this.otpRef4 = React.createRef();
    }

    handleChange = ({ target }, o) => {
        const { name, value } = target || {}
        const { fields } = this.state;
        this.setState({
            fields: {
                ...fields,
                [name]: value
            }
        }, () => this.validateForm([name]))
    }

    handleRestrictOtpValue1 = (e) => {
        if (e.target.value.length > 1) return false

        this.setState({ otpValue1: e.target.value })
        if (this.state.otpValue1) {
            this.otpRef1.current.focus();
        } else if (this.state.otpValue1 !== '') {
            this.otpRef2.current.focus();
        }
        let otpValue1 = e.target.value;
        if (!otpValue1 || otpValue1.length === 0) {
            this.setState({ error: { isotpValue1: true }, message: { isotpValue1: Constants.PLEASE_ENTER_PASSCODE } })
            this.setState({ validOtp1: true })
        }
        else {
            this.setState({ error: { isotpValue1: false }, message: { isotpValue1: '' } })
            this.setState({ validOtp1: false })
        }
    }
    handleRestrictOtpValue2 = (e) => {
        if (e.target.value.length > 1) return false

        this.setState({ otpValue2: e.target.value })
        if (this.state.otpValue2) {
            this.otpRef2.current.focus();
        }
        else if (this.state.otpValue2 !== '') {
            this.otpRef3.current.focus();
        }

        let otpValue2 = e.target.value;
        if (!otpValue2 || otpValue2.length === 0) {
            this.setState({ error: { isotpValue2: true }, message: { isotpValue2: Constants.PLEASE_ENTER_PASSCODE } })
            this.setState({ validOtp2: true })
        }
        else {
            this.setState({ error: { isotpValue2: false }, message: { isotpValue2: '' } })
            this.setState({ validOtp2: false })
        }
    }
    handleRestrictOtpValue3 = (e) => {
        if (e.target.value.length > 1) return false

        this.setState({ otpValue3: e.target.value });
        if (this.state.otpValue3) {
            this.otpRef3.current.focus();
        }
        else if (this.state.otpValue3 !== '') {
            this.otpRef4.current.focus();
        }

        let otpValue3 = e.target.value;
        if (!otpValue3 || otpValue3.length === 0) {
            this.setState({ error: { isotpValue3: true }, message: { isotpValue3: Constants.PLEASE_ENTER_PASSCODE } })
            this.setState({ validOtp3: true })
        }
        else {
            this.setState({ error: { isotpValue3: false }, message: { isotpValue3: '' } })
            this.setState({ validOtp3: false })
        }
    }
    handleRestrictOtpValue4 = (e) => {
        if (e.target.value.length > 1) return false
        this.setState({ otpValue4: e.target.value })
        let otpValue4 = e.target.value;
        if (!otpValue4 || otpValue4.length === 0) {
            this.setState({ error: { isotpValue4: true }, message: { isotpValue4: Constants.PLEASE_ENTER_PASSCODE } })
            this.setState({ validOtp4: true })
        }
        else {
            this.setState({ error: { isotpValue4: false }, message: { isotpValue4: '' } })
            this.setState({ validOtp4: false })
        }
    }
    submitVerifyOtp = () => {
        const { verifyOtp, email } = this.props;
        const { otpValue1, otpValue2, otpValue3, otpValue4, } = this.state;

        let data = otpValue1 + otpValue2 + otpValue3 + otpValue4;
        if (!data) {
            this.setState({ error: { NotMatch: true }, message: { NotMatch: Constants.PLEASE_ENTER_PASSCODE } })
        } else if (data === "5432") {
            this.props.onSuccess()
        } else {
            this.setState({ error: { NotMatch: true }, message: { NotMatch: "Passcode not match" } })
        }
    }
    render() {
        const { title, proceed, cancel, onFailure, onSuccess } = this.props
        const { isLoading, otpValue1, otpValue2, otpValue3, otpValue4, error, message } = this.state
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabIndex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-100">
                            <div className="modal-body">
                                <div style={{ float: 'right', cursor: 'pointer' }}>
                                    <img
                                        style={{ height: '35px', width: '35px' }}
                                        id='close'
                                        src={Close}
                                        alt="X"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        className="modal-close "
                                        onClick={() => onFailure()} />
                                </div>
                                <form>
                                    <div
                                        className="row mb-4">
                                        <div
                                            className="col">
                                            {
                                                title && <h3
                                                    className="font18 fw-bold mb-5">{title}</h3>
                                            }
                                            <div className="mt-5 mb-3 d-flex justify-content-center">
                                                <input type="number" className="form-control otp_input p-2 text-center" id="otp" value={otpValue1} onChange={(e) => this.handleRestrictOtpValue1(e)} ref={this.otpRef1} />
                                                <input type="number" className="form-control otp_input p-2 text-center" id="otp" value={otpValue2} onChange={(e) => this.handleRestrictOtpValue2(e)} ref={this.otpRef2} />
                                                <input type="number" className="form-control otp_input p-2 text-center" id="otp" value={otpValue3} onChange={(e) => this.handleRestrictOtpValue3(e)} ref={this.otpRef3} />
                                                <input type="number" className="form-control otp_input p-2 text-center" id="otp" value={otpValue4} onChange={(e) => this.handleRestrictOtpValue4(e)} ref={this.otpRef4} />
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                {error.isotpValue1 || error.isotpValue2 || error.isotpValue3 || error.isotpValue4 || error.NotMatch ?
                                                    <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{
                                                        message.isotpValue1 || message.isotpValue2 || message.isotpValue3 || message.isotpValue4 || message.NotMatch}
                                                    </h5> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div
                                            id='onClickSuccess' className="col pe-0" onClick={(e) => this.submitVerifyOtp()}>
                                            <span className="btn buttonSuccess font14 w-100 p-3">{proceed}</span> </div>
                                        <div
                                            id="onClickFailure" className="col text-end" onClick={() => onFailure()}>
                                            <span className="btn buttonFailure font14 w-100 p-3">{cancel}</span> </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                {!isLoading && <div className="modal-backdrop show"></div>}
                {isLoading && <AppLoader />}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default withRouter(connect(mapStateToProps, {
})(EmergencyCallAlert))