import React, { Component } from 'react';
import WaveSurfer from 'wavesurfer.js';

class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.waveformRef = React.createRef();
    this.waveform = null; // Initialize waveform as null
  }

  componentDidMount() {
    this.audioRef.current.addEventListener('ended', this.handleAudioEnded);

    if (this.waveformRef.current) {
      this.initWaveform(); // Initialize WaveSurfer instance
    }
  }

  componentWillUnmount() {
    this.audioRef.current.removeEventListener('ended', this.handleAudioEnded);
    if (this.waveform) {
      this.waveform.destroy(); // Destroy WaveSurfer instance when component unmounts
    }
  }

  initWaveform() {
    this.waveform = WaveSurfer.create({
      container: this.waveformRef.current,
      waveColor: 'gray',
      progressColor: 'black',
      cursorColor: 'white',
      barWidth: 2,
      barRadius: 3,
      responsive: true,
    });
    this.waveform.load(this.props.audioUrl);
  }

  handleAudioEnded = () => {
    if (this.props.onEnded) {
      this.props.onEnded();
    }
  };

  startPlayback = () => {
    if (this.audioRef.current) {
      this.audioRef.current.play();
    }
    if (this.waveform) {
      this.waveform.seekTo(0);
      this.waveform.play();
    }
  };

  replayAudio = () => {
    if (this.audioRef.current) {
      this.audioRef.current.currentTime = 0;
      this.audioRef.current.play();
    }
    if (this.waveform) {
      this.waveform.seekTo(0);
      this.waveform.play();
    }
  };

  render() {
    const { showControls, waveform } = this.props;
    return (
      <div className="my-4 text-center">
        <div className="audio-controls-container d-flex flex-column align-items-center gap-2">
          {(!showControls && waveform) && <div ref={this.waveformRef} style={{ width: '90%', height: '100px' }}></div>}
          <audio ref={this.audioRef} controls={showControls} style={{ width: '90%' }}>
            <source src={this.props.audioUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          {/* <div onClick={this.replayAudio}>
            <button className="btn btn-primary rounded-circle py-2 px-3">
              <i className='fa fa-play'></i>
            </button>
          </div> */}
        </div>
      </div>
    );
  }
}

export default AudioPlayer;
