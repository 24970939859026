import { ADMIN_AGENT_CALL_HISTROY, CALL_HISTROY, VENDOR_AGENT_CALL_HISTROY } from "../actions/CallHistory";
import { takeLatest, put } from "redux-saga/effects";
import Actions from "../actions";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doAdminGET, doAgentGET, doVendorGET } from "../../utils/service/index";
export function* callHistorySaga({ params, callback }) {
    try {
        const response = yield doAgentGET(END_POINTS.CALL_HISTORY, params);
        if (response) {
            yield put(Actions.callHistorySuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.callHistoryFailureAction(e));
        callback && callback(e);
    }
}
export function* adminAgentCallHistorySaga({ params, callback, isAdmin = true }) {
    try {
        const response = yield doAdminGET(END_POINTS.ADMIN_AGENT_CALL_HISTORY, params, isAdmin);
        if (response) {
            yield put(Actions.adminAgentCallHistorySuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.adminAgentCallHistoryFailureAction(e));
        callback && callback(e);
    }
}

export function* vendorAgentCallHistorySaga({ params, callback, isAdmin = false }) {
    try {
        const response = yield doVendorGET(END_POINTS.VENDOR_AGENT_CALL_HISTORY, params, isAdmin);
        if (response) {
            yield put(Actions.vendorAgentCallHistorySuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.vendorAgentCallHistoryFailureAction(e));
        callback && callback(e);
    }
}
export default function* callHistoryListener() {
    yield takeLatest(CALL_HISTROY, callHistorySaga);
    yield takeLatest(ADMIN_AGENT_CALL_HISTROY, adminAgentCallHistorySaga);
    yield takeLatest(VENDOR_AGENT_CALL_HISTROY, vendorAgentCallHistorySaga);

}