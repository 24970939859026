import { Component } from "react";
import io from 'socket.io-client';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from '../redux/actions/index';
import Constants, { SOCKET_IO_PATH, SOCKET_URL } from "../utils/Constants";
import { isUserName } from "../utils/validation";
import AppLoader from "../utils/AppLoader";
import { toast } from "react-toastify";
class VideoConferencing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomId: '',
      fullname: '',
      error: {},
      message: {},
      number: '',
      vaildFullName: true,
      vaildNumber: true,
      vendorId: '',
      srm_room: ''
    }
  }
  componentDidMount() {
    localStorage?.clear();
    this.setState({ vendorId: this.props.match.params.vendorId })
    localStorage.setItem("vendorId", this.props.match.params.vendorId)
    const srm_room = new URLSearchParams(this.props.location.search).get("srm_room");
    localStorage.setItem("srm_room", srm_room)
    this.setState({ fullname: srm_room })
    if (window.location.hostname === 'localhost') {
      this.socketURL = SOCKET_URL;
    } else {
      this.socketURL = SOCKET_URL;
    }
    const socket = io(this.socketURL, { path: SOCKET_IO_PATH, transports: ['websocket'] });
    this.socket = socket;
    if (srm_room) {
      this.setState({ isLoading: true, srm_room: srm_room })
      setTimeout(() => {
        this.setState({ isLoading: false })
        this.join()
      }, 1000);
    }
  }
  join = () => {
    const { fullname, number, srm_room } = this.state
    const params = {
      fullName: fullname,
      phone: "0000000000",
      vendorId: this.props.match.params.vendorId,
      roleId: 4,
      callPriority: Constants.CALL_PRIORITY.NORMAL_CALL
    }
    if (srm_room) {
      this.props.customerListAction(params, (res) => {
        localStorage.removeItem("srm_room");
        if (res?.response_code === 0) {
          const roomId = res?.response?.roomId
          this.props.history.push('/customer/' + roomId)
        } else if (res?.response_code === 5) {
          toast.error(res?.response)
        } else {
          this.props.history.push('/pageNotFound')
        }
      })
    }
    if (!params.fullName) {
      this.setState({ error: { isFullName: true }, vaildFullName: true, message: { isFullName: Constants.PLEASE_ENTER_THE_NAME } })
    } else if (params.fullName.trim().length < 3) {
      this.setState({ error: { isFullName: true }, vaildFullName: true, message: { isFullName: Constants.FULL_NAME_MINIMUM_CHARACTER } })
    } else if (!isUserName(params.fullName)) {
      this.setState({ error: { isFullName: true }, vaildFullName: true, message: { isFullName: Constants.NAME_REGX } })
    }
    // else if (!params.phone) {
    //   this.setState({ error: { isMobileNumber: true }, vaildNumber: true, message: { isMobileNumber: Constants.PLEASE_ENTER_THE_MOBLIE_NUMBER } })
    // } else if (params?.phone?.length < 10) {
    //   this.setState({ error: { isMobileNumber: true }, vaildNumber: true, message: { isMobileNumber: Constants.MOBILE_NUMBER_MUST_BE_10_CHARCTER } })
    // } 
    else if (params.vendorId === "null") {
      this.props.history.push('/pageNotFound')
    } else {
      this.props.customerListAction(params, (res) => {
        if (res?.response_code === 0) {
          const roomId = res?.response?.roomId
          this.props.history.push('/customer/' + roomId)
        } else if (res?.response_code === 5) {
          toast.error(res?.response)
        } else {
          this.props.history.push('/pageNotFound')
        }
      })
    }

  }

  componentWillUnmount() {
    if (this.socket) this.socket.disconnect()
  }

  handleChange = (e) => {
    let fullname = e.target.value;
    if (!fullname || fullname.length === 0) {
      this.setState({ error: { isFullName: true }, vaildFullName: true, fullname: e.target.value, message: { isFullName: Constants.PLEASE_ENTER_THE_NAME } })
      return false;
    }
    else if (fullname.trim().length < 3) {
      this.setState({ error: { isFullName: true }, vaildFullName: true, fullname: e.target.value, message: { isFullName: Constants.FULL_NAME_MINIMUM_CHARACTER } })
    }
    else if (!isUserName(fullname)) {
      this.setState({ error: { isFullName: true }, vaildFullName: true, fullname: e.target.value, message: { isFullName: Constants.NAME_REGX } })
    }
    else {
      this.setState({ error: { isEmailId: false }, vaildFullName: false, fullname: e.target.value, message: { isFullName: '' } })
    }
  }
  handleMobileNumber = (e) => {
    e.target.value = e.target.value.slice(0, 13).trim()
    let number = e.target.value
    if (!number || number.length === 0) {
      this.setState({ error: { isMobileNumber: true }, number: e.target.value, vaildNumber: true, message: { isMobileNumber: Constants.PLEASE_ENTER_THE_MOBLIE_NUMBER } })
      return false;
    }
    else if (number.length < 10) {
      this.setState({ error: { isMobileNumber: true }, number: e.target.value, vaildNumber: true, message: { isMobileNumber: Constants.MOBILE_NUMBER_MUST_BE_10_CHARCTER } })
      return false;
    }
    else {
      this.setState({ error: { isMobileNumber: false }, number: e.target.value, vaildNumber: false, message: { isMobileNumber: '' } })
    }
  }
  render() {
    const { message, error, vaildFullName, vaildNumber, srm_room } = this.state;
    const { isLoading } = this.props.customerListLoader
    return (
      <div className="customer-care">
        {isLoading && <AppLoader />}
        <div className="row call-row mx-0">
          <div className="col-md-6">
            <div className="column-first">
              <div className="img-content">
                <img src={require('../assets/img/Customer_service.png')} className="img-fluid" alt="customer-care" />
                <div className="para-content ">
                  <p className="para-text mb-0">{Constants.WE_ARE_DELIGHTED_TO_HAVE_AS_OUR_CUSTOMER}</p>
                  <p className="para-text">{Constants.IT_DESIGNED_TO_HELP_YOU}</p>
                </div>
              </div>
            </div>
          </div>
          {!srm_room && <div className="col-md-6 column-align">
            <div className="content-center d-md-flex align-items-md-center">
              <div className="d-grid label-content p-md-0 p-3">
                <div className="label-text">
                  <label htmlFor="exampleFormControlInput1" className="form-label form-text">{Constants.FULLNAME}</label>
                  <input autoComplete="off" type="text" className="form-control input-box-size" id="exampleFormControlInput1"
                    placeholder={Constants.ENTER_NAME} style={{ height: '46px' }} maxLength="30" onChange={(e) => this.handleChange(e)} onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        this.join()
                      }
                    }} />
                  <div>
                    {error.isFullName && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isFullName}</h5>}
                  </div>
                </div>
                {/* <div className="label-text" style={{ paddingTop: '20px' }}>
                  <label htmlFor="exampleFormControlInput1" className="form-label form-text">{Constants.MOBILE_NUMBER}</label>
                  <input autoComplete="off" type="number" maxLength={13} minLength={9} className="form-control input-box-size" id="exampleFormControlInput1"
                    placeholder={Constants.ENTER_MOBILE_NUMBER} style={{ height: '46px' }} onChange={(e) => this.handleMobileNumber(e)} onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        this.join()
                      }
                    }} />
                  <div>
                    {error.isMobileNumber && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isMobileNumber}</h5>}
                  </div>
                </div> */}
                <button className="btn btn-align" onClick={() => this.join()}><span className="btn-text-align" href="customer-call.html" >{Constants.CALL_NOW}</span></button>
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    getQueueList: state?.CHAT_LIST?.queueList,
    customerListLoader: state?.CUSTOMER_LIST
  }
};

export default withRouter(connect(mapStateToProps, {
  customerListAction: Actions.cutomerListAction,
  customerIdAction: Actions.customerID,
  appendQueueList: Actions.addChatQueueList,
})(VideoConferencing))



