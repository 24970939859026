import { ADD_AGENT, ADMIN_AGENT_DETAILS, AGENT_LIST, DELETE_AGENT, GET_AGENT, UPDATE_AGENT } from "../actions/AgentDetails";
import { takeLatest, put } from "redux-saga/effects";
import Actions from "../actions";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doAdminGET, doAdminPost } from "../../utils/service/index";
export function* agentList({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(isAdmin ? END_POINTS.AGENT_LIST_DETAILS : END_POINTS.VENDOR_AGENT_LIST_DETAILS, params, isAdmin);
        if (response) {
            yield put(Actions.agentListSuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.agentListFailureAction(e));
        callback && callback(e);
    }
}

export function* addAgent({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminPost(isAdmin ? END_POINTS.ADD_AGENT : END_POINTS.VENDOR_ADD_AGENT, params, isAdmin);
        if (response) {
            yield put(Actions.addAgentSuccessAction(response));
            callback && callback(response);
        }
    } catch (error) {
        yield put(Actions.addAgentFailureAction({ error }));
        callback && callback({ error });
    }
}

export function* updateAgent({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminPost(isAdmin ? END_POINTS.UPDATE_AGENT : END_POINTS.VENDOR_UPDATE_AGENT, params, isAdmin);
        if (response) {
            yield put(Actions.updateAgentSuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.updateAgentFailureAction(e));
        callback && callback(e);
    }
}

export function* getAgent({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(isAdmin ? END_POINTS.GET_AGENT : END_POINTS.VENDOR_GET_AGENT_VIEW_DETAILS, params, isAdmin);
        if (response) {
            yield put(Actions.getAgentSuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getAgentFailureAction(e));
        callback && callback(e);
    }
}

export function* deleteAgent({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminPost(isAdmin ? END_POINTS.DELETE_AGENT : END_POINTS.VENDOR_DELETE_AGENT, params, isAdmin);
        if (response) {
            yield put(Actions.deleteAgentSuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.deleteAgentFailureAction(e));
        callback && callback(e);
    }
}

export function* getAdminAgentDeatils({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(isAdmin ? END_POINTS.GET_AGENT : END_POINTS.VENDOR_GET_AGENT_VIEW_DETAILS, params, isAdmin);
        if (response) {
            yield put(Actions.getAdminAgentDetailSuccess(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getAdminAgentDetailFailure(e));
        callback && callback(e);
    }
}


export default function* agentListListener() {
    yield takeLatest(AGENT_LIST, agentList);
    yield takeLatest(ADD_AGENT, addAgent);
    yield takeLatest(UPDATE_AGENT, updateAgent);
    yield takeLatest(GET_AGENT, getAgent);
    yield takeLatest(DELETE_AGENT, deleteAgent);
    yield takeLatest(ADMIN_AGENT_DETAILS, getAdminAgentDeatils)
}