import { Component } from "react";
import Header from "../component/Header";
import SideBar from "../component/SideBar";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Actions from "../redux/actions";
import AppLoader from "../utils/AppLoader";
import Constants, { meetURL } from "../utils/Constants";
import { msToHMSShort } from "../utils/validation";
import AddonPopup from "../component/alert/addonPopup";

class DashboardScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textToCopy: "",
            isCopied: false,
            isCopied1: false,
            isCopied2: false,
            addonModel: false
        }
    }

    componentDidMount = () => {
        const route = this.props.match.path.split('/')
        this.props.getDashboardDetails('', "", route[1] === 'admin' ? true : false)
    }
    openCustomer = (link) => {
        window.open(link, "_blank")
    }

    // // onClick handler function for the copy button
    handleCopyClick = async (copyText) => {
        navigator.clipboard.writeText(copyText);
        this.setState({ isCopied: true, isCopied1: false, isCopied2: false })
    }
    handleCopyClick1 = async (copyText) => {
        navigator.clipboard.writeText(copyText);
        this.setState({ isCopied1: true, isCopied: false, isCopied2: false })
    }
    handleCopyClick2 = async (copyText) => {
        navigator.clipboard.writeText(copyText);
        this.setState({ isCopied2: true, isCopied1: false, isCopied: false })
    }
    render() {
        const { getDashboardValues = {} } = this.props
        const { dashboardData = {}, vendorDashboardData = {}, isLoading } = getDashboardValues
        const route = this.props.match.path.split('/')
        let isFeedback = vendorDashboardData?.isFeedback
        let isSrmIp = vendorDashboardData?.isSrmIp
        const CustomerURL = meetURL + vendorDashboardData?.vendorId
        const KioskCustomerURL = vendorDashboardData?.isFeedback && meetURL.replace("custom", "kiosk_srm") + vendorDashboardData?.vendorId
        const inPatientURL = vendorDashboardData?.isSrmIp ? meetURL.replace("custom/", "SRM_IP/custom/:room_code") : null
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <div className="content-part-dash px-1 px-md-5 py-md-4">
                            {route[1] === 'vendor' && <>
                                <div className="card url-card">
                                    <div className="url_card_content d-flex justify-content-between align-items-center py-2">
                                        <div className="d-flex">
                                            <p className="m-0">{Constants.CUSTOMER_URL} :</p>
                                            <p className="m-0" id="myInput" onClick={() => this.openCustomer(CustomerURL)}>{CustomerURL}</p>
                                        </div>
                                        <button className="script_code_btn" onClick={() => this.handleCopyClick(CustomerURL)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-files" viewBox="0 0 16 16">
                                                <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                                            </svg>
                                            {this.state.isCopied ? <p className="m-0">Copied</p> : <p className="m-0">Copy</p>}
                                        </button>
                                    </div>
                                </div>
                                {(isFeedback == 1) && <div className="card url-card">
                                    <div className="url_card_content d-flex justify-content-between align-items-center py-2">
                                        <div className="d-flex">
                                            <p className="m-0">{Constants.KIOSK_CUSTOMER_URL} :</p>
                                            <p className="m-0" id="myInput" onClick={() => this.openCustomer(KioskCustomerURL)}>{KioskCustomerURL}</p>
                                        </div>
                                        <button className="script_code_btn" onClick={() => this.handleCopyClick1(KioskCustomerURL)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-files" viewBox="0 0 16 16">
                                                <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                                            </svg>
                                            {this.state.isCopied1 ? <p className="m-0">Copied</p> : <p className="m-0">Copy</p>}
                                        </button>
                                    </div>
                                </div>}
                                {(vendorDashboardData?.isSrmIp === 1) && <div className="card url-card">
                                    <div className="url_card_content d-flex justify-content-between align-items-center py-2">
                                        <div className="d-flex">
                                            <p className="m-0">{Constants.IN_PATIENT_URL} :</p>
                                            <p className="m-0" id="myInput" onClick={() => this.openCustomer(inPatientURL)}>{inPatientURL}</p>
                                        </div>
                                        <button className="script_code_btn" onClick={() => this.handleCopyClick2(inPatientURL)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-files" viewBox="0 0 16 16">
                                                <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                                            </svg>
                                            {this.state.isCopied2 ? <p className="m-0">Copied</p> : <p className="m-0">Copy</p>}
                                        </button>
                                    </div>
                                </div>
                                }
                            </>}
                            {/* {route[1] === 'vendor' && <div className="card url-card">
                                <div className="row">
                                    <div className="col-4 url_card_content">
                                        <p className="m-0">Subscription :</p>
                                        <p className="m-0" id="myInput">{vendorDashboardData?.subscriptionDetails?.planName} (${vendorDashboardData?.subscriptionDetails?.planPrice})</p>
                                    </div>
                                    <div className="col-4 url_card_content">
                                        <p className="m-0">Addon :</p>
                                        <p className="m-0" id="myInput">{vendorDashboardData?.addonDetails?.map((o) => {
                                            return <>
                                                <ul>
                                                    <li className="mt-3">{o?.Addon?.addonPlanName} (${o?.Addon?.addonPlanPrice})</li>
                                                </ul>
                                            </>
                                        })}</p>
                                    </div>
                                </div>
                            </div>} */}
                            <div className="dashboard-content-cards">
                                <h3 className="my-3">Information</h3>
                                <div className="dashboard-card-width row row-cols-1 row-cols-md-3 g-4 m-0">
                                    <div className="dashboard-cards col"
                                    // onClick={() => this.props.history.push(route[1] === 'admin' ? RoutePath.ADMIN_AGENT_LIST : RoutePath.VENDOR_AGENT_LIST)}
                                    >
                                        <div className="card card-align-text">
                                            <p className="p-text ">{Constants.TOTAL_SHARED_AGENT}</p>
                                            <p className="count-text">{route[1] === 'admin' ? dashboardData?.totalSharedAgent : vendorDashboardData?.totalSharedAgent}</p>
                                        </div>
                                    </div>
                                    <div className="dashboard-cards col"
                                    // onClick={() => this.props.history.push(route[1] === 'admin' ? RoutePath.ADMIN_AGENT_LIST : RoutePath.VENDOR_AGENT_LIST)}
                                    >
                                        <div className="card card-align-text">
                                            <p className="p-text ">{Constants.TOTAL_OWNED_AGENT}</p>
                                            <p className="count-text">{route[1] === 'admin' ? dashboardData?.totalOwnedAgent : vendorDashboardData?.totalOwnedAgent}</p>
                                        </div>
                                    </div>
                                    <div className="dashboard-cards col"
                                    // onClick={() => ''}
                                    >
                                        <div className="card card-align-text">
                                            <p className="p-text ">{Constants.TOTAL_CUSTOMER}</p>
                                            <p className="count-text">{route[1] === 'admin' ? dashboardData?.totalCustomers : vendorDashboardData?.totalCustomers}</p>
                                        </div>
                                    </div>
                                    {route[1] !== 'admin' && < div className="dashboard-cards"
                                    // onClick={() => ''}
                                    >
                                        <div className="card card-align-text">
                                            <p className="p-text">{Constants.REMAINING_TIME}</p>
                                            <p className="hours-text">{msToHMSShort(vendorDashboardData?.remainingSec)}</p>
                                        </div>
                                    </div>}
                                    {/* {route[1] === 'admin' && <div className="dashboard-cards"
                                    // onClick={() => this.props.history.push(RoutePath.SUBSCRIPTION_LIST)}
                                    >
                                        <div className="card card-align-text">
                                            <p className="p-text">{Constants.TOTAL_SUBSCRIPTION}</p>
                                            <p className="count-text">{dashboardData?.totalSubscriptions}</p>
                                        </div>
                                    </div>} */}
                                    {route[1] === 'admin' && <div className="dashboard-cards"
                                    // onClick={() => this.props.history.push(RoutePath.ADMIN_VENDOR_LIST)}
                                    >
                                        <div className="card card-align-text">
                                            <p className="p-text">{Constants.TOTAL_VENDOR}</p>
                                            <p className="count-text">{dashboardData?.totalVendors}</p>
                                        </div>
                                    </div>}
                                </div>
                                {route[1] === 'vendor' &&
                                    <div className="subscription">
                                        <h3 className="my-3">Subscription</h3>
                                        <div className="row m-0">
                                            <div className="subscription_cards col-4">
                                                <div className="card">
                                                    <div className="d-flex justify-content-between mb-3">
                                                        <p className="text-start m-0">{vendorDashboardData?.subscriptionDetails?.planName}</p>
                                                        {vendorDashboardData?.addonDetails?.length > 0 && <button type="button" className="btn subscription-btn" onClick={() => this.setState({ addonModel: true })}>View Addon</button>}
                                                    </div>
                                                    <h2 className="m-0">${vendorDashboardData?.subscriptionDetails?.planPrice}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    {this.state.addonModel && <AddonPopup
                        id="Alert"
                        description={false}
                        searchField={false}
                        title={"Addon Details"}
                        data={vendorDashboardData?.addonDetails}
                        onSuccess={() => this.logout()}
                        onFailure={() => this.setState({ addonModel: false })}
                    />}
                </div>
                {isLoading && <AppLoader />}
            </div >
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        getDashboardValues: Actions.getDashboardDetails(state)
    }
};

export default withRouter(connect(mapStateToProps, {
    getDashboardDetails: Actions.getDashboardAction
})(DashboardScreen));