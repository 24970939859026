import { doResponseAction } from ".";



const AGE_VERIFICATION = "AGE_VERIFICATION";
const AGE_VERIFICATION_SUCCESS = "AGE_VERIFICATION_SUCCESS";
const AGE_VERIFICATION_FAILURE = "AGE_VERIFICATION_FAILURE";

//Virtual Background
const VBIMAGE = "VBIMAGE";
const VBIMAGE_SUCCESS = "VBIMAGE_SUCCESS";
const VBIMAGE_FAILURE = "VBIMAGE_FAILURE";

export {
    AGE_VERIFICATION,
    AGE_VERIFICATION_SUCCESS,
    AGE_VERIFICATION_FAILURE,

    //Virtual Background
    VBIMAGE,
    VBIMAGE_SUCCESS,
    VBIMAGE_FAILURE,
};
export const getAgeVerificationDetails = store => store["AGE_VERIFICATION"]

export const getAgeVerificationAction = (params, callback) => {
    return {
        type: AGE_VERIFICATION,
        params,
        callback
    }
}
export const getAgeVerificationSuccessAction = (data) => doResponseAction(AGE_VERIFICATION_SUCCESS, data);
export const getAgeVerfificationFailureAction = (data) => doResponseAction(AGE_VERIFICATION_FAILURE, data);


//Virtual Background
export const getVirtualBackgroundAction = (params, callback) => {
    return {
        type: VBIMAGE,
        params,
        callback
    }
}
export const getVirtualBackgroundSuccessAction = (data) => doResponseAction(VBIMAGE_SUCCESS, data);
export const getVirtualBackgroundFailureAction = (data) => doResponseAction(VBIMAGE_FAILURE, data);
