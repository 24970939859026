import Actions from "../actions";
const initialState = {
    isLoading: false,
    vendorList: {},
    vendorActiveList: [],
    updateVendor: {},
    vendorDetails: {
        selectedVendor: {}
    },
    error: false,
    vendorSelectedId: '',
    webhook: {},
    deleteAgent: {}
}

export const vendorListReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.VENDOR_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.VENDOR_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorList: action.data.response,
                error: false,
            };
        }
        case Actions.VENDOR_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                vendorList: {},
                error: action.error,
            };
        }
        case Actions.UPDATE_VENDOR: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UPDATE_VENDOR_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                updateVendor: action.data.response,
                error: false,
            };
        }
        case Actions.UPDATE_VENDOR_FAILURE: {
            return {
                ...state,
                isLoading: false,
                updateVendor: {},
                error: action.error,
            };
        }
        case Actions.GET_VENDOR_ONBOARD: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_VENDOR_ONBOARD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorDetails: action.data
            };
        }
        case Actions.GET_VENDOR_ONBOARD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.VENDOR_DETAILS: {
            return {
                ...state,
                isLoading: true
            }
        }
        case Actions.VENDOR_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                selectedVendor: action.data
            }
        }
        case Actions.VENDOR_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        case Actions.VENDOR_SELECTED_ID: {
            return {
                ...state,
                vendorSelectedId: action.id
            }
        }
        case Actions.GET_VENDOR_CONFIG_WEBHOOK: {
            return {
                ...state,
                isLoading: true
            }
        }
        case Actions.GET_VENDOR_CONFIG_WEBHOOK_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                webhook: action.data
            }
        }
        case Actions.GET_VENDOR_CONFIG_WEBHOOK_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.data,
                webhook: {}
            }
        }
        case Actions.VENDOR_CONFIG_WEBHOOK: {
            return {
                ...state,
                isLoading: true
            }
        }
        case Actions.VENDOR_CONFIG_WEBHOOK_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                webhook: action.data
            }
        }
        case Actions.VENDOR_CONFIG_WEBHOOK_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.data,
                webhook: {}
            }
        }
        case Actions.UPDATE_VENDOR_CONFIG_WEBHOOK: {
            return {
                ...state,
                isLoading: true
            }
        }
        case Actions.UPDATE_VENDOR_CONFIG_WEBHOOK_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                webhook: action.data
            }
        }
        case Actions.UPDATE_VENDOR_CONFIG_WEBHOOK_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.data,
                webhook: {}
            }
        }
        case Actions.VENDOR_ACTIVE_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.VENDOR_ACTIVE_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorActiveList: action.data.response,
                error: false,
            };
        }
        case Actions.VENDOR_ACTIVE_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                vendorActiveList: [],
                error: action.error,
            };
        }
        case Actions.VENDOR_AGENT_DELETE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.VENDOR_AGENT_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                deleteAgent: action.data.response,
                error: false,
            };
        }
        case Actions.VENDOR_AGENT_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                deleteAgent: {},
                error: action.error,
            };
        }
        default: {
            return state;
        }
    }
};

export const getstatusHookUrlDetails = (state) => state?.VENDOR_LIST_REDUCER
