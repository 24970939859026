import { FEEDBACK } from "../actions/FeedBackAction";
import { takeLatest, put } from "redux-saga/effects";
import Actions from "../actions";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doAdminPost, doPost } from "../../utils/service/index";
import { RENEW_SUBSCRIPTION } from "../actions/RenewSubscriptionAction";
export function* renewSubscriptionSaga({ params, callback }) {
    try {
        const response = yield doAdminPost(END_POINTS.RENEW_VENDOR_SUBSCRIPTION, params);
        if (response) {
            yield put(Actions.renewSubscriptionSuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.renewSubscriptionFailureAction(e));
        callback && callback(e);
    }
}

export default function* renewSubscriptionListener() {
    yield takeLatest(RENEW_SUBSCRIPTION, renewSubscriptionSaga);
}