import React, { Component } from "react";
import io from 'socket.io-client';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from '../redux/actions/index';
import Constants, { CONSTANTS, SOCKET_IO_PATH, SOCKET_URL } from "../utils/Constants";
import { isUserName } from "../utils/validation";
import AppLoader from "../utils/AppLoader";
import { toast } from "react-toastify";
import EMERGENCY_CALL from "../assets/img/emergency-call.svg";
import stringTamil from "../utils/stringTamil";
import stringEnglish from "../utils/stringEnglish";
import EmergencyCallAlert from "../component/alert/EmergencyCallAlert";
import SRM_GLOBAL from "../assets/img/srm-global.jpeg";
import VIDEO_CALL from "../assets/img/customer_girl.png";
import Carousel from 'react-bootstrap/Carousel';
import AudioPlayer from "../component/AudioPlayer";
import { StereoAudioRecorder } from "recordrtc";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
class VideoConferencing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomId: '',
      fullname: '',
      error: {},
      message: {},
      number: '',
      vaildFullName: true,
      vaildNumber: true,
      vendorId: '',
      vendorLogo: null,
      reason: '',
      tab: 1,
      language: parseInt(localStorage.getItem("language")) || 1,
      emergencyAlert: false,
      welcomVoiceDuration: 1000,
      welcomVoice: true,
      mediaStream: null,
      recorder: null,
      recordedBlob: null,
      isRecording: false, // Flag to indicate if recording is in progress
      recordingDuration: 0, // Duration of the recorded audio
      audioData: []
    };
    this.welcomeAudioPlayerRef = React.createRef();
    this.audioRef = React.createRef();
    this.canvasRef = React.createRef();
    this.animationId = null;
  }
  startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        this.setState({ mediaStream: stream, isRecording: true });
        const recorder = new StereoAudioRecorder(stream);
        recorder.record();
        this.setState({ recorder });
        this.drawWaveform();
      })
      .catch(error => {
        console.error('Error accessing microphone:', error);
      });
  };

  stopRecording = () => {
    const { recorder, mediaStream } = this.state;
    if (recorder) {
      recorder.stop(blob => {
        this.setState({ recordedBlob: blob, isRecording: false });
        this.updateRecordingDuration(blob);
      });
    }
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
      this.setState({ mediaStream: null });
    }
    cancelAnimationFrame(this.animationId); // Stop waveform animation
  };

  updateRecordingDuration = (blob) => {
    const audioElement = document.createElement('audio');
    audioElement.src = URL.createObjectURL(blob);
    audioElement.addEventListener('loadedmetadata', () => {
      this.setState({ recordingDuration: audioElement.duration });
    });
  };

  drawWaveform = () => {
    const canvas = this.canvasRef.current;
    const canvasCtx = canvas.getContext('2d');

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();

    const source = audioContext.createMediaStreamSource(this.state.mediaStream);
    source.connect(analyser);

    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      this.animationId = requestAnimationFrame(draw);

      analyser.getByteTimeDomainData(dataArray);

      canvasCtx.fillStyle = 'rgb(255, 255, 255)';
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

      canvasCtx.beginPath();

      const sliceWidth = canvas.width * 1.0 / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = v * canvas.height / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    };

    draw();
  }
  componentDidMount() {
    localStorage?.clear();
    this.setState({ vendorId: this.props.match.params.vendorId });
    localStorage.setItem("vendorId", this.props.match.params.vendorId);
    localStorage.setItem("language", 1);
    let params = {
      vendorId: this.props.match.params.vendorId
    };
    this.props.getVendorDetails(params, (res) => {
      if (res?.response_code === 0) {
        this.setState({ vendorLogo: res?.response?.Vendor?.vendorLogo });
      }
    });
    if (window.location.hostname === 'localhost') {
      this.socketURL = SOCKET_URL;
    } else {
      this.socketURL = SOCKET_URL;
    }
    const socket = io(this.socketURL, { path: SOCKET_IO_PATH, transports: ['websocket'] });
    this.socket = socket;
  }
  join = (value) => {
    const { fullname, number, reason } = this.state;
    let MESSAGE = this.state?.language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
    const params = {
      fullName: "Customer",
      phone: "0000000000",
      vendorId: this.props.match.params.vendorId,
      roleId: 4,
      reason: value,
      callPriority: Constants.CALL_PRIORITY.NORMAL_CALL
    };
    if (!params.fullName) {
      this.setState({ error: { isFullName: true }, vaildFullName: true, message: { isFullName: MESSAGE?.PLEASE_ENTER_THE_NAME } });
    } else if (params.fullName.trim().length < 3) {
      this.setState({ error: { isFullName: true }, vaildFullName: true, message: { isFullName: MESSAGE?.FULL_NAME_MINIMUM_CHARACTER } });
    } else if (!isUserName(params.fullName)) {
      this.setState({ error: { isFullName: true }, vaildFullName: true, message: { isFullName: MESSAGE?.NAME_REGX } });
    } else if (!params?.reason) {
      this.setState({ error: { isReason: true }, message: { isReason: MESSAGE?.PLEASE_SELECT_THE_REASON } });
    } else if (params.vendorId === "null") {
      this.props.history.push('/pageNotFound');
    } else {
      this.props.customerListAction(params, (res) => {
        if (res?.response_code === 0) {
          const roomId = res?.response?.roomId;
          this.props.history.push('/kiosk_srm/customer/' + roomId);
        } else if (res?.response_code === 5) {
          toast.error(res?.response);
        } else {
          this.props.history.push('/pageNotFound');
        }
      });
    }

  };

  joinForEmergency = () => {
    if (this.state.emergencyAlert === false) {
      this.setState({ emergencyAlert: true });
    } else {
      const { fullname, number, reason } = this.state;
      const params = {
        fullName: "Emergency",
        phone: "0000000000",
        vendorId: this.props.match.params.vendorId,
        roleId: 4,
        callPriority: Constants.CALL_PRIORITY.EMERGENCY_CALL,
        isCallFromKiosk: 1
      };
      if (params.vendorId === "null") {
        this.props.history.push('/pageNotFound');
      } else {
        this.setState({ emergencyAlert: false });
        this.props.customerListAction(params, (res) => {
          if (res?.response_code === 0) {
            const roomId = res?.response?.roomId;
            this.props.history.push('/kiosk_srm/customer/' + roomId);
          } else if (res?.response_code === 5) {
            toast.error(res?.response);
          } else {
            this.props.history.push('/pageNotFound');
          }
        });
      }
    }
  };

  componentWillUnmount() {
    if (this.socket) this.socket.disconnect();
  }

  handleChange = (e) => {
    let fullname = e.target.value;
    let MESSAGE = this.state?.language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
    if (!fullname || fullname.length === 0) {
      this.setState({ error: { isFullName: true }, vaildFullName: true, fullname: e.target.value, message: { isFullName: MESSAGE?.PLEASE_ENTER_THE_NAME } });
      return false;
    }
    else if (fullname.trim().length < 3) {
      this.setState({ error: { isFullName: true }, vaildFullName: true, fullname: e.target.value, message: { isFullName: MESSAGE?.FULL_NAME_MINIMUM_CHARACTER } });
    }
    else if (!isUserName(fullname)) {
      this.setState({ error: { isFullName: true }, vaildFullName: true, fullname: e.target.value, message: { isFullName: MESSAGE?.NAME_REGX } });
    }
    else {
      this.setState({ error: { isEmailId: false }, vaildFullName: false, fullname: e.target.value, message: { isFullName: '' } });
    }
  };
  handleChangeReason = (e) => {
    let reason = e.target.value;
    let MESSAGE = this.state?.language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
    if (!reason) {
      this.setState({ error: { isRason: true }, reason: e.target.value, message: { isReason: MESSAGE?.PLEASE_SELECT_THE_REASON } });
      return false;
    }
    else {
      this.setState({ error: { isRason: false }, reason: e.target.value, message: { isReason: '' } });
    }
  };
  handleChooseEnglishLanguage = (e) => {
    localStorage.setItem("language", 1);
    this.setState({ tab: 2, language: 1, error: {} });
  };
  componentDidUpdate(prevProps, prevState) {
    // Check if the tab has changed to 2 and the language has been set to English
    if (this.state.tab === 2 && this.state.language && this.state.welcomVoice) {
      // Start playback when the conditions are met
      setTimeout(() => {
        this.welcomeAudioPlayerRef?.current?.startPlayback();
        this.setState({ welcomVoice: false })
      }, this.state.welcomVoiceDuration);
    }
  }
  handleChooseTamilLanguage = (e) => {
    localStorage.setItem("language", 2);
    this.setState({ tab: 2, language: 2, error: {} });
  };
  handleChangeLanguage = (e) => {
    this.setState({ tab: 1, language: '', error: {} });
  };
  handleChangeToFeedback = (tab) => {
    this.props.history.push({
      pathname: '/kiosk_srm/general/customer/feedback',
      state: tab
    });
  };

  render() {
    const { message, error, vendorLogo, tab, language, mediaStream, recordedBlob, isRecording, recordingDuration } = this.state;
    const { isLoading } = this.props.customerListLoader;
    let MESSAGE = language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
    return (
      <div className="customer-care" style={{ backgroundImage: `url(${SRM_GLOBAL})`, backgroundSize: 'auto 100%', backgroundPosition: 'center' }}>
        {isLoading && <AppLoader />}
        <div className="row call-row mx-0 overflow-auto">
          <div className="col-12 d-flex justify-content-end">
            <div className="fs-5 p-3">
              {tab === 2 &&
                <div onClick={() => this.setState({ tab: 1, welcomVoice: true })} style={{ color: 'white' }}>
                  <i className="fa-solid fa-language" style={{ color: 'white' }}></i> {MESSAGE?.CHANGE_LANGUAGE}
                </div>
              }
            </div>
            {/* <div className="fs-5 p-3">
              <a className="dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="fa-solid fa-language"></i> {language === 2 ? "தமிழ்" : "English"}
              </a>
              <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                <li onClick={(e) => this.handleChooseEnglishLanguage(e)}>English</li>
                <li onClick={(e) => this.handleChooseTamilLanguage(e)}>தமிழ்</li>
              </ul>
            </div> */}
          </div>
          <div className="col-md-12 d-flex flex-column gap-sm-5 gap-3 align-items-center pt-3 pb-sm-3 pb-5">
            {/* <div className="d-flex flex-column align-items-center my-5">
              <img src={require('../assets/img/Customer_service.png')} className="w-50 d-tabel" alt="customer-care" />
              <div className="para-content ">
                <p className="para-text mb-0">{tab === 1 ? (stringEnglish?.MESSAGE?.CONTENT + " / " + stringTamil?.MESSAGE?.CONTENT) : (MESSAGE?.CONTENT)}</p>
                <p className="para-text mb-0">{Constants.WE_ARE_DELIGHTED_TO_HAVE_AS_OUR_CUSTOMER}</p>
                <p className="para-text">{Constants.IT_DESIGNED_TO_HELP_YOU}</p>
              </div>
            </div> */}
            {tab === 1 &&
              <div className="d-flex flex-column gap-3 width_value">
                <div className="blured-layout"></div>
                <div className="text-center position-relative z-3">
                  <img className="logo" src={require('../assets/img/srm-logo.png')} alt="logo" />
                </div>
                <div className="d-flex flex-column p-sm-5 p-3 rounded-4 position-relative z-3" style={{ backgroundColor: 'rgb(255 255 255 / 80%)', gap: '2rem' }}>
                  <label htmlFor="exampleFormControlInput1" className="form-label h3">{MESSAGE?.SELECT_LANGUAGE}</label>
                  {/* <div>
                    <button onClick={this.startRecording} disabled={mediaStream !== null || isRecording}>Start Recording</button>
                    <button onClick={this.stopRecording} disabled={mediaStream === null || !isRecording}>Stop Recording</button>
                    <div>
                      <canvas ref={this.canvasRef} width="500" height="100"></canvas>
                    </div>
                    {recordedBlob &&
                      <div>
                        <audio ref={this.audioRef} controls src={URL.createObjectURL(recordedBlob)} />
                        <div>
                          Recording Duration: {recordingDuration.toFixed(2)} seconds
                        </div>
                      </div>
                    }
                  </div> */}
                  <div className="d-sm-flex gap-5 z-1">
                    <button className="btn btn-gradient w-sm-50 w-100 h1 d-sm-inline d-block btn_font" onClick={(e) => this.handleChooseEnglishLanguage(e)}>English</button>
                    <button className="btn btn-gradient w-sm-50 w-100 h1 d-sm-inline d-block btn_font" onClick={(e) => this.handleChooseTamilLanguage(e)}>தமிழ்</button>
                  </div>
                </div>
              </div>
            }
            {tab === 2 &&
              <React.Fragment>
                <div className="width_value text-white">
                  <h1>{MESSAGE?.FEEDBACK}</h1>
                  <h6>{MESSAGE?.FEEDBACK_CONTENT}</h6>
                </div>
                <div className="d-flex flex-lg-row flex-column width_value gap-sm-5 gap-3">
                  <div className="p-sm-5 p-4 text-dark rounded-4 w-100" style={{ backgroundColor: 'rgb(255 255 255 / 80%)', gap: '3rem' }} onClick={(e) => this.handleChangeToFeedback("feedbackNameAudio")}>
                    <div className="d-sm-block d-flex gap-3 align-items-center">
                      <h1 className="display-3"><i className="fal fa-volume"></i></h1>
                      <h4>{MESSAGE?.AUDIO}</h4>
                      {language === 1 && <p className="d-sm-block d-none">{MESSAGE?.AUDIO_CONTENT}</p>}
                    </div>
                    <h3 className="d-sm-block d-none text-end">
                      <i className="fa fa-caret-right"></i>
                    </h3>
                  </div>
                  <div className="p-sm-5 p-4 text-dark rounded-4 w-100" style={{ backgroundColor: 'rgb(255 255 255 / 80%)', gap: '3rem' }} onClick={(e) => this.handleChangeToFeedback("feedbackText")}>
                    <div className="d-sm-block d-flex gap-3 align-items-center">
                      <h1 className="display-3"><i className="fal fa-text-size"></i></h1>
                      <h4>{MESSAGE?.TEXT}</h4>
                      {language === 1 && <p className="d-sm-block d-none">{MESSAGE?.TEXT_CONTENT}</p>}
                    </div>
                    <h3 className="d-sm-block d-none text-end">
                      <i className="fa fa-caret-right"></i>
                    </h3>
                  </div>
                </div>
                <div className="width_value">
                  <Carousel>
                    <Carousel.Item>
                      <img src={require('../assets/img/srm-banner1.png')} className="d-block w-100" alt="..." />
                    </Carousel.Item>
                    {/* <Carousel.Item>
                      <img src={require('../assets/img/ad-2.jpeg')} className="d-block w-100" alt="..." />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img src={require('../assets/img/ad-3.jpg')} className="d-block w-100" alt="..." />
                    </Carousel.Item> */}
                  </Carousel>
                </div>
                <div className="p-lg-5 p-3  width_value text-white rounded-4 flex flex-row" style={{ backgroundColor: 'rgb(100 70 236 / 80%)', gap: '3rem' }}>
                  <AudioPlayer audioUrl={MESSAGE?.WELCOME} ref={this.welcomeAudioPlayerRef} showControls={false} waveform={false} />
                  <div className="d-flex flex-lg-row flex-column gap-4 align-items-center">
                    <div>
                      <h1>{MESSAGE?.CONTENT}</h1>
                      {language === 1 && <h6>This application will assist you with your needs through our customer agents.</h6>}
                    </div>
                    <div>
                      <img className="d-sm-block d-none" src={VIDEO_CALL} alt="video call" style={{ width: "152px" }} />
                    </div>
                  </div>
                  <div className="d-flex flex-lg-row flex-column gap-4" style={{ marginTop: '2rem' }}>
                    <button style={{ backgroundColor: 'rgb(179 81 253)' }} className="d-sm-block d-flex align-items-center gap-3 p-3 border-0 text-start rounded-4 text-white w-100 ribbon-label"
                      data-ribbon-content={MESSAGE?.COMMING_SOON}
                    // onClick={(e) => this.join(MESSAGE?.LIST_OF_REASON?.APPOINTMENT)}
                    >
                      <h1 className="display-4"><i className="fal fa-calendar"></i></h1>
                      <h3 className="mt-sm-4">{MESSAGE?.LIST_OF_REASON?.APPOINTMENT}</h3>
                    </button>
                    <button style={{ backgroundColor: 'rgb(179 81 253)' }} className="d-sm-block d-flex align-items-center gap-3 p-3 border-0 text-start rounded-4 text-white w-100 ribbon-label"
                      data-ribbon-content={MESSAGE?.COMMING_SOON}
                    // onClick={(e) => this.join(MESSAGE?.LIST_OF_REASON?.NAVIGATION)}
                    >
                      <h1 className="display-4"><i className="fal fa-location"></i></h1>
                      <h3 className="mt-sm-4">{MESSAGE?.LIST_OF_REASON?.NAVIGATION}</h3>
                    </button>
                    <button style={{ backgroundColor: 'rgb(179 81 253)' }} className="d-sm-block d-flex align-items-center gap-3 p-3 border-0 text-start rounded-4 text-white w-100 ribbon-label"
                      data-ribbon-content={MESSAGE?.COMMING_SOON}
                    // onClick={(e) => this.join(MESSAGE?.LIST_OF_REASON?.PATIENT_VISITING)}
                    >
                      <h1 className="display-4"><i className="fal fa-bed"></i></h1>
                      <h3 className="mt-sn-4">{MESSAGE?.LIST_OF_REASON?.PATIENT_VISITING}</h3>
                    </button>
                  </div>
                </div>
              </React.Fragment>
            }

            {/* {tab === 1 && <div className="d-flex flex-column gap-3 width_value">
              <label htmlFor="exampleFormControlInput1" className="form-label form-text">{MESSAGE?.SELECT_LANGUAGE}</label>
              <button className="btn btn-dark custom-form-control mt-3" onClick={(e) => this.handleChooseEnglishLanguage(e)}><span className="btn-text-align" >English</span></button>
              <button className="btn btn-dark custom-form-control mt-3" onClick={(e) => this.handleChooseTamilLanguage(e)}><span className="btn-text-align" >தமிழ்</span></button>
              <hr style={{ borderTopColor: 'black' }} />
              <button className="btn btn-dark custom-form-control mt-3" onClick={(e) => this.handleChangeToFeedback(e)}><span className="btn-text-align" > {tab === 1 && (stringEnglish?.MESSAGE?.FEEDBACK + " / " + stringTamil?.MESSAGE.FEEDBACK)}</span></button>
            </div>} */}
            {/* {tab === 2 && <div className="d-flex flex-column gap-3 width_value">
              <div className="d-flex flex-column">
                <label htmlFor="exampleFormControlInput1" className="form-label form-text">{MESSAGE?.FULLNAME}</label>
                <input autoComplete="off" type="text" className="form-control custom-form-control w-100" id="exampleFormControlInput1"
                  placeholder={MESSAGE?.ENTER_NAME} style={{ height: '46px' }} maxLength="30" onChange={(e) => this.handleChange(e)} onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      this.join();
                    }
                  }} />
                <div>
                  {error.isFullName && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message?.isFullName}</h5>}
                </div>
              </div>
              <div className="d-flex flex-column">
                <label htmlFor="exampleFormControlInput1" className="form-label form-text">{MESSAGE?.REASON}</label>
                <select name='reason' className="form-control custom-form-control w-100" onChange={(e) => this.handleChangeReason(e)}>
                  <option hidden>{MESSAGE?.SELECT_REASON}</option>
                  <option value={MESSAGE?.LIST_OF_REASON?.APPOINTMENT}>{MESSAGE?.LIST_OF_REASON?.APPOINTMENT}</option>
                  <option value={MESSAGE?.LIST_OF_REASON?.NAVIGATION}>{MESSAGE?.LIST_OF_REASON?.NAVIGATION}</option>
                  <option value={MESSAGE?.LIST_OF_REASON?.PATIENT_VISITING}>{MESSAGE?.LIST_OF_REASON?.PATIENT_VISITING}</option>
                </select>
                {error.isReason && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message?.isReason}</span>}
              </div>
              <button className="btn btn-dark custom-form-control mt-3" onClick={() => this.join()}><span className="btn-text-align" >{MESSAGE?.CALL_NOW}</span></button>
              <div className="skipDiv text-center mt-2" onClick={(e) => this.handleChangeLanguage(e)}>
                {MESSAGE?.CHANGE_LANGUAGE}
              </div>
            </div>} */}
            <div className="fixed-bottom d-flex justify-content-end py-sm-5 py-3 px-5 z-0">
              <div className="pulse ribbon-label"
                data-ribbon-content={MESSAGE?.COMMING_SOON}
              // onClick={() => this.joinForEmergency()}
              >
                <i className="fa fa-plus"></i>
                Emergency <br /> Call
              </div>
            </div>
          </div>
        </div>
        {this.state.emergencyAlert && <EmergencyCallAlert
          id="Alert"
          searchField={false}
          title={"Please enter your passcode"}
          proceed="Proceed"
          cancel="Cancel"
          onSuccess={() => this.joinForEmergency()}
          onFailure={() => this.setState({ emergencyAlert: false })}
        />}
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    getQueueList: state?.CHAT_LIST?.queueList,
    customerListLoader: state?.CUSTOMER_LIST
  };
};

export default withRouter(connect(mapStateToProps, {
  getVendorDetails: Actions.getVendorDetailsAction,
  customerListAction: Actions.cutomerListAction,
  customerIdAction: Actions.customerID,
  appendQueueList: Actions.addChatQueueList,
})(VideoConferencing))



