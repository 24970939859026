import IO from 'socket.io-client';
import { store } from '../redux/store';
import { SOCKET_IO_PATH, SOCKET_URL } from '../utils/Constants';

let socket;


const destroySocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
}
/**
 * Start socket conection
 */
export const startSocketConnect = (callback, props) => {

  if (socket) {
    destroySocket()
  }
  // const token = 'bearer ' + store?.getState()?.USER?.token?.access_token;

  //  const token = 'bearer 31dd5ae-6522-4207-8f6d-cbf52453636a'

  // connect to socket
  socket = IO(SOCKET_URL, {
    path: SOCKET_IO_PATH,
    autoConnect: true,
    forceNew: true,
    verify: true,
    //  timeout: 6000,
    //  connect_timeout: 3000,
    transports: ['websocket'],
    jsonp: false,
    // query: {
    //   authorization: token
    // },
  });
  socket.on('connect', val => {
    console.log('************ APP Connected ************');
  });
  socket.on('disconnect', () => {
    console.log('************ _onDisConnect ************');
  });
  socket.on('socket_connected', data => {
    console.log('************ socket_connected ************');
  });
  socket.on('socket_disconnected', data => {
    console.log('************ socket_disconnected ************');
  });

  socket.on('connect_error', data => {
    console.log('************ error ************', data);
  });
  socket.on('connect_timeout', data => {
    console.log('************ connect_timeout ************');
  });
  const tokenData = store?.getState()?.LOGIN_CREDS
  const location = window?.location
  let arr = location?.pathname?.split('/')
  let customerId
  let roleId
  if (arr[1] === 'admin') {
    customerId = tokenData?.adminTokenDetails?.user?.id
    roleId = tokenData?.adminTokenDetails?.user?.roleId
  } else if (arr[1] === 'vendor') {
    customerId = tokenData?.vendorTokenDetails?.user?.id
    roleId = tokenData?.vendorTokenDetails?.user?.roleId
  } else {
    customerId = tokenData?.tokenDetails?.user?.id
    roleId = tokenData?.tokenDetails?.user?.roleId
  }
  if (customerId) {
    if (roleId === 1) {
      console.log("ADMIN", customerId);
      socket.on(
        `/SOCKET/ADMIN/${customerId}`,
        data => {
          console.log('------>>>> Socket notification for admin', data);
          callback(data, roleId)
        })
    } else if (roleId === 2) {
      console.log("VENDOR", customerId);
      socket.on(
        `/SOCKET/VENDOR/${customerId}`,
        data => {
          console.log('------>>>> Socket notification for vendor', data);
          callback(data, roleId)
        })
    } else {
      console.log("AGENT", customerId);
      socket.on(
        `/SOCKET/AGENT/${customerId}`,
        data => {
          console.log('------>>>> Socket notification for agent', data);
          callback(data, roleId)
        })
    }
  } else {
    destroySocket()
  }

}
/**
 * Stop socket conection
 */
export const token = () => {
  return {
  };
};
export const stopSocketConnect = () => {
  destroySocket()
};

