import React, { Component } from "react";
import RoutePath from "../utils/routes/RoutePath";
import { withRouter } from "react-router-dom";
import Constants, { LIMIT } from "../utils/Constants";
import NoDataFound from "./NoDataFound";
import { connect } from "react-redux";
import { getPaymnetData } from '../redux/reducer/AddOnReducer ';

class VendorService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomId: '',
            isLoading: false,
            stylesLoaded: false
        }
    }

    addAgent = () => {
        this.props.history.push(RoutePath.ADMIN_ADD_AGENT_LIST)
    }

    editService = (id) => {
        this.props.history.push("/vendor/service/edit/" + id)
    }
    viewService = (id) => {
        const { isVendor } = this.props
        if (isVendor) {
            this.props.history.push("/admin/products/view/" + id)
        } else {
            this.props.history.push("/vendor/service/view/" + id)
        }
    }
    render() {
        const { serviceList, updateStatus, isVendor, } = this.props
        return (
            <React.Fragment>
                <div className="admin_table">
                    <div role="region" aria-label="data table" tabIndex="0" className="candidate_primary">
                        <table className="dashboard_table">
                            <thead>
                                <tr>
                                    <th className="pin">{Constants.ID}</th>
                                    <th>Service Name</th>
                                    <th>Service Head</th>
                                    <th>{Constants.ACTION}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {serviceList?.length > 0 ?
                                    serviceList?.map((service, index) => {
                                        return <tr key={index}>
                                            <td>{((this.props.currentPage - 1) * LIMIT) + (index + 1)}</td>
                                            <td>{service?.serviceName}</td>
                                            <td>{service?.User?.email}</td>
                                            <td>
                                                <i className="fa-solid fa-pen-to-square updateIcon edit-icon" onClick={() => this.editService(service?.id)} />
                                                <i className={service?.isActive === 1 ? "fa-solid fa-toggle-on toggle-icon ms-2 edit-icon" : "fa-solid fa-toggle-off toggle-icon ms-2 edit-icon"} onClick={() => updateStatus(service?.id, service.isActive)} />
                                                <i className="fa-solid fa-eye ms-2 edit-icon" onClick={() => this.viewService(service?.id)}></i>
                                            </td>
                                        </tr>
                                    }) : <NoDataFound />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export const mapStateToProps = (state) => {
    return {
        isLoading: getPaymnetData(state)
    }
};
export default withRouter(connect(mapStateToProps, {
})(VendorService));
