import React, { Component } from "react";
import RoutePath from "../utils/routes/RoutePath";
import { withRouter } from "react-router-dom";
import Constants, { LIMIT } from "../utils/Constants";
import NoDataFound from "./NoDataFound";
import Actions from "../redux/actions";
import { connect } from "react-redux";
import { getPaymnetData } from '../redux/reducer/AddOnReducer ';
import AppLoader from "../utils/AppLoader";
import { msToHMS } from "../utils/validation";

class AddOnList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomId: '',
            isLoading: false,
            stylesLoaded: false
        }
    }

    addAgent = () => {
        this.props.history.push(RoutePath.ADMIN_ADD_AGENT_LIST)
    }

    updateAddOn = (id) => {
        this.props.history.push("/admin/addon/edit/" + id)
    }
    viewAddOn = (id) => {
        const { isVendor } = this.props
        this.props.history.push(isVendor ? "/admin/addon/view/" + id : "/vendor/addon/view/" + id)
    }
    payment = (item) => {
        if (item?.VendorAddon?.paymentLink) {
            window.open(item?.VendorAddon?.paymentLink, "_blank")
        } else {
            const callback = (response) => {
                this.setState({ isLoading: false })

                // this.props.getAddonList()
                if (response?.response?.paymentLink) {
                    window.open(response?.response?.paymentLink, "_blank")
                }
            }
            let params = {
                addonId: item.id
            }
            this.setState({ isLoading: true })
            this.props.payment(params, callback, false)
        }
    }
    handleRoute = () => {
        this.props.history.push(RoutePath.ADD_ADDON)
    }
    loadScript = (url, status) => {
        let head = document.getElementsByTagName("head")[0]
        let body = document.getElementsByTagName("body")[0]
        let script
        if (status) {
            script = document.createElement("script")
            script.type = "text/javascript";
            script.src = url;
            head.appendChild(script);
        } else {
            var div = body.querySelector('container p-3');
            var scripts = head.querySelector('https://dev-meet.proglint.com/my-script.js', 'p');
            if (scripts.length > 0) {
                head.removeChild(scripts[0]);
                body.removeChild(div[0]);
            }
        }
    }
    componentDidMount() {
        // this.loadScript("https://dev-meet.proglint.com/my-script.js", true)
    }
    handleStyle() {
        this.setState({ stylesLoaded: !this.state.stylesLoaded })
        if (!this.state.stylesLoaded) {
            this.loadScript("https://dev-meet.proglint.com/my-script.js", true)
        } else {
            this.loadScript("https://dev-meet.proglint.com/my-script.js", false)
        }
    }
    render() {
        const { addonList, updateStatus, isVendor } = this.props
        const { isLoading } = this.state
        const route = window.location.href.split('/')
        const isAdmin = route[3] === 'admin' ? true : false
        return (
            <React.Fragment>
                {isVendor ?
                    <div className="addButton">
                        <div className="add-btn">
                            <button className="btn add-item" type="button" onClick={() => this.handleRoute()} ><i className="fa-solid fa-plus me-3"
                            ></i>{Constants.ADD_ADDON}</button>
                        </div>
                    </div>
                    : <div className="addButton">
                        <div className="add-btn">
                            {/* Vendor Design Purpose this div created. */}
                        </div>
                    </div>}
                <div className="admin_table">
                    <div role="region" aria-label="data table" tabIndex="0" className="candidate_primary">
                        <table className="dashboard_table">
                            <thead>
                                <tr>
                                    <th className="pin">{Constants.ID}</th>
                                    <th>Addon Name</th>
                                    <th>{Constants.PRICE}</th>
                                    <th>Total Live Stream</th>
                                    {isVendor && <th>{Constants.STATUS}</th>}
                                    <th>{Constants.ACTION}</th>
                                    {!isAdmin && <th>{Constants.PAYMENT}</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {addonList?.length > 0 ? addonList?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{((this.props.currentPage - 1) * LIMIT) + (index + 1)}</td>
                                        <td >{item.addonPlanName}</td>
                                        <td >${item.addonPlanPrice}</td>
                                        <td >{msToHMS(item && item.totalSec)}</td>
                                        {isVendor && <td><i className={item.isActive === 1 ? "fa-solid fa-toggle-on toggle-icon edit-icon" : "fa-solid fa-toggle-off toggle-icon edit-icon"} onClick={() => updateStatus(item.id, item.isActive)} /></td>}
                                        <td>
                                            {isVendor && <i className="fa-solid fa-pen-to-square edit-icon" onClick={() => this.updateAddOn(item.id)} />}
                                            <i className="fa-solid fa-eye ms-2 edit-icon" onClick={() => this.viewAddOn(item.id)}></i>
                                        </td>
                                        {!isAdmin && <td>
                                            <button className='btn input-btn' disabled={item.isSubscribed === 1} onClick={() => this.payment(item)}>{item.isSubscribed === 1 ? "Subscribed" : "Subscribe now"}</button>
                                        </td>}
                                    </tr>
                                )) : <NoDataFound />
                                }
                            </tbody>
                        </table>
                    </div>
                    {isLoading && <AppLoader />}
                </div>
            </React.Fragment>
        )
    }
}
export const mapStateToProps = (state) => {
    return {
        isLoading: getPaymnetData(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    payment: Actions.payment,
})(AddOnList));
// export default withRouter(AddOnList)
