import Actions from "../actions";

const initialState = {
  data: undefined,
  isLoading: false,
  error: undefined,
  subscriptionDetails: null,
  subscriptions: null,
  subscriptionActiveList: null,
  paymentList:{}
};

export const SubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_SUBSCRIPTION_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.GET_SUBSCRIPTION_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        subscriptions: action.data,
        error: false,
      };
    }
    case Actions.GET_SUBSCRIPTION_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    //Payment List
    case Actions.GET_PAYMENT_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.GET_PAYMENT_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        paymentList: action.data,
        error: false,
      };
    }
    case Actions.GET_PAYMENT_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.GET_SUBSCRIPTION_DETAILS: {
      return {
        ...state,
        isLoading: true,
        error: action.error,
      };
    }
    case Actions.GET_SUBSCRIPTION_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        subscriptionDetails: action.data,
        error: action.error,
      };
    }
    case Actions.GET_SUBSCRIPTION_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        subscriptionDetails: {},
        error: action.error,
      };
    }
    case Actions.ADD_SUBSCRIPTION: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.ADD_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case Actions.ADD_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case Actions.GET_SUBSCRIPTION_ACTIVE_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.GET_SUBSCRIPTION_ACTIVE_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        subscriptionActiveList: action.data,
        error: false,
      };
    }
    case Actions.GET_SUBSCRIPTION_ACTIVE_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export const subscriptionList = (state) => state?.SUBSCRIPTIONS_DETAILS?.data;
export const subscriptionListLoader = (state) => state?.SUBSCRIPTIONS_DETAILS?.isLoading;
export const subscriptionActiveList = (state) => state?.SUBSCRIPTIONS_DETAILS?.subscriptionActiveList;