import Actions from "../actions";

const initialState = {
  data: undefined,
  isLoading: false,
  error: undefined,
  vbImageListData: null,
  adminVbImageListData: null,
  vbImageDetails: null,
  agentVbImageListData: null
};

export const VBImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_VB_IMAGE_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.GET_VB_IMAGE_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        vbImageListData: action.data?.isVendor ? (action.data ? action?.data : {}) : (!!action.data ? state?.vbImageListData : {}),
        adminVbImageListData: !action.data?.isVendor ? (action.data ? action?.data : {}) : (!!action.data ? state?.adminVbImageListData : {}),
        error: false,
      };
    }
    case Actions.GET_VB_IMAGE_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.CREATE_VB_IMAGE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.CREATE_VB_IMAGE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    }
    case Actions.CREATE_VB_IMAGE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.UPDATE_VB_IMAGE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.UPDATE_VB_IMAGE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    }
    case Actions.UPDATE_VB_IMAGE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.VB_IMAGE_DETAILS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.VB_IMAGE_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        productDetails: action.data,
        error: false,
      };
    }
    case Actions.VB_IMAGE_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.GET_AGENT_VB_IMAGE_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.GET_AGENT_VB_IMAGE_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        agentVbImageListData: action.data,
        error: false,
      };
    }
    case Actions.GET_AGENT_VB_IMAGE_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
