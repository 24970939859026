import { Component } from "react";
import Constants, { LIMIT } from "../utils/Constants";
import RoutePath from "../utils/routes/RoutePath";
import '@google/model-viewer/dist/model-viewer'
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Actions from "../redux/actions";
import { connect } from "react-redux";
class AddRoomService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendorRoomListData: ''
        }
    }

    componentDidMount() {
        const Agent = this.props.match.path.split('/')
        if (Agent[2] === "products" && Agent[1] === "admin") {
            this.setState({ isVendor: true })
        } else {
            this.setState({ isVendor: false })
        }
        this.getVendorAllServiceList()
        this.getVendorAllRoomList()
        this.getVendorAllSrmIpAgentList()
    }

    getVendorAllServiceList = () => {
        let params = {
            limit: LIMIT,
            pageNumber: 0
        }
        const callback = (response) => {
            if (response) {
                this.setState({ totalItem: response.response.totalItems })
            }
        }
        this.props.getVendorServiceList(params, callback)
    }

    getVendorAllRoomList = () => {
        let params = {
            limit: LIMIT,
            pageNumber: 0
        }
        const callback = (response) => {
            if (response) {
                this.setState({ totalItem: response.response.totalItems, vendorRoomListData: response?.response })
            }
        }
        this.props.getRoomList(params, callback)
    }

    getVendorAllSrmIpAgentList = () => {
        let params = {
            srmIpAgent: true
        }
        const callback = (response) => {
            if (response) {
                //this.setState({vendorRoomListData: response?.response })
            }
        }
        this.props.getVendorSrmIpAgentList(params, callback)
    }



    render() {
        const { isEdit, handleSubmit, handleRoomName, handleServiceName, handleAgentName, value, message, error } = this.props
        const { getVendorRoomService = {} } = this.props
        const { vendorRoomListData } = this.state
        const { vendorServiceListData, vendorSrmIpAgentListData } = getVendorRoomService
        return (
            <div className="content-part px-5 py-4">
                <div className="agent-details">
                    <div className="row m-0">
                        <div className="col-md-6" >
                            <div className="mt-3">
                                <p className="text-bold">Room Name</p>
                                <select name='vendorId' className="form-select w-50" value={value?.srmIpRoomId} onChange={(e) => handleRoomName(e)} disabled={isEdit}>

                                    <option hidden value={"ALL"}> Select Room </option>
                                    {
                                        vendorRoomListData?.rooms?.length > 0 && vendorRoomListData?.rooms?.map((o) => {
                                            return (
                                                <option key={o?.id} value={o?.id}>{o?.patientRoomName}</option>
                                            )
                                        })
                                    }
                                </select>
                                {error?.srmIpRoomId && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.srmIpRoomId}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-md-6" >
                            <div className="mt-3">
                                <p className="text-bold">Service Name</p>
                                <select name='vendorId' className="form-select w-50" value={value?.srmIpServiceId} onChange={(e) => handleServiceName(e)}>

                                    <option hidden value={"ALL"}> Select Service </option>
                                    {
                                        vendorServiceListData?.services?.length > 0 && vendorServiceListData?.services?.map((o) => {
                                            return (
                                                <option key={o?.id} value={o?.id}>{o?.serviceName}</option>
                                            )
                                        })
                                    }
                                </select>
                                {error?.srmIpServiceId && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.srmIpServiceId}</span>}
                            </div>
                        </div>

                    </div>
                    <div className="row m-0">
                        <div className="col-md-6" >
                            <div className="mt-3">
                                <p className="text-bold">Inpatient Agent</p>
                                <select name='vendorId' className="form-select w-50" value={value?.userId} onChange={(e) => handleAgentName(e)}>

                                    <option hidden value={"ALL"}> Select Inpatient Agent</option>
                                    {
                                        vendorSrmIpAgentListData?.srmVendorSeviceAgents?.length > 0 && vendorSrmIpAgentListData?.srmVendorSeviceAgents?.map((o) => {
                                            return (
                                                <option key={o?.User?.id} value={o?.User?.id}>{o?.User?.fullName + ' (' + o?.User?.email + ')'}</option>
                                            )
                                        })
                                    }
                                </select>
                                {error?.userId && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.userId}</span>}
                            </div>
                        </div>

                    </div>
                    <div className="mt-5 me-5 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.push(RoutePath.VENDOR_ROOM_SERVICE)}>{Constants.BACK}</button>
                        <button type="button" className="btn input-btn mx-3" onClick={() => handleSubmit()}>{isEdit ? Constants.UPDATE : Constants.CREATE}</button>
                    </div>
                </div>
            </div>
        )
    }
}
export const mapStateToProps = (state) => {
    return {
        getVendorRoomService: Actions.getVendorRoomService(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    getVendorServiceList: Actions.getVendorServiceList,
    getRoomList: Actions.getRoomList,
    getVendorSrmIpAgentList: Actions.getVendorSrmIpAgentList
})(AddRoomService
));
