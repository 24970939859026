import React, { Component } from "react";
import RoutePath from "../utils/routes/RoutePath";
import { withRouter } from "react-router-dom";
import Constants, { LIMIT } from "../utils/Constants";
import NoDataFound from "./NoDataFound";
import { msToHMS } from "../utils/validation";

class SubscriptionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomId: ''
        }
    }

    addAgent = () => {
        this.props.history.push(RoutePath.ADMIN_ADD_AGENT_LIST)
    }

    updateSubscription = (id) => {
        this.props.history.push("/admin/subscription/edit/" + id)
    }
    viewSubscription = (id) => {
        this.props.history.push("/admin/subscription/view/" + id)
    }
    handleRoute = () => {
        this.props.history.push(RoutePath.ADD_SUBSCRIPTION)
    }
    render() {
        const { subscriptionList, updateStatus } = this.props
        return (
            <React.Fragment>
                <div className="addButton">
                    <div className="add-btn">
                        <button className="btn add-item" type="button" onClick={() => this.handleRoute()} ><i className="fa-solid fa-plus me-3"
                        ></i>{Constants.ADD_SUBSCRIPTION}</button>
                    </div>
                </div>
                <div className="admin_table">
                    <div role="region" aria-label="data table" tabIndex="0" className="candidate_primary">
                        <table className="dashboard_table">
                            <thead>
                                <tr>
                                    <th className="pin">{Constants.ID}</th>
                                    <th>{Constants.SUBSCRIPTION_NAME}</th>
                                    <th>{Constants.PRICE}</th>
                                    <th>Total Live Stream</th>
                                    <th>{Constants.STATUS}</th>
                                    <th>{Constants.ACTION}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subscriptionList?.length > 0 ?
                                    subscriptionList?.map((item, index) => (
                                        <tr key={index}>
                                            <td className="pin">{((this.props.currentPage - 1) * LIMIT) + (index + 1)}</td>
                                            <td>{item.planName}</td>
                                            <td>${item.planPrice}</td>
                                            <td >{msToHMS(item && item.totalSec)}</td>
                                            <td><i className={item.isActive === 1 ? "fa-solid fa-toggle-on toggle-icon edit-icon" : "fa-solid fa-toggle-off toggle-icon edit-icon"} onClick={() => updateStatus(item.id, item.isActive)} /></td>
                                            <td>
                                                <i className="fa-solid fa-pen-to-square edit-icon" onClick={() => this.updateSubscription(item.id)} />
                                                <i className="fa-solid fa-eye ms-2 edit-icon" onClick={() => this.viewSubscription(item.id)}></i>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <NoDataFound />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(SubscriptionList)
