import Actions from "../actions";
const initialState = {
    isLoading: false,
    customerCallList: undefined,
    error: false,
}

export const CustomerCallListReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.CUSTOMER_CALL_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CUSTOMER_CALL_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customerCallList: action.data.response,
                error: false,
            };
        }
        case Actions.CUSTOMER_CALL_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                customerCallList: undefined,
                error: action.error,
            };
        }
        default: {
            return state;
        }
    }
};
export const CustomerCall = (state) => state?.CUSTOMER_CALL_LIST;
