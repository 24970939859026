import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import PaginationComponenent from '../component/Pagination';
import Constants, { LIMIT, THREEDSCRIPT } from '../utils/Constants';
import { toast } from 'react-toastify';
import RoutePath from '../utils/routes/RoutePath';
import ARProductsList from '../component/ARProductsList';

class AdminARProductsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageNumber: 1,
            totalItem: '',
            isVendor: false,
            isCopied: false,
            allVendorList: undefined,
            vendorId: undefined
        }
    }
    componentDidMount() {
        const Agent = this.props.match.path.split('/')
        if (Agent[2] === "products" && Agent[1] === "admin") {
            this.setState({ isVendor: true })
        } else {
            this.setState({ isVendor: false })
        }
        this.getARProductList('', 1)
        this.getAllVendorList()
    }
    getAllVendorList = () => {
        this.props.getAllVendorList("", (res) => {
            if (res?.response_code === 0) {
                this.setState({ allVendorList: res?.response })
            }
        }, (this.props.location.pathname === RoutePath.ADMIN_AR_PRODUCTS))
    }
    getARProductList = (value, page) => {
        this.setState({ pageNumber: page })
        let params = {
            vendorId: value,
            limit: LIMIT,
            pageNumber: parseInt(page) - 1
        }
        this.setState({ vendorId: value })
        const callback = (response) => {
            if (response) {
                this.setState({ totalItem: response.response.totalItems })
            }
        }
        this.props.getProductList(params, callback, (this.props.location.pathname === RoutePath.ADMIN_AR_PRODUCTS))
    }

    onChangePagination = (pageNumber) => {
        let params = {
            vendorId: this.state.vendorId,
            limit: LIMIT,
            pageNumber: (pageNumber - 1),
        }
        this.props.getProductList(params, '', (this.props.location.pathname === RoutePath.ADMIN_AR_PRODUCTS))
        this.setState({ pageNumber: pageNumber })
    }

    updateStatus = (id, active) => {
        const updateParams = {
            id,
            isActive: active ? 0 : 1
        }
        const callback = (response) => {
            if (response.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.getARProductList('', 1)
            }
            else {
                toast.error(response.response_message)
            }
        }
        this.props.updateProduct(updateParams, callback, (this.props.location.pathname === RoutePath.ADMIN_AR_PRODUCTS))
    }
    addProduct = () => {
        this.props.history.push(RoutePath.VENDOR_AR_PRODUCTS_ADD)
    }
    // // onClick handler function for the copy button
    handleCopyClick = async (copyText) => {
        navigator.clipboard.writeText(copyText);
        this.setState({ isCopied: true })
    }
    handleChange = ({ target }, o) => {
        const { value } = target || {}
        if (value === "ALL") {
            this.getARProductList("", 1)
        } else {
            this.getARProductList(value, 1)
        }
    }
    render() {
        const { pageNumber, isVendor, allVendorList } = this.state;
        const { getProduct = {} } = this.props
        const { isLoading, adminProductListData } = getProduct
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <div className='addButton'>
                            <div className="add-btn vendorFilter">
                                <p className="text-bold vendorFilterPara">Select Vendor :</p>
                                <select name='vendorId' className="form-select w-25" value={this.state.vendorId} onChange={(e) => this.handleChange(e)}>
                                    <option value={"ALL"}> All Vendors </option>
                                    {
                                        allVendorList?.length > 0 && allVendorList?.map((o) => {
                                            return (
                                                <option key={o?.id} value={o?.id}>{o?.companyName} - {o?.User?.fullName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <ARProductsList productList={adminProductListData?.products} updateStatus={(id, active) => this.updateStatus(id, active)}
                            isVendor={isVendor}
                            currentPage={pageNumber} />
                        <div className='page-content'>
                            {
                                adminProductListData?.totalItems > (LIMIT - 1) ? <PaginationComponenent currentPage={pageNumber} countValue={adminProductListData?.totalItems} onChangeHandle={this.onChangePagination} /> : ''
                            }
                        </div>
                    </div>
                </div>

                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getProduct: Actions.getProduct(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    getProductList: Actions.getProductList,
    getAllVendorList: Actions.getAllVendorList,
    updateProduct: Actions.updateProduct
})(AdminARProductsList
));