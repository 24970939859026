import Actions from "../actions";

const initialState = {
  isLoading: false,
  customerList: undefined,
  vendorDetails: undefined,
  addFeedback: undefined,
  addFeedbackVoice: undefined,
  error: false,
};

export const CustomerListReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CUSTOMER_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.CUSTOMER_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        customerList: action.data,
        error: false,
      };
    }
    case Actions.CUSTOMER_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.GET_VENDOR_DETAILS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.GET_VENDOR_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        vendorDetails: action.data,
        error: false,
      };
    }
    case Actions.GET_VENDOR_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.ADD_FEEDBACK: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.ADD_FEEDBACK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        addFeedback: action.data,
        error: false,
      };
    }
    case Actions.ADD_FEEDBACK_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.ADD_FEEDBACK_VOICE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.ADD_FEEDBACK_VOICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        addFeedbackVoice: action.data,
        error: false,
      };
    }
    case Actions.ADD_FEEDBACK_VOICE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.SRM_IP_ROOM_SRVICES_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.SRM_IP_ROOM_SRVICES_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        srmIpRoomService: action.data,
        error: false,
      };
    }
    case Actions.SRM_IP_ROOM_SRVICES_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state
    }
  }
};
export const customerListLoader = (state) => state?.CUSTOMER_LIST;
