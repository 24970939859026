import Actions from "../actions";
const initialState = {
    isLoading: false,
    vendorRoomListData: null,
    vendorRoomDetailData: null,
    vendorServiceListData: null,
    vendorServiceDetailData: null,
    vendorRoomServiceListData: null,
    vendorRoomServiceDetailData: null,
    vendorSrmIpAgentListData: null,
    error: false,
}

export const vendorRoomServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_VENDOR_SERVICE_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_VENDOR_SERVICE_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorServiceListData: action.data,
                error: false,
            };
        }
        case Actions.GET_VENDOR_SERVICE_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                vendorServiceListData: {},
                error: action.error,
            };
        }
        case Actions.UPDATE_VENDOR_SERVICE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UPDATE_VENDOR_SERVICE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorServiceDetailData: action.data,
                error: false,
            };
        }
        case Actions.UPDATE_VENDOR_SERVICE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                vendorServiceDetailData: {},
                error: action.error,
            };
        }
        case Actions.VENDOR_SERVICE_DETAIL: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.VENDOR_SERVICE_DETAIL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorServiceDetailData: action.data
            };
        }

        case Actions.CREATE_VENDOR_SERVICE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CREATE_VENDOR_SERVICE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case Actions.CREATE_VENDOR_SERVICE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.GET_VENDOR_ROOM_SERVICE_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_VENDOR_ROOM_SERVICE_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorRoomServiceListData: action.data,
                error: false,
            };
        }
        case Actions.GET_VENDOR_ROOM_SERVICE_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                vendorRoomServiceListSaga: {},
                error: action.error,
            };
        }
        case Actions.UPDATE_VENDOR_ROOM_SERVICE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UPDATE_VENDOR_ROOM_SERVICE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorRoomServiceDetailData: action.data,
                error: false,
            };
        }
        case Actions.UPDATE_VENDOR_ROOM_SERVICE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                vendorRoomServiceDetailData: {},
                error: action.error,
            };
        }
        case Actions.VENDOR_ROOM_SERVICE_DETAIL: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.VENDOR_ROOM_SERVICE_DETAIL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorRoomServiceDetailData: action.data
            };
        }

        case Actions.CREATE_VENDOR_ROOM_SERVICE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CREATE_VENDOR_ROOM_SERVICE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case Actions.CREATE_VENDOR_ROOM_SERVICE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.GET_VENDOR_SRM_IP_AGENT_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_VENDOR_SRM_IP_AGENT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorSrmIpAgentListData: action.data,
                error: false,
            };
        }
        case Actions.GET_VENDOR_SRM_IP_AGENT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                vendorSrmIpAgentListData: {},
                error: action.error,
            };
        }

        default: {
            return state;
        }
    }
};


