import React from 'react';
import { withRouter } from 'react-router-dom';
import Constants from '../utils/Constants';
import NoDataFound from './NoDataFound';
import { msToHMS } from '../utils/validation';
import '@google/model-viewer/dist/model-viewer'

class ViewProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const { data } = this.props;
        return (
            <div className="content-part px-5 py-4 list">
                <div className="agent-details">
                    <div className='row'>

                        <div className="col-md-8">
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Product Name
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.productName ? data.productName : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Product SKU
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.productSku ? data.productSku : '-'}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Product Page URL
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.pageURL ? data.pageURL : "-"}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Product type is shoe
                                </div>
                                <div className='col-7'>
                                    <p>{(data?.isShoe == 1 ? "Yes" : "No")}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Product View
                                </div>
                                <div className='col-7'>
                                    {data?.productGLB ?
                                        <div className="img-wraps">
                                            <model-viewer
                                                src={data?.productGLB}
                                                poster={require("../assets/img/ar.gif")}
                                                shadow-intensity="1" ar camera-controls touch-action="pan-y"
                                                // style={{ height: "230px" }}
                                                alt="A 3D model carousel"
                                                auto-rotate>
                                            </model-viewer>
                                        </div>
                                        : "-"}
                                </div>
                            </div>
                        </div>
                        {!data && <NoDataFound />}
                    </div>
                    <div className=" mt-5 me-5 pb-3 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.goBack()} >{Constants.BACK}</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(ViewProduct);