const protocol = window.location.protocol || "http:";
const hostname = window.location.hostname || "localhost";
let port = window.location.port;

if (!port) {
  port = protocol === "http:" ? 80 : protocol === "https:" ? 443 : "";
}
let baseUrl = "http://localhost:8081/api/";
let socketUrl = "http://localhost:8083";
let socketRtcUrl = "http://localhost:9001/";
let meetUrl = "http://localhost:3000/custom/";
let s3_url =
  "https://proglint-shop.s3.ap-south-1.amazonaws.com/liveAgent_dev/glb/3dImage/";
let threeDscript = "https://meet.h4helpnow.com/3dButtonScript.js";
let real_life_ar_url = "https://meet.h4helpnow.com/arproducts/";
let shop_ar_url = "https://ar.h4helpnow.com/";
let shopping_domain = "shop.proglint.com";
let companyName = localStorage.getItem("companyName");
if (hostname === "dev-meet.proglint.com") {
  baseUrl = "https://dev-webrtcapi.proglint.com/api";
  socketUrl = "https://devsocket-meet.proglint.com";
  socketRtcUrl = "https://devsocket-rtc.proglint.com/";
  meetUrl = "https://dev-meet.proglint.com/custom/";
  s3_url =
    "https://proglint-shop.s3.ap-south-1.amazonaws.com/liveAgent_dev/glb/3dImage/";
  threeDscript = "https://dev-meet.proglint.com/3dButtonScript.js";
  real_life_ar_url = "https://dev-meet.proglint.com/arproducts/";
  shop_ar_url = "https://dev-liveagentar.proglint.com/";
  if (companyName === "Pauls Fashion") {
    shopping_domain = "www.thepaulsfashion.com/customercare";
  } else {
    shopping_domain = "shop.proglint.com";
  }
} else if (hostname === "fix-meet.proglint.com") {
  baseUrl = "https://dev-webrtcapi.proglint.com/api";
  socketUrl = "https://devsocket-meet.proglint.com";
  socketRtcUrl = "https://devsocket-rtc.proglint.com/";
  meetUrl = "https://fix-meet.proglint.com/custom/";
  s3_url =
    "https://proglint-shop.s3.ap-south-1.amazonaws.com/liveAgent_dev/glb/3dImage/";
  threeDscript = "https://dev-meet.proglint.com/3dButtonScript.js";
  real_life_ar_url = "https://dev-meet.proglint.com/arproducts/";
  shop_ar_url = "https://dev-liveagentar.proglint.com/";
  if (companyName === "Pauls Fashion") {
    shopping_domain = "www.thepaulsfashion.com/customercare";
  } else {
    shopping_domain = "shop.proglint.com";
  }
} else if (hostname === "meet.proglint.com") {
  baseUrl = "https://webrtcapi.proglint.com/api";
  socketUrl = "https://socket-meet.proglint.com";
  socketRtcUrl = "https://socket-rtc.proglint.com/";
  meetUrl = "https://meet.proglint.com/custom/";
  s3_url =
    "https://proglint-shop.s3.ap-south-1.amazonaws.com/liveAgent/glb/3dImage/";
  threeDscript = "https://dev-meet.proglint.com/3dButtonScript.js";
  real_life_ar_url = "https://dev-meet.proglint.com/arproducts/";
  shop_ar_url = "https://dev-liveagentar.proglint.com/";
  if (companyName === "Pauls Fashion") {
    shopping_domain = "www.thepaulsfashion.com/customercare";
  } else {
    shopping_domain = "shop.proglint.com";
  }
} else if (hostname === "liveagent-meet.cvi.ai") {
  baseUrl = "https://liveagent-api.cvi.ai/api";
  socketUrl = "https://liveagent-socket.cvi.ai";
  socketRtcUrl = "https://liveagent-rtc.cvi.ai/";
  meetUrl = "https://liveagent-meet.cvi.ai/custom/";
  s3_url =
    "https://proglint-shop.s3.ap-south-1.amazonaws.com/liveAgent/glb/3dImage/";
  threeDscript = "https://liveagent-meet.cvi.ai/3dButtonScript.js";
  real_life_ar_url = "https://liveagent-meet.cvi.ai/arproducts/";
  shop_ar_url = "https://liveagent-ar.cvi.ai/";
  if (companyName === "Pauls Fashion") {
    shopping_domain = "www.thepaulsfashion.com/customercare";
  } else {
    shopping_domain = "shop.proglint.com";
  }
} else if (hostname === "meet.h4helpnow.com") {
  baseUrl = "https://api.h4helpnow.com/api";
  socketUrl = "https://socket.h4helpnow.com";
  socketRtcUrl = "https://rtc.h4helpnow.com/";
  meetUrl = "https://meet.h4helpnow.com/custom/";
  s3_url =
    "https://proglint-shop.s3.ap-south-1.amazonaws.com/liveAgent/glb/3dImage/";
  threeDscript = "https://meet.h4helpnow.com/3dButtonScript.js";
  real_life_ar_url = "https://meet.h4helpnow.com/arproducts/";
  shop_ar_url = "https://ar.h4helpnow.com/";
  if (companyName === "Pauls Fashion") {
    shopping_domain = "www.thepaulsfashion.com/customercare";
  } else {
    shopping_domain = "shop.proglint.com";
  }
}

export const BASE_URL = baseUrl;
export const SOCKET_URL = socketUrl;
export const SOCKET_RTC_URL = socketRtcUrl;
export const meetURL = meetUrl;
export const S3_URL = s3_url;
export const REAL_LIFE_AR_URL = real_life_ar_url;
export const THREEDSCRIPT = threeDscript;
export const SHOE_AR_URL = shop_ar_url;
export const SHOPPING_DOMAIN = shopping_domain;

export const LIMIT = 5;
export const SOCKET_IO_PATH = "/socket.io";

export const CONSTANTS = {
  LOG_OUT: {
    desc: "Do you want to logout?",
    proceed: "Yes",
    cancel: "Cancel",
  },
  FEEDBACK_CANCEL: {
    desc: "Do you want to cancel the feedback?",
    proceed: "Yes",
    cancel: "No",
  },
};
export default {
  LOGIN: "Login",
  ADMIN_LOGIN: "Admin Login",
  VENDOR_LOGIN: "Vendor Login",
  PLEASE_ENTER_EMAIL: "Please Enter the email",
  INVALID_EMAIL_ID: "Invalid Email Id",
  PLEASE_ENTER_PASSWORD: "Please Enter the Password",
  PLEASE_ENTER_OTP: "Please Enter the Otp",
  PLEASE_ENTER_PASSCODE: "Please Enter the Passcode",
  EMAIL_ID: "Email Id",
  PASSWORD: "Password",
  SUBMIT: "Submit",
  CREATE: "Create",
  ADDED_SUCCESSFULLY: "Successfully Added",
  DELETED_SUCCESSFULLY: "Successfully Deleted",
  PLEASE_ENTER_ALL_DATA: "Please Enter All data",
  UPDATED_SUCCESSFULLY: "Successfully Updated",
  PLEASE_ENTER_THE_MOBLIE_NUMBER: "Please Enter the mobile number",
  MOBILE_NUMBER_MUST_BE_10_CHARCTER: "Please Enter Valid Mobile Number",
  PLEASE_ENTER_THE_NAME: "Please Enter the Name",
  PLEASE_ENTER_THE_VALID_NAME: "Please Enter the Valid Name",
  FULL_NAME_MINIMUM_CHARACTER:
    "Full Name should contain miniumum 3 characters.",
  NAME_LENGTH: "Name should be minimum 3 character",
  NAME_REGX: "Special Characters & Numbers are not allowed",
  ACCOUNT_NUMBER_ACCEPT: "Account Number field accepts only numeric.",
  ONLY_NUMBER_ACCEPT: "Field should be numberic",
  NAME: "Name",
  EMAIL: "Email",
  ENTER_EMAIL_ID: "Enter the email",
  ENTER_NAME: "Enter the Name",
  ENTER_PASSWORD: "Enter the Password",
  ENTER_MOBILE_NUMBER: "Enter the Mobile Number",
  SHARED: "Shared",
  OWNED: "Owned",
  UPDATE: "Update",
  BACK: "Back",
  MOBILE_NUMBER: "Mobile Number",
  SUBSCRIPTION_ID: "Subscription",
  SELECT_SUBSCRIPTION_ID: "- Select Subscription -",
  SELECT_PAYMENT_TYPE_ID: "- Select Payment Type -",
  PAYMENT_TYPE: "Payment Type",
  ACCOUNT_TYPE: "Account Type",
  SELECT_ACCOUNT_TYPE: "- Select Account Type -",
  CURRENT: "CURRENT",
  SAVINGS: "SAVINGS",
  IFSC: "IFSC",
  ENTER_THE_IFSC: "Enter the IFSC",
  COMPANY_NAME: "Company Name",
  STORE_LOGO: "Store Logo",
  ENTER_COMPANY_NAME: "Enter the company name",
  ACCOUNT_NUMBER: "Account Number",
  ENTER_THE_ACCOUNT_NUMBER: "Enter the account number",
  GST: "GST",
  ENTER_GST: "Enter the gst",
  REGISTER_NUMBER: "Register Number",
  ENTER_REGISTER_NUMBER: "Enter the register number",
  PAN: "PAN",
  ENTER_PAN_NUMBER: "Enter the pan number",
  AGENT_TYPE: "Agent Type",
  PAYMENT_STATUS: "Payment Status",
  TOTAL_TIME: "Total Streams ",
  USED_TIME: "Used Streams",
  REMAINING_TIME: "Remaining Streams",
  AGENT_NAME: "Agent Name",
  SELECT_AGENT_TYPE: "Select Agent",
  SELECT_VENDOR: "Select Vendor",
  ADDRESS: "Address",
  RECEIVED_EMAIL: "who received the feedback report?",
  ENTER_ADDRESS: "Please Enter an address",
  ENTER_FEEDBACK_EMAIL: "Please Enter the emails",
  VALID_ADDRESS: "Please Enter an Valid Address",
  EDIT: "Edit",
  ACTIVE: "Active",
  ID: "S.No",
  SUBSCRIPTION_NAME: "Subscription Name",
  SUBSCRIPTION_PRICE: "Subscription Price",
  ENTER_PRICE: "Enter the price",
  PRICE_ACCEPT: "Price field accepts only numeric.",
  PRICE_AMOUNT_ACCEPT: "Please Enter valid amount",
  AGENT_LIMIT: "Please enter agent limit",
  AGENT_LIMIT_ACCEPT: "Agent Limit field accepts only numeric.",
  AGENT_LIMIT_VALUE: "Agent Limit should be minimum 1",
  LIVE_STREAM_HOURS_ACCEPT: "Live Stream Hours field accepts only numeric.",
  LIVE_STREAM_MINIS_ACCEPT: "Live Stream Minutes field accepts only numeric.",
  TOTAL_MINS_ACCEPT: "Total Stream Minutes field accepts only numeric",
  AVAILABILITY: "Please Select the availability",
  CUSTOMER_FEEDBACK: "Please select customer feedback",
  LIVE_CHAT: "Please Select Live Chat",
  PAYMENT_INTEGRATION: "Please Select Payment Integration",
  CART_GENERATION: "Please Select Cart Generation",
  ARGUMENTED_REALITY: "Please Select Argumented Reality",
  SCREEN_SHARING: "Please Select Screen Shareing",
  USEAGE_REPORT: "Please select usage report",
  FULLY_CUSTOM_BRANDING: "Please select fully custom branding",
  LIVE_STREAM_HOURS: "Please Enter Live Stream hours",
  LIVE_STREAM_MINIS: "Please Enter live stream minutes",
  TOTAL_MINS: "Please Enter Total Stream Minutes",
  DESCRIPTION: "Description",
  ENTER_DESCRIPTION: "Enter the description",
  ADD_SUBSCRIPTION: "Add Subscription",
  ADD_ADDON: "Create Add-on",
  STATUS: "Status",
  WE_ARE_DELIGHTED_TO_HAVE_AS_OUR_CUSTOMER:
    "we're delighted to have you as our customer",
  IT_DESIGNED_TO_HELP_YOU: "It's designed to help you.",
  FULLNAME: "Full Name",
  CALL_NOW: "Call Now",
  EMERGENCY_CALL: "Emergency Call",
  SKIP: "Skip",
  FEEDBACK: "FEEDBACK",
  FEEDBACK_HEADERS:
    "Please help us to serve you better by taking a couple of minutes,so we'd like to hear your feedback.",
  RATE_YOUR_EXPERIENCE: "Rate Your Experience",
  COMMMENT_OPTIONAL: "Comments(optional)",
  YOUR_COMMENTS: "Your comment",
  SEND_FEEDBACK: "Send Feedback",
  NO_DATA_FOUND: "No Record Found",
  ADD_NEW_AGENT: "Add agent",
  COUNTRY_CODE: "+91",
  COMMENTS: "Comments",
  TYPE: "Type",
  IS_CALLING: "is calling you,",
  DASHBOARD: "Dashboard",
  VENDOR: "Vendor",
  AGENT: "Agent",
  SUBSCRIPTION: "Subscription",
  ADDON: "Addon",
  AGENT_COUNT: "Agent Count",
  ACTION: "Action",
  PAYMENT: "Payment",
  TOTAL_AGENT: "Total Agents",
  TOTAL_SHARED_AGENT: "Total Shared Agent",
  TOTAL_OWNED_AGENT: "Total Owned Agent",
  TOTAL_CUSTOMER: "Total Customers",
  CUSTOMER_URL: "Customer URL",
  KIOSK_CUSTOMER_URL: "Kiosk Customer URL",
  IN_PATIENT_URL: "In-Patient URL",
  TOTAL_SUBSCRIPTION: "Total Subscriptions",
  TOTAL_VENDOR: "Total Vendors",
  CUSTOMER: "Customer",
  CUSTOMER_CARE: "Customer Care",
  CALL_IN_QUEUE: "Call's In Queue",
  HISTORY: "History",
  ADD_NEW_VENDOR: "Add vendor",
  CHAT: "Chat",
  PAY_NOW: "Pay Now",
  PASSWORD_REG:
    "Password should be min 8 characters in length,atleast one capital letter,one special character and a number",
  PLEASE_SELECT_SUBSCRIPTION: "Please Select the Subscriptions",
  PLEASE_SELECT_PAYMENT_TYPE: "Please Select the Payment type",
  PLEASE_SELECT_ACCOUNT_TYPE: "Please Select the Account Type",
  IFSC_LENGTH: "IFSC Number minimun 11 character.",
  GST_IN_LENGTH: "Gst In should be 15 characters.",
  PAN_LENGTH: "PAN minimum 10 character.",
  REG_LENGTH: "Register Number minimum 10 character.",
  PLEASE_SELECT_AGENT_TYPE: "Please Select Agent Type",
  PLEASE_SELECT_VENDOR_LOGO: "Please Select Vendor Logo",
  PLEASE_SELECT_VENDOR: "Please Select the Vendor",
  PLEASE_SELECT_AGENT_ROLE: "Please Select the agent role",
  DATE: "Date",
  INACTIVE: "Inactive",
  PRICE: "Price",
  ADDON_DETAILS: "Addon Details",
  DO_YOU_HAVE_KIOSK: "Do you have the feedback form?",
  DO_YOU_HAVE_IN_PATIENT: "Do you have the In-patient app?",
  MARK_AS_INPATIENT: "Mark as In-patient agent",
  MARK_AS_SERVICE_HEAD: "Mark as service head",
  SELECT: "Select",
  YES: "Yes",
  NO: "No",
  CALL_PRIORITY: {
    NORMAL_CALL: 1,
    EMERGENCY_CALL: 2,
  },
};
