import { takeLatest, put } from "redux-saga/effects";
import Actions from "../actions";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doAgentPost } from "../../utils/service/index";
import { AGE_VERIFICATION, VBIMAGE } from "../actions/AgeVerification";

export function* ageVerificationSaga({ params, callback }) {
    try {
        const response = yield doAgentPost(END_POINTS.AGE_VERIFICATION, params);
        if (response) {
            yield put(Actions.getAgeVerificationSuccessAction(response?.response));
            callback && callback(response?.response);
        }
    } catch (e) {
        yield put(Actions.getAgeVerfificationFailureAction(e));
        callback && callback(e);
    }
}
export function* virtualBackgroundSaga({ params, callback }) {
    try {
        const response = yield doAgentPost(END_POINTS.VIRTUAL_BACKGROUND, params);
        if (response) {
            yield put(Actions.getVirtualBackgroundSuccessAction(response?.response));
            callback && callback(response?.response);
        }
    } catch (e) {
        yield put(Actions.getVirtualBackgroundFailureAction(e));
        callback && callback(e);
    }
}
export default function* ageVerificationListener() {
    yield takeLatest(AGE_VERIFICATION, ageVerificationSaga);
    yield takeLatest(VBIMAGE, virtualBackgroundSaga);
}