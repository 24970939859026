import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { startSocketConnect } from '../../socket'
import Actions from '../../redux/actions';

class SocketMessage extends React.Component {
    componentDidMount() {
        this._initiateSocket()
    }

    _initiateSocket() {
        const tokenData = this.props.getLoginDetails
        const location = this.props.location
        let arr = location?.pathname?.split('/')
        let token
        if (arr[1] === 'admin') {
            token = tokenData?.adminTokenDetails?.access_token
        } else if (arr[1] === 'vendor') {
            token = tokenData?.vendorTokenDetails?.access_token
        } else {
            token = tokenData?.tokenDetails?.access_token
        }
        if (token) {
            startSocketConnect((socket, roleId) => {
                this.showToastMessage(socket, roleId);
            })
        }
    }


    showToastMessage(socketObj, roleId) {
        // this.props.chatMessage()
        // const toastId = Date.now()
        // toast.success(socketObj.message, {
        //     position: toast.POSITION.TOP_RIGHT,
        //     closeOnClick: () => { },
        //     onClick: () => this.navToaster(socketObj, toastId, roleId),
        //     toastId
        // });
        const currentScreenId = this.props.history.location.pathname
        let arr = currentScreenId?.split("/")
        arr.splice(4, 1)
        let currentUrl = arr?.join('/')
        if (socketObj?.type === 'vendor_update') {
            if (roleId === 2) {
                if (currentScreenId === ('/vendor/profile/details') || currentScreenId === ('/vendor/profile/update')) {
                    window.location.reload();
                }
                if (currentScreenId === ('/vendor/products/list') || currentScreenId === ('/vendor/products/add') || currentUrl === ('/vendor/products/view')) {
                    this.props.history.push('/vendor/dashboard')
                }
                this.props.socketVendorNameUpdate(socketObj)
            }
            if (roleId === 3) {
                this.props.socketVendorNameUpdate(socketObj)
            }
        }
        if ((socketObj?.type === 'subscription_payment') || (socketObj?.type === 'renew_subscription')) {
            // if (roleId === 2) {
                this.props.socketPaymentUpdate(socketObj)
            // }
        }
        if ((socketObj?.type === 'agent_update')) {
            if (roleId === 3) {
                this.props.socketAgentNameUpdate(socketObj)
            }
        }
    }

    navToaster(socketObj, toastId, roleId) {

    }

    render() {
        return <ToastContainer />
    }
}

export const mapStateToProps = (state) => {
    return {
        getLoginDetails: Actions.getLoginDetails(state)
    };
};

export default withRouter(connect(mapStateToProps, {
    socketVendorNameUpdate: Actions.socketVendorNameUpdate,
    socketAgentNameUpdate: Actions.socketAgentNameUpdate,
    socketPaymentUpdate: Actions.socketPaymentUpdate
})(SocketMessage));
