import React from 'react';
import { withRouter } from 'react-router-dom';
import Constants from '../utils/Constants';
import PlaceHolderImage from "../assets/img/no_image.png";
import { msToHMS, timeConvert } from '../utils/validation';
import moment from 'moment';


class ViewVendorScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const { data } = this.props;
        let count = 0
        data?.Vendor?.VendorAddons?.forEach((o) => {
            count += o?.remainingSec
        })
        return (
            <div className="content-part px-5 py-4 list">
                <div className="agent-details">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.NAME}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.fullName ? data.fullName : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.EMAIL}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.email ? data.email : "-"}</p>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.PASSWORD}
                                </div>
                                <div className='col-7'>
                                    <p>{data && (data?.password?.slice(0, 20) + "....")}
                                    </p>
                                </div>
                            </div> */}
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.MOBILE_NUMBER}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.phone ? data.phone : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.SUBSCRIPTION_ID}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data?.Vendor?.Subscription?.planName ? data?.Vendor?.Subscription?.planName : "-"}</p>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.ACCOUNT_TYPE}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data?.Vendor?.accountType}</p>
                                </div>
                            </div> */}
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.IFSC}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data?.Vendor?.ifsc}</p>
                                </div>
                            </div> */}
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.AGENT_TYPE}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data?.Vendor?.agentTypeId === 1 ? "Shared Agent" : "Owned Agent"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.TOTAL_TIME}
                                </div>
                                <div className='col-7'>
                                    <p>{data.Vendor?.Subscription?.totalMins ? timeConvert(data && data.Vendor?.Subscription?.totalMins) : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.USED_TIME}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data.Vendor?.agentCallLimit ? msToHMS(data && data.Vendor?.agentCallLimit) : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.REMAINING_TIME}
                                </div>
                                <div className='col-7'>
                                    <p>{data.Vendor?.remainingSec ? msToHMS(data && data.Vendor?.remainingSec + count) : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Reminder Minutes
                                </div>
                                <div className='col-7'>
                                    <p>{data.Vendor?.remainderSec ? msToHMS(data && data.Vendor?.remainderSec) : "-"}</p>
                                </div>
                            </div>
                            {data?.Vendor?.Subscription?.argumentedReality === 1 && <div className='row'>
                                <div className='col-5 text-bold'>
                                    Argumented Reality
                                </div>
                                <div className='col-7'>
                                    <p>{data.Vendor?.isAR ? (data.Vendor?.isAR == 1 ? "Yes" : "No") : "-"}</p>
                                </div>
                            </div>}
                            {data && data?.Vendor?.VendorAddons.length > 0 &&
                                <div className='row'>
                                    <div className='col-5 text-bold'>
                                        {Constants.ADDON_DETAILS}
                                    </div>
                                    <div className='col-7'>
                                        {data && data?.Vendor?.VendorAddons?.map((o) => {
                                            return <>
                                                <ul>
                                                    <li>{o?.Addon?.addonPlanName}</li>
                                                    <p>({msToHMS(o?.Addon?.totalSec)})</p>
                                                </ul>
                                            </>
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-md-6">
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.STORE_LOGO}
                                </div>
                                <div className='col-7'>
                                    <img style={{ width: '90px', height: '90px', marginBottom: '4px' }} alt="Product" src={data && data?.Vendor?.vendorLogo ? data?.Vendor?.vendorLogo : (PlaceHolderImage)} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.COMPANY_NAME}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data?.Vendor?.companyName ? data?.Vendor?.companyName : "-"}</p>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.ACCOUNT_NUMBER}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data?.Vendor?.accountNumber ? data?.Vendor?.accountNumber : "-"}</p>
                                </div>
                            </div> */}
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.GST}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data?.Vendor?.gstIn ? data?.Vendor?.gstIn : "-"}</p>
                                </div>
                            </div> */}
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.REGISTER_NUMBER}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data?.Vendor?.regNo ? data?.Vendor?.regNo : "-"}</p>
                                </div>
                            </div> */}
                            {/* <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.PAN}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data?.Vendor?.pan ? data?.Vendor?.pan : "-"}</p>
                                </div>
                            </div> */}
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.ADDRESS}
                                </div>
                                <div className='col-7'>
                                    <p>{data && data?.Vendor?.address ? data?.Vendor?.address : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Created Date
                                </div>
                                <div className='col-7'>
                                    <p>{data && data?.Vendor?.createdAt ? moment(data?.Vendor?.createdAt).format("DD/MM/YYYY") : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    {Constants.PAYMENT_STATUS}
                                </div>
                                <div className='col-3'>
                                    <p>{data?.Vendor?.paymentStatus === 0 ? "Pending" : "Done"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Payment Completed Date
                                </div>
                                <div className='col-7'>
                                    <p>{data && data?.Vendor?.PaymentTransaction?.createdAt ? moment(data?.Vendor?.PaymentTransaction?.createdAt).format("DD/MM/YYYY") : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Subscription Start Date
                                </div>
                                <div className='col-3'>
                                    <p>{data?.Vendor?.subscriptionStartDate ? moment(data?.Vendor?.subscriptionStartDate).format("DD/MM/YYYY") : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Subscription End Date
                                </div>
                                <div className='col-3'>
                                    <p>{data?.Vendor?.subscriptionEndDate ? moment(data?.Vendor?.subscriptionEndDate).format("DD/MM/YYYY") : "-"}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-bold'>
                                    Remaining Day
                                </div>
                                <div className='col-3'>
                                    <p>{data?.Vendor?.dayCount ? data?.Vendor?.dayCount : "-"}</p>
                                </div>
                            </div>
                            {data && data?.Vendor?.agentTypeId === 1 &&
                                <div className='row'>
                                    <div className='col-5 text-bold'>
                                        {Constants.AGENT_NAME}
                                    </div>
                                    <div className='col-7'>
                                        {data && data?.Vendor?.VendorAgents?.map((o) => {
                                            return <>
                                                <ul>
                                                    <li>{o?.User?.fullName}</li>
                                                </ul>
                                            </>
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className=" mt-5 me-5 pb-3 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.goBack()} >{Constants.BACK}</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(ViewVendorScreen);