import { takeLatest, put } from "redux-saga/effects";
import Actions from "../actions";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doAdminGET } from "../../utils/service/index";
import { PROFILE } from "../actions/VendorProfileAction";

export function* vendorProfileSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(END_POINTS.VENDOR_GET_AGENT, params, isAdmin);
        if (response) {
            yield put(Actions.getProfileSuccessAction(response?.response));
            callback && callback(response?.response);
        }
    } catch (e) {
        yield put(Actions.getProfileFailureAction(e));
        callback && callback(e);
    }
}

export default function* vendorProfileListener() {
    yield takeLatest(PROFILE, vendorProfileSaga);
}