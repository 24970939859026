import { doAction, doResponseAction } from ".";

const GET_SUBSCRIPTION_LIST = 'GET_SUBSCRIPTION_LIST';
const GET_SUBSCRIPTION_LIST_SUCCESS = 'GET_SUBSCRIPTION_LIST_SUCCESS';
const GET_SUBSCRIPTION_LIST_FAILURE = 'GET_SUBSCRIPTION_LIST_FAILURE';

const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
const ADD_SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION_SUCCESS';
const ADD_SUBSCRIPTION_FAILURE = 'ADD_SUBSCRIPTION_FAILURE';

const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';

const GET_SUBSCRIPTION_DETAILS = 'GET_SUBSCRIPTION_DETAILS';
const GET_SUBSCRIPTION_DETAILS_SUCCESS = 'GET_SUBSCRIPTION_DETAILS_SUCCESS';
const GET_SUBSCRIPTION_DETAILS_FAILURE = 'GET_SUBSCRIPTION_DETAILS_FAILURE';

const GET_SUBSCRIPTION_ACTIVE_LIST = 'GET_SUBSCRIPTION_ACTIVE_LIST';
const GET_SUBSCRIPTION_ACTIVE_LIST_SUCCESS = 'GET_SUBSCRIPTION_ACTIVE_LIST_SUCCESS';
const GET_SUBSCRIPTION_ACTIVE_LIST_FAILURE = 'GET_SUBSCRIPTION_ACTIVE_LIST_FAILURE';

const GET_PAYMENT_LIST = 'GET_PAYMENT_LIST';
const GET_PAYMENT_LIST_SUCCESS = 'GET_PAYMENT_LIST_SUCCESS';
const GET_PAYMENT_LIST_FAILURE = 'GET_PAYMENT_LIST_FAILURE';
export {
    GET_SUBSCRIPTION_LIST,
    GET_SUBSCRIPTION_LIST_SUCCESS,
    GET_SUBSCRIPTION_LIST_FAILURE,
    GET_PAYMENT_LIST,
    GET_PAYMENT_LIST_SUCCESS,
    GET_PAYMENT_LIST_FAILURE,
    ADD_SUBSCRIPTION,
    ADD_SUBSCRIPTION_SUCCESS,
    ADD_SUBSCRIPTION_FAILURE,
    UPDATE_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_FAILURE,
    GET_SUBSCRIPTION_DETAILS,
    GET_SUBSCRIPTION_DETAILS_SUCCESS,
    GET_SUBSCRIPTION_DETAILS_FAILURE,
    GET_SUBSCRIPTION_ACTIVE_LIST,
    GET_SUBSCRIPTION_ACTIVE_LIST_SUCCESS,
    GET_SUBSCRIPTION_ACTIVE_LIST_FAILURE
}

export const getSubscription = store => store['SUBSCRIPTIONS_DETAILS']
export const getSubscriptionList = (params, callback, isAdmin) => doAction(GET_SUBSCRIPTION_LIST, params, callback, isAdmin);
export const getSubscriptionListSuccess = data => doResponseAction(GET_SUBSCRIPTION_LIST_SUCCESS, data);
export const getSubscriptionListFailure = data => doResponseAction(GET_SUBSCRIPTION_LIST_FAILURE, data);


export const addSubscription = (params, callback, isAdmin) => doAction(ADD_SUBSCRIPTION, params, callback, isAdmin);
export const addSubscriptionSuccess = data => doResponseAction(ADD_SUBSCRIPTION_SUCCESS, data);
export const addSubscriptionFailure = data => doResponseAction(ADD_SUBSCRIPTION_FAILURE, data);

export const updateSubscription = (params, callback, isAdmin) => doAction(UPDATE_SUBSCRIPTION, params, callback, isAdmin);
export const updateSubscriptionSuccess = data => doResponseAction(UPDATE_SUBSCRIPTION_SUCCESS, data);
export const updateSubscriptionFailure = data => doResponseAction(UPDATE_SUBSCRIPTION_FAILURE, data);

export const getSubscriptionDetails = (params, callback, isAdmin) => doAction(GET_SUBSCRIPTION_DETAILS, params, callback, isAdmin);
export const getSubscriptionDetailsSuccess = data => doResponseAction(GET_SUBSCRIPTION_DETAILS_SUCCESS, data);
export const getSubscriptionDetailsFailure = data => doResponseAction(GET_SUBSCRIPTION_DETAILS_FAILURE, data);

export const getSubscriptionActiveList = (params, callback, isAdmin) => doAction(GET_SUBSCRIPTION_ACTIVE_LIST, params, callback, isAdmin);
export const getSubscriptionActiveListSuccess = data => doResponseAction(GET_SUBSCRIPTION_ACTIVE_LIST_SUCCESS, data);
export const getSubscriptionActiveListFailure = data => doResponseAction(GET_SUBSCRIPTION_ACTIVE_LIST_FAILURE, data);

export const getPaymentList = (params, callback, isAdmin) => doAction(GET_PAYMENT_LIST, params, callback, isAdmin);
export const getPaymentListSuccess = data => doResponseAction(GET_PAYMENT_LIST_SUCCESS, data);
export const getPaymentListFailure = data => doResponseAction(GET_PAYMENT_LIST_FAILURE, data);