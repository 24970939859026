import React from 'react';
import { withRouter } from 'react-router-dom';


class AccessDenied extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="access-content">
                <div className="access-denied">
                    <img src={require('../assets/img/access-denied.png')} className="access-denied-img" alt='image-not-found'/>
                    <div className='m-3 p-2'>
                        <h5>You Need To Buy the Subscription To Access</h5>
                        <p className="access-text">Access Denied</p>
                    </div>
                    <button className='btn add-item' onClick={() => this.props.history.push('/')}>GO Back</button>
                </div>
            </div>
        )
    }
}

export default withRouter(AccessDenied)