import { doAction, doResponseAction } from ".";

const VENDOR_LIST = "VENDOR_LIST";
const VENDOR_LIST_SUCCESS = "VENDOR_LIST_SUCCESS";
const VENDOR_LIST_FAILURE = "VENDOR_LIST_FAILURE";

const VENDOR_ACTIVE_LIST = "VENDOR_ACTIVE_LIST";
const VENDOR_ACTIVE_LIST_SUCCESS = "VENDOR_ACTIVE_LIST_SUCCESS";
const VENDOR_ACTIVE_LIST_FAILURE = "VENDOR_ACTIVE_LIST_FAILURE";


const VENDOR_AGENT_DELETE = "VENDOR_AGENT_DELETE";
const VENDOR_AGENT_DELETE_SUCCESS = "VENDOR_AGENT_DELETE_SUCCESS";
const VENDOR_AGENT_DELETE_FAILURE = "VENDOR_AGENT_DELETE_FAILURE";

const VENDOR_DETAILS = "VENDOR_DETAILS";
const VENDOR_DETAILS_SUCCESS = "VENDOR_DETAILS_SUCCESS";
const VENDOR_DETAILS_FAILURE = "VENDOR_DETAILS_FAILURE";

const UPDATE_VENDOR = "UPDATE_VENDOR";
const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";
const UPDATE_VENDOR_FAILURE = "UPDATE_VENDOR_FAILURE";

const GET_VENDOR_ONBOARD = `GET_VENDOR_ONBOARD`;
const GET_VENDOR_ONBOARD_SUCCESS = `GET_VENDOR_ONBOARD_SUCCESS`;
const GET_VENDOR_ONBOARD_FAILURE = `GET_VENDOR_ONBOARD_FAILURE`;

const VENDOR_ONBOARD = `VENDOR_ONBOARD`;
const VENDOR_ONBOARD_SUCCESS = `VENDOR_ONBOARD_SUCCESS`;
const VENDOR_ONBOARD_FAILURE = `VENDOR_ONBOARD_FAILURE`;

const VENDOR_SELECTED_ID = 'VENDOR_SELECTED_ID';

const GET_VENDOR_CONFIG_WEBHOOK = 'GET_VENDOR_CONFIG_WEBHOOK';
const GET_VENDOR_CONFIG_WEBHOOK_SUCCESS = 'GET_VENDOR_CONFIG_WEBHOOK_SUCCESS';
const GET_VENDOR_CONFIG_WEBHOOK_FAILURE = 'GET_VENDOR_CONFIG_WEBHOOK_FAILURE'

const VENDOR_CONFIG_WEBHOOK = 'ADD_VENDOR_CONFIG_WEBHOOK';
const VENDOR_CONFIG_WEBHOOK_SUCCESS = 'ADD_VENDOR_CONFIG_WEBHOOK_SUCCESS';
const VENDOR_CONFIG_WEBHOOK_FAILURE = 'ADD_VENDOR_CONFIG_WEBHOOK_FAILURE';

const UPDATE_VENDOR_CONFIG_WEBHOOK = 'UPDATE_VENDOR_CONFIG_WEBHOOK';
const UPDATE_VENDOR_CONFIG_WEBHOOK_SUCCESS = 'UPDATE_VENDOR_CONFIG_WEBHOOK_SUCCESS';
const UPDATE_VENDOR_CONFIG_WEBHOOK_FAILURE = 'UPDATE_VENDOR_CONFIG_WEBHOOK_FAILURE';

export {
    VENDOR_LIST,
    VENDOR_LIST_SUCCESS,
    VENDOR_LIST_FAILURE,

    VENDOR_ACTIVE_LIST,
    VENDOR_ACTIVE_LIST_SUCCESS,
    VENDOR_ACTIVE_LIST_FAILURE,

    VENDOR_AGENT_DELETE,
    VENDOR_AGENT_DELETE_SUCCESS,
    VENDOR_AGENT_DELETE_FAILURE,

    UPDATE_VENDOR,
    UPDATE_VENDOR_SUCCESS,
    UPDATE_VENDOR_FAILURE,

    GET_VENDOR_ONBOARD,
    GET_VENDOR_ONBOARD_SUCCESS,
    GET_VENDOR_ONBOARD_FAILURE,

    VENDOR_ONBOARD,
    VENDOR_ONBOARD_SUCCESS,
    VENDOR_ONBOARD_FAILURE,

    VENDOR_DETAILS,
    VENDOR_DETAILS_SUCCESS,
    VENDOR_DETAILS_FAILURE,

    VENDOR_SELECTED_ID,

    GET_VENDOR_CONFIG_WEBHOOK,
    GET_VENDOR_CONFIG_WEBHOOK_SUCCESS,
    GET_VENDOR_CONFIG_WEBHOOK_FAILURE,

    VENDOR_CONFIG_WEBHOOK,
    VENDOR_CONFIG_WEBHOOK_SUCCESS,
    VENDOR_CONFIG_WEBHOOK_FAILURE,

    UPDATE_VENDOR_CONFIG_WEBHOOK,
    UPDATE_VENDOR_CONFIG_WEBHOOK_SUCCESS,
    UPDATE_VENDOR_CONFIG_WEBHOOK_FAILURE,
};

export const getCustomerCallList = store => store['VENDOR_LIST_REDUCER']
export const vendorListAction = (params, callback) => doAction(VENDOR_LIST, params, callback);
export const vendorListSuccess = (data) => doResponseAction(VENDOR_LIST_SUCCESS, data);
export const vendorListFailure = (data) => doResponseAction(VENDOR_LIST_FAILURE, data);

export const vendorActiveListAction = (params, callback) => doAction(VENDOR_ACTIVE_LIST, params, callback);
export const vendorActiveListSuccess = (data) => doResponseAction(VENDOR_ACTIVE_LIST_SUCCESS, data);
export const vendorActiveListFailure = (data) => doResponseAction(VENDOR_ACTIVE_LIST_FAILURE, data)

export const vendorAgentDeleteAction = (params, callback) => doAction(VENDOR_AGENT_DELETE, params, callback);
export const vendorAgentDeleteSuccess = (data) => doResponseAction(VENDOR_AGENT_DELETE_SUCCESS, data);
export const vendorAgentDeleteFailure = (data) => doResponseAction(VENDOR_AGENT_DELETE_FAILURE, data)

export const updateVendorAction = (params, callback,isAdmin) => doAction(UPDATE_VENDOR, params, callback,isAdmin)
export const updateVendorSuccessAction = (data) => doResponseAction(UPDATE_VENDOR_SUCCESS, data);
export const updateVendorFailureAction = (data) => doResponseAction(UPDATE_VENDOR_FAILURE, data);

export const getVendorOnboardDetails = (params, callback) => doAction(GET_VENDOR_ONBOARD, params, callback)
export const getVendorOnboardDetailsSuccessAction = (data) => doResponseAction(GET_VENDOR_ONBOARD_SUCCESS, data);
export const getVendorOnboardDetailsFailureAction = (data) => doResponseAction(GET_VENDOR_ONBOARD_FAILURE, data);

export const vendorOnBoard = (params, callback) => doAction(VENDOR_ONBOARD, params, callback)
export const vendorOnBoardSuccessAction = (data) => doResponseAction(VENDOR_ONBOARD_SUCCESS, data);
export const vendorOnBoardFailureAction = (data) => doResponseAction(VENDOR_ONBOARD_FAILURE, data);

export const vendorDetails = (params, callback ,isAdmin) => doAction(VENDOR_DETAILS, params, callback ,isAdmin);
export const vendorDetailsSuccess = (data) => doResponseAction(VENDOR_DETAILS_SUCCESS, data);
export const vendorDetailsFailure = (data) => doResponseAction(VENDOR_DETAILS_FAILURE, data);

export const vendorWeBhookConfig = (params, callback, isAdmin) => doAction(VENDOR_CONFIG_WEBHOOK, params, callback, isAdmin);
export const vendorWeBhookConfigSuccess = (data) => doResponseAction(VENDOR_CONFIG_WEBHOOK_SUCCESS, data);
export const vendorWeBhookConfigFailure = (data) => doResponseAction(VENDOR_CONFIG_WEBHOOK_FAILURE, data);

export const updateVendorWeBhookConfig = (params, callback, isAdmin) => doAction(UPDATE_VENDOR_CONFIG_WEBHOOK, params, callback, isAdmin);
export const updateVendorWeBhookConfigSuccess = (data) => doResponseAction(UPDATE_VENDOR_CONFIG_WEBHOOK_SUCCESS, data);
export const updateVendorWeBhookConfigFailure = (data) => doResponseAction(UPDATE_VENDOR_CONFIG_WEBHOOK_SUCCESS, data);


export const getVendorWeBhookConfig = () => doAction(GET_VENDOR_CONFIG_WEBHOOK);
export const getVendorWeBhookConfigSuccess = (data) => doResponseAction(GET_VENDOR_CONFIG_WEBHOOK_SUCCESS, data)
export const getVendorWeBhookConfigFailure = (data) => doResponseAction(GET_VENDOR_CONFIG_WEBHOOK_FAILURE, data)

 

export const selectVendorListById = (id) => {
    return {
        type: VENDOR_SELECTED_ID,
        id
    }
}