import Actions from "../actions";

const initialState = {
  isLoading: false,
  customerDecline: undefined,
  error: false,
};

export const CustomerDeclineReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CUSTOMER_DECLINE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.CUSTOMER_DECLINE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        customerDecline: action.data,
        error: false,
      };
    }
    case Actions.CUSTOMER_DECLINE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export const customerDeclineLoader = (state) => state?.CUSTOMER_DECLINE;
