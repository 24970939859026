import { doVendorGET, doVendorPost } from "../../utils/service";
import { put, takeLatest, all } from "redux-saga/effects";
import { END_POINTS } from "../../utils/service/EndPoints";
import Actions from "../actions";
import { ADD_VENDOR_ROOM, GET_ROOM_LIST, GET_VENDOR_ROOM_DETAILS, UPDATE_VENDOR_ROOM } from "../actions/VendorRoomAction";


export function* roomListSaga({ params, callback }) {
    try {
        const response = yield doVendorGET(END_POINTS.GET_ROOM_LIST, params);
        if (response) {
            yield put(Actions.getRoomListSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getRoomListFailure(e));
        callback && callback(e);
    }
}

export function* vendorRoomDetailsSage({ params, callback }) {
    try {
        const response = yield doVendorGET(END_POINTS.GET_VENDOR_ROOM_DETAILS, params);
        if (response) {
            yield put(Actions.getVendorRoomDetailSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.getVendorRoomDetailFailure(e));
        callback && callback(e);
    }
}

export function* addVendorRoomSaga({ params, callback }) {
    try {
        const response = yield doVendorPost(params?.id ? END_POINTS.UPDATE_VENDOR_ROOM : END_POINTS.ADD_VENDOR_ROOM, params);
        if (response) {
            yield put(Actions.addVendorRoomSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.addVendorRoomFailure(e));
        callback && callback(e);
    }
}

export function* updateVendorRoomSaga({ params, callback }) {
    try {
        const response = yield doVendorPost(END_POINTS.UPDATE_VENDOR_ROOM, params);
        if (response) {
            yield put(Actions.updateVendorRoomSuccess(response.response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.updateVendorRoomFailure(e));
        callback && callback(e);
    }
}

export default function* vendorRoomListener() {
    yield all([
        takeLatest(GET_ROOM_LIST, roomListSaga),
        takeLatest(GET_VENDOR_ROOM_DETAILS,vendorRoomDetailsSage),
        takeLatest(ADD_VENDOR_ROOM,addVendorRoomSaga),
        takeLatest(UPDATE_VENDOR_ROOM,updateVendorRoomSaga)
    ]);
}