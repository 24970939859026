import { doAction, doResponseAction } from ".";

const CUSTOMER_CALL_LIST = "CUSTOMER_CALL_LIST";
const CUSTOMER_CALL_LIST_SUCCESS = "CUSTOMER_CALL_LIST_SUCCESS";
const CUSTOMER_CALL_LIST_FAILURE = "CUSTOMER_CALL_LIST_FAILURE";

export { CUSTOMER_CALL_LIST, CUSTOMER_CALL_LIST_SUCCESS, CUSTOMER_CALL_LIST_FAILURE };
export const getCustomerCallListDetails = store => store['CUSTOMER_CALL_LIST']
export const customerCallListAction = (params, callback) => doAction(CUSTOMER_CALL_LIST, params, callback);
export const customerCallListSuccess = (data) => doResponseAction(CUSTOMER_CALL_LIST_SUCCESS, data);
export const customerCallListFailure = (data) => doResponseAction(CUSTOMER_CALL_LIST_FAILURE, data);
