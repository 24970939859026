import React from 'react'
// import { withRouter } from 'react-router-dom'
import Close from '../../assets/img/modal-close.svg';
import Verfied from '../../assets/img/verified.gif'
class Alert extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { title, description, proceed, cancel, onSuccess, onFailure, textField, screen } = this.props
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabIndex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-100">
                            <div className="modal-body">
                                {(screen !== 'submittedFeedback' && screen !== 'feedback') && <div style={{ float: 'right', cursor: 'pointer' }}>
                                    <img
                                        style={{ height: '35px', width: '35px' }}
                                        id='close'
                                        src={Close}
                                        alt="X"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        className="modal-close "
                                        onClick={() => onFailure()} />
                                </div>}
                                <form>
                                    {screen === 'submittedFeedback' && <div className='text-center mb-4 mt-4'>
                                        <img src={Verfied} />
                                    </div>}
                                    <div
                                        className="row mb-4">
                                        <div
                                            className="col">
                                            {
                                                title && <h3
                                                    className="font18 fw-bold mb-5">{title}</h3>
                                            }
                                            <p className='mt-3'>{description}</p>
                                            {textField && <div className="col-12 col-lg pe-lg-3">
                                                <div className="input-group mb-3 mb-lg-0">
                                                    <textarea type="textarea" className="form-control " placeholder="Search by order no" />
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    {screen !== 'submittedFeedback' && <div className="row mt-2">
                                        <div
                                            id='onClickSuccess' className="col pe-0" onClick={() => onSuccess()}>
                                            <span className={`btn ${screen === 'feedback' ? 'btn-gradient' : 'buttonSuccess'} font14 w-100 p-3`}>{proceed}</span>
                                        </div>
                                        <div
                                            id="onClickFailure" className="col text-end" onClick={() => onFailure()}>
                                            <span className={`btn ${screen === 'feedback' ? 'cancel_btn' : 'buttonFailure'} font14 w-100 p-3`}>{cancel}</span> </div>
                                    </div>}
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show"></div>
            </React.Fragment>
        )
    }
}

export default Alert;