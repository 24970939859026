import { Component } from "react";
import Constants from "../utils/Constants";
import RoutePath from "../utils/routes/RoutePath";
import '@google/model-viewer/dist/model-viewer'
import VendorService from "./VendorService";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Actions from "../redux/actions";
import { connect } from "react-redux";
class AddService extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        const Agent = this.props.match.path.split('/')
        if (Agent[2] === "products" && Agent[1] === "admin") {
            this.setState({ isVendor: true })
        } else {
            this.setState({ isVendor: false })
        }
        this.getSrmServiceAgentList()
    }

    getSrmServiceAgentList = () => {
        let params = {
            srmIpagent: false
        }
        const callback = (response) => {
            if (response) {

            }
        }
        this.props.getVendorSrmIpAgentList(params, callback)
    }

    render() {
        const { isEdit, error, message, handleSubmit, value, handleServiceName, handleSrmServiceHead } = this.props
        const { getVendorRoomService = {} } = this.props
        const { vendorSrmIpAgentListData } = getVendorRoomService
        return (
            <div className="content-part px-5 py-4">
                <div className="agent-details">
                    <div className="row m-0">
                        <div className="col-md-6" >
                            <div className="mt-3">
                                <p className="text-bold">Service Name</p>
                                <input autoComplete="off" type="text" name='ServiceName' maxLength="20" className="form-control w-75" value={value?.serviceName} placeholder="Enter Service Name" onChange={(e) => handleServiceName(e)} />
                                {error?.serviceName && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.serviceName}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-md-6" >
                            <div className="mt-3">
                                <p className="text-bold">Service Head</p>
                                <select name='srmServiceHead' className="form-select w-75" value={value?.userId} onChange={(e) => handleSrmServiceHead(e)}>
                                    <option hidden value={"ALL"}> Select Service Head</option>
                                    {
                                        vendorSrmIpAgentListData?.srmVendorSeviceAgents?.length > 0 && vendorSrmIpAgentListData?.srmVendorSeviceAgents?.map((o) => {
                                            return (
                                                <option key={o?.User?.id} value={o?.User?.id}>{o?.User?.fullName + ' (' + o?.User?.email + ')'}</option>
                                            )
                                        })
                                    }
                                </select>
                                {error?.userId && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.userId}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 me-5 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.push(RoutePath.VENDOR_SERVICE)}>{Constants.BACK}</button>
                        <button type="button" className="btn input-btn mx-3" onClick={() => handleSubmit()}>{isEdit ? Constants.UPDATE : Constants.CREATE}</button>
                    </div>
                </div>
            </div>
        )
    }
}
export const mapStateToProps = (state) => {
    return {
        getVendorRoomService: Actions.getVendorRoomService(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    getVendorSrmIpAgentList: Actions.getVendorSrmIpAgentList
})(AddService
));
