import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../redux/actions";
import Constants, { LIMIT } from "../utils/Constants";
import RoutePath from "../utils/routes/RoutePath";
import NoDataFound from "./NoDataFound";
class AgentListScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    handleViewScreen = (id) => {
        const { isAgent } = this.props
        this.props.history.push(isAgent ? RoutePath.ADMIN_AGENT_VIEW : RoutePath.VENDOR_AGENT_VIEW);
        let params = {
            id: id,
            agent: isAgent
        }
        this.props.selectedId(params)
    }
    render() {
        const { agentLists, editAgent, updateActive, isAgent } = this.props
        return (
            <div className="admin_table">
                <div role="region" aria-label="data table" tabIndex="0" className="candidate_primary">
                    <table className="dashboard_table">
                        <thead>
                            <tr>
                                <th className="pin">{Constants.ID}</th>
                                <th>{Constants.NAME}</th>
                                <th>{Constants.AGENT_TYPE}</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isAgent ?
                                (agentLists?.length > 0 ?
                                    agentLists?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="pin">{((this.props.currentPage - 1) * LIMIT) + (index + 1)}</td>
                                                <td>{item.fullName}</td>
                                                <td>{item.agentType === 1 ? Constants.SHARED : Constants.OWNED}</td>
                                                <td >
                                                    <i className="fa-solid fa-pen-to-square updateIcon edit-icon" onClick={() => editAgent(item.id)} />
                                                    <span onClick={() => updateActive(item.isActive && item.isActive === 1 ? 0 : 1, item)}>
                                                    </span>
                                                    <span onClick={() => updateActive(item.isActive && item.isActive === 1 ? 0 : 1, item)}>
                                                        <i style={{ fontSize: '23px' }} className={item?.isActive && item?.isActive === 1 ? "fa-solid fa-toggle-on toggle-icon ms-2 edit-icon" : "fa-solid fa-toggle-off toggle-icon ms-2 edit-icon"} />
                                                    </span>
                                                    <i className="fa-solid fa-eye ms-2 edit-icon" style={{ fontSize: '23px' }} onClick={() => this.handleViewScreen(item?.id)}></i>
                                                </td>
                                            </tr>
                                        );
                                    }) : <NoDataFound />)
                                :
                                (agentLists?.length > 0 ?
                                    agentLists?.map((item, index) => {
                                        const { User } = item;
                                        return (
                                            <tr key={index}>
                                                <td className="pin">{((this.props.currentPage - 1) * LIMIT) + (index + 1)}</td>
                                                <td>{User.fullName}</td>
                                                <td>{User.agentType === 1 ? Constants.SHARED : Constants.OWNED}</td>
                                                <td >
                                                    <i className="fa-solid fa-pen-to-square updateIcon edit-icon" onClick={() => editAgent(User.id)} />
                                                    <span onClick={() => updateActive(User.isActive && User.isActive === 1 ? 0 : 1, User)}>
                                                    </span>
                                                    <span onClick={() => updateActive(User.isActive && User.isActive === 1 ? 0 : 1, User)}>
                                                        <i style={{ fontSize: '23px' }} className={User?.isActive && User?.isActive === 1 ? "fa-solid fa-toggle-on toggle-icon ms-2 edit-icon" : "fa-solid fa-toggle-off toggle-icon ms-2 edit-icon"} />
                                                    </span>
                                                    <i className="fa-solid fa-eye ms-2 edit-icon" style={{ fontSize: '23px' }} onClick={() => this.handleViewScreen(User?.id)}></i>
                                                </td>
                                            </tr>
                                        );
                                    }) : <NoDataFound />)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
export const mapStateToProps = (state) => {
    return {

    }
};
export default withRouter(connect(mapStateToProps, {
    selectedId: Actions.getAgentSelectedById
})(AgentListScreen));
