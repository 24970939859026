import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import ViewVendorScreen from '../component/ViewVendorScreen';
import AppLoader from '../utils/AppLoader';


class ViewVendor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {}
        }
    }
    componentDidMount() {
        const { vendorSelectedId } = this.props;
        let param = {
            id: vendorSelectedId
        }
        const callback = (response) => {
            this.setState({ data: response?.response })
        }
        this.props.vendorDetails(param, callback, true)
    }

    render() {
        const { isLoading } = this.props.vendorState
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <ViewVendorScreen isEdit={this.props.match.params.id ? true : false} data={this.state.data} />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        vendorSelectedId: state?.VENDOR_LIST_REDUCER?.vendorSelectedId,
        vendorState: state?.VENDOR_LIST_REDUCER
    }
};
export default withRouter(connect(mapStateToProps, {
    vendorDetails: Actions.vendorDetails
})(ViewVendor));