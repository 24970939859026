import { takeLatest, put } from "redux-saga/effects";
import Actions from "../actions";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doAdminGET } from "../../utils/service/index";
import { DASHBOARD } from "../actions/DashboardAction";

export function* adminDashboardSaga({ params, callback, isAdmin }) {
    try {
        const response = yield doAdminGET(isAdmin ? END_POINTS.ADMIN_DASHBOARD : END_POINTS.VENDOR_DASHBOARD, params, isAdmin);
        if (response) {
            yield put(Actions.getDashboardSuccessAction(response?.response));
            callback && callback(response?.response);
        }
    } catch (e) {
        yield put(Actions.getDashboardFailureAction(e));
        callback && callback(e);
    }
}

export default function* adminDashboardListener() {
    yield takeLatest(DASHBOARD, adminDashboardSaga);
}