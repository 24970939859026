import { doAction, doResponseAction } from ".";



const VOICE_RECORDING_LIST = "VOICE_RECORDING_LIST";
const VOICE_RECORDING_LIST_SUCCESS = "VOICE_RECORDING_LIST_SUCCESS";
const VOICE_RECORDING_LIST_FAILURE = "VOICE_RECORDING_LIST_FAILURE";

export {
    VOICE_RECORDING_LIST,
    VOICE_RECORDING_LIST_SUCCESS,
    VOICE_RECORDING_LIST_FAILURE,
};
export const getVoiceRecordingData = store => store["VOICE_RECORDING"]

export const getVoiceRecordingListAction = (params, callback) => doAction(VOICE_RECORDING_LIST, params, callback);
export const getVoiceRecordingListSuccessAction = (data) => doResponseAction(VOICE_RECORDING_LIST_SUCCESS, data);
export const getVoiceRecordingListFailureAction = (data) => doResponseAction(VOICE_RECORDING_LIST_FAILURE, data);

