import React, { Component } from 'react';
import { StereoAudioRecorder } from 'recordrtc';

class VoiceRecorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      recordingTime: this.props.duration,
      mediaStream: null,
      recorder: null,
    };
    this.timerInterval = null;
    this.canvasRef = React.createRef();
  }

  startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const recorder = new StereoAudioRecorder(stream);
        recorder.record();
        this.setState({ mediaStream: stream, isRecording: true, recorder });
        this.drawWaveform();
        this.startTimer();
      })
      .catch(error => {
        console.error('Error accessing microphone:', error);
      });
  };

  stopRecording = () => {
    const { recorder, mediaStream } = this.state;
    if (recorder) {
      recorder.stop(blob => {
        this.setState({ recordedBlob: blob, isRecording: false });
        const blobURL = URL.createObjectURL(blob);
        let params = {
          blob,
          blobURL
        }
        this.props.onSave(params);
      });
    }
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
      this.setState({ mediaStream: null });
    }
    cancelAnimationFrame(this.animationId); // Stop waveform animation
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  };

  startTimer = () => {
    this.timerInterval = setInterval(() => {
      this.setState(prevState => ({ recordingTime: prevState.recordingTime - 1000 }), () => {
        if (this.state.recordingTime === 0) {
          this.stopRecording();
        }
      });
    }, 1000);
  };

  drawWaveform = () => {
    const canvas = this.canvasRef.current;
    const canvasCtx = canvas.getContext('2d');

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();

    const source = audioContext.createMediaStreamSource(this.state.mediaStream);
    source.connect(analyser);

    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      this.animationId = requestAnimationFrame(draw);

      analyser.getByteTimeDomainData(dataArray);

      canvasCtx.fillStyle = 'rgb(255, 255, 255)';
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

      canvasCtx.beginPath();

      const sliceWidth = canvas.width * 1.0 / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = v * canvas.height / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    };

    draw();
  };

  componentWillUnmount() {
    if (this.state.isRecording) {
      this.stopRecording();
    }
  }

  render() {
    const { isRecording, recordingTime } = this.state;
    const minutes = Math.floor(recordingTime / 60000);
    const seconds = Math.floor((recordingTime % 60000) / 1000);

    return (
      <div className="my-4 text-center">
        <div className='d-flex align-items-center overflow-hidden'>
          <canvas ref={this.canvasRef} className='canva_width' height="100"></canvas>
          <button className={`btn btn-light text-nowrap mx-2 ${!isRecording ? 'disabled' : ''}`} onClick={isRecording ? this.stopRecording : this.startRecording}>
            <i className={`fa ${isRecording ? 'fa-stop' : 'fa-microphone'} fs-4`}></i>
          </button>
        </div>
        <div className="mt-2">
          <span style={{ fontSize: '24px', color: 'black', fontWeight: 'bold' }}>{`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</span>
        </div>
      </div>
    );
  }
}

export default VoiceRecorder;
