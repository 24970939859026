import Actions from "../actions";

const initialState = {
    isLoading: false,
    feedBack: undefined,
    error: false,
    isAttended: false,
    downloadFeedback: undefined
};

export const feedBackReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FEEDBACK: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FEEDBACK_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                feedBack: action.data,
                error: false,
            };
        }
        case Actions.FEEDBACK_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.DOWNLOAD_FEEDBACK: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DOWNLOAD_FEEDBACK_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                downloadFeedback: action.data,
                error: false,
            };
        }
        case Actions.DOWNLOAD_FEEDBACK_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.ATTEND_CALL: {
            return {
                ...state,
                isAttended: action.data
            };
        }
        default: {
            return state
        }
    }
};
export const feedBackLoader = (state) => state?.FEEDBACK;