import Actions from "../actions";

const initialState = {
  data: undefined,
  isLoading: false,
  error: undefined,
  productListData: null,
  adminProductListData: null,
  arProductListData: null,
  productDetails: null,
  allVendorList: null,
};

export const ARProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_PRODUCT_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.GET_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        productListData: action.data?.isVendor ? (action.data ? action?.data : {}) : (!!action.data ? state?.productListData : {}),
        adminProductListData: !action.data?.isVendor ? (action.data ? action?.data : {}) : (!!action.data ? state?.adminProductListData : {}),
        error: false,
      };
    }
    case Actions.GET_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.CREATE_PRODUCT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.CREATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    }
    case Actions.CREATE_PRODUCT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.UPDATE_PRODUCT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.UPDATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    }
    case Actions.UPDATE_PRODUCT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.PRODUCT_DETAILS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.PRODUCT_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        productDetails: action.data,
        error: false,
      };
    }
    case Actions.PRODUCT_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.GET_AR_PRODUCT_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.GET_AR_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        arProductListData: action.data,
        error: false,
      };
    }
    case Actions.GET_AR_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.GET_ALL_VENDOR_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.GET_ALL_VENDOR_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allVendorList: action?.data?.response,
        error: false,
      };
    }
    case Actions.GET_ALL_VENDOR_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export const productList = (state) => state?.AR_PRODUCTS?.productListData;
export const productDetails = (state) => state?.AR_PRODUCTS?.productDetails;
export const productLoading = (state) => state?.AR_PRODUCTS?.isLoading;
export const productListLoader = (state) => state?.AR_PRODUCTS?.isLoading;
export const arProductList = (state) => state?.AR_PRODUCTS?.arProductListData;
