import { withRouter } from "react-router-dom/cjs/react-router-dom.min"
import SideBar from "../component/SideBar"
import ViewService from "../component/ViewService"
import Actions from "../redux/actions"
import AppLoader from "../utils/AppLoader"
import Header from "../component/Header"
import React from 'react';
import { connect } from "react-redux"

class VendorViewService extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            vendorServiceDetailData: [],
            isLoading: false
        }
    }
    componentDidMount() {
        this.getServicedDetail()
    }
    getServicedDetail = () => {
        const route = window.location.href.split('/')
        let params = {
            id: this.props.match.params.id
        }
        const callback = (response) => {
            if (response) {
                this.setState({ viewData: response?.response, isLoading: false })
            }
        }
        this.setState({ isLoading: true })
        this.props.getVendorServiceDetail(params, callback)
    }
    render() {
        const { getVendorRoomService = {} } = this.props
        const { isLoading, vendorServiceDetailData } = getVendorRoomService
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <ViewService data={vendorServiceDetailData} />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        getVendorRoomService: Actions.getVendorRoomService(state)
    }
};

export default withRouter(connect(mapStateToProps, {
    getVendorServiceDetail: Actions.getVendorServiceDetail,
})(VendorViewService));