import Actions from "../actions";

const initialState = {
    isLoading: false,
    subscription: undefined,
    error: false,
    isAttended:false
};

export const RenewSubscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.RENEW_SUBSCRIPTION: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.RENEW_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                subscription: action.data,
                error: false,
            };
        }
        case Actions.RENEW_SUBSCRIPTION_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.ATTEND_CALL: {
            return {
                ...state,
                isAttended:action.data
            };
        }
        default: {
            return state
        }
    }
};
export const renewSubscriptionLoader = (state) => state?.RENEW_SUBSCRIPTION;