import React from 'react'
import { REAL_LIFE_AR_URL, S3_URL, SHOE_AR_URL } from '../utils/Constants';
import '@google/model-viewer/dist/model-viewer'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { productLoading } from '../redux/reducer/ARProductReducer';
import AppLoader from '../utils/AppLoader';
import Actions from '../redux/actions';
var QRCode = require('qrcode.react');

class GlbRendering extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            productGLB: '',
            isShoe: false
        }
    }
    componentDidMount() {
        this.getArProductList()
    }
    getArProductList = () => {
        const { sku } = this.props.match.params || {}
        const { location } = this.props
        const Arr = location?.pathname.split("/")
        const productId = Arr[Arr.length - 2]
        let params = {
            sku: sku,
            productId: productId
        }
        const callback = (response) => {
            if (response?.response_code == 0) {
                const { productGLB, isShoe } = response?.response || {}
                this.setState({
                    productGLB: productGLB,
                    isShoe: isShoe == 1 ? true : false
                })
            }
        }
        this.props.getArProductList(params, callback, false)
    }
    render() {
        const { sku } = this.props.match.params || {}
        const { location } = this.props
        const { isLoading, arProductListData } = this.props.getArProduct
        const Arr = location?.pathname.split("/")
        const productId = Arr[Arr.length - 2]
        return (
            <React.Fragment>
                <div className='ar_render' >
                    <div className='row m-0'>
                        <div className='col-6'>
                            <model-viewer
                                src={this.state.productGLB}
                                poster={require("../assets/img/ar.gif")}
                                shadow-intensity="1" ar camera-controls touch-action="pan-y"
                                style={{ height: "70vh" }}
                                alt="A 3D model carousel"
                                auto-rotate>
                            </model-viewer>
                        </div>
                        <div className='col-6'>
                            <center>
                                <QRCode size={140}
                                    imageSettings={{
                                        height: 124,
                                        width: 124,
                                    }}
                                    value={`${this.state.isShoe ? SHOE_AR_URL : REAL_LIFE_AR_URL}${productId}/${sku}`} />
                            </center>
                            <div className="text-center mt-2">
                                <p className="text-uppercase scanner_text">SCAN THIS QR WITH YOUR PHONE TO VIEW THE PRODUCT IN YOUR SPACE</p>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </React.Fragment>
        )
    }
}
export const mapStateToProps = (state) => {
    return {
        getArProduct: Actions.getArProductList(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    getArProductList: Actions.getArProductList,
})(GlbRendering));