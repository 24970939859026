import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../redux/actions";
import PlaceHolderImage from "../assets/img/no_image.png";
import Constants from '../utils/Constants'
import { accountNumberOnly, convertToMilliSeconds, isCharacterReg, isOnlyCharacterReg, isValidEmail, isValidMobileNumber, isValidPassword, msToHMS } from "../utils/validation";
import { toast } from 'react-toastify';
import RoutePath from "../utils/routes/RoutePath";
import MultiSelect from "../utils/MultiSelect";
import AppLoader from "../utils/AppLoader";
import { convertToBase64 } from "../utils/ConvertBase64";
import '@google/model-viewer/dist/model-viewer'
const onlyNumberRegex = new RegExp(`^[A-Z0-9.]+$`);

class AddVendorScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            isLoading: false,
            fields: {},
            showPassword: false,
            isClick: false,
            isAccountActive: {}
        }
    }
    componentDidMount = () => {
        const callback = (response) => {
            const data = response
            const { Vendor = {} } = data
            const selectedAgent = Vendor?.VendorAgents?.map(o => {
                return {
                    label: o?.User?.fullName + " - (" + o?.User?.phone + ")",
                    id: o?.User?.id,
                    data: o?.User,
                    vendorAgentId: o?.id
                }
            })
            let convertToSec = convertToMilliSeconds(Vendor?.remainderMins)
            let convertToTime = msToHMS(convertToSec)
            const { isEdit } = this.props
            this.setState({
                isLoading: false,
                fields: {
                    isAR: Vendor?.isAR,
                    name: data.fullName,
                    email: data.email,
                    mobileNumber: data.phone,
                    subscriptionId: Vendor?.subscriptionId,
                    accountType: Vendor?.accountType,
                    ifsc: Vendor?.ifsc,
                    companyName: Vendor?.companyName,
                    accountNumber: Vendor?.accountNumber,
                    gst: Vendor?.gstIn,
                    registerNumber: Vendor?.regNo,
                    pan: Vendor?.pan,
                    agentType: Vendor?.agentTypeId,
                    agentType1: Vendor?.agentTypeId,
                    agentLimit: Vendor?.Subscription?.agentLimit,
                    argumentedReality: Vendor?.Subscription?.argumentedReality,
                    address: Vendor?.address,
                    selectedAgents: selectedAgent,
                    vendorLogo: Vendor?.vendorLogo,
                    remainderSec: Vendor?.remainderMins,
                    totalSec: Vendor?.totalSec,
                    paymentTypeId: Vendor?.paymentTypeId,
                    isFeedback: Vendor?.isFeedback,
                    feedbackEmail: Vendor?.feedbackEmail,
                    isSrmIp: Vendor?.isSrmIp
                },
                error: {
                    time: convertToTime
                },
                isAccountActive: {
                    subscriptionId: isEdit && Vendor?.subscriptionId,
                    paymentTypeId: isEdit && Vendor?.paymentTypeId,
                    agentTypeId: isEdit && Vendor?.agentTypeId
                }
            })
        }
        const paymentListCallback = (response) => {
            if (response?.response_code !== 0) {
                toast.error("Something went wrong")
            }
        }
        if (this.props.match.params.id) {
            this.setState({ isLoading: true })
            let param = {
                id: this.props.match.params.id
            }
            this.props.getVendorOnboardDetails(param, callback, true)
        }
        this.props.getSubscriptionAciveList('', '', true)
        this.props.paymentList("", paymentListCallback)
    }
    validateForm = (validateArray) => {
        let { error, fields } = this.state
        let isFailed = false;
        validateArray?.forEach((a) => {
            switch (a) {
                case 'name':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.PLEASE_ENTER_THE_NAME
                    } else if (!isCharacterReg(fields[a])) {
                        isFailed = true
                        error[a] = Constants.NAME_REGX
                    } else if (fields[a].trim().length < 3) {
                        isFailed = true
                        error[a] = Constants.NAME_LENGTH
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'email':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.ENTER_EMAIL_ID
                    } else if (!isValidEmail(fields[a])) {
                        isFailed = true
                        error[a] = Constants.INVALID_EMAIL_ID
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'password':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.ENTER_PASSWORD
                    } else if (!isValidPassword(fields[a])) {
                        isFailed = true
                        error[a] = Constants.PASSWORD_REG
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'mobileNumber':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.PLEASE_ENTER_THE_MOBLIE_NUMBER
                    } else if (!isValidMobileNumber(fields[a])) {
                        isFailed = true
                        error[a] = Constants.MOBILE_NUMBER_MUST_BE_10_CHARCTER
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'subscriptionId':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.PLEASE_SELECT_SUBSCRIPTION
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'ifsc':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.ENTER_THE_IFSC
                    } else if (fields[a].length > 0 && !isCharacterReg(fields[a])) {
                        isFailed = true
                        error[a] = Constants.NAME_REGX
                    } else if (fields[a].trim().length < 11) {
                        isFailed = true
                        error[a] = Constants.IFSC_LENGTH
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'gst':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.ENTER_GST
                    } else if (fields[a].trim().length > 0 && !isOnlyCharacterReg(fields[a])) {
                        isFailed = true
                        error[a] = Constants.NAME_REGX
                    } else if (fields[a].trim().length < 15) {
                        isFailed = true
                        error[a] = Constants.GST_IN_LENGTH
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'companyName':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.ENTER_COMPANY_NAME
                    } else if (fields[a].trim().length > 0 && !isCharacterReg(fields[a])) {
                        isFailed = true
                        error[a] = Constants.NAME_REGX
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'remainderSec':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = "Please Enter Remainder Minutes"
                    } else if (!onlyNumberRegex.test(fields[a])) {
                        isFailed = true
                        error[a] = "Field only accepts numeric"
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'pan':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.ENTER_PAN_NUMBER
                    } else if (fields[a].trim().length > 0 && !isOnlyCharacterReg(fields[a])) {
                        isFailed = true
                        error[a] = Constants.NAME_REGX
                    } else if (fields[a].trim().length < 10) {
                        isFailed = true
                        error[a] = Constants.PAN_LENGTH
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'registerNumber':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.ENTER_REGISTER_NUMBER
                    } else if (fields[a].trim().length > 0 && !isCharacterReg(fields[a])) {
                        isFailed = true
                        error[a] = Constants.NAME_REGX
                    } else if (fields[a].trim().length < 10) {
                        isFailed = true
                        error[a] = Constants.REG_LENGTH
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'address':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.ENTER_ADDRESS
                    } else if (fields[a].trim().length < 5) {
                        isFailed = true
                        error[a] = Constants.VALID_ADDRESS
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'accountType':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.PLEASE_SELECT_ACCOUNT_TYPE
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'accountNumber':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.ENTER_THE_ACCOUNT_NUMBER
                    } else if (fields[a].trim().length > 0 && !accountNumberOnly(fields[a])) {
                        isFailed = true
                        error[a] = Constants.ACCOUNT_NUMBER_ACCEPT
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'agentType':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.PLEASE_SELECT_AGENT_TYPE
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'vendorLogo':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.PLEASE_SELECT_VENDOR_LOGO
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'isAR':
                    if (fields[a] === undefined) {
                        isFailed = true
                        error[a] = "Please Select AR model."
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'paymentTypeId':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.PLEASE_SELECT_PAYMENT_TYPE
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'agentLimit':
                    if (fields['selectedAgents'].length > fields['agentLimit']) {
                        isFailed = true
                        error['agentLimit'] = `You reached maximum agent limit for selected subscription.`
                    } else {
                        error['agentLimit'] = ''
                    }
                    break;
                case 'isFeedback':
                    if (fields[a] === undefined) {
                        isFailed = true
                        error[a] = "Please Select feedback details."
                    } else {
                        error[a] = ''
                    }
                    break;
                case 'feedbackEmail':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = Constants.ENTER_FEEDBACK_EMAIL
                    } else {
                        error[a] = ''
                    }
                    break
                case 'isSrmIp':
                    if (fields[a] === undefined) {
                        isFailed = true
                        error[a] = "Please Select In-patient details."
                    } else {
                        error[a] = ''
                    }
                    break;
                default:
                    break;
            }
        })
        this.setState({ error })
        return isFailed
    }
    handleChange = ({ target }, o) => {
        const { name, value } = target || {}
        const { fields } = this.state;
        if (name == "subscriptionId") {
            let subscription = o?.find(o => o.id == value)
            fields["totalSec"] = subscription?.totalSec
            fields['agentLimit'] = subscription?.agentLimit
            fields['argumentedReality'] = subscription?.argumentedReality
        }
        this.setState({
            fields: {
                ...fields,
                [name]: value
            }
        }, () => this.validateForm([name]))
    }
    MobileHandleChange = ({ target }) => {
        const { name } = target || {}
        const { fields } = this.state;
        this.setState({
            fields: {
                ...fields,
                [name]: target.value.slice(0, 10).trim()
            }
        }, () => this.validateForm([name]))
    }
    handleSubmit = async () => {
        const { subscriptionActiveList } = this.props.getSubscriptionListData;
        let selectedItem = {};
        const arr = [];
        const { fields, isAccountActive } = this.state;
        const { accountNumber, accountType, address, agentType, companyName, email, gst, ifsc, mobileNumber, name, pan, registerNumber, subscriptionId, vendorLogo, remainderSec, isAR, paymentTypeId, argumentedReality, isFeedback, isSrmIp, feedbackEmail } = this.state?.fields;
        await this.state.fields?.selectedAgents?.map((item) => {
            arr.push(item?.data?.id)
        })
        if (this.props.isEdit) {
            if (this.validateForm(['name', 'mobileNumber', 'email', 'companyName', 'agentType', 'subscriptionId', 'vendorLogo', "remainderSec", "paymentTypeId", "feedbackEmail", 'isFeedback', 'isSrmIp'])) {
                return false
            }
            if (agentType == 1 && fields['selectedAgents']?.length > 0) {
                if (this.validateForm(['agentLimit'])) {
                    return false
                }
            }
            if (argumentedReality == 1) {
                if (this.validateForm(['isAR'])) {
                    return false
                }
            } else {
                this.setState({ fields: { ...this.state.fields, isAR: undefined } })
            }
        } else {
            if (this.validateForm(['name', 'mobileNumber', 'email', 'companyName', 'agentType', 'subscriptionId', 'vendorLogo', "remainderSec", "paymentTypeId", "feedbackEmail", 'isFeedback', 'isSrmIp'])) {
                return false
            }
            if (agentType == 1 && fields['selectedAgents']?.length > 0) {
                if (this.validateForm(['agentLimit'])) {
                    return false
                }
            }
            if (argumentedReality == 1) {
                if (this.validateForm(['isAR'])) {
                    return false
                }
            } else {
                this.setState({ fields: { ...this.state.fields, isAR: undefined } })
            }
        }

        subscriptionActiveList?.filter(async (item) => {
            if (item.id == subscriptionId) {
                selectedItem = item;
            }
        })
        let params = {
            fullName: name,
            phone: mobileNumber,
            email: email,
            companyName: companyName,
            accountNumber: accountNumber,
            gstIn: gst,
            regNo: registerNumber,
            pan: pan,
            address: address,
            subscriptionId: { subscriptionId: subscriptionId, data: selectedItem },
            accountType: accountType,
            ifsc: ifsc,
            vendorLogo: this.state.isClick ? vendorLogo : null,
            remainderMins: remainderSec,
            remainderSec: convertToMilliSeconds(remainderSec),
            isAR: isAR,
            paymentTypeId: paymentTypeId,
            isFeedback: isFeedback,
            feedbackEmail: feedbackEmail,
            isSrmIp: isSrmIp
        }
        if (fields['agentType1'] !== agentType) {
            params.agentTypeId = agentType
        }

        if (agentType == 1) {
            if (this.props.match.params.id) {
                params.agentId = arr
            } else {
                const array = this.state.fields?.selectedAgents?.map(o => o?.data?.id)
                params.agentId = array
            }
        }
        if (!isAccountActive['subscriptionId']) {
            params.isAccountActive = true
        }
        const callback = (response) => {
            this.setState({ isLoading: false })
            if (response?.response_code === 0) {
                toast.success(Constants.ADDED_SUCCESSFULLY)
                return this.props.history.push(RoutePath.ADMIN_VENDOR_LIST)
            }
            else {
                toast.error(response?.response_message)
            }
        }
        if (this.props.match.params.id) {
            this.setState({ isLoading: true })
            params.id = this.props.match.params.id
            this.props.updateVendorOnboardDetails(params, callback, true)
        } else {
            this.setState({ isLoading: true })
            this.props.vendorOnBoard(params, callback, true)
        }
    }
    handleSelectedAgents = (val) => {
        const { fields, error } = this.state
        if (val?.length > fields['agentLimit']) {
            error['agentLimit'] = `You reached maximum agent limit for selected subscription.`
        } else {
            error['agentLimit'] = ''
        }
        this.setState({ fields: { ...this.state.fields, selectedAgents: val } })
    }
    handleRemovedAgents = (val) => {
        if (this.props.isEdit) {
            const results = this.state?.fields?.selectedAgents?.filter(({ id: id1 }) => !val.some(({ id: id2 }) => id2 === id1));
            this.props.deleteAgent({ id: results[0]?.vendorAgentId }, (res) => {
                if (res.response_code === 0) {
                    toast.success("Agent removed Successfully")
                }
            })
        }
        const { fields, error } = this.state
        if (val?.length > fields['agentLimit']) {
            error['agentLimit'] = 'You reached maximum agent limit for selected subscription.'
        } else {
            error['agentLimit'] = ''
        }
        this.setState({ fields: { ...this.state.fields, selectedAgents: val } })
    }
    uploadProductImage = async (e) => {
        this.setState({ isLoading: true })
        const { name } = e.target || {}
        const { fields, error } = this.state;
        e.preventDefault();
        let file = e.target.files[0];
        let arrary = file && file.type.split('/')
        let type = arrary[arrary.length - 2]
        if (type !== 'image') {
            this.setState({ isLoading: false })
            error[name] = "Please Upload jpeg or png file only."
        } else {
            if (typeof file !== "undefined") {
                let imageBase64 = await convertToBase64(file)
                this.setState({
                    isLoading: false,
                    isClick: true,
                    fields: {
                        ...fields,
                        [name]: imageBase64
                    }
                }, () => this.validateForm([name]))
            } else {
                this.setState({ isLoading: false, isClick: false })
            }
        }
    }
    removeVendorLogo = async (target) => {
        this.setState({ isLoading: true })
        let { fields } = this.state;
        const name = "vendorLogo"
        if (this.props.match.params.id) {
            let params = {
                id: this.props.match.params.id,
                removeS3: true
            }
            this.props.updateVendorOnboardDetails(params, (res) => {
                const { response } = res
                this.setState({
                    isLoading: false,
                })
                if (res.response_code === 0) {
                    toast.success(Constants.DELETED_SUCCESSFULLY)
                    this.componentDidMount()
                    document.getElementById('exampleFormControlFile1').value = "";
                } else {
                    toast.error(response?.data?.response_message)
                }
            }, true)
        } else {
            this.setState({
                isLoading: false,
                fields: {
                    ...fields,
                    [name]: null
                }
            })
            document.getElementById('exampleFormControlFile1').value = "";
        }

    }
    handleTotalMinutes = (e) => {
        let { error, fields } = this.state
        let totalMins = e.target.value.slice(0, 7).trim();
        let convertToSec = convertToMilliSeconds(totalMins)
        let convertToTime = msToHMS(convertToSec)
        error['time'] = convertToTime
        this.handleChange(e)
        if (convertToSec >= fields["totalSec"]) {
            error['totalSec'] = "Please enter below your subscription time."
        } else {
            error['totalSec'] = ''
        }
        this.setState({ error })
    }
    render() {
        const { fields = {}, error = {}, isLoading, isClick, isAccountActive } = this.state
        const { isEdit } = this.props
        const { subscriptionActiveList, paymentList } = this.props.getSubscriptionListData || []
        return (
            <div className="content-part px-5 py-4 list">
                <div className="agent-details">
                    <div className="row m-0">
                        <div className="col-md-6">
                            <div className="mt-3">
                                <p className="text-bold">{Constants.NAME}</p>
                                <input autoComplete="off" type="text" name='name' maxLength="35" className="form-control w-75" placeholder={Constants.ENTER_NAME} value={fields['name']} onChange={(e) => this.handleChange(e)} />
                                {error['name'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['name']}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">{Constants.EMAIL}</p>
                                <input autoComplete="off" type="text" name='email' className="form-control w-75" placeholder={Constants.ENTER_EMAIL_ID} value={fields['email']} onChange={(e) => this.handleChange(e)} />
                                {error['email'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['email']}</span>}
                            </div>
                            {/* {!isEdit && <>
                                <div className="mt-3">
                                    <p className="text-bold">{Constants.PASSWORD}</p>
                                    <div className="mt-4 input-group flex-nowrap mb-4 w-75">
                                        <input autoComplete="off" type={showPassword ? 'text' : 'password'} name='password' className="form-control w-75" aria-label="Password" aria-describedby="view-pass" placeholder={Constants.ENTER_PASSWORD} value={fields['password']} onChange={(e) => this.handleChange(e)} />
                                        <button className="btn"
                                            onClick={() => this.setState({ showPassword: !showPassword })}
                                            type="button"
                                            id="view-pass">
                                            <FontAwesomeIcon
                                                style={{ cursor: 'pointer' }}
                                                icon={showPassword ? faEye : faEyeSlash} />
                                        </button>
                                    </div>
                                    {error['password'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['password']}</span>}
                                </div>
                            </>} */}
                            <div className="mt-3">
                                <p className="text-bold">{Constants.MOBILE_NUMBER}</p>
                                <input autoComplete="off" type="number" name='mobileNumber' className="form-control w-75" placeholder={Constants.ENTER_MOBILE_NUMBER} value={fields['mobileNumber']} onChange={(e) => this.MobileHandleChange(e)} />
                                {error['mobileNumber'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['mobileNumber']}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">{Constants.SUBSCRIPTION_ID}</p>
                                <select disabled={isAccountActive['subscriptionId'] || !subscriptionActiveList?.length > 0} name='subscriptionId' className="form-select w-75" value={fields['subscriptionId']} onChange={(e) => this.handleChange(e, subscriptionActiveList)}>
                                    <option hidden> {Constants.SELECT_SUBSCRIPTION_ID} </option>
                                    {
                                        subscriptionActiveList?.length > 0 && subscriptionActiveList?.map((o) => {
                                            return (
                                                <option key={o?.id} value={o?.id} totalSec={o?.totalSec}>{o?.planName} ({(msToHMS(o?.totalSec))})</option>
                                            )
                                        })
                                    }
                                </select>
                                {(error['subscriptionId'] || !subscriptionActiveList?.length > 0) && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{`${!subscriptionActiveList?.length > 0 ? "Please add the master subscription" : error['subscriptionId']}`}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Remainder Minutes</p>
                                <input autoComplete="off" type="number" name='remainderSec' value={fields['remainderSec']}
                                    className="form-control w-75" placeholder="Enter Remainder Minutes"
                                    onChange={(e) => this.handleTotalMinutes(e)} />
                                {error['remainderSec'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['remainderSec']}</span>}
                                {error['totalSec'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['totalSec']}</span>}
                            </div>
                            <div className="mt-3">
                                {error['time'] && <span style={{ color: 'black', fontSize: '16px', marginTop: '10px' }}>{error['time']}</span>}
                            </div>
                            {fields['argumentedReality'] === 1 && <>
                                <div className="mt-3">
                                    <p className="text-bold">Do you wish to include AR feature for the products?</p>
                                    <div onChange={(e) => { this.handleChange(e) }}>
                                        <input type="radio" value={1} name="isAR" checked={fields['isAR'] == '1' ? true : false} /> Yes &nbsp;
                                        <input type="radio" value={0} name="isAR" checked={fields['isAR'] == '0' ? true : false} /> No &nbsp;
                                    </div>
                                </div>
                                <div className="mt-3">
                                    {error['isAR'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['isAR']}</span>}
                                </div>
                            </>}
                            <div className="mt-3">
                                <p className="text-bold">{Constants.PAYMENT_TYPE}</p>
                                <select disabled={isAccountActive['paymentTypeId'] ? true : false} name='paymentTypeId' className="form-select w-75" value={fields['paymentTypeId']} onChange={(e) => this.handleChange(e)}>
                                    <option hidden> {Constants.SELECT_PAYMENT_TYPE_ID} </option>
                                    {
                                        paymentList?.length > 0 && paymentList?.map((o) => {
                                            return (
                                                <option key={o?.id} value={o?.id}>{o?.paymentType}</option>
                                            )
                                        })
                                    }
                                </select>
                                {error['paymentTypeId'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['paymentTypeId']}</span>}
                            </div>
                            {/* <div className="mt-3">
                                <p className="text-bold">{Constants.ACCOUNT_TYPE}</p>
                                <select name='accountType' className="form-select w-75" value={fields['accountType']} onChange={(e) => this.handleChange(e)}>
                                    <option hidden>{Constants.SELECT_ACCOUNT_TYPE} </option>
                                    <option value={'CURRENT'}>{Constants.CURRENT}</option>
                                    <option value={'SAVINGS'}>{Constants.SAVINGS}</option>
                                </select>
                                {error['accountType'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['accountType']}</span>}
                            </div> */}
                            {/* <div className="mt-3">
                                <p className="text-bold">{Constants.IFSC}</p>
                                <input autoComplete="off" type="text" name='ifsc' className="form-control w-75" placeholder={Constants.ENTER_THE_IFSC} value={fields['ifsc']} onChange={(e) => this.handleChange(e)} maxLength="11" />
                                {error['ifsc'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['ifsc']}</span>}
                            </div> */}
                        </div>
                        <div className="col-md-6">
                            <div className="mt-3">
                                <p className="text-bold">Store Logo</p>
                                <div className="custom-file custom-file-naked d-block mb-1">
                                    <div className="img-wraps_logo">
                                        <img style={{ width: '90px', height: '90px', marginBottom: '4px' }} alt="Store" src={fields['vendorLogo'] ? fields['vendorLogo'] : (PlaceHolderImage)} />
                                        {fields['vendorLogo'] &&
                                            <span className="closes" title="Delete" onClick={(e) => this.removeVendorLogo(e)}>×</span>
                                        }
                                    </div><br />
                                    <input type="file" className={`form-control-file${!isClick && isEdit ? 'removeNoFile' : ''}`} id="exampleFormControlFile1" onChange={(e) => this.uploadProductImage(e)} name="vendorLogo" accept="image/*" />
                                </div>
                                {error['vendorLogo'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['vendorLogo']}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">{Constants.COMPANY_NAME}</p>
                                <input autoComplete="off" maxLength="60" type="text" name='companyName' className="form-control w-75" placeholder={Constants.ENTER_COMPANY_NAME} value={fields['companyName']} onChange={(e) => this.handleChange(e)} />
                                {error['companyName'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['companyName']}</span>}
                            </div>
                            {/* <div className="mt-3">
                                <p className="text-bold">{Constants.ACCOUNT_NUMBER}</p>
                                <input autoComplete="off" type="text" name='accountNumber' className="form-control w-75" placeholder={Constants.ENTER_THE_ACCOUNT_NUMBER} value={fields['accountNumber']} onChange={(e) => this.handleChange(e)} maxLength="12" />
                                {error['accountNumber'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['accountNumber']}</span>}
                            </div> */}
                            {/* <div className="mt-3">
                                <p className="text-bold">{Constants.GST}</p>
                                <input autoComplete="off" type="text" name='gst' value={fields['gst']} className="form-control w-75" placeholder={Constants.ENTER_GST} onChange={(e) => this.handleChange(e)} maxLength="15" />
                                {error['gst'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['gst']}</span>}
                            </div> */}
                            {/* <div className="mt-3">
                                <p className="text-bold">{Constants.REGISTER_NUMBER}</p>
                                <input autoComplete="off" type="text" name='registerNumber' value={fields['registerNumber']} className="form-control w-75" placeholder="Enter the register number" onChange={(e) => this.handleChange(e)} maxLength="15" />
                                {error['registerNumber'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['registerNumber']}</span>}
                            </div> */}
                            {/* <div className="mt-3">
                                <p className="text-bold">{Constants.PAN}</p>
                                <input autoComplete="off" type="text" name='pan' className="form-control w-75" value={fields['pan']} placeholder={Constants.ENTER_PAN_NUMBER} onChange={(e) => this.handleChange(e)} maxLength="10" />
                                {error['pan'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['pan']}</span>}
                            </div> */}
                            <div className="mt-3">
                                <p className="text-bold">{Constants.AGENT_TYPE}</p>
                                <select disabled={isAccountActive['agentTypeId'] ? true : false} name='agentType' className="form-select w-75" value={fields['agentType']} onChange={(e) => this.handleChange(e)}>
                                    <option hidden>{Constants.SELECT_AGENT_TYPE}</option>
                                    <option value={1}>{Constants.SHARED}</option>
                                    <option value={2}>{Constants.OWNED}</option>
                                </select>
                                {error['agentType'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['agentType']}</span>}
                            </div>
                            {fields['agentType'] == 1 && <div className="w-75">
                                <label htmlFor="product-list" className="w-75 form-label fw-bold">{Constants.SELECT_AGENT_TYPE}</label>
                                {error['agentLimit'] && <p style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['agentLimit']}</p>}
                                <MultiSelect
                                    id="assignedStore"
                                    style={{ margin: '10px' }}
                                    placeholder="Select Agents"
                                    value={fields['selectedAgents']}
                                    isAdmin={true}
                                    onSelect={(val) => this.handleSelectedAgents(val)}
                                    onRemove={(val) => this.handleRemovedAgents(val)}
                                />
                            </div>}
                            <div className="mt-3">
                                <p className="text-bold">{Constants.ADDRESS}</p>
                                <textarea name='address' className="form-control w-75" rows="4" cols="50" value={fields['address']} placeholder={Constants.ENTER_ADDRESS} onChange={(e) => this.handleChange(e)}>
                                </textarea>
                                {error['address'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['address']}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">{Constants.DO_YOU_HAVE_KIOSK}</p>
                                <select name='isFeedback' className="form-select w-75" value={fields['isFeedback']} onChange={(e) => this.handleChange(e)}>
                                    <option hidden>{Constants.SELECT}</option>
                                    <option value={1}>{Constants.YES}</option>
                                    <option value={0}>{Constants.NO}</option>
                                </select>
                                {error['isFeedback'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['isFeedback']}</span>}
                            </div>
                            {fields['isFeedback'] == 1 && <div className="mt-3">
                                <p className="text-bold">{Constants.RECEIVED_EMAIL}</p>
                                <textarea name='feedbackEmail' className="form-control w-75" rows="4" cols="50" value={fields['feedbackEmail']} placeholder={Constants.ENTER_FEEDBACK_EMAIL} onChange={(e) => this.handleChange(e)}>
                                </textarea>
                                {error['feedbackEmail'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['feedbackEmail']}</span>}
                            </div>}
                            <div className="mt-3">
                                <p className="text-bold">{Constants.DO_YOU_HAVE_IN_PATIENT}</p>
                                <select name='isSrmIp' className="form-select w-75" value={fields['isSrmIp']} onChange={(e) => this.handleChange(e)}>
                                    <option hidden>{Constants.SELECT}</option>
                                    <option value={1}>{Constants.YES}</option>
                                    <option value={0}>{Constants.NO}</option>
                                </select>
                                {error['isSrmIp'] && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error['isSrmIp']}</span>}
                            </div>
                        </div>
                        <div className="d-flex justify-content-start my-5">
                            <button type="button" className="btn input-btn" onClick={() => this.props.history.goBack()}>{Constants.BACK}</button>
                            <button type="button" disabled={!subscriptionActiveList?.length > 0} className="btn input-btn ms-2" onClick={() => this.handleSubmit()}>{isEdit ? Constants.UPDATE : Constants.SUBMIT}</button>
                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        getSubscriptionListData: Actions.getSubscription(state),
    }
}
export default withRouter(connect(mapStateToProps, {
    vendorOnBoard: Actions.vendorOnBoard,
    getVendorOnboardDetails: Actions.getVendorOnboardDetails,
    updateVendorOnboardDetails: Actions.updateVendorAction,
    adminAgentList: Actions.agentListAction,
    getSubscriptionList: Actions.getSubscriptionList,
    getSubscriptionAciveList: Actions.getSubscriptionActiveList,
    deleteAgent: Actions.vendorAgentDeleteAction,
    paymentList: Actions.getPaymentList,
})(AddVendorScreen))
