import Actions from "../actions";

const initialState = {
    isLoading: false,
    recordingList: null,
    error: false,
};

export const VoiceRecordingReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.VOICE_RECORDING_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.VOICE_RECORDING_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                recordingList: action.data
            };
        }
        case Actions.VOICE_RECORDING_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
                recordingList: action.data
            };
        }
        default: {
            return state
        }
    }
};
