import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Actions from '../redux/actions';
import Constants from '../utils/Constants';
import { isValidEmail, isValidPassword } from '../utils/validation';
import { toast } from 'react-toastify';
import AppLoader from '../utils/AppLoader';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.otpRef1 = React.createRef();
        this.otpRef2 = React.createRef();
        this.otpRef3 = React.createRef();
        this.otpRef4 = React.createRef();

        this.state = {
            email: '',
            isOtp: false,
            isPassword: false,
            newPassword: '',
            confirmPassword: '',
            validEmail: true,
            error: {},
            message: {},
            validNewPassword: true,
            validConfirmPassword: true,
            validOtp1: true,
            validOtp2: true,
            validOtp3: true,
            validOtp4: true,
            showPassword: false,
            showConfirmPassword: false
        };
    }

    handleChangeEmail = (e) => {
        this.setState({ email: e.target.value });
        let email = e.target.value;
        if (email.length === 0) {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: Constants.PLEASE_ENTER_EMAIL } });
            this.setState({ validEmail: true });
            return false;
        }
        else if (!isValidEmail(email)) {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: Constants.INVALID_EMAIL_ID } });
            this.setState({ validEmail: true });
            return false;
        }
        else {
            this.setState({ error: { isEmailId: true }, message: { isEmailId: '' } });
            this.setState({ validEmail: false });
        }
    };

    handleChangeNewPassword = (e) => {
        this.setState({ newPassword: e.target.value });
        let newPassword = e.target.value;
        if (!newPassword || newPassword.length === 0) {
            this.setState({ error: { isNewPassword: true }, message: { isNewPassword: Constants.PLEASE_ENTER_PASSWORD } });
            this.setState({ validNewPassword: true });
            return false;
        }
        else if (!isValidPassword(newPassword)) {
            this.setState({ error: { isNewPassword: true }, message: { isNewPassword: Constants.PASSWORD_REG } });
            this.setState({ validNewPassword: true });
            return false;
        }
        else {
            this.setState({ error: { isNewPassword: false }, message: { isNewPassword: '' } });
            this.setState({ validNewPassword: false });
        }
    };


    handleChangeConfirmPassword = (e) => {
        this.setState({ confirmPassword: e.target.value });
        let confirmPassword = e.target.value;
        if (!confirmPassword || confirmPassword.length === 0) {
            this.setState({ error: { isConfirmPassword: true }, message: { isConfirmPassword: Constants.PLEASE_ENTER_PASSWORD } });
            this.setState({ validConfirmPassword: true });
            return false;
        }
        else if (!isValidPassword(confirmPassword)) {
            this.setState({ error: { isConfirmPassword: true }, message: { isConfirmPassword: Constants.PASSWORD_REG } });
            this.setState({ validConfirmPassword: true });
            return false;
        }
        else {
            this.setState({ error: { isConfirmPassword: false }, message: { isConfirmPassword: '' } });
            this.setState({ validConfirmPassword: false });
        }
    };


    submitVerifyEmail = () => {
        const { verifyEmail } = this.props;
        const callback = response => {
            if (response?.response?.otp) {
                this.setState({ isOtp: true });
            } else if (response?.response_code === 3) {
                toast.warn(response?.response_message);
                this.setState({ isOtp: false });
            }
            else {
                this.setState({ isOtp: false });
            }
        };

        verifyEmail({ email: this.state.email }, callback);
    };

    submitVerifyOtp = () => {
        const { verifyOtp, email } = this.props;
        const { otpValue1, otpValue2, otpValue3, otpValue4, } = this.state;
        let data = otpValue1 + otpValue2 + otpValue3 + otpValue4;
        const callback = (response) => {
            if (response.response_code == 0) {
                toast.success(response?.response_message);
                this.setState({ isOtp: false, isPassword: true });
            } else if (response.response_code != 0) {
                toast.error(response?.response_message);
                this.setState({ isPassword: false });
            }
            else {
                this.setState({ isPassword: false });
            }
        };
        let params = {
            email: email,
            forgetOtp: data
        };
        verifyOtp(data && params, callback);
    };

    submitVerifyPassword = () => {
        const { newPassword, confirmPassword } = this.state;
        const { email, verifyPassword } = this.props;

        if (confirmPassword !== newPassword) {
            toast.warn('password doesnot match');
            return false;
        }

        const params = {
            email: email,
            password: confirmPassword
        };

        const callback = response => {
            if (response.response_message === 'Your New password update Successfully') {
                toast.success(response.response_message);
                return this.props.history.push('/');
            } else {
                return false;
            }
        };

        verifyPassword(params, callback);
    };
    handleRestrictOtpValue1 = (e) => {
        if (e.target.value.length > 1) return false;

        this.setState({ otpValue1: e.target.value });
        if (this.state.otpValue1) {
            this.otpRef1.current.focus();
        } else if (this.state.otpValue1 !== '') {
            this.otpRef2.current.focus();
        }
        let otpValue1 = e.target.value;
        if (!otpValue1 || otpValue1.length === 0) {
            this.setState({ error: { isotpValue1: true }, message: { isotpValue1: Constants.PLEASE_ENTER_OTP } });
            this.setState({ validOtp1: true });
        }
        else {
            this.setState({ error: { isotpValue1: false }, message: { isotpValue1: '' } });
            this.setState({ validOtp1: false });
        }
    };
    handleRestrictOtpValue2 = (e) => {
        if (e.target.value.length > 1) return false;

        this.setState({ otpValue2: e.target.value });
        if (this.state.otpValue2) {
            this.otpRef2.current.focus();
        }
        else if (this.state.otpValue2 !== '') {
            this.otpRef3.current.focus();
        }

        let otpValue2 = e.target.value;
        if (!otpValue2 || otpValue2.length === 0) {
            this.setState({ error: { isotpValue2: true }, message: { isotpValue2: Constants.PLEASE_ENTER_OTP } });
            this.setState({ validOtp2: true });
        }
        else {
            this.setState({ error: { isotpValue2: false }, message: { isotpValue2: '' } });
            this.setState({ validOtp2: false });
        }
    };
    handleRestrictOtpValue3 = (e) => {
        if (e.target.value.length > 1) return false;

        this.setState({ otpValue3: e.target.value });
        if (this.state.otpValue3) {
            this.otpRef3.current.focus();
        }
        else if (this.state.otpValue3 !== '') {
            this.otpRef4.current.focus();
        }

        let otpValue3 = e.target.value;
        if (!otpValue3 || otpValue3.length === 0) {
            this.setState({ error: { isotpValue3: true }, message: { isotpValue3: Constants.PLEASE_ENTER_OTP } });
            this.setState({ validOtp3: true });
        }
        else {
            this.setState({ error: { isotpValue3: false }, message: { isotpValue3: '' } });
            this.setState({ validOtp3: false });
        }
    };
    handleRestrictOtpValue4 = (e) => {
        if (e.target.value.length > 1) return false;
        this.setState({ otpValue4: e.target.value });
        let otpValue4 = e.target.value;
        if (!otpValue4 || otpValue4.length === 0) {
            this.setState({ error: { isotpValue4: true }, message: { isotpValue4: Constants.PLEASE_ENTER_OTP } });
            this.setState({ validOtp4: true });
        }
        else {
            this.setState({ error: { isotpValue4: false }, message: { isotpValue4: '' } });
            this.setState({ validOtp4: false });
        }
    };
    render() {
        const { email, isOtp, otpValue1, otpValue2, otpValue3, otpValue4, isPassword, newPassword, confirmPassword,
            error, message, validEmail, validNewPassword, validConfirmPassword, validOtp1, validOtp2, validOtp3, validOtp4, showPassword, showConfirmPassword } = this.state;
        const { isLoading } = this.props.getLoginDetails;
        return (
            <div>
                <div className="container">
                    <div className="email_otp">
                        <div className="card otp_card">
                            <div className="row">
                                {!isOtp && !isPassword &&
                                    <div className="col-12">
                                        <h3 className="text-center my-2">Please Enter Email</h3>
                                        <div className="mt-4 mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                            <input autoComplete="off" type="email" className="form-control p-2" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" value={email} onChange={(e) => this.handleChangeEmail(e)} />
                                        </div>
                                        <div>
                                            {error.isEmailId && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isEmailId}</h5>}
                                        </div>
                                        <div className="d-flex justify-content-center mt-5 mb-3">
                                            <button type="submit" className="btn otp_btn" disabled={validEmail} onClick={() => this.submitVerifyEmail()}>
                                                Verify Email
                                            </button>
                                        </div>
                                        <div className="d-flex justify-content-center mb-3" >
                                            <div style={{ borderBottom: '2px solid black' }}>
                                                <span onClick={() => window.history.go(-1)} style={{ cursor: 'pointer', color: 'black' }}>
                                                    Login
                                                </span>
                                            </div>
                                        </div>
                                    </div>}
                                {isOtp && <div className="mb-3">
                                    <label htmlFor="otp" className="form-label"></label>
                                    <h3 className="text-center my-2">Please Enter Your Otp</h3>
                                    <div className="mt-5 mb-3 d-flex justify-content-center">
                                        <input type="number" className="form-control otp_input p-2 text-center" id="otp" value={otpValue1} onChange={(e) => this.handleRestrictOtpValue1(e)} ref={this.otpRef1} />
                                        <input type="number" className="form-control otp_input p-2 text-center" id="otp" value={otpValue2} onChange={(e) => this.handleRestrictOtpValue2(e)} ref={this.otpRef2} />
                                        <input type="number" className="form-control otp_input p-2 text-center" id="otp" value={otpValue3} onChange={(e) => this.handleRestrictOtpValue3(e)} ref={this.otpRef3} />
                                        <input type="number" className="form-control otp_input p-2 text-center" id="otp" value={otpValue4} onChange={(e) => this.handleRestrictOtpValue4(e)} ref={this.otpRef4} />
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        {error.isotpValue1 || error.isotpValue2 || error.isotpValue3 || error.isotpValue4 ?
                                            <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{
                                                message.isotpValue1 || message.isotpValue2 || message.isotpValue3 || message.isotpValue4}
                                            </h5> : ''
                                        }
                                    </div>
                                    <div className="d-flex justify-content-center mt-5 mb-3"> <button type="submit" className="btn otp_btn" disabled={validOtp1 || validOtp2 || validOtp3 || validOtp4}
                                        onClick={() => this.submitVerifyOtp()}>Verify Otp</button></div>
                                </div>}
                                {isPassword &&
                                    <>
                                        <h3 className="text-center my-2">Please Set Your New Password</h3>
                                        <div className="mt-3">
                                            <p className="text-bold">New Password</p>
                                            <div className="mt-4 input-group flex-nowrap mb-4">
                                                <input autoComplete="off" type={showPassword ? 'text' : 'password'} name='password' className="form-control w-75" aria-label="Password" aria-describedby="view-pass" placeholder="Please Enter the New Password" value={newPassword} onChange={(e) => this.handleChangeNewPassword(e)} />
                                                <button className="btn"
                                                    onClick={() => this.setState({ showPassword: !showPassword })}
                                                    type="button"
                                                    id="view-pass">
                                                    {/* <FontAwesomeIcon
                                                        style={{ cursor: 'pointer' }}
                                                        icon={showPassword ? faEye : faEyeSlash} /> */}
                                                </button>
                                            </div>
                                            {error.isNewPassword && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isNewPassword}</h5>}
                                        </div>
                                        <div className='mt-4'>
                                            <p className="text-bold">Confirm Password</p>
                                            <div className="mt-4 input-group flex-nowrap mb-4">
                                                <input autoComplete="off" type={showConfirmPassword ? 'text' : 'password'} name='password' className="form-control w-75" aria-label="Password" aria-describedby="view-pass" placeholder="Please Enter the confirm password" value={confirmPassword} onChange={(e) => this.handleChangeConfirmPassword(e)} />
                                                <button className="btn"
                                                    onClick={() => this.setState({ showConfirmPassword: !showConfirmPassword })}
                                                    type="button"
                                                    id="view-pass">
                                                    {/* <FontAwesomeIcon
                                                        style={{ cursor: 'pointer' }}
                                                        icon={showConfirmPassword ? faEye : faEyeSlash} /> */}
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            {error.isConfirmPassword && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isConfirmPassword}</h5>}
                                        </div>
                                        <div className="d-flex justify-content-center mt-5 mb-3"> <button type="submit" disabled={validNewPassword || validConfirmPassword} className="btn otp_btn" onClick={() => this.submitVerifyPassword()}>Submit</button></div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        otp: state?.LOGIN_CREDS?.otp?.otp,
        email: state?.LOGIN_CREDS?.otp?.email,
        getLoginDetails: Actions.getLoginDetails(state)
    };
};

export default withRouter(connect(mapStateToProps, {
    verifyEmail: Actions.loginVerifyEmail,
    verifyOtp: Actions.loginVerifyOtp,
    verifyPassword: Actions.loginVerifyPassword
})(ForgetPassword));