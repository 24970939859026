import { doResponseAction } from ".";



const DASHBOARD = "DASHBOARD";
const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";

export {
    DASHBOARD,
    DASHBOARD_SUCCESS,
    DASHBOARD_FAILURE,
};
export const getDashboardDetails = store => store["ADMIN_DASHBOARD_DETAILS"]

export const getDashboardAction = (params, callback, isAdmin) => {
    return {
        type: DASHBOARD,
        params,
        callback,
        isAdmin
    }
}
export const getDashboardSuccessAction = (data) => doResponseAction(DASHBOARD_SUCCESS, data);
export const getDashboardFailureAction = (data) => doResponseAction(DASHBOARD_FAILURE, data);

