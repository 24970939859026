import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from '../redux/actions/index';
import io from 'socket.io-client';
import QRCode from "qrcode.react";
import ScreenShare from "../component/ScreenShare";
import { v4 as uuidv4 } from 'uuid';
import Constants, { SHOPPING_DOMAIN, SOCKET_IO_PATH, SOCKET_RTC_URL, SOCKET_URL } from "../utils/Constants";
import Avatar from 'react-avatar';
import AppLoader from "../utils/AppLoader";
import loader from '../assets/img/loader.gif'
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import NoEffect from '../assets/img/no_effect.svg'
import stringTamil from "../utils/stringTamil";
import stringEnglish from "../utils/stringEnglish";

let canvas = null;
let width = 320;
let height = 0;
var backgroundInterval
var segmentationStream = true
class KioskVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomId: '',
            isChatOpened: false,
            chat: [],
            message: '',
            isMute: false,
            isVideoMute: false,
            unreadMessageCount: 0,
            isZoom: false,
            isScreenShared: false,
            isCaptureButton: false,
            verificationImage: "",
            screenRoomId: "",
            isScreenView: false,
            video: [],
            microphone: [],
            speaker: [],
            chatList: [],
            avatarmain: false,
            customerName: '',
            agentName: '',
            connnectionMessage: {},
            checkIsConnected: false,
            isLoading: false,
            isMobileDevice: false,
            isVrBackgroundIcon: false,
            isVirtualBackground: false,
            isVirtualBackgroundIMG: '',
            virtualBackgroundSelectId: 0,
            virtualBackgroundImages: [],
            vbOption: 1,
            language: parseInt(localStorage.getItem("language")) || 1
        }
        this.canvasRef = React.createRef();
    }

    appHeight = () => {
        setTimeout(() => {
            const doc = document.documentElement
            doc.style.setProperty('--app-height', `${window.innerHeight}px`)
            doc.style.setProperty('--video-height', `${(window.innerHeight) * 0.3}px`)
            doc.style.setProperty('--chat-height', `${(window.innerHeight) * 0.7}px`)
            doc.style.setProperty('--screen-share-height', `${(window.innerHeight) * 0.85}px`)
        }, 200)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.appHeight)
    }

    getConnectedDevices = async (type) => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter(device => device.kind === type)
    }

    getVideoElement = async () => {
        const video = await this.getConnectedDevices('videoinput');
        const audio = await this.getConnectedDevices('audioinput');
        const speaker = await this.getConnectedDevices('audiooutput');
        this.setState({ video, audio, speaker });
    }

    componentDidMount() {
        const isMobile = /Mobile/.test(window.navigator.userAgent) || window.matchMedia("(max-width: 767px)").matches;
        this.setState({ isMobileDevice: isMobile })

        window.addEventListener('beforeunload', this.handleTabClose);
        this.setState({ roomId: this.props.match.params.roomId })
        const callBack = (response) => {
            if (response.response_code === 0) {
                this.setState({ chatList: response?.response?.ChatMessages.sort((f, s) => f?.id - s?.id), customerName: response?.response?.customerName, agentName: response?.response?.agentName })
                this.getVirtualBackgroundList()
                this.getVideoElement();
                window.addEventListener('resize', this.appHeight)
                this.appHeight()
                this.loadVideoConferencing();
                if (window.location.hostname === 'localhost') {
                    this.socketURL = SOCKET_URL;
                } else {
                    this.socketURL = SOCKET_URL;
                }
                const socket = io(this.socketURL, { path: SOCKET_IO_PATH, transports: ['websocket'] });
                this.socket = socket;
                const { isCustomer } = this.props;
                const { roomId } = this.props.match.params;
                socket.on(`chat/${roomId}/${!isCustomer ? 'customer' : 'store'}`, (response) => {
                    let arrayList = []
                    arrayList = this.props.getChatListAction
                    arrayList.push(response?.message.ChatMessages)
                    this.props.appendChat(arrayList)
                    let { unreadMessageCount = 0, isChatOpened } = this.state
                    if (!isChatOpened) {
                        unreadMessageCount = unreadMessageCount + 1
                    }
                    this.setState({ unreadMessageCount })
                })

                socket.on(`endcall/${roomId}/${!isCustomer ? 'customer' : 'store'}`, (response) => {
                    this.endCall(true);
                    if (!isCustomer) {
                        window.close()
                        // this.props.history.push('/store')
                    } else {
                        this.props.history.push('/kiosk_srm/customer/feedback')
                        // window.location.reload()
                        this.mediaStream.stop()
                    }
                })
                socket.on(`call_connect/${roomId}`, (response) => {
                    this.setState({ customerName: response?.customerName, agentName: response?.agentName })
                    // const { appointmentDetails = {} } = this.props;
                    // const { Vendor } = appointmentDetails;
                    // const { Subscription } = Vendor;
                    // let callStreamMinLimit = Math.floor(parseInt(Vendor?.agentCallLimit) / 1000 / 60);
                    // let maxCallStreamLimit = Subscription?.liveStreamMins;
                    // maxCallStreamLimit = maxCallStreamLimit.replace("mins", "");
                    // maxCallStreamLimit = parseInt(maxCallStreamLimit);
                    // if (callStreamMinLimit <= maxCallStreamLimit) {
                    //     this.endCall()
                    //     toast.error("Reached Max Limit For Live Streaming")
                    // }

                })
                // socket.on(`screen/${roomId}/${!isCustomer ? 'customer' : 'store'}`, (response) => {
                //     if (response?.stop) {
                //         this.setState({
                //             isScreenView: false,
                //             screenRoomId: ''
                //         })
                //     } else {
                //         this.setState({
                //             isScreenView: true,
                //             screenRoomId: response?.screenRoomId
                //         })
                //     }
                // })


                socket.on(`screen/${roomId}/${!isCustomer ? 'customer' : 'store'}`, (response) => {
                    const { roomData } = response;
                    const { isVirtualBackground } = this.state
                    const { isCustomer } = this.props
                    let backgroundCanvas = document.getElementById("bgCanvas")
                    if (roomData.stop) {
                        this.setState({
                            isScreenView: false,
                            screenRoomId: ''
                        })
                        if (isCustomer && isVirtualBackground) {
                            backgroundCanvas.setAttribute("style", "display: block;position: absolute;top: 0;bottom: 0;left: 0;right: 0;margin: auto")
                            this.canvasRef.current = backgroundCanvas
                        }
                    } else {
                        this.setState({
                            isScreenView: true,
                            screenRoomId: roomData.screenRoomId
                        })
                        if (isCustomer && isVirtualBackground) {
                            backgroundCanvas.setAttribute("style", "display: block;position: absolute;height: 150px !important;width: auto !important;top: 3vh;right: 2vw;border: 2px solid white;border-radius: 10px;")
                            this.canvasRef.current = backgroundCanvas
                        }
                    }
                })
                socket.on(`end_streaming/${roomId}`, (response) => {
                    console.log("end Stream Socket------>", response)
                })
                socket.on(`age_verification/${roomId}`, (response) => {
                    if (response?.message?.isAbove18) {
                        toast.success(`Profile verified age is ${response?.message?.age}`)
                        if (!isCustomer) {
                            this.onCaptureButtonClose()
                        }
                    } else {
                        if (response?.message?.age === null) {
                            if (isCustomer) {
                                toast.error(`Could not able to recognized the image.`)
                            } else {
                                toast.error(`Could not able to recognized the image please retake.`)
                            }
                        } else {
                            toast.error("Profile not verified.")
                        }
                    }

                })
                socket.on(`virtual_back_ground/${roomId}/${isCustomer ? 'customer' : 'store'}`, (response) => {
                    if (response) {
                        let findDinamicImgDiv = document.getElementsByClassName("dinamicImgDiv")
                        if (findDinamicImgDiv.length > 0) {
                            document.querySelector('#videos-container').removeChild(findDinamicImgDiv[0])
                        }
                        let createDinamicImg = document.createElement('div');
                        createDinamicImg.className = "dinamicImgDiv"
                        createDinamicImg.innerHTML = `<img id='vbackground_${response?.id}' src=${this.setImg(response?.id, response?.imageURL)} alt="dinamic" />`
                        document.querySelector('#videos-container').appendChild(createDinamicImg)
                        let img = document.getElementById(`vbackground_${response?.id}`)
                        img.setAttribute("style", 'display: none')
                        this.setVideo(img, response?.id);
                    }
                })

            }
            else {
                if (!this.props.isCustomer) {
                    this.props.history.push('/store')
                } else {
                    this.props.history.push('/kiosk_srm/customer/feedback')
                }
            }
        }
        const params = {
            roomId: this.props.match.params.roomId
        }
        this.props.chatListAction(params, callBack)

    }
    setImg = (key, imageURL) => {
        switch (key) {
            case 0:
                return NoEffect
            default:
                return imageURL
        }
    }
    onResults = async (results) => {
        const { isVirtualBackgroundIMG } = this.state
        // const img = document.getElementById(`vbackground_${isVirtualBackgroundIMG}`)
        const img = isVirtualBackgroundIMG
        const videoWidth = results?.image?.width;
        const videoHeight = results?.image?.height;
        this.canvasRef.current.width = videoWidth;
        this.canvasRef.current.height = videoHeight;

        const canvasElement = this.canvasRef.current;
        const canvasCtx = canvasElement.getContext("2d");

        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);

        // Only overwrite existing pixels.
        canvasCtx.globalCompositeOperation = 'destination-atop';
        canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height);

        // Only overwrite missing pixels.

        canvasCtx.globalCompositeOperation = 'destination-over';
        canvasCtx.drawImage(img, 0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.restore();

    }
    virtualBackgroundSet = (stream, useSegment) => {
        const selfieSegmentation = new SelfieSegmentation({
            locateFile: (file) => {
                return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
            },
        });
        selfieSegmentation.setOptions({
            modelSelection: 1,
        });
        selfieSegmentation.onResults(this.onResults);
        if (segmentationStream) {
            const processFrame = async () => {
                await selfieSegmentation.send({ image: stream });
                requestAnimationFrame(processFrame);
            };
            requestAnimationFrame(processFrame);
            segmentationStream = false;
        }
        // if (useSegment) {
        //     myRef = requestAnimationFrame(processFrame);
        // } else {
        //     cancelAnimationFrame(myRef);
        // }
        //     const camera = new cam.Camera(stream, {
        //         onFrame: async () => {
        //             await selfieSegmentation.send({ image: stream });
        //         },
        //         width: 1280,
        //         height: 720
        //     });
        //     selfieSegmentation.send({ image: stream });
        //     if (useSegment) {
        //         camera.start();
        //     } else {
        //         camera.stop();
        //     }
    }
    handleTabClose = (event) => {
        event.preventDefault();
        this.endCall()
    }
    getChatList = () => {
        const callBack = (response) => {
            if (response.response_code === 0) {
                this.setState({ chatList: response?.response?.ChatMessages.sort((f, s) => f?.id - s?.id) })
                return true
            }
            else {
                this.endCall()
                return false
            }
        }
        const params = {
            roomId: this.props.match.params.roomId
        }
        this.props.chatListAction(params, callBack)
    }
    getVirtualBackgroundList = () => {
        const callBack = (response) => {
            this.setState({ isLoading: false })
            if (response.response_code === 0) {
                this.setState({ virtualBackgroundImages: response?.response })
                return true
            }
            else {
                this.endCall()
                return false
            }
        }
        const params = {
            roomId: this.props.match.params.roomId
        }
        this.setState({ isLoading: true })
        this.props.virtualBackgroundListAction(params, callBack)
    }
    startVideConferencing = () => {
        const { roomId } = this.props.match.params;
        if (!this.connection) {
            return false
        }
        this.connection.socket.emit(`call_request/${this.props.attenedName?.tokenDetails?.user?.id}`, { roomId })
        this.connection.openOrJoin(roomId, (isRoomOpened, roomid, error) => {
            if (error) {
                if (error === this.connection.errors.ROOM_NOT_AVAILABLE) {
                    alert('Someone already created this room. Please either join or create a separate room.');
                    return;
                }
                alert(error);
            }

            this.connection.socket.on('disconnect', () => {
                window.location.reload();
            });
        });
    }

    joinVideConferencing = () => {
        const { isCustomer } = this.props;
        const { roomId } = this.props.match.params;
        if (!this.connection) {
            return false
        }
        if (isCustomer) {
            this.connection.openOrJoin(roomId);
        } else {
            this.connection.sdpConstraints.mandatory = {
                OfferToReceiveAudio: true,
                OfferToReceiveVideo: true
            };
            this.connection.openOrJoin(roomId);
        }
    }

    loadVideoConferencing = () => {
        const { isCustomer } = this.props;
        (function () {
            var params = {},
                r = /([^&=]+)=?([^&]*)/g;

            function d(s) {
                return decodeURIComponent(s.replace(/\+/g, ' '));
            }
            var match, search = window.location.search;
            while (match === r.exec(search.substring(1)))
                params[d(match[1])] = d(match[2]);
            window.params = params;
        })();
        // ......................................................
        // ..................RTCMultiConnection Code.............
        // ......................................................

        this.connection = new global.RTCMultiConnection();
        // this.setState({ isMobileDevice: this.connection?.DetectRTC?.isMobileDevice })
        this.connection.autoCloseEntireSession = true;
        this.connection.publicRoomIdentifier = global.params.publicRoomIdentifier;

        // by default, socket.io server is assumed to be deployed on your own URL
        if (window.location.hostname === 'localhost') {
            this.connection.socketURL = SOCKET_RTC_URL;
        } else {
            this.connection.socketURL = SOCKET_RTC_URL;
        }


        // comment-out below line if you do not have your own socket.io server
        // connection.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';

        this.connection.socketMessageEvent = 'video-conference-demo';
        this.connection.session = {
            audio: true,
            video: true,
            // oneway: true
        };

        this.connection.sdpConstraints.mandatory = {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: true
        };

        this.connection.videosContainer = document.getElementById('videos-container');
        this.connection.onstream = (event) => {
            const isLocal = event.type === 'local';
            this.setState({ checkIsConnected: isLocal });
            var existing = document.getElementById(event.streamid);
            if (existing && existing.parentNode) {
                existing.parentNode.removeChild(existing);
            }

            event.mediaElement.removeAttribute('src');
            event.mediaElement.removeAttribute('srcObject');
            event.mediaElement.muted = true;
            event.mediaElement.volume = 0;

            var video = document.createElement('video');
            if (isLocal) {
                this.mediaStream = event.stream
            }
            try {
                video.setAttributeNode(document.createAttribute('autoplay'));
                video.setAttributeNode(document.createAttribute('playsinline'));
            } catch (e) {
                video.setAttribute('autoplay', true);
                video.setAttribute('playsinline', true);
            }

            if (event.type === 'local') {
                video.volume = 0;
                try {
                    video.setAttributeNode(document.createAttribute('muted'));
                } catch (e) {
                    video.setAttribute('muted', true);
                }
                video.setAttribute('id', "canvasVideo"); //Back Streaming view
            } else {
                video.setAttribute('id', "video"); //front Streaming View
            }
            video.srcObject = event.stream;
            let initialConnect;
            if (isLocal) {
                initialConnect = document.createElement('div');
                initialConnect.className = 'initial-connect'
                initialConnect.insertAdjacentHTML('beforeend', `
                <div>
                    <h1 style="color:white;">Connecting</h1>
                    <img src=${loader} alt="loading..." />
                </div>
                `);
                document.querySelector('#videos-container').appendChild(initialConnect)
            } else {
                document.querySelector(".initial-connect").remove();
            }

            var width = parseInt(this.connection.videosContainer.clientWidth / 3) - 20;
            var mediaElement = global.getHTMLMediaElement(video, {
                title: '',
                buttons: [],
                width: width,
                showOnMouseEnter: false
            }, isLocal, isCustomer);

            this.connection.videosContainer.appendChild(mediaElement);

            setTimeout(() => {
                // mediaElement.media.play();
            }, 5000);

            mediaElement.id = event.streamid;

            if (event.type === 'local') {
                this.connection.socket.on('disconnect', () => {
                    if (!this.connection.getAllParticipants().length) {
                        window.location.reload();
                    }
                });
            }
        };

        this.connection.onCustomMsg = (msg) => {
            this.setState({ connnectionMessage: msg })
            const { isVirtualBackground, isScreenView } = this.state
            if (msg?.message?.type === 'video') {
                if (msg.message.action === 'mute') { // Camera Mute
                    this.setState({
                        avatarmain: true
                    })
                    if (isVirtualBackground) { // Check Virtual Background Apply or not
                        let backVideo = document.getElementById("bgCanvas");
                        if (backVideo) { // if customer
                            backVideo.setAttribute("style", "display:none");
                            let video = document.getElementById('video')
                            video.setAttribute("style", "display:none");
                        } else {
                            let video = document.getElementById('video')
                            video.setAttribute("style", "display:block");
                        }
                    } else {
                        let backgroundCanvas = document.getElementById("bgCanvas")
                        if (backgroundCanvas) { //if customer
                            backgroundCanvas.setAttribute("style", "display:none");
                            let video = document.getElementById('video')
                            video.setAttribute("style", "display:none");
                        } else {
                            let video = document.getElementById('video')
                            video.setAttribute("style", "display:block");
                        }
                    }
                } else {
                    this.setState({
                        avatarmain: false
                    })
                    if (isVirtualBackground) { // agent side front video display none and canvas display block
                        let backVideo = document.getElementById("bgCanvas");
                        if (backVideo) { // if customer
                            if (isScreenView) {
                                backVideo.setAttribute("style", "display: block;position: absolute;height: 150px !important;width: auto !important;top: 3vh;right: 2vw;border: 2px solid white;border-radius: 10px;")
                            } else {
                                backVideo.setAttribute("style", "display:block");
                            }
                            let video = document.getElementById('video')
                            video.setAttribute("style", "display:none");
                        } else {
                            let video = document.getElementById('video')
                            video.setAttribute("style", "display:block");
                        }
                    } else {
                        let backVideo = document.getElementById("bgCanvas");
                        if (backVideo) { // if customer
                            backVideo.setAttribute("style", "display:none");
                            let video = document.getElementById('video')
                            video.setAttribute("style", "display:block");
                        } else {
                            let video = document.getElementById('video')
                            video.setAttribute("style", "display:block");
                        }
                    }
                }
            }
        }

        this.connection.onstreamended = (event) => {
            var mediaElement = document.getElementById(event.streamid);
            if (mediaElement) {
                mediaElement.parentNode.removeChild(mediaElement);
            }
        };

        this.connection.onMediaError = (e) => {
            if (e.message === 'Concurrent mic process limit.') {
                if (global.DetectRTC.audioInputDevices.length <= 1) {
                    alert('Please select external microphone. Check github issue number 483.');
                    return;
                }

                var secondaryMic = global.DetectRTC.audioInputDevices[1].deviceId;
                this.connection.mediaConstraints.audio = {
                    deviceId: secondaryMic
                };

                this.connection.join(this.connection.sessionid);
            }
        };

        if (!!global.params.password) {
            this.connection.password = global.params.password;
        }

        // detect 2G
        if (navigator.connection &&
            navigator.connection.type === 'cellular' &&
            navigator.connection.downlinkMax <= 0.115) {
            alert('2G is not supported. Please use a better internet service.');
        }
        canvas = document.getElementById("canvas");
        this.joinVideConferencing()
    }

    //Function to trigger call end api by checking the isCustomer
    endCall = (isSocket) => {
        const { isCustomer } = this.props;
        this.setState({ isLoading: true })
        if (isSocket) {
            return false;
        }
        if (isCustomer) {
            const params = {
                roomId: this.props.match.params.roomId,
                callEndTime: new Date().getTime(),
            }
            this.props.customerDeclineAction(params, (res) => {
                // const callerList=this.props.getQueueList
                // const id = callerList.findIndex(o => o?.User?.id === res?.response?.User?.id)
                // if (id !== -1) {
                //   callerList.splice(id, 1);
                //   this.props.appendQueueList(callerList)
                // }
                this.setState({ isLoading: false })
                if (!isCustomer) {
                    window.close()
                    // this.props.history.push('/store')
                } else {
                    this.props.history.push('/kiosk_srm/customer/feedback')
                    // window.location.reload()
                    this.mediaStream.stop()
                }
            })
        }
        else {
            let params = {
                roomId: this.props.match.params.roomId,
                callStatus: 3,
                isActive: false,
                callEndTime: new Date().getTime()
            }
            const declinecallback = (response) => {
                this.setState({ isLoading: false })
                if (!isCustomer) {
                    window.close()
                    // this.props.history.push('/store')
                    // window.location.reload()
                } else {
                    this.props.history.push('/kiosk_srm/customer/feedback')
                    // window.location.reload()
                    this.mediaStream.stop()
                }
            }
            this.props.updateAppointment(params, declinecallback)
        }
        // this.props.customerDeclineAction(params, (res) => {
        //     if (res.response.status !== 500) {
        //         this.connection.close();
        //         if (isCustomer) {
        //             this.props.history.push('/customer');
        //             setTimeout(() => {
        //                 window.location.reload();
        //             }, 200)
        //         } else {
        //             window.close();
        //         }
        //         if (isSocket) {
        //             return false
        //         }
        //         const { roomId } = this.props.match.params;
        //         let data = {
        //             customer: !!isCustomer
        //         }
        //         const requestOptions = {
        //             method: 'POST',
        //             headers: { 'Content-Type': 'application/json' },
        //             body: JSON.stringify({ url: `endcall/${roomId}/${isCustomer ? 'customer' : 'store'}`, data })
        //         };

        //         fetch(`${this.socketURL}/api/socket`, requestOptions)
        //             .then((res) => res.json())
        //             .then(data => {
        //             })
        //     }
        //     else {
        //         return false;
        //     }
        // })

    }

    handleChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    }

    handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            this.sendMessage()
        }
    }

    sendMessage = () => {
        const role = this.props.getTokenDetails?.LOGIN_CREDS?.tokenDetails?.user?.roleId
        const user = this.props.getTokenDetails?.LOGIN_CREDS?.tokenDetails?.user?.id
        let { message, chat } = this.state;
        if (message) {
            const { roomId } = this.props.match.params;
            let data;
            if (!this.props.isCustomer) {
                data = {
                    message,
                    roleId: role,
                    roomId,
                    userId: user
                }
            } else {
                data = {
                    message,
                    roleId: this.props.getCustomerToken?.roleId,
                    roomId,
                    userId: this.props.getCustomerToken?.id
                }
            }

            chat.push(data)
            const callBack = (response) => {
                if (response.response_code === 0) {
                    let arrayList = []
                    arrayList = this.props.getChatListAction
                    arrayList.push(response?.response?.ChatMessages)
                    this.props.socketAppendChatAction(arrayList)
                }
            }
            this.props.addChatListAction(data, callBack)
        }
        this.setState({ message: '', chat })
    }
    openApp() {
        window.open(`https://${SHOPPING_DOMAIN}`, "_blank")
    }
    openLink(link) {
        window.open(link, "_blank")
    }

    onMute = (e, check) => {
        if (check) {
            e.preventDefault()
        } else {
            this.setState({ isMute: true })
            this.mediaStream && this.mediaStream.mute('audio')
        }
    }
    onUnMute = () => {
        this.setState({ isMute: false })
        this.mediaStream && this.mediaStream.unmute('audio')
    }

    onMuteVideo = (e, check) => {
        if (check) {
            e.preventDefault()
        } else {
            const { isCustomer } = this.props
            this.setState({ isVideoMute: true, isVrBackgroundIcon: false })
            this.mediaStream && this.mediaStream.mute('video')
            this.handleAvatatOnMute();
            if (!isCustomer && this.state.virtualBackgroundSelectId) {
                clearInterval(backgroundInterval)
            }
        }
    }
    onUnMuteVideo = () => {
        const { isCustomer } = this.props
        this.setState({ isVideoMute: false })
        this.mediaStream && this.mediaStream.unmute('video')
        this.handleAvatatOnUnMute();
        if (!isCustomer && this.state.virtualBackgroundSelectId && this.state.vbOption === 2) {
            this.setIntervalForAutoChangeBackground(0, this.state.virtualBackgroundImages)
        }
    }

    onStartScreenShare = () => {
        const screenRoomId = uuidv4();
        this.setState({ screenRoomId, isScreenShared: true });
    }
    takePicture = () => {
        let video = document.getElementById("video")
        const output = document.getElementById('capture-img')
        const context = canvas.getContext("2d");
        height = video.videoHeight / (video.videoWidth / width)
        if (isNaN(height)) {
            height = width / (4 / 3);
        }
        canvas.setAttribute("width", width);
        canvas.setAttribute("height", height);
        if (width && height) {
            context.drawImage(video, 0, 0, width, height);
            const data = canvas.toDataURL("image/png")
            let img = document.createElement("img")
            img.id = 'photo'
            img.alt = "The screen capture will appear in this box."
            img.setAttribute("src", data);
            this.setState({ verificationImage: data })
            output.appendChild(img)
        }
    }
    onCaptureButtonOpen = () => {
        this.setState({ isCaptureButton: true }, () => {
            this.takePicture()
        });
    }
    onScreenShared = (screenRoomId) => {
        const { isCustomer } = this.props;
        const { roomId } = this.props.match.params;
        let params = {
            roomId,
            stop: false,
            isCustomer: !!isCustomer,
            screenRoomId: this.state.screenRoomId
        }
        this.props.screenShareAction(params, (res) => {
        })
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ url: `screen/${roomId}/${isCustomer ? 'customer' : 'store'}`, data })
        // };
        // fetch(`${this.socketURL}/api/socket`, requestOptions)
        //     .then(response => response.json())
        //     .then(data => {
        //     });
    }

    onStopScreenShare = () => {
        const { isCustomer } = this.props;
        const { roomId } = this.props.match.params;
        let params = {
            roomId,
            stop: true,
            isCustomer: !!isCustomer
        }
        this.props.updateScreenShareAction(params)
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ url: `screen/${roomId}/${isCustomer ? 'customer' : 'store'}`, data })
        // };
        // fetch(`${this.socketURL}/api/socket`, requestOptions)
        //     .then(response => response.json())
        //     .then(data => {
        //     });
        this.setState({ screenRoomId: '', isScreenShared: false });
    }

    removePicture = () => {
        let captureContainer = document.getElementById('capture-img')
        let captureImage = document.getElementById("photo")
        captureContainer.removeChild(captureImage)
    }
    onCaptureButtonClose = () => {
        this.setState({ isCaptureButton: false }, () => {
            this.removePicture()
        })
    }
    repeatPicture = () => {
        this.removePicture()
        this.takePicture()
    }
    handleChat = (e, check) => {
        if (check) {
            e.preventDefault()
        } else {
            this.setState({ isChatOpened: !this.state.isChatOpened, unreadMessageCount: 0 })
            this.getChatList()
            if (this.state.isVrBackgroundIcon) {
                this.setState({ isVrBackgroundIcon: false })
            }
        }
    }
    handleBackground = (e, check) => {
        if (this.state.isVideoMute && this.state.virtualBackgroundSelectId === 0) {
            toast.warning("Please Turn on your Camera")
        } else {
            this.setState({ isVrBackgroundIcon: !this.state.isVrBackgroundIcon }) // virtual background popup open and close
            if (this.state.isChatOpened) {
                this.setState({ isChatOpened: false }) // if chat popup is opend that time open the virtual background means only colse the chat popup
            }
        }
    }
    handleAvatatOnMute = () => {
        const { customerName, agentName, isVirtualBackground } = this.state
        const { isCustomer } = this.props;
        document.querySelectorAll('video[muted]').forEach(function (e) {
            e.style.display = "none";
        });
        if (isVirtualBackground && !isCustomer) {
            let frontVideo = document.getElementById("frCanvas");
            frontVideo.setAttribute("style", "display:none");
        }
        const avatarMessage = document.createElement('div');
        avatarMessage.className = "inner-media-box";
        const avatar = document.createElement('div')
        avatar.className = "localAvatar"
        avatar.dataset.label = isCustomer ? customerName?.[0]?.toUpperCase() || 'C' : agentName?.[0]?.toUpperCase() || 'A   '
        avatarMessage.appendChild(avatar)
        document.querySelector('.media-box').appendChild(avatarMessage);
    }
    handleAvatatOnUnMute = () => {
        const { isVirtualBackground } = this.state
        const { isCustomer } = this.props;
        if (isVirtualBackground && !isCustomer) { // agent side front video display none and canvas display block
            let frontVideo = document.getElementById("frCanvas");
            frontVideo.setAttribute("style", "display:block");
            document.querySelectorAll('video[muted]').forEach(function (e) {
                e.style.display = "block";
            });
        } else {
            if (!isCustomer) { // agent side front video canvas display none.
                let frontVideo = document.getElementById("frCanvas");
                frontVideo.setAttribute("style", "display:none");
            }
            document.querySelectorAll('video[muted]').forEach(function (e) {
                e.style.display = "block";
            });
        }
        document.querySelectorAll('.inner-media-box').forEach(function (e) {
            e.parentElement.removeChild(e);
        });

    }
    ageVerification = () => {
        const { roomId } = this.props.match.params;
        const { verificationImage } = this.state
        this.setState({ isLoading: true })
        let img = verificationImage.substring(verificationImage.indexOf(",") + 1);
        let params = {
            roomId: roomId,
            imageUrl: img
        }
        this.props.ageVerification(params, (res) => {
            this.setState({ isLoading: false })
            if (res?.response?.data?.response_code === 2) {
                toast.error("OCR Server down")
            }
        })
    }
    setIntervalForAutoChangeBackground = (index, virtualBackgroundImages) => {
        if (virtualBackgroundImages.length > 0) {
            backgroundInterval = setInterval(() => {
                if ((virtualBackgroundImages.length - 1) === index) {
                    index = 0;
                } else {
                    index += 1;
                }
                let img = virtualBackgroundImages[index]
                let findDinamicImgDiv = document.getElementsByClassName("dinamicImgDivRender")
                if (findDinamicImgDiv.length > 0) {
                    document.querySelector('#videos-container').removeChild(findDinamicImgDiv[0])
                }
                let createDinamicImg = document.createElement('div');
                createDinamicImg.className = "dinamicImgDivRender"
                createDinamicImg.innerHTML = `<img id='vbackground_${img?.id}' src=${this.setImg(img?.id, img?.imageURL)} alt="dinamic" />`
                document.querySelector('#videos-container').appendChild(createDinamicImg)
                let imgTag = document.getElementById(`vbackground_${img?.id}`)
                imgTag.setAttribute("style", 'display: none')
                if (this.state.virtualBackgroundSelectId && !this.state.isVideoMute) {
                    this.setVideo(imgTag, img.id, false)
                }
            }, 15000);  // 15 sec once virtual background auto change.
        }
    }
    autoChangeBackground = (e, imageIndex, index, virtualBackgroundImages) => {
        clearInterval(backgroundInterval)
        const { isCustomer } = this.props;
        if (this.state.isVideoMute && !isCustomer) {
            return toast.warning("Please Turn on your Camera")
        }
        if (imageIndex != 0) {
            if (this.state.vbOption === 2) {
                this.setIntervalForAutoChangeBackground(index, virtualBackgroundImages)
            }
        }
        this.setVideo(e, imageIndex, true)
    }

    setVideo = (e, imageIndex, loading) => {
        const { isCustomer } = this.props;
        if (this.state.isVideoMute && !isCustomer) {
            return toast.warning("Please Turn on your Camera")
        }
        this.setState({ virtualBackgroundSelectId: imageIndex })
        if (loading) {
            this.setState({ isLoading: true, })
        }
        if (imageIndex == 0) {
            clearInterval(backgroundInterval)
            if (this.state.isVirtualBackground) {
                this.setState({ isVirtualBackground: false, isVirtualBackgroundIMG: e })
                let frontVideo = document.getElementById("canvasVideo")
                if (!isCustomer) {
                    let fronendCanvas = document.getElementById("frCanvas")
                    frontVideo.setAttribute("style", "display: block")
                    fronendCanvas.setAttribute("style", "display:none")
                    this.virtualBackgroundSet(frontVideo, false)
                } else {
                    let backVideo = document.getElementById("video")
                    let backgroundCanvas = document.getElementById("bgCanvas")
                    backVideo.setAttribute("style", "display:block");
                    backgroundCanvas.setAttribute("style", "display:none")
                    this.setState({ isLoading: false })
                    this.virtualBackgroundSet(backVideo, false)
                }
                if (!isCustomer) {
                    const { roomId } = this.props.match.params;
                    let params = {
                        roomId: roomId,
                        id: imageIndex
                    }
                    this.props.vbImage(params, (res) => {
                        this.setState({ isLoading: false })
                        if (res?.response?.data?.response_code === 2) {
                            toast.error(res?.response?.data?.response_message)
                        }
                    })
                }
            } else {
                this.setState({ isLoading: false })
            }
        } else {
            this.setState({ isVirtualBackground: true, isVirtualBackgroundIMG: e })
            const { isCustomer } = this.props;
            let frontVideo = document.getElementById("canvasVideo")
            if (isCustomer) {
                let backVideo = document.getElementById("video")
                let backgroundCanvas = document.getElementById("bgCanvas")
                if (this.state.isScreenView) {
                    backgroundCanvas.setAttribute("style", "display: block;position: absolute;height: 150px !important;width: auto !important;top: 3vh;right: 2vw;border: 2px solid white;border-radius: 10px;")
                } else {
                    backgroundCanvas.setAttribute("style", "display: block;position: absolute;top: 0;bottom: 0;left: 0;right: 0;margin: auto")
                }
                backVideo.setAttribute("style", "display: none")
                this.canvasRef.current = backgroundCanvas
                this.virtualBackgroundSet(backVideo, true)
                this.setState({ isLoading: false })
            }
            else {
                let fronendCanvas = document.getElementById("frCanvas")
                fronendCanvas.setAttribute("style", "display:block")
                frontVideo.setAttribute("style", "visibility: hidden;position: absolute;")
                this.canvasRef.current = fronendCanvas
                this.virtualBackgroundSet(frontVideo, true)
            }
            if (!isCustomer) {
                const { roomId } = this.props.match.params;
                let params = {
                    roomId: roomId,
                    id: imageIndex
                }
                this.props.vbImage(params, (res) => {
                    this.setState({ isLoading: false })
                    if (res?.response?.data?.response_code === 2) {
                        toast.error(res?.response?.data?.response_message)
                    }
                })
            }
        }

    }
    zoomIn = () => {
        const { isCustomer } = this.props;
        this.setState({ isZoom: true })
        if (this.state.isVirtualBackground && isCustomer) {
            let backgroundCanvas = document.getElementById("bgCanvas")
            backgroundCanvas.setAttribute("style", "object-fit: contain;")
            this.canvasRef.current = backgroundCanvas
        }
    }
    zoomOut = () => {
        const { isCustomer } = this.props;
        this.setState({ isZoom: false })
        if (this.state.isVirtualBackground && isCustomer) {
            let backgroundCanvas = document.getElementById("bgCanvas")
            backgroundCanvas.setAttribute("style", "object-fit: cover;")
            this.canvasRef.current = backgroundCanvas
        }
    }
    changeVbOption = (e) => {
        if (e.target.value == 1) {
            this.setState({ vbOption: 2 })
            if (this.state.isVirtualBackground) {
                this.setIntervalForAutoChangeBackground(0, this.state.virtualBackgroundImages)
            }
        } else {
            this.setState({ vbOption: 1 })
            clearInterval(backgroundInterval)
        }
    }
    render() {
        const { isCustomer, attenedName } = this.props;
        const { isChatOpened, message, customerName, agentName, isMute, avatarmain, isVideoMute, unreadMessageCount = 0, isZoom, isScreenShared, isCaptureButton, isScreenView, screenRoomId, checkIsConnected, isLoading, isMobileDevice, isVrBackgroundIcon, virtualBackgroundSelectId, isVirtualBackground, virtualBackgroundImages, vbOption, language } = this.state;
        const chatList = this.props.getChatListAction;
        // const { isLoading } = this.props.customerDeclineLoader;
        // const { appointmentLoading } = this.props.updateAppointmentLoader;
        const { cartGeneration } = attenedName
        let MESSAGE = language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE
        return (
            <div className="customer-care-call" id="chat-page">
                {(isLoading) && <AppLoader />}
                <div className="customer-care-call-content">
                    <div className="row bc-black">
                        <div className={`col-12 ${(isChatOpened || isVrBackgroundIcon) ? 'chat-open col-lg-9' : 'col-md-12'} p-0`} id="video-hide">
                            <div id="videos-container" className={`${!isZoom ? 'max-video' : ''} ${isScreenView ? 'video-screen' : ''}`}>
                                {(avatarmain && !isScreenView) ?
                                    <div className="hello">
                                        {isCustomer ? <Avatar name={agentName} round={true} color={'gray'} size='180px' /> : <Avatar name={customerName} round={true} color={'gray'} size='180px' />}
                                    </div> : ""}
                                <div className="d-flex justify-content-center  customer-end-call-content icons">
                                    {isMute ? (<div className="cuatomer-call-circle-slash mx-3 OnMuteIcon unMuteMicTooltip" onClick={() => this.onUnMute()}>
                                        <i className="fa-solid fa-microphone-slash mic-icon-slash"></i>
                                        <Tooltip anchorSelect=".unMuteMicTooltip" place="top">
                                            {MESSAGE?.MIC?.UNMUTE}
                                        </Tooltip>
                                    </div>) :
                                        (<div className="cuatomer-call-circle mx-3 OnMuteIcon" onClick={(e) => this.onMute(e, checkIsConnected)}>
                                            <i className="fa-solid fa-microphone mic-icon muteMicTooltip" ></i>
                                            <Tooltip anchorSelect=".muteMicTooltip" place="top">
                                                {MESSAGE?.MIC?.MUTE}
                                            </Tooltip>
                                        </div>)}
                                    {isVideoMute ? (<div className="cuatomer-call-circle-slash mx-3 OnVideoMute unMuteVideoTooltip" onClick={() => this.onUnMuteVideo()}>
                                        <i className="fa-solid fa-video-slash mic-icon-slash" ></i>
                                        <Tooltip anchorSelect=".unMuteVideoTooltip" place="top">
                                            {MESSAGE?.VIDEO?.UNMUTE}
                                        </Tooltip>
                                    </div>) :
                                        (<div className="cuatomer-call-circle mx-3 OnVideoMute muteVideoTooltip" onClick={(e) => this.onMuteVideo(e, checkIsConnected)}>
                                            <i className="fa-solid fa-video-camera mic-icon"></i>
                                            <Tooltip anchorSelect=".muteVideoTooltip" place="top">
                                                {MESSAGE?.VIDEO?.MUTE}
                                            </Tooltip>
                                        </div>)}
                                    <div className="cuatomer-call-circle mx-3 OnEndCall endCallTooltip" onClick={() => this.endCall()}>
                                        <i className="fa-solid fa-phone call-icon"></i>
                                        <Tooltip anchorSelect=".endCallTooltip" place="top">
                                            {MESSAGE?.END_CALL}
                                        </Tooltip>
                                    </div>
                                    {/* Removed other then audio, video and end */}
                                    {/* <div className="cuatomer-call-circle mx-3 p-relative OnHandleIcon chatTooltip" id="chat-icon" onClick={(e) => this.handleChat(e, checkIsConnected)}>
                                        {unreadMessageCount > 0 && <div className="chat-count">{unreadMessageCount}</div>}
                                        <i className='fa-solid fa-comment-dots chat-icon1'></i>
                                        <Tooltip anchorSelect=".chatTooltip" place="top">
                                            Chat
                                        </Tooltip>
                                    </div>
                                    {(!isCustomer && virtualBackgroundImages?.length > 0) && <div className={`mx-3 p-relative OnHandleIcon virtualBackgroundTooltip ${isVirtualBackground ? "cuatomer-call-circle-slash" : "cuatomer-call-circle"} `} id="chat-icon" onClick={(e) => this.handleBackground(e, checkIsConnected)}>
                                        <i className={`fa-solid fa-chalkboard chat-icon1 ${isVirtualBackground ? "onScreenShareIcon" : ""}`}></i>
                                        <Tooltip anchorSelect=".virtualBackgroundTooltip" place="top">
                                            Virtual Background
                                        </Tooltip>
                                    </div>}
                                    {cartGeneration == 1 &&
                                        <>
                                            {!isCustomer && <div className="cuatomer-call-circle mx-3 OnOpenApp shopptingTooltip" id="chat-icon">
                                                <i className="fa fa-cart-plus  mic-icon" onClick={() => this.openApp()}></i>
                                                <Tooltip anchorSelect=".shopptingTooltip" place="top">
                                                    Shopping
                                                </Tooltip>
                                            </div>}
                                            {(!isCustomer && !isMobileDevice) &&
                                                <div className={`${!isScreenShared ? 'cuatomer-call-circle screenShareTooltip' : 'cuatomer-call-circle-slash'} mx-3 OnScreenStart`} id="chat-icon" onClick={() => isScreenShared ? this.onStopScreenShare() : this.onStartScreenShare()}>
                                                    <i className={`${isScreenShared && 'onScreenShareIcon'} fa-solid fa-arrow-up-from-bracket chat-icon1`}></i>
                                                    <Tooltip anchorSelect=".screenShareTooltip" place="top">
                                                        {
                                                            isScreenShared ? "Stop Screen Share" : "  Screen Share"
                                                        }
                                                    </Tooltip>
                                                </div>}
                                            {(!isCustomer) &&
                                                <div className="capture_icon">
                                                    <div className={`${!isCaptureButton ? 'cuatomer-call-circle ' : 'cuatomer-call-circle-slash '} mx-3 OnScreenStart`} id="chat-icon"
                                                        onClick={() => isCaptureButton ? this.onCaptureButtonClose() : this.onCaptureButtonOpen()}>
                                                        {!isCaptureButton ?
                                                            <>
                                                                <i className={`fa-solid fa-camera chat-icon1 captureTooltip`}></i>
                                                                <Tooltip anchorSelect=".captureTooltip" place="top">
                                                                    Capture
                                                                </Tooltip>
                                                            </>
                                                            :
                                                            <>
                                                                <i className={`${isCaptureButton && 'onScreenShareIcon captureCloseTooltip'} fa-solid fa-x chat-icon1`}></i>
                                                                <Tooltip anchorSelect=".captureCloseTooltip" place="top">
                                                                    Close
                                                                </Tooltip>
                                                            </>
                                                        }
                                                    </div>

                                                    {isCaptureButton &&
                                                        <div className="capture_open_icon">
                                                            <div className={`cuatomer-call-circle mx-3 OnScreenStart retakeTooltip`} id="chat-icon" onClick={() => this.repeatPicture()}>
                                                                <i className={`fa-solid fa-repeat chat-icon1`}></i>
                                                                <Tooltip anchorSelect=".retakeTooltip" place="top">
                                                                    Retake
                                                                </Tooltip>
                                                            </div>
                                                            <div className={`cuatomer-call-circle mx-3 OnScreenStart submitTooltip`} id="chat-icon" onClick={() => this.ageVerification()}>
                                                                <i className={`fa-solid fa-check chat-icon1`}></i>
                                                                <Tooltip anchorSelect=".submitTooltip" place="top">
                                                                    Submit
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </>
                                    } */}
                                </div>
                                {(isScreenShared || isScreenView) && <ScreenShare share={isScreenShared} view={isScreenView} roomId={screenRoomId} onScreenShared={(d) => this.onScreenShared(d)} onStopScreenShare={this.onStopScreenShare} />}
                            </div>
                            <div className="mx-3 bg-white">
                                {(!isCustomer) &&
                                    <>
                                        <canvas id="canvas"> </canvas>
                                        <div className="scan-box" id="capture-img"></div>
                                    </>}
                                {!isScreenView &&
                                    <>
                                        {!isZoom && <div className="zoom-icon zoomInTooltip" onClick={() => this.zoomIn()}>
                                            <i className="fas fa-expand-arrows-alt"></i>
                                            <Tooltip anchorSelect=".zoomInTooltip" place="top">
                                                Zoom In
                                            </Tooltip>
                                        </div>}
                                        {isZoom && <div className="zoom-icon zoomOutTooltip" onClick={() => this.zoomOut()}>
                                            <i className="fas fa-compress-arrows-alt"></i>
                                            <Tooltip anchorSelect=".zoomOutTooltip" place="top">
                                                Zoom Out
                                            </Tooltip>
                                        </div>}
                                    </>
                                }
                            </div>
                        </div>
                        {isChatOpened && <div className="col-12 col-lg-3 p-0" style={{ borderRadius: 0, zIndex: 111 }}>
                            <div className="card chat-card" id="chart-page">
                                <div className="card-header heading-part">
                                    <div className="d-flex head-content">
                                        <div className="chat-left">
                                            <i className="fa-solid fa-comment-dots chat-icon"></i>
                                            <p className="chat-text">{Constants.CHAT}</p>
                                        </div>
                                        <i className="fa-solid fa-close close-icon" id="close-query" onClick={() => this.setState({ isChatOpened: false })}></i>
                                    </div>
                                </div>
                                <div className="card-body chat-body-content">
                                    {Array.isArray(chatList) && chatList?.map((message, i) => {
                                        return (<div className="message-content" key={i}>
                                            <div className={`message-content-box 
                                             ${this.props.isCustomer ?
                                                    message.messageType === 4 ? 'me' : ''
                                                    : message.messageType === 3 ? 'me' : ''
                                                }`}>
                                                <div className="message-text">
                                                    {(message?.message?.includes(`https://${SHOPPING_DOMAIN}/customercare/payment`) ||
                                                        message?.message?.includes("paulsfashion.myshopify")) ? <div>
                                                        <QRCode
                                                            value={message.message}
                                                        /><br />
                                                        {isCustomer && <span style={{ color: '#0d6efd', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.openLink(message.message)}>{Constants.PAY_NOW}</span>}
                                                    </div> : message.message}
                                                </div>
                                            </div>
                                        </div>)
                                    })}
                                </div>
                                <div className="card-footer chat-footer">
                                    <div className="input-content">
                                        <input autoComplete="off" type="text" className="form-control type-message" placeholder="Type a message"
                                            value={message} name="message" onChange={(e) => this.handleChange(e)} onKeyUp={(e) => this.handleKeyUp(e)} />
                                    </div>
                                    <i className="fas fa-paper-plane send-icon" onClick={() => this.sendMessage()}></i>
                                </div>
                            </div>
                        </div>}
                        {isVrBackgroundIcon && <div className="col-12 col-lg-3 p-0" style={{ borderRadius: 0, zIndex: 111 }}>
                            <div className="card chat-card" id="chart-page">
                                <div className="card-header heading-part">
                                    <div className="d-flex head-content">
                                        <div className="chat-left">
                                            <i className="fa-solid fa-chalkboard chat-icon"></i>
                                            <p className="chat-text">Virtual Background</p>
                                        </div>
                                        <i className="fa-solid fa-close close-icon" id="close-query" onClick={() => this.setState({ isVrBackgroundIcon: false })}></i>
                                    </div>
                                </div>
                                <div className="card-body chat-body-content">
                                    <p className="m-0">No effects</p>
                                    <div className="row m-0">
                                        <div className="col-6 px-2 py-2">
                                            <div className={`noeffect_box ${virtualBackgroundSelectId === 0 ? " bg_active" : ""}`}>
                                                <img id="vbackground_0" src={NoEffect} alt="No effect" width="30%" onClick={(e) => this.setVideo(e.target, 0, true)} />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 mt-1">{virtualBackgroundImages.length > 1 ? "Backgrounds" : "Background"}</p>
                                    {virtualBackgroundImages.length > 1 && <div className="row m-0 mt-3">
                                        <div onClick={(e) => { this.changeVbOption(e) }}>
                                            View Options &nbsp; &nbsp;
                                            <input type="radio" value={vbOption} name="vbOption" checked={vbOption === 1 ? true : false} /> Static &nbsp;
                                            <input type="radio" value={vbOption} name="vbOption" checked={vbOption === 2 ? true : false} /> Loop &nbsp;
                                        </div>
                                    </div>}
                                    <div className="row m-0">
                                        {virtualBackgroundImages?.map((img, i) => {
                                            return <div className="col-6 px-2 py-2" key={i}>
                                                <div className={`bg_effect_align ${virtualBackgroundSelectId === img?.id ? " bg_active" : ""}`}>
                                                    <img id={`vbackground_${img?.id}`} src={img?.imageURL} alt="The Screen" onClick={(e) => this.autoChangeBackground(e.target, img?.id, i, virtualBackgroundImages)} />
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}
export const mapStateToProps = (state) => {
    return {
        getTokenDetails: state,
        getCustomerToken: state?.CUSTOMER_LIST?.customerList?.response?.User,
        getChatListAction: state?.CHAT_LIST?.currentArrayList,
        getQueueList: state?.CHAT_LIST,
        isCallAttended: state?.FEEDBACK?.isAttended,
        attenedName: Actions.getLoginDetails(state),
        callerName: Actions.getCustomerCallListDetails(state),
        appointmentDetails: state?.UPDATE_APPOINTMENT?.updateAppointment?.response,
        customerDeclineLoader: state?.CUSTOMER_DECLINE,
        chatListLoader: state?.CHAT_LIST,
        updateAppointmentLoader: state?.UPDATE_APPOINTMENT
    }
};
export default withRouter(connect(mapStateToProps, {
    customerDeclineAction: Actions.customerDeclineAction,
    updateAppointment: Actions.updateAppointmentAction,
    chatListAction: Actions.chatListAction,
    virtualBackgroundListAction: Actions.getAgentVbImageList,
    addChatListAction: Actions.addChatListAction,
    appendChat: Actions.appendChatAction,
    socketAppendChatAction: Actions.socketAppendChatAction,
    appendQueueList: Actions.addChatQueueList,
    screenShareAction: Actions.screenShareAction,
    updateScreenShareAction: Actions.updateScreenShareAction,
    ageVerification: Actions.getAgeVerificationAction,
    vbImage: Actions.getVirtualBackgroundAction
})(KioskVerification))