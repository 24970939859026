import { doAgentPost } from "../../utils/service";
import { put, takeLatest,all } from "redux-saga/effects";
import { END_POINTS } from "../../utils/service/EndPoints";
import { SCREEN_SHARE, UPDATE_SCREEN_SHARE } from "../actions/ScreenShareAction";
import Actions from "../actions";


export function* screenShareSaga({ params, callback }) {
    try {
        const response = yield doAgentPost(END_POINTS.AGENT_SCREEN_SHARE, params);
        if (response) {
            yield put(Actions.screenShareSuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.screenShareFailureAction(e));
        callback && callback(e);
    }
}
export function* updateScreenShareSaga({ params, callback }) {
    try {
        const response = yield doAgentPost(END_POINTS.AGENT_SCREEN_SHARE_UPDATE, params);
        if (response) {
            yield put(Actions.screenShareSuccessAction(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.screenShareFailureAction(e));
        callback && callback(e);
    }
}

export default function* screenShareListener() {
    yield all([
        takeLatest(SCREEN_SHARE, screenShareSaga),
        takeLatest(UPDATE_SCREEN_SHARE, updateScreenShareSaga),
    ]);
}