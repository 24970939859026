import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Actions from '../redux/actions/index';
import ReactStars from "react-rating-stars-component";
import Constants from '../utils/Constants';
import AppLoader from '../utils/AppLoader';
import stringTamil from '../utils/stringTamil';
import stringEnglish from '../utils/stringEnglish';
import { isUserName } from '../utils/validation';
import SRM_GLOBAL from "../assets/img/srm-global.jpeg";
class KioskFeedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            feedBack: '',
            customerRating: 0,
            encryptId: '',
            language: parseInt(localStorage.getItem("language")) || 1,
            error: {},
            message: {},
            fullname: ""
        };
    }
    componentDidMount() {
        const { getcutomerList } = this.props;
        let encryptId = localStorage.getItem("vendorId");
        this.setState({ encryptId: encryptId });
    }
    submitHandler = () => {
        const { feedBack, customerRating, fullname } = this.state;
        const customerRoomId = this.props.getCustomerToken;
        let params = {
            roomId: customerRoomId,
            ratings: customerRating,
            feedback: feedBack,
            fullName: fullname
        };
        const feedBackCallBack = (response) => {
            this.props.history.push(`/kiosk_srm/${this.state.encryptId}`);
        };
        let MESSAGE = this.state?.language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
        if (!params.fullName) {
            this.setState({ error: { isFullName: true }, message: { isFullName: MESSAGE?.PLEASE_ENTER_THE_NAME } });
        } else if (params.fullName.trim().length < 3) {
            this.setState({ error: { isFullName: true }, message: { isFullName: MESSAGE?.FULL_NAME_MINIMUM_CHARACTER } });
        } else if (!isUserName(params.fullName)) {
            this.setState({ error: { isFullName: true }, message: { isFullName: MESSAGE?.NAME_REGX } });
        } else {
            this.props.feedBack(params, feedBackCallBack);
        }
    };
    ratingChanged = (newRating) => {
        this.setState({ customerRating: newRating });
    };
    handleChange = (e) => {
        let fullname = e.target.value;
        let MESSAGE = this.state?.language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
        if (!fullname || fullname.length === 0) {
            this.setState({ error: { isFullName: true }, fullname: e.target.value, message: { isFullName: MESSAGE?.PLEASE_ENTER_THE_NAME } });
            return false;
        }
        else if (fullname.trim().length < 3) {
            this.setState({ error: { isFullName: true }, fullname: e.target.value, message: { isFullName: MESSAGE?.FULL_NAME_MINIMUM_CHARACTER } });
        }
        else if (!isUserName(fullname)) {
            this.setState({ error: { isFullName: true }, fullname: e.target.value, message: { isFullName: MESSAGE?.NAME_REGX } });
        }
        else {
            this.setState({ error: { isFullName: false }, fullname: e.target.value, message: { isFullName: '' } });
        }
    };
    render() {
        const { isLoading } = this.props.feedBackLoader;
        const { language, error, message } = this.state;
        let MESSAGE = language === 2 ? stringTamil?.MESSAGE : stringEnglish?.MESSAGE;
        return (
            <div className='d-flex flex-column overflow-auto gap-5 feedback-form' style={{ backgroundImage: `url(${SRM_GLOBAL})`, backgroundSize: 'auto 100%', backgroundPosition: 'center' }}>
                {isLoading && <AppLoader />}
                <img className="logo" src={require('../assets/img/srm-logo.png')} alt="logo" />
                <div className='w-75 card p-5' style={{ backgroundColor: 'rgb(255 255 255 / 80%)' }}>
                    <h1 className='text-center'>{MESSAGE?.CALL_FEEDBACK?.FEEDBACK}</h1>
                    <p className='text-center'>
                        {MESSAGE?.CALL_FEEDBACK?.FEEDBACK_HEADERS}
                    </p>
                    <h4 className='text-center mb-0'>{MESSAGE?.CALL_FEEDBACK?.RATE_YOUR_EXPERIENCE}</h4>
                    <div className='rating-stars'>
                        <ReactStars
                            count={5}
                            onChange={this.ratingChanged}
                            size={50}
                            isHalf={true}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#ffd700"
                        />
                    </div>
                    <div className="d-flex flex-column">
                        <label htmlFor="exampleFormControlInput1" className="form-label form-text">{MESSAGE?.FULLNAME} <span style={{ color: "red" }}>*</span></label>
                        <input autoComplete="off" type="text" className="form-control custom-form-control w-100" id="exampleFormControlInput1"
                            placeholder={MESSAGE?.ENTER_NAME} style={{ height: '46px' }} maxLength="30" onChange={(e) => this.handleChange(e)} />
                        <div>
                            {error.isFullName && <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message?.isFullName}</h5>}
                        </div>
                    </div>
                    <div className='d-grid gap-2 mt-4'>
                        <label className='form-label form-text'>{MESSAGE?.CALL_FEEDBACK?.COMMMENT_OPTIONAL} {MESSAGE?.CALL_FEEDBACK?.OPTIONAL}</label>
                        <textarea className='form-control textarea-width' placeholder={MESSAGE?.CALL_FEEDBACK?.YOUR_COMMENTS} onChange={(e) => this.setState({ feedBack: e.target.value })}></textarea>
                    </div>
                    <button className='btn btn-gradient btn-lg mt-4' type='button' onClick={() => this.submitHandler()}>{MESSAGE?.CALL_FEEDBACK?.SEND_FEEDBACK}</button>
                    <div className="skipDiv text-center mt-4">
                        <Link to={`/kiosk_srm/${this.state.encryptId}`} className="skip text-center">
                            {MESSAGE?.CALL_FEEDBACK?.SKIP}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
export const mapStateToProps = (state) => {
    return {
        getCustomerToken: state?.CUSTOMER_LIST?.customerList?.response?.roomId,
        feedBackLoader: state?.FEEDBACK,
        getcutomerList: Actions.getcutomerList(state)
    };
};
export default withRouter(connect(mapStateToProps, {
    feedBack: Actions.feedBackAction
})(KioskFeedback));