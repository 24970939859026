import { Route, Switch, useLocation } from "react-router-dom"
import ROUTES from "./Routes"
import { ToastContainer } from "react-toastify"
import { useSelector } from "react-redux"
import SocketMessage from "../../container/SocketMessage"


export const ProtectedRoutes = (props) => {
    // const token=useSelector(state=>state?.LOGIN_CREDS?.tokenDetails?.access_token)
    // const history=useHistory()
    // useEffect(()=>{
    //    if(!token){
    //       history.replace('/')
    //    }
    // },[token])
    return (
        <Route {...props} />
    )
}
const AppRoutes = () => {
    const tokenData = useSelector((store) => store?.LOGIN_CREDS)
    const location = useLocation()
    let arr = location?.pathname?.split('/')
    let token
    if (arr[1] === 'admin') {
        token = tokenData?.adminTokenDetails?.access_token
    } else if (arr[1] === 'vendor') {
        token = tokenData?.vendorTokenDetails?.access_token
    } else {
        token = tokenData?.tokenDetails?.access_token
    }
    return (
        <div>
            <Switch>
                {ROUTES?.map(route => <ProtectedRoutes key={route.path} {...route} />)}
            </Switch>
            {!token && <ToastContainer />}
            {token && <SocketMessage />}
        </div>
    )
}

export default AppRoutes