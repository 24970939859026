import { doAction, doResponseAction } from ".";

const CUSTOMER_LIST = "CUSTOMER_LIST";
const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";
const CUSTOMER_LIST_FAILURE = "CUSTOMER_LIST_FAILURE";

const GET_VENDOR_DETAILS = "GET_VENDOR_DETAILS";
const GET_VENDOR_DETAILS_SUCCESS = "GET_VENDOR_DETAILS_SUCCESS";
const GET_VENDOR_DETAILS_FAILURE = "GET_VENDOR_DETAILS_FAILURE";

const ADD_FEEDBACK = "ADD_FEEDBACK";
const ADD_FEEDBACK_SUCCESS = "ADD_FEEDBACK_SUCCESS";
const ADD_FEEDBACK_FAILURE = "ADD_FEEDBACK_FAILURE";

const ADD_FEEDBACK_VOICE = "ADD_FEEDBACK_VOICE";
const ADD_FEEDBACK_VOICE_SUCCESS = "ADD_FEEDBACK_VOICE_SUCCESS";
const ADD_FEEDBACK_VOICE_FAILURE = "ADD_FEEDBACK_VOICE_FAILURE";

const SRM_IP_ROOM_SRVICES_LIST = "SRM_IP_ROOM_SRVICES_LIST";
const SRM_IP_ROOM_SRVICES_LIST_SUCCESS = "SRM_IP_ROOM_SRVICES_LIST_SUCCESS";
const SRM_IP_ROOM_SRVICES_LIST_FAILURE = "SRM_IP_ROOM_SRVICES_LIST_FAILURE";
export {
  CUSTOMER_LIST,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_LIST_FAILURE,

  GET_VENDOR_DETAILS,
  GET_VENDOR_DETAILS_SUCCESS,
  GET_VENDOR_DETAILS_FAILURE,

  ADD_FEEDBACK,
  ADD_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_FAILURE,

  ADD_FEEDBACK_VOICE,
  ADD_FEEDBACK_VOICE_SUCCESS,
  ADD_FEEDBACK_VOICE_FAILURE,

  SRM_IP_ROOM_SRVICES_LIST,
  SRM_IP_ROOM_SRVICES_LIST_SUCCESS,
  SRM_IP_ROOM_SRVICES_LIST_FAILURE,
};
export const getcutomerList = store => store['CUSTOMER_LIST']
export const cutomerListAction = (params, callback) => doAction(CUSTOMER_LIST, params, callback);
export const customerListSuccessAction = (data) => doResponseAction(CUSTOMER_LIST_SUCCESS, data);
export const customerListFailureAction = (data) => doResponseAction(CUSTOMER_LIST_FAILURE, data);

export const getVendorDetailsAction = (params, callback) => doAction(GET_VENDOR_DETAILS, params, callback);
export const getVendorDeailsSuccessAction = (data) => doResponseAction(GET_VENDOR_DETAILS_SUCCESS, data);
export const getVendorDeailsFailureAction = (data) => doResponseAction(GET_VENDOR_DETAILS_FAILURE, data);

export const addFeedbackAction = (params, callback) => doAction(ADD_FEEDBACK, params, callback);
export const addFeedbackSuccessAction = (data) => doResponseAction(ADD_FEEDBACK_SUCCESS, data);
export const addFeedbackFailureAction = (data) => doResponseAction(ADD_FEEDBACK_FAILURE, data);

export const addFeedbackVoiceAction = (params, callback) => doAction(ADD_FEEDBACK_VOICE, params, callback);
export const addFeedbackVoiceSuccessAction = (data) => doResponseAction(ADD_FEEDBACK_VOICE_SUCCESS, data);
export const addFeedbackVoiceFailureAction = (data) => doResponseAction(ADD_FEEDBACK_VOICE_FAILURE, data);

export const srmIpRoomServiceListAction = (params, callback) => doAction(SRM_IP_ROOM_SRVICES_LIST, params, callback);
export const srmIpRoomServiceListSuccessAction = (data) => doResponseAction(SRM_IP_ROOM_SRVICES_LIST_SUCCESS, data);
export const srmIpRoomServiceListFailureAction = (data) => doResponseAction(SRM_IP_ROOM_SRVICES_LIST_FAILURE, data);
