import { Component } from "react";
import { Link } from "react-router-dom";
import Constants from "../utils/Constants";

class VideoConferencing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomId: ''
    }
  }

  render() {
    return (
      <div className="proglint-meet">
        <div className="container">
          <div className="row ">
            <div className="d-flex justify-content-center  justify-content-md-end justify-content-lg-end mt-5">
              <img src={require('../assets/img/proglint Transparent.png')} className="logo-1" alt="logo" width="15%" />
              <img src={require('../assets/img/proglint Transparent.png')} className="logo-2" alt="logo" width="30%" />
              <img src={require('../assets/img/proglint Transparent.png')} className="logo-3" alt="logo" width="50%" />
            </div>
          </div>
          <div className="row meet-card-content">
            <div className="col-md-6 col-lg-6">
              <div className="d-flex justify-content-md-end justify-content-center mx-4">
                <div className="card meet-card h-100" style={{ width: '18rem' }}>
                  <div className="d-flex justify-content-center mt-3">
                    <img src={require('../assets/img/customer_care.png')} alt="customer" width="60%" />
                  </div>
                  <div className="card-body mt-3">
                    <div className="d-grid gap-2 col-8 mx-auto mt-2">
                      <Link to={'/customer'} className="btn btn-dark rounded-0 p-2" target='_blank' type="button"
                      ><span className="btn-text-fam" style={{ position: 'relative' }}>{Constants.CUSTOMER}</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="d-flex justify-content-md-start justify-content-center mx-4">
                <div className="card meet-card h-100" style={{ width: '18rem' }}>
                  <div className="d-flex justify-content-center mt-3">
                    <img src={require('../assets/img/customer_care.png')} alt="customer" width="60%" />
                  </div>
                  <div className="card-body mt-3">
                    <div className="d-grid gap-2 col-8 mx-auto mt-2">
                      <Link to={'/store'} className="btn btn-dark rounded-0 p-2" target='_blank' type="button"
                      ><span className="btn-text-fam" style={{ position: 'relative' }}>{Constants.CUSTOMER_CARE}</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoConferencing;

