import Actions from "../actions";
const initialState = {
    appointmentLoading: false,
    updateAppointment: undefined,
    error: false,
}

export const UpdateAppointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_APPOINTMENT: {
            return {
                ...state,
                appointmentLoading: true,
            };
        }
        case Actions.UPDATE_APPOINTMENT_SUCCESS: {
            return {
                ...state,
                appointmentLoading: false,
                updateAppointment: action.data,
                error: false,
            };
        }
        case Actions.UPDATE_APPOINTMENT_FAILURE: {
            return {
                ...state,
                appointmentLoading: false,
                updateAppointment: undefined,
                error: action.error,
            };
        }
        default: {
            return state;
        }
    }
};
export const updateAppointmentData = (state) => state?.CUSTOMER_CALL_LIST;
export const updateAppointmentLoader = (state) => state?.UPDATE_APPOINTMENT
