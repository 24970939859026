import Actions from "../actions";

const initialState = {
  data: undefined,
  isLoading: false,
  error: undefined,
  vendorRoomListData: null
};

export const VendorRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ROOM_LIST: {
      return {
          ...state,
          isLoading: true,
      };
  }
  case Actions.GET_ROOM_LIST_SUCCESS: {
      return {
          ...state,
          isLoading: false,
          vendorRoomListData: action.data,
          error: false,
      };
  }
  case Actions.GET_ROOM_LIST_FAILURE: {
      return {
          ...state,
          isLoading: false,
          vendorRoomListSaga: {},
          error: action.error,
      };
  }
  case Actions.ADD_ROOM_SERVICE: {
    return {
        ...state,
        isLoading: true,
    };
}
case Actions.ADD_VENDOR_ROOM_SUCCESS: {
    return {
        ...state,
        isLoading: false,
    };
}
case Actions.ADD_VENDOR_ROOM_FAILURE: {
    return {
        ...state,
        isLoading: false,
        error: action.error,
    };
}
  case Actions.UPDATE_VENDOR_ROOM: {
      return {
          ...state,
          isLoading: true,
      };
  }
  case Actions.UPDATE_VENDOR_ROOM_SUCCESS: {
      return {
          ...state,
          isLoading: false,
          vendorRoomDetailData: action.data,
          error: false,
      };
  }
  case Actions.UPDATE_VENDOR_ROOM_FAILURE: {
      return {
          ...state,
          isLoading: false,
          vendorRoomDetailData: {},
          error: action.error,
      };
  }
  case Actions.GET_VENDOR_ROOM_DETAILS: {
      return {
          ...state,
          isLoading: true,
      };
  }
  case Actions.VENDOR_ROOM_DETAIL_SUCCESS: {
      return {
          ...state,
          isLoading: false,
          vendorRoomDetailData: action.data
      };
  }
  case Actions.VENDOR_ROOM_DETAIL_FAILURE: {
    return {
        ...state,
        isLoading: false,
        vendorRoomDetailData: {},
        error: action.error,
    };
}
 
    default: {
      return state;
    }
  }
};