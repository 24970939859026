import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import PaginationComponenent from '../component/Pagination';
import Constants, { LIMIT, THREEDSCRIPT } from '../utils/Constants';
import { toast } from 'react-toastify';
import RoutePath from '../utils/routes/RoutePath';
import ARProductsList from '../component/ARProductsList';

class VendorARProductsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageNumber: 1,
            totalItem: '',
            isVendor: false,
            isCopied: false
        }
    }
    componentDidMount() {
        const Agent = this.props.match.path.split('/')
        if (Agent[2] === "products" && Agent[1] === "admin") {
            this.setState({ isVendor: true })
        } else {
            this.setState({ isVendor: false })
        }
        this.getARProductList()
    }

    getARProductList = () => {
        let params = {
            limit: LIMIT,
            pageNumber: 0
        }
        const callback = (response) => {
            if (response) {
                this.setState({ totalItem: response.response.totalItems })
            }
        }
        this.props.getProductList(params, callback, false)
    }
    onChangePagination = (pageNumber) => {
        let params = {
            pageNumber: (pageNumber - 1),
            limit: LIMIT
        }
        this.props.getProductList(params, '', false)
        this.setState({ pageNumber: pageNumber })
    }
    updateStatus = (id, active) => {
        const updateParams = {
            id,
            isActive: active ? 0 : 1
        }
        const callback = (response) => {
            if (response.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.getARProductList()
            }
            else {
                toast.error(response.response_message)
            }
        }
        this.props.updateProduct(updateParams, callback, false)
    }
    addProduct = () => {
        this.props.history.push(RoutePath.VENDOR_AR_PRODUCTS_ADD)
    }
    // // onClick handler function for the copy button
    handleCopyClick = async (copyText) => {
        navigator.clipboard.writeText(copyText);
        this.setState({ isCopied: true })
    }
    render() {
        const { pageNumber, isVendor } = this.state;
        const { getProduct = {} } = this.props
        const { isLoading, productListData } = getProduct
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <div className="JS_script_card">
                            <h3 className="my-3">JS Code</h3>
                            <div className="row m-0">
                                <div className="script_cards col-12 p-0">
                                    <div className="card">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <p className="script_code_font m-0">&#60;script src="{THREEDSCRIPT}"&#62;&#60;/script&#62;</p>
                                            <button className="script_code_btn" onClick={() => this.handleCopyClick(`<script src="${THREEDSCRIPT}"></script>`)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-files" viewBox="0 0 16 16">
                                                    <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                                                </svg>
                                                {this.state.isCopied ? <p className="m-0">Copied</p> : <p className="m-0">Copy</p>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='addButton'>
                            <div className="add-btn">
                                <button className="btn add-item" type="button" onClick={() => this.addProduct()}><i className="fa-solid fa-plus me-3"></i>Add Product</button>
                            </div>
                        </div>
                        <ARProductsList productList={productListData?.products} updateStatus={(id, active) => this.updateStatus(id, active)}
                            isVendor={isVendor} 
                            currentPage={pageNumber} />
                        <div className='page-content'>
                            {
                                productListData?.totalItems > (LIMIT - 1)  ? <PaginationComponenent currentPage={pageNumber} countValue={productListData?.totalItems} onChangeHandle={this.onChangePagination} /> : ''
                            }
                        </div>
                    </div>
                </div>

                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getProduct: Actions.getProduct(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    getProductList: Actions.getProductList,
    updateProduct: Actions.updateProduct
})(VendorARProductsList
));