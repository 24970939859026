import { doAction, doResponseAction } from ".";

const AGENT_LIST = "AGENT_LIST";
const AGENT_LIST_SUCCESS = "AGENT_LIST_SUCCESS";
const AGENT_LIST_FAILURE = "AGENT_LIST_FAILURE";

const ADD_AGENT_DETAILS = "ADD_AGENT_DETAILS";
const ADD_AGENT = "ADD_AGENT";
const ADD_AGENT_SUCCESS = "ADD_AGENT_SUCCESS";
const ADD_AGENT_FAILURE = "ADD_AGENT_FAILURE";

const UPDATE_AGENT = "UPDATE_AGENT";
const UPDATE_AGENT_SUCCESS = "UPDATE_AGENT_SUCCESS";
const UPDATE_AGENT_FAILURE = "UPDATE_AGENT_FAILURE";

const GET_AGENT = "GET_AGENT";
const GET_AGENT_SUCCESS = "GET_AGENT_SUCCESS";
const GET_AGENT_FAILURE = "GET_AGENT_FAILURE";

const DELETE_AGENT = "DELETE_AGENT";
const DELETE_AGENT_SUCCESS = "DELETE_AGENT_SUCCESS";
const DELETE_AGENT_FAILURE = "DELETE_AGENT_FAILURE";

const ADMIN_AGENT_DETAILS = 'ADMIN_AGENT_DETAILS';
const ADMIN_AGENT_DETAILS_SUCCESS = 'ADMIN_AGENT_DETAILS_SUCCESS';
const ADMIN_AGENT_DETAILS_FAILURE = 'ADMIN_AGENT_DETAILS_FAILURE';

const AGENT_GET_SELECTED_BY_ID = 'AGENT_GET_SELECTED_BY_ID';


export {
    AGENT_LIST,
    AGENT_LIST_SUCCESS,
    AGENT_LIST_FAILURE,

    ADD_AGENT_DETAILS,
    ADD_AGENT,
    ADD_AGENT_SUCCESS,
    ADD_AGENT_FAILURE,

    UPDATE_AGENT,
    UPDATE_AGENT_SUCCESS,
    UPDATE_AGENT_FAILURE,

    GET_AGENT,
    GET_AGENT_SUCCESS,
    GET_AGENT_FAILURE,

    DELETE_AGENT,
    DELETE_AGENT_SUCCESS,
    DELETE_AGENT_FAILURE,

    ADMIN_AGENT_DETAILS,
    ADMIN_AGENT_DETAILS_FAILURE,
    ADMIN_AGENT_DETAILS_SUCCESS,

    AGENT_GET_SELECTED_BY_ID
};

export const getAgentDetails = store => store['AGENR_DETAILS']
export const agentListAction = (params, callback, isAdmin) => {
    return {
        type: AGENT_LIST,
        params,
        callback,
        isAdmin
    }
}
export const agentListSuccessAction = (data) => doResponseAction(AGENT_LIST_SUCCESS, data);
export const agentListFailureAction = (data) => doResponseAction(AGENT_LIST_FAILURE, data);

export const addAgentDetails = (data) => {
    return {
        type: ADD_AGENT_DETAILS,
        data,
    }
}

export const addAgentAction = (params, callback, isAdmin) => {
    return {
        type: ADD_AGENT,
        params,
        callback,
        isAdmin
    }
}
export const addAgentSuccessAction = (data) => doResponseAction(ADD_AGENT_SUCCESS, data);
export const addAgentFailureAction = (data) => doResponseAction(ADD_AGENT_FAILURE, data);


export const getAdminAgentDetails = (params, callback, isAdmin) => doAction(ADMIN_AGENT_DETAILS,params,callback,isAdmin)
export const getAdminAgentDetailSuccess = (data) => doResponseAction(ADMIN_AGENT_DETAILS_SUCCESS, data)
export const getAdminAgentDetailFailure = (error) => doResponseAction(ADMIN_AGENT_DETAILS_FAILURE, error)


export const getAgentSelectedById = (params) => doAction(AGENT_GET_SELECTED_BY_ID,params)


export const updateAgentAction = (params, callback, isAdmin) => {
    return {
        type: UPDATE_AGENT,
        params,
        callback,
        isAdmin
    }
}
export const updateAgentSuccessAction = (data) => doResponseAction(UPDATE_AGENT_SUCCESS, data);
export const updateAgentFailureAction = (data) => doResponseAction(UPDATE_AGENT_FAILURE, data);


export const getAgentAction = (params, callback, isAdmin) => {
    return {
        type: GET_AGENT,
        params,
        callback,
        isAdmin
    }
}
export const getAgentSuccessAction = (data) => doResponseAction(GET_AGENT_SUCCESS, data);
export const getAgentFailureAction = (data) => doResponseAction(GET_AGENT_FAILURE, data);


export const deleteAgentAction = (params, callback, isAdmin) => {
    return {
        type: DELETE_AGENT,
        params,
        callback,
        isAdmin
    }
}
export const deleteAgentSuccessAction = (data) => doResponseAction(DELETE_AGENT_SUCCESS, data);
export const deleteAgentFailureAction = (data) => doResponseAction(DELETE_AGENT_FAILURE, data);