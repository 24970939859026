import { Component } from "react";
import { withRouter } from "react-router-dom";
import Constants from "../utils/Constants";
import RoutePath from "../utils/routes/RoutePath";
import '@google/model-viewer/dist/model-viewer'

class AddProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomId: ''
        }
    }


    render() {
        const { isEdit, error, message, handlePageURL, handleProductSKU, uploadProductglb, handleProductName, handleSubmit, value, removeProducyImage, isShoe } = this.props
        return (
            <div className="content-part px-5 py-4">
                <div className="agent-details">
                    <div className="row m-0">
                        <div className="col-md-6" >
                            <div className="mt-3">
                                <p className="text-bold">Product SKU</p>
                                <input autoComplete="off" type="text" name='productSku' maxLength="20" className="form-control w-75" value={value?.productSku} placeholder="Enter Product SKU" onChange={(e) => handleProductSKU(e)} />
                                {error?.productSku && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.productSku}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Product Name</p>
                                <input autoComplete="off" type="text" name='productName' maxLength="100" className="form-control w-75" value={value?.productName} placeholder="Enter Product Name" onChange={(e) => handleProductName(e)} />
                                {error?.productName && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.productName}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Page URL</p>
                                <input autoComplete="off" type="text" name='pageURL' className="form-control w-75" value={value?.pageURL} placeholder="Enter Page URL" onChange={(e) => handlePageURL(e)} />
                                {error?.pageURL && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.pageURL}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Product type is shoe</p>
                                <div onChange={(e) => isShoe(e)}>
                                    <input type="radio" value={1} name="isShoe" checked={value?.isShoe == '1' ? true : false} /> Yes &nbsp;
                                    <input type="radio" value={0} name="isShoe" checked={value?.isShoe == '0' ? true : false} /> No &nbsp;
                                </div>
                                {error?.isShoe && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message.isShoe}</span>}
                            </div>
                            <div className="mt-3">
                                <p className="text-bold">Product GLB file</p>
                                {/* <div className="custom-file custom-file-naked d-block mb-1">
                                    {value?.productGLB && <div className="img-wraps">
                                        <model-viewer
                                            src={value?.productGLB}
                                            poster={require("../assets/img/ar.gif")}
                                            shadow-intensity="1" ar camera-controls touch-action="pan-y"
                                            // style={{ height: "230px" }}
                                            alt="A 3D model carousel"
                                            auto-rotate>
                                        </model-viewer>
                                    </div>
                                    }
                                    <input type="file" className="form-control-file" id="exampleFormControlFile2" onChange={(e) => uploadProductglb(e)} name="productGLB" accept="file/*" />
                                </div> */}
                                <div className="custom-file custom-file-naked d-block mb-1">
                                    {value?.base64file &&
                                        <div className="custom-file custom-file-naked d-block mb-1">
                                            <div className="img-wraps">
                                                <model-viewer
                                                    src={value?.base64file}
                                                    poster={require("../assets/img/ar.gif")}
                                                    shadow-intensity="1" ar camera-controls touch-action="pan-y"
                                                    // style={{ height: "230px" }}
                                                    alt="A 3D model carousel"
                                                    auto-rotate>
                                                </model-viewer>
                                                {value?.base64file &&
                                                    <span className="closes" title="Delete" onClick={(e) => removeProducyImage(e)}>×</span>
                                                }
                                            </div>
                                        </div>

                                    }
                                    <input type="file" className={`form-control-file${!value.isClick && isEdit ? 'removeNoFile' : ''}`} id="exampleFormControlFile2" onChange={(e) => uploadProductglb(e)} name="productGLB" accept="file/*" />
                                </div>
                                {error?.productGLB && <span style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{message?.productGLB}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 me-5 d-flex justify-content-start">
                        <button type="button" className="btn input-btn" onClick={() => this.props.history.push(RoutePath.VENDOR_AR_PRODUCTS)}>{Constants.BACK}</button>
                        <button type="button" className="btn input-btn mx-3" onClick={() => handleSubmit()}>{isEdit ? Constants.UPDATE : Constants.CREATE}</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(AddProduct)
