import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import RoutePath from '../utils/routes/RoutePath';
import { toast } from 'react-toastify';
import Constants from '../utils/Constants';
import { convertToMilliSeconds, isCharacterReg, minutesToHours, minutesToSec, msToHMS } from '../utils/validation';
import AddAddOn from '../component/AddAddOn';
const onlyNumberRegex = new RegExp(`^[A-Z0-9.]+$`);

class AdminAddOnAddOn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addonPlanName: '',
            addonPlanPrice: '',
            description: '',
            error: {},
            message: {},
            isActive: false,
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({ isLoading: true })
            this.props?.getAddOnDetails({ id: this.props.match.params.id }, (response) => {
                const data = response || {}
                this.setState({
                    isActive: data.isActive ? data.isActive : false,
                    addonPlanName: data?.addonPlanName,
                    addonPlanPrice: data?.addonPlanPrice,
                    description: data?.description,
                    totalMins: data?.totalMins,
                    isLoading: false
                })
            }, true)
        }
    }


    editAgent = (id) => {
        this.props.history.push("/admin/agent/" + id,)
    }


    updateAddOn = () => {
        // debugger
        const callback = (res) => {
            this.setState({ isLoading: false })
            const { response } = res
            if (res?.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.props.history.push(RoutePath.ADDON_LIST)
            } else {
                toast.error(response?.data?.response_message)
            }
        }
        let params = {
            totalMins: this.state?.totalMins,
            liveStreamHrs: minutesToHours(this.state?.totalMins),
            liveStreamMins: minutesToSec(this.state?.totalMins),
            totalSec: convertToMilliSeconds(this.state?.totalMins),
            addonPlanName: this.state?.addonPlanName,
            addonPlanPrice: this.state?.addonPlanPrice,
            description: this.state?.description,
            id: this.props.match.params.id,
        }
        if (!params.addonPlanName) {
            this.setState({ error: { addonPlanName: true }, message: { addonPlanName: Constants.PLEASE_ENTER_THE_NAME } })
        } else if (!isCharacterReg(params.addonPlanName)) {
            this.setState({ error: { addonPlanName: true }, message: { addonPlanName: Constants.NAME_REGX } })
        } else if (params.addonPlanName.trim().length < 3) {
            this.setState({ error: { addonPlanName: true }, message: { addonPlanName: Constants.NAME_LENGTH } })
        } else if (!params.addonPlanPrice || params.addonPlanPrice.length === 0) {
            this.setState({ error: { addonPlanPrice: true }, message: { addonPlanPrice: Constants.ENTER_PRICE } })
        } else if (params.addonPlanPrice == 0) {
            this.setState({ error: { addonPlanPrice: true }, message: { addonPlanPrice: Constants.PRICE_AMOUNT_ACCEPT } })
        } else if (!onlyNumberRegex.test(params.addonPlanPrice)) {
            this.setState({ error: { addonPlanPrice: true }, message: { addonPlanPrice: Constants.PRICE_ACCEPT } })
        } else if (!params.totalMins) {
            this.setState({ error: { totalMins: true }, message: { totalMins: Constants.TOTAL_MINS } })
        } else if (!onlyNumberRegex.test(params.totalMins)) {
            this.setState({ error: { totalMins: true }, message: { totalMins: Constants.TOTAL_MINS_ACCEPT } })
        } else {
            this.setState({ isLoading: true })
            if (params.id) {
                this.props.updateAddOnDetails(params, callback, true)
            } else {
                this.props.addAddOn(params, (res) => {
                    this.setState({ isLoading: false })
                    const { response } = res
                    if (res.response_code === 0) {
                        toast.success(Constants.UPDATED_SUCCESSFULLY)
                        this.props.history.push(RoutePath.ADDON_LIST)
                    } else {
                        toast.error(response?.data?.response_message)
                    }
                }, true)
            }
        }
    }

    handleUserName = (e) => {
        let fullname = e.target.value;
        if (!fullname || fullname.length === 0) {
            this.setState({ addonPlanName: fullname, error: { addonPlanName: true }, message: { addonPlanName: Constants.PLEASE_ENTER_THE_NAME } })
        } else if (!isCharacterReg(fullname)) {
            this.setState({ addonPlanName: fullname, error: { addonPlanName: true }, message: { addonPlanName: Constants.NAME_REGX } })
        } else if (fullname.trim().length < 3) {
            this.setState({ addonPlanName: fullname, error: { addonPlanName: true }, message: { addonPlanName: Constants.NAME_LENGTH } })
        } else {
            this.setState({ addonPlanName: fullname, error: { addonPlanName: false }, message: { addonPlanName: ' ' } })
        }
        this.setState({ addonPlanName: fullname })
    }
    handleDescriptions = (e) => {
        // let description = e.target.value;
        // if (!description || description.length === 0) {
        //     this.setState({ description, error: { description: true }, message: { description: Constants.ENTER_DESCRIPTION } })
        // } else {
        //     this.setState({ description, error: { description: true }, message: { description: '' } })
        // }
        this.setState({ description: e.target.value })
    }

    handleAgentLimit = (e) => {
        let agentLimit = e.target.value;
        if (!agentLimit || agentLimit.length === 0) {
            this.setState({ agentLimit: agentLimit, error: { agentLimit: true }, message: { agentLimit: Constants.AGENT_LIMIT } })
        } else if (!onlyNumberRegex.test(agentLimit)) {
            this.setState({ agentLimit: agentLimit, error: { agentLimit: true }, message: { agentLimit: Constants.AGENT_LIMIT_ACCEPT } })
        } else if (agentLimit <= 0) {
            this.setState({ agentLimit: agentLimit, error: { agentLimit: true }, message: { agentLimit: Constants.AGENT_LIMIT_VALUE } })
        } else {
            this.setState({ agentLimit: agentLimit, error: { agentLimit: false }, message: { agentLimit: '' } })
        }
        this.setState({ agentLimit: agentLimit })

    }

    handleAvailability = (e) => {
        let availability = e.target.value;
        if (!availability) {
            this.setState({ availability: availability, error: { availability: true }, message: { availability: Constants.AVAILABILITY } })
        } else {
            this.setState({ availability: availability, error: { availability: false }, message: { availability: '' } })
        }
        this.setState({ availability: availability })
    }

    handleCustomerFeedback = (e) => {
        let customerFeedback = e.target.value;
        if (!customerFeedback) {
            this.setState({ customerFeedback: customerFeedback, error: { customerFeedback: true }, message: { customerFeedback: Constants.CUSTOMER_FEEDBACK } })
        } else {
            this.setState({ customerFeedback: customerFeedback, error: { customerFeedback: false }, message: { customerFeedback: '' } })
        }
        this.setState({ customerFeedback: customerFeedback })
    }

    handleUsageReporting = (e) => {
        let usageReporting = e.target.value;
        if (!usageReporting) {
            this.setState({ usageReporting: usageReporting, error: { usageReporting: true }, message: { usageReporting: Constants.USEAGE_REPORT } })
        } else {
            this.setState({ usageReporting: usageReporting, error: { usageReporting: false }, message: { usageReporting: '' } })
        }
        this.setState({ usageReporting: usageReporting })
    }

    handleFullyCustomBranding = (e) => {
        let fullyCustomBranding = e.target.value;
        if (!fullyCustomBranding) {
            this.setState({ fullyCustomBranding: fullyCustomBranding, error: { fullyCustomBranding: true }, message: { fullyCustomBranding: 'ENTER FULLY BRANDING' } })
        } else {
            this.setState({ fullyCustomBranding: fullyCustomBranding, error: { fullyCustomBranding: false }, message: { fullyCustomBranding: '' } })
        }
        this.setState({ fullyCustomBranding: fullyCustomBranding })
    }

    handleliveStreamHours = (e) => {
        let liveStreamHours = e.target.value;
        if (!liveStreamHours || liveStreamHours.length === 0) {
            this.setState({ liveStreamHours: liveStreamHours, error: { liveStreamHours: true }, message: { liveStreamHours: Constants.LIVE_STREAM_HOURS } })
        } else if (!onlyNumberRegex.test(liveStreamHours)) {
            this.setState({ liveStreamHours: liveStreamHours, error: { liveStreamHours: true }, message: { liveStreamHours: Constants.LIVE_STREAM_HOURS_ACCEPT } })
        } else {
            this.setState({ liveStreamHours: liveStreamHours, error: { liveStreamHours: false }, message: { liveStreamHours: '' } })
        }
        this.setState({ liveStreamHours: liveStreamHours })

    }

    handleliveStreamMinutes = (e) => {
        let liveStreamMins = e.target.value;
        if (!liveStreamMins) {
            this.setState({ liveStreamMins: liveStreamMins, error: { liveStreamMins: true }, message: { liveStreamMins: Constants.LIVE_STREAM_MINIS } })
        } else if (!onlyNumberRegex.test(liveStreamMins)) {
            this.setState({ liveStreamMins: liveStreamMins, error: { liveStreamMins: true }, message: { liveStreamMins: Constants.LIVE_STREAM_MINIS_ACCEPT } })
        } else {
            this.setState({ liveStreamMins: liveStreamMins, error: { liveStreamMins: false }, message: { liveStreamMins: '' } })
        }
        this.setState({ liveStreamMins: liveStreamMins })
    }
    handleTotalMinutes = (e) => {
        let totalMins = e.target.value;
        if (!totalMins) {
            this.setState({ totalMins: totalMins, error: { totalMins: true, time: false }, message: { totalMins: Constants.TOTAL_MINS } })
        } else if (!onlyNumberRegex.test(totalMins)) {
            this.setState({ totalMins: totalMins, error: { totalMins: true, time: false }, message: { totalMins: Constants.TOTAL_MINS_ACCEPT } })
        } else {
            this.setState({ totalMins: totalMins, error: { totalMins: false }, message: { totalMins: '' } })
        }
        let convertToSec = convertToMilliSeconds(totalMins)
        let convertToTime = msToHMS(convertToSec)
        this.setState({ totalMins: totalMins, error: { time: true }, message: { time: convertToTime } })
    }

    handleLivechat = (e) => {
        let livechat = e.target.value;
        if (!livechat) {
            this.setState({ livechat: livechat, error: { livechat: true }, message: { livechat: Constants.LIVE_CHAT } })
        } else {
            this.setState({ livechat: livechat, error: { livechat: false }, message: { livechat: '' } })
        }
        this.setState({ livechat: livechat })
    }

    handlePaymentIntegration = (e) => {
        let paymentIntegration = e.target.value;
        if (!paymentIntegration || paymentIntegration.length === 0) {
            this.setState({ paymentIntegration: paymentIntegration, error: { paymentIntegration: true }, message: { paymentIntegration: Constants.PAYMENT_INTEGRATION } })
        } else {
            this.setState({ paymentIntegration: paymentIntegration, error: { paymentIntegration: false }, message: { paymentIntegration: '' } })
        }
        this.setState({ paymentIntegration: paymentIntegration })
    }

    handleCartGeneration = (e) => {
        let cartGeneration = e.target.value;
        if (!cartGeneration) {
            this.setState({ cartGeneration: cartGeneration, error: { cartGeneration: true }, message: { cartGeneration: Constants.CART_GENERATION } })
        } else {
            this.setState({ cartGeneration: cartGeneration, error: { cartGeneration: false }, message: { cartGeneration: '' } })
        }
        this.setState({ cartGeneration: cartGeneration })
    }

    handleAdditionalCharges = (e) => {
        let additionalCharges = e.target.value;
        if (!additionalCharges || additionalCharges.length === 0) {
            this.setState({ additionalCharges: additionalCharges, error: { additionalCharges: true }, message: { additionalCharges: "ENTER CART GENERATION" } })
        } else {
            this.setState({ additionalCharges: additionalCharges, error: { additionalCharges: false }, message: { additionalCharges: '' } })
        }
        this.setState({ additionalCharges: additionalCharges })
    }



    handleScreenSharing = (e) => {
        let screenSharing = e.target.value;
        if (!screenSharing) {
            this.setState({ screenSharing: screenSharing, error: { screenSharing: true }, message: { screenSharing: Constants.SCREEN_SHARING } })
        } else {
            this.setState({ screenSharing: screenSharing, error: { screenSharing: false }, message: { screenSharing: '' } })
        }
        this.setState({ screenSharing: screenSharing })
    }

    handlesubscriptionPrice = (e) => {
        let addonPlanPrice = e.target.value;
        if (!addonPlanPrice || addonPlanPrice.length === 0) {
            this.setState({ addonPlanPrice: addonPlanPrice, error: { addonPlanPrice: true }, message: { addonPlanPrice: Constants.ENTER_PRICE } })
        } else if (addonPlanPrice == 0) {
            this.setState({ addonPlanPrice: addonPlanPrice, error: { addonPlanPrice: true }, message: { addonPlanPrice: Constants.PRICE_AMOUNT_ACCEPT } })
        } else if (!onlyNumberRegex.test(addonPlanPrice)) {
            this.setState({ addonPlanPrice: addonPlanPrice, error: { addonPlanPrice: true }, message: { addonPlanPrice: Constants.PRICE_ACCEPT } })
        } else {
            this.setState({ addonPlanPrice: addonPlanPrice, error: { addonPlanPrice: false }, message: { addonPlanPrice: '' } })
        }
        this.setState({ addonPlanPrice: addonPlanPrice })

    }
    handleStatus = (e) => {
        this.setState({ isActive: e.target.value })
    }

    render() {
        const { isLoading } = this.state
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <AddAddOn
                            value={this.state}
                            handleSubmit={() => this.updateAddOn()}
                            handleUserName={(e) => { this.handleUserName(e) }}
                            handleTotalMinutes={(e) => this.handleTotalMinutes(e)}
                            handlesubscriptionPrice={(e) => { this.handlesubscriptionPrice(e) }}
                            handleDescriptions={(e) => { this.handleDescriptions(e) }}
                            message={this.state.message}
                            error={this.state.error}
                            isEdit={this.props.match.params.id}
                        />
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getAddOn: Actions.getAddOn(state),
    }
};
export default withRouter(connect(mapStateToProps, {
    addAgent: Actions.addAgentAction,
    updateAgent: Actions.updateAgentAction,
    getAgent: Actions.getAgentAction,
    addAgentDetails: Actions.addAgentDetails,
    addAddOn: Actions.addAddOn,
    updateAddOnDetails: Actions.updateAddOn,
    getAddOnDetails: Actions.getAddOnDetails
})(AdminAddOnAddOn));