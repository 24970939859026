import { takeLatest, put, all } from "redux-saga/effects";
import Actions from "../actions";
import { UPDATE_APPOINTMENT } from "../actions/UpdateAppointment";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doAgentPost } from "../../utils/service/index";
export function* updateAppointmentSaga({ params, callback }) {
    try {
        const response = yield doAgentPost(END_POINTS.UPDATE_APPOINTMENT, params);
        if (response) {
            yield put(Actions.updateAppointmentSuccess(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.updateAppointmentFailure(e));
        callback && callback(e);
    }
}
export default function* updateAppointmentListener() {
    yield all([
        takeLatest(UPDATE_APPOINTMENT, updateAppointmentSaga),
    ]);
}
