import Actions from "../actions";

const initialState = {
    isLoading: false,
    dashboardData: {},
    vendorDashboardData: {},
    error: false,
};

export const adminDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.DASHBOARD: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DASHBOARD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                dashboardData: !action.data?.isVendor ? action.data : state.dashboardData,
                vendorDashboardData: action.data?.isVendor ? action.data : state.vendorDashboardData
            };
        }
        case Actions.DASHBOARD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        default: {
            return state
        }
    }
};
