import { doAction, doResponseAction } from ".";

const GET_ROOM_LIST= 'GET_ROOM_LIST';
const GET_ROOM_LIST_SUCCESS = 'GET_ROOM_LIST_SUCCESS';
const GET_ROOM_LIST_FAILURE = 'GET_ROOM_LIST_FAILURE';

const GET_VENDOR_ROOM_DETAILS = 'GET_VENDOR_ROOM_DETAILS';
const VENDOR_ROOM_DETAIL_SUCCESS = 'VENDOR_ROOM_DETAIL_SUCCESS';
const VENDOR_ROOM_DETAIL_FAILURE = 'VENDOR_ROOM_DETAIL_FAILURE';

const ADD_VENDOR_ROOM = 'ADD_VENDOR_ROOM'
const ADD_VENDOR_ROOM_SUCCESS = 'ADD_VENDOR_ROOM_SUCCESS';
const ADD_VENDOR_ROOM_FAILURE = 'ADD_VENDOR_ROOM_FAILURE';

const UPDATE_VENDOR_ROOM = 'UPDATE_VENDOR_ROOM'
const UPDATE_VENDOR_ROOM_SUCCESS = ' UPDATE_VENDOR_ROOM_SUCCESS';
const UPDATE_VENDOR_ROOM_FAILURE = ' UPDATE_VENDOR_ROOM_FAILURE';

export {
    GET_ROOM_LIST,
    GET_ROOM_LIST_SUCCESS,
    GET_ROOM_LIST_FAILURE,

    GET_VENDOR_ROOM_DETAILS,
    VENDOR_ROOM_DETAIL_SUCCESS,
    VENDOR_ROOM_DETAIL_FAILURE,

    ADD_VENDOR_ROOM,
    ADD_VENDOR_ROOM_SUCCESS,
    ADD_VENDOR_ROOM_FAILURE,

    UPDATE_VENDOR_ROOM,
    UPDATE_VENDOR_ROOM_SUCCESS,
    UPDATE_VENDOR_ROOM_FAILURE 
}

export const getRoom = store => store['VENDOR_ROOM']

export const getRoomList = (params, callback) => doAction(GET_ROOM_LIST, params, callback);
export const getRoomListSuccess = data => doResponseAction(GET_ROOM_LIST_SUCCESS, data);
export const getRoomListFailure = data => doResponseAction(GET_ROOM_LIST_FAILURE, data);

export const getVendorRoomDetails = (params, callback) => doAction(GET_VENDOR_ROOM_DETAILS, params, callback);
export const getVendorRoomDetailSuccess = data => doResponseAction(VENDOR_ROOM_DETAIL_SUCCESS, data);
export const getVendorRoomDetailFailure = data => doResponseAction(VENDOR_ROOM_DETAIL_FAILURE, data);

export const addVendorRoom = (params, callback) => doAction(ADD_VENDOR_ROOM, params, callback);
export const addVendorRoomSuccess = data => doResponseAction(ADD_VENDOR_ROOM_SUCCESS, data);
export const addVendorRoomFailure = data => doResponseAction(ADD_VENDOR_ROOM_FAILURE, data);

export const updateVendorRoom = (params, callback) => doAction(UPDATE_VENDOR_ROOM, params, callback);
export const updateVendorRoomSuccess = data => doResponseAction(UPDATE_VENDOR_ROOM_SUCCESS, data);
export const updateVendorRoomFailure = data => doResponseAction(UPDATE_VENDOR_ROOM_FAILURE, data);