import { Component } from "react";


class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomId: ''
    }
  }
  render() {
    return (
      <div className="container cs_bg text-center">
        <div className="d-flex justify-content-center m-25vh">
          <div className="w-box">
            <img src={require('../assets/img/customer.png')} className="w-100" />
            <p className="font-30 pb-4">Show your card Here</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Welcome;
