import Actions from "../actions";

const initialState = {
  data: undefined,
  isLoading: false,
  error: undefined,
  addOnDetails: null,
  addOns: null,
  addOnActiveList: null,
  paymentVendorAddon:null
};

export const AddOnReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ADDON_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.GET_ADDON_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
        addOns: action.data,
        error: false,
      };
    }
    case Actions.GET_ADDON_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case Actions.GET_ADDON_DETAILS: {
      return {
        ...state,
        isLoading: true,
        error: action.error,
      };
    }
    case Actions.GET_ADDON_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        addOnDetails: action.data,
        error: action.error,
      };
    }
    case Actions.GET_ADDON_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        addOnDetails: {},
        error: action.error,
      };
    }
    case Actions.ADD_ADDON: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.ADD_ADDON_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case Actions.ADD_ADDON_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case Actions.GET_ADDON_ACTIVE_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.PAYMENT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.PAYMENT_SUCCESS: {
      return {
        ...state,
        paymentVendorAddon:action.data,
        isLoading: false,
      };
    }
    case Actions.PAYMENT_FAILURE: {
      return {
        ...state,
        paymentVendorAddon:null,
        isLoading: false,
      };
    }
    case Actions.GET_ADDON_ACTIVE_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case Actions.GET_ADDON_ACTIVE_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        addOnActiveList: action.data,
        error: false,
      };
    }
    case Actions.GET_ADDON_ACTIVE_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export const addOnList = (state) => state?.ADDON_DETAILS?.data;
export const addOnListLoader = (state) => state?.ADDON_DETAILS?.isLoading;
export const addOnActiveList = (state) => state?.ADDON_DETAILS?.addOnActiveList;
export const getPaymnetData = (state) => state?.ADDON_DETAILS?.paymentVendorAddon;