import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import AppLoader from '../utils/AppLoader';
import PaginationComponenent from '../component/Pagination';
import Constants, { LIMIT, THREEDSCRIPT } from '../utils/Constants';
import { toast } from 'react-toastify';
import RoutePath from '../utils/routes/RoutePath';
import ARProductsList from '../component/ARProductsList';
import VendorService from '../component/VendorService';

class VendorServiceList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageNumber: 1,
            totalItem: '',
            isVendor: false,
            isCopied: false
        }
    }
    componentDidMount() {
        const Agent = this.props.match.path.split('/')
        if (Agent[2] === "service" && Agent[1] === "admin") {
            this.setState({ isVendor: true })
        } else {
            this.setState({ isVendor: false })
        }
        this.getVendorAllServiceList(false)
    }

    getVendorAllServiceList = (isUpdate) => {
        let params = {
            limit: LIMIT,
            pageNumber: this.state.pageNumber - 1
        }
        const callback = (response) => {
            if (response) {
                this.setState({ totalItem: response.response.totalItems })
            }
        }
        this.props.getVendorServiceList(params, callback)
    }
    onChangePagination = (pageNumber) => {
        let params = {
            pageNumber: (pageNumber - 1),
            limit: LIMIT
        }
        this.props.getVendorServiceList(params, '', false)
        this.setState({ pageNumber: pageNumber })
    }
    updateStatus = (id, active) => {
        const updateParams = {
            id,
            isActive: active ? 0 : 1
        }
        const callback = (response) => {
            if (response.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.getVendorAllServiceList(true)
            }
            else {
                toast.error(response.response_message)
            }
        }
        this.props.updateVendorService(updateParams, callback, false)
    }
    addVendorService = () => {
        this.props.history.push(RoutePath.VENDOR_SERVICE_ADD)
    }
    render() {
        const { pageNumber, isVendor } = this.state;
        const { getVendorRoomService = {} } = this.props
        const { isLoading, vendorServiceListData } = getVendorRoomService
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <div className='addButton'>
                            <div className="add-btn">
                                <button className="btn add-item" type="button" onClick={() => this.addVendorService()}><i className="fa-solid fa-plus me-3"></i>Add Service</button>
                            </div>
                        </div>
                        <VendorService serviceList={vendorServiceListData?.services} updateStatus={(id, active) => this.updateStatus(id, active)}
                            isVendor={isVendor}
                            currentPage={pageNumber} />
                        <div className='page-content'>
                            {
                                vendorServiceListData?.totalItems > (LIMIT - 1) ? <PaginationComponenent currentPage={pageNumber} countValue={vendorServiceListData?.totalItems} onChangeHandle={this.onChangePagination} /> : ''
                            }
                        </div>
                    </div>
                </div>

                {isLoading && <AppLoader />}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getVendorRoomService: Actions.getVendorRoomService(state)
    }
};
export default withRouter(connect(mapStateToProps, {
    getVendorServiceList: Actions.getVendorServiceList,
    updateVendorService: Actions.updateVendorService
})(VendorServiceList
));