import { doAction, doResponseAction } from ".";

const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
const GET_PRODUCT_LIST_FAILURE = 'GET_PRODUCT_LIST_FAILURE';

const CREATE_PRODUCT = 'CREATE_PRODUCT';
const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';

const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';

const PRODUCT_DETAILS = 'PRODUCT_DETAILS';
const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
const PRODUCT_DETAILS_FAILURE = 'PRODUCT_DETAILS_FAILURE';

const GET_AR_PRODUCT_LIST = 'GET_AR_PRODUCT_LIST';
const GET_AR_PRODUCT_LIST_SUCCESS = 'GET_AR_PRODUCT_LIST_SUCCESS';
const GET_AR_PRODUCT_LIST_FAILURE = 'GET_AR_PRODUCT_LIST_FAILURE';

const GET_ALL_VENDOR_LIST = "GET_ALL_VENDOR_LIST";
const GET_ALL_VENDOR_LIST_SUCCESS = "GET_ALL_VENDOR_LIST_SUCCESS";
const GET_ALL_VENDOR_LIST_FAILURE = "GET_ALL_VENDOR_LIST_FAILURE";

export {
    GET_PRODUCT_LIST,
    GET_PRODUCT_LIST_SUCCESS,
    GET_PRODUCT_LIST_FAILURE,

    CREATE_PRODUCT,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILURE,
    
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,

    PRODUCT_DETAILS,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAILURE,

    GET_AR_PRODUCT_LIST,
    GET_AR_PRODUCT_LIST_SUCCESS,
    GET_AR_PRODUCT_LIST_FAILURE,

    GET_ALL_VENDOR_LIST,
    GET_ALL_VENDOR_LIST_SUCCESS,
    GET_ALL_VENDOR_LIST_FAILURE
}

export const getProduct = store => store['AR_PRODUCTS']


export const getProductList = (params, callback, isAdmin) => doAction(GET_PRODUCT_LIST, params, callback, isAdmin);
export const getProductListSuccess = data => doResponseAction(GET_PRODUCT_LIST_SUCCESS, data);
export const getProductListFailure = data => doResponseAction(GET_PRODUCT_LIST_FAILURE, data);

export const createProduct = (params, callback, isAdmin) => doAction(CREATE_PRODUCT, params, callback, isAdmin);
export const createProductSuccess = data => doResponseAction(CREATE_PRODUCT_SUCCESS, data);
export const createProductFailure = data => doResponseAction(CREATE_PRODUCT_FAILURE, data);

export const updateProduct = (params, callback, isAdmin) => doAction(UPDATE_PRODUCT, params, callback, isAdmin);
export const updateProductSuccess = data => doResponseAction(UPDATE_PRODUCT_SUCCESS, data);
export const updateProductFailure = data => doResponseAction(UPDATE_PRODUCT_FAILURE, data);

export const productDetails = (params, callback, isAdmin) => doAction(PRODUCT_DETAILS, params, callback, isAdmin);
export const productDetailsSuccess = data => doResponseAction(PRODUCT_DETAILS_SUCCESS, data);
export const productDetailsFailure = data => doResponseAction(PRODUCT_DETAILS_FAILURE, data);

export const getArProductList = (params, callback, isAdmin) => doAction(GET_AR_PRODUCT_LIST, params, callback, isAdmin);
export const getArProductListSuccess = data => doResponseAction(GET_AR_PRODUCT_LIST_SUCCESS, data);
export const getArProductListFailure = data => doResponseAction(GET_AR_PRODUCT_LIST_FAILURE, data);

export const getAllVendorList = (params, callback, isAdmin) => doAction(GET_ALL_VENDOR_LIST, params, callback, isAdmin)
export const getAllVendorListSuccess = data => doResponseAction(GET_ALL_VENDOR_LIST_SUCCESS, data)
export const getAllVendorListFailure = data => doResponseAction(GET_ALL_VENDOR_LIST_FAILURE, data)