import React, { Component } from 'react';

class AudioJoiner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      joinedAudioUrl: null,
      error: null,
    };
    this.audioRef = React.createRef();
  }

  componentDidMount() {
    this.joinAudio();
  }

  joinAudio = async () => {
    const { audioUrl1, audioUrl2 } = this.props;
    try {
      const audioBuffers = await Promise.all([this.loadAudioBuffer(audioUrl1), this.loadAudioBuffer(audioUrl2)]);
      const joinedBuffer = this.concatenateBuffers(audioBuffers);
      const joinedBlob = new Blob([joinedBuffer], { type: 'audio/mpeg' });

      const joinedAudioUrl = URL.createObjectURL(joinedBlob);
      this.setState({ joinedAudioUrl, error: null });
    } catch (error) {
      console.error('Error joining audio:', error);
      this.setState({ error: error.message });
    }
  };

  loadAudioBuffer = async (audioUrl) => {
    const response = await fetch(audioUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch audio from ${audioUrl}: ${response.status} ${response.statusText}`);
    }
    const arrayBuffer = await response.arrayBuffer();
    return arrayBuffer;
  };

  concatenateBuffers = (audioBuffers) => {
    const totalLength = audioBuffers.reduce((acc, buffer) => acc + buffer.byteLength, 0);
    const joinedBuffer = new ArrayBuffer(totalLength);
    const result = new Uint8Array(joinedBuffer);
    let offset = 0;

    audioBuffers.forEach((buffer) => {
      result.set(new Uint8Array(buffer), offset);
      offset += buffer.byteLength;
    });

    return joinedBuffer;
  };
  replayAudio = () => {
    if (this.audioRef.current) {
      this.audioRef.current.currentTime = 0;
      this.audioRef.current.play();
    }
  };
  render() {
    const { joinedAudioUrl, error } = this.state;
    const { audioUrl1, audioUrl2, showControls } = this.props;

    if (error) {
      return <div>Error joining audio: {error}</div>;
    }

    return (
      <div className="my-4 text-center">
        <div className="audio-controls-container d-flex align-items-center gap-2">
          <audio ref={this.audioRef} src={joinedAudioUrl} controls={showControls} style={{ width: '100%' }}>
            <source src={audioUrl1} type="audio/mpeg" />
            <source src={audioUrl2} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      </div>
    );
  }
}

export default AudioJoiner;
