export default {
  HOME_SCREEN_PATH: "/home",
  VERIFICATION_SCREEN_PATH: "/verification",
  WELCOME_SCREEN_PATH: "/welcome",
  DASHBOARD_SCREEN_PATH: "/dashboard",

  FORGET_PASSWORD: "/forgetpassword",
  ACCESS_DENIED: "/accessdenied",
  SET_PASSWORD: "/setpassword/:vendorId",
  PAGE_NOT_FOUND: "/pageNotFound",
  AR_PRODUCT_RENDER: "/products/:productId/:sku",
  AR_PRODUCT_MODEL: "/arproducts/:productId/:sku",

  //agent
  LOGIN_SCREEN_PATH: "/",
  STOREMANAGER_SCREEN_PATH: "/store",
  AGENT_VERIFICATION_SCREEN_PATH: "/manager/:roomId",
  KIOSK_AGENT_VERIFICATION_SCREEN_PATH: "/kiosk_srm/manager/:roomId",

  //customer
  CUSTOMER_SCREEN_PATH: "/custom/:vendorId",
  FEEDBACK_SCREEN_PATH: "/customer/feedback",
  CUSTOMER_VERIFICATION_SCREEN_PATH: "/customer/:roomId",
  SRM_HEAD_VERIFICATION_SCREEN_PATH: "/srm_head/:roomId",

  //SRM_IP
  SRM_IP_CUSTOMER_SCREEN_PATH: "/SRM_IP/custom/:roomId",

  //kiosk
  KIOSK_SCREEN_PATH: "/kiosk_srm/:vendorId",
  KIOSK_FEEDBACK_SCREEN_PATH: "/kiosk_srm/customer/feedback",
  KIOSK_GENERAL_CUSTOMER_FEEDBACK_SCREEN_PATH:
    "/kiosk_srm/general/customer/feedback",
  KIOSK_CUSTOMER_VERIFICATION_SCREEN_PATH: "/kiosk_srm/customer/:roomId",

  //admin
  ADMIN_LOGIN_SCREEN_PATH: "/admin",
  ADMIN_AGENT_LIST: "/admin/agent/list",
  ADMIN_UPDATE_AGENT_LIST: "/admin/agent/edit/:id",
  ADMIN_ADD_AGENT_LIST: "/admin/agent/add",
  ADMIN_VENDOR_LIST: "/admin/vendor/list",
  ADD_SUBSCRIPTION: "/admin/subscription/add",
  UPDATE_SUBSCRIPTION: "/admin/subscription/edit/:id",
  VIEW_SUBSCRIPTION: "/admin/subscription/view/:id",
  SUBSCRIPTION_LIST: "/admin/subscription/list",
  ADDON_LIST: "/admin/addon/list",
  ADD_ADDON: "/admin/addon/add",
  UPDATE_ADDON: "/admin/addon/edit/:id",
  VIEW_ADDON: "/admin/addon/view/:id",
  ADMIN_DASHBOARD_SCREEN: "/admin/dashboard",
  ADMIN_VENDOR_UPDATE_: "/admin/vendor/update/",
  ADMIN_VENDOR_UPDATE: "/admin/vendor/update/:id",
  ADD_ADMIN_VENDOR: "/admin/vendor/add",
  VIEW_ADMIN_VENDOR: "/admin/vendor/view",
  ADMIN_AGENT_VIEW: "/admin/agent/view",
  ADMIN_AR_PRODUCTS: "/admin/products/list",
  ADMIN_AR_PRODUCTS_ADD: "/admin/products/add",
  ADMIN_AR_PRODUCT_VIEW: "/admin/products/view/:id",
  ADMIN_AR_PRODUCT_EDIT: "/admin/products/edit/:id",

  //vendor
  VENDOR_LOGIN_SCREEN_PATH: "/vendor",
  VENDOR_AGENT_LIST: "/vendor/agent/list",
  VENDOR_UPDATE_AGENT_LIST: "/vendor/agent/edit/:id",
  VENDOR_ADD_AGENT_LIST: "/vendor/agent/add",
  VENDOR_ADDON_LIST: "/vendor/addon/list",
  VENDOR_VIEW_ADDON: "/vendor/addon/view/:id",
  VENDOR_DASHBOARD_SCREEN: "/vendor/dashboard",
  VENDOR_AGENT_VIEW: "/vendor/agent/view",
  VENDOR_WEBHOOK_CONFIG: "/vendor/webhook/config",
  VENDOR_PROFILE_DETAILS: "/vendor/profile/details",
  VENDOR_PROFILE_UPDATE: "/vendor/profile/update",
  VENDOR_AR_PRODUCTS: "/vendor/products/list",
  VENDOR_FEEDBACK: "/vendor/feedback/list",
  VENDOR_AR_PRODUCTS_ADD: "/vendor/products/add",
  VENDOR_AR_PRODUCT_VIEW: "/vendor/products/view/:id",
  VENDOR_AR_PRODUCT_EDIT: "/vendor/products/edit/:id",
  VENDOR_VB_LIST: "/vendor/virtualBackground/list",
  VENDOR_VB_ADD: "/vendor/virtualBackground/add",
  VENDOR_VB_VIEW: "/vendor/virtualBackground/view/:id",
  VENDOR_VB_EDIT: "/vendor/virtualBackground/edit/:id",
  VENDOR_SERVICES: "/vendor/services",
  VENDOR_ROOM: "/vendor/room",
  VENDOR_ROOM_VIEW: "/vendor/room/view/:id",
  VENDOR_ROOM_ADD: "/vendor/room/add",
  VENDOR_ROOM_EDIT: "/vendor/room/edit/:id",
  VENDOR_SERVICE: "/vendor/service/list",
  VENDOR_SERVICE_ADD: "/vendor/service/add",
  VENDOR_SERVICE_VIEW: "/vendor/service/view/:id",
  VENDOR_SERVICE_EDIT: "/vendor/service/edit/:id",
  VENDOR_ROOM_SERVICE: "/vendor/room/service/list",
  VENDOR_ROOM_SERVICE_ADD: "/vendor/room/service/add",
  VENDOR_ROOM_SERVICE_EDIT: "/vendor/room/service/edit/:id",
  VENDOR_ROOM_SERVICE_VIEW: "/vendor/room/service/view/:id",

  //voice recorder
  VOICE_RECORDER: "/babycry",
};
