import React, { Component } from "react";
import RoutePath from "../utils/routes/RoutePath";
import { withRouter } from "react-router-dom";
import Constants, { LIMIT } from "../utils/Constants";
import NoDataFound from "./NoDataFound";
import { connect } from "react-redux";
import { getPaymnetData } from '../redux/reducer/AddOnReducer ';

class VendorRoomService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomId: '',
            isLoading: false,
            stylesLoaded: false
        }
    }

    addAgent = () => {
        this.props.history.push(RoutePath.ADMIN_ADD_AGENT_LIST)
    }

    editRoomService = (id) => {
        this.props.history.push("/vendor/room/service/edit/" + id)
    }
    viewRoomService = (id) => {
        const { isVendor } = this.props
        if (isVendor) {
            this.props.history.push("/admin/products/view/" + id)
        } else {
            this.props.history.push("/vendor/room/service/view/" + id)
        }
    }
    loadScript = (url, status) => {
        let head = document.getElementsByTagName("head")[0]
        let body = document.getElementsByTagName("body")[0]
        let script
        if (status) {
            script = document.createElement("script")
            script.type = "text/javascript";
            script.src = url;
            head.appendChild(script);
        } else {
            var div = body.querySelector('container p-3');
            var scripts = head.querySelector('https://dev-meet.proglint.com/my-script.js', 'p');
            if (scripts.length > 0) {
                head.removeChild(scripts[0]);
                body.removeChild(div[0]);
            }
        }
    }
    componentDidMount() {
        // this.loadScript("https://dev-meet.proglint.com/my-script.js", true)
    }
    handleStyle() {
        this.setState({ stylesLoaded: !this.state.stylesLoaded })
        if (!this.state.stylesLoaded) {
            this.loadScript("https://dev-meet.proglint.com/my-script.js", true)
        } else {
            this.loadScript("https://dev-meet.proglint.com/my-script.js", false)
        }
    }
    render() {
        const { roomServiceList, updateStatus, isVendor } = this.props
        return (
            <React.Fragment>
                <div className="admin_table">
                    <div role="region" aria-label="data table" tabIndex="0" className="candidate_primary">
                        <table className="dashboard_table">
                            <thead>
                                <tr>
                                    <th className="pin">{Constants.ID}</th>
                                    <th>Room Number</th>
                                    <th>Room Name</th>
                                    <th>Service Name</th>
                                    <th>Inpatient Agent</th>
                                    <th>{Constants.ACTION}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {roomServiceList?.length > 0 ?
                                    roomServiceList?.map((roomService, index) => {
                                        return <tr key={index}>
                                            <td>{((this.props.currentPage - 1) * LIMIT) + (index + 1)}</td>
                                            <td>{roomService?.SrmIpRoom?.patientRoomId}</td>
                                            <td>{roomService?.SrmIpRoom?.patientRoomName}</td>
                                            <td>{roomService?.SrmIpServiceMaster?.serviceName}</td>
                                            <td>{roomService?.User?.email}</td>
                                            <td>
                                                {!isVendor && <i className="fa-solid fa-pen-to-square updateIcon edit-icon" onClick={() => this.editRoomService(roomService?.id)} />}
                                                {/* <i className={roomService?.isActive === 1 ? "fa-solid fa-toggle-on toggle-icon ms-2 edit-icon" : "fa-solid fa-toggle-off toggle-icon ms-2 edit-icon"} onClick={() => updateStatus(roomService?.id, roomService.isActive)} />* */}
                                                <i className="fa-solid fa-eye ms-2 edit-icon" onClick={() => this.viewRoomService(roomService?.id)}></i>
                                            </td>
                                        </tr>
                                    }) : <NoDataFound />
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export const mapStateToProps = (state) => {
    return {
        isLoading: getPaymnetData(state)
    }
};
export default withRouter(connect(mapStateToProps, {
})(VendorRoomService));
