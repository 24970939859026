import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AgentListScreen from '../component/AgentListScreen';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import Actions from '../redux/actions';
import RoutePath from '../utils/routes/RoutePath';
import PaginationComponenent from '../component/Pagination';
import Constants, { LIMIT } from '../utils/Constants';
import AppLoader from '../utils/AppLoader';
import NoDataFound from '../component/NoDataFound';
import { toast } from 'react-toastify';

class AgentList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageNumber: 1,
            totalItem: '',
            isAgent: false
        }
    }
    componentDidMount() {
        const Agent = this.props.match.path.split('/')
        if (Agent[2] === "agent" && Agent[1] === "admin") {
            this.setState({ isAgent: true })
        } else {
            this.setState({ isAgent: false })
        }
        this.getAgentList()
    }

    getAgentList = (isUpdate) => {
        let params = {
            limit: LIMIT,
            pageNumber: isUpdate ? this.state.pageNumber - 1 : 0
        }
        this.props.agentDetailsList(params, "", (this.props.location.pathname === RoutePath.ADMIN_AGENT_LIST))
    }

    editAgent = (id) => {
        this.props.addAgentDetails(undefined)
        // this.getAgentList()
        this.props.history.push(this.props.location.pathname === RoutePath.ADMIN_AGENT_LIST ? "/admin/agent/edit/" + id : "/vendor/agent/edit/" + id)
    }

    updateActive = (e, obj) => {
        const callback = (response) => {
            if (response.response_code === 0) {
                toast.success(Constants.UPDATED_SUCCESSFULLY)
                this.getAgentList(true)
            }
            else {
                toast.error(response.response_message)
            }
        }
        let params = {
            id: obj.id,
            isActive: e
        }

        this.props.updateAgent(params, callback, (this.props.location.pathname === RoutePath.ADMIN_AGENT_LIST))
    }

    addAgent = () => {
        this.props.addAgentDetails(undefined)
        this.props.history.push(this.props.location.pathname === RoutePath.ADMIN_AGENT_LIST ? RoutePath.ADMIN_ADD_AGENT_LIST : RoutePath.VENDOR_ADD_AGENT_LIST)
    }
    onChangePagination = (pageNumber) => {
        let params = {
            pageNumber: (pageNumber - 1),
            limit: LIMIT
        }
        const callBack = (response) => {
            this.setState({ callerList: response.response })
        }
        this.props.agentDetailsList(params, callBack, (this.props.location.pathname === RoutePath.ADMIN_AGENT_LIST))
        this.setState({ pageNumber: pageNumber })
    }
    render() {
        const { pageNumber, isAgent } = this.state;
        const { getAgentDetails = {} } = this.props
        const { agentList = {}, vendorAgentList = {}, isLoading } = getAgentDetails
        const { agentLists = [] } = agentList
        const { agents = [], isVendor, totalItems = 0 } = vendorAgentList
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <div className='addButton'>
                            <div className="add-btn">
                                <button className="btn add-item" type="button" onClick={() => this.addAgent()}><i className="fa-solid fa-plus me-3"></i>{Constants.ADD_NEW_AGENT}</button>
                            </div>
                        </div>
                        <AgentListScreen
                            agentLists={isAgent ? agentLists : agents}
                            editAgent={(id) => this.editAgent(id)}
                            updateActive={(value, index) => this.updateActive(value, index)}
                            isAgent={isAgent}
                            currentPage={pageNumber}
                        />
                        <div className='page-content'>
                            {
                                isAgent ? (getAgentDetails?.agentList?.totalItems > (LIMIT - 1) &&
                                    <PaginationComponenent currentPage={pageNumber} countValue={isAgent ? agentList?.totalItems : totalItems} onChangeHandle={this.onChangePagination} />)
                                    :
                                    (totalItems > (LIMIT - 1) &&
                                        <PaginationComponenent currentPage={pageNumber} countValue={isAgent ? agentList?.totalItems : totalItems} onChangeHandle={this.onChangePagination} />)
                            }
                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}

            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getAgentDetails: Actions.getAgentDetails(state),
    }
};
export default withRouter(connect(mapStateToProps, {
    agentDetailsList: Actions.agentListAction,
    addAgentDetails: Actions.addAgentDetails,
    updateAgent: Actions.updateAgentAction,
})(AgentList));