import io from 'socket.io-client';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Actions from '../redux/actions/index';
import Constants, { CONSTANTS, SOCKET_IO_PATH, SOCKET_URL } from '../utils/Constants';
import CallNotification from '../component/CallNotification'
import moment from 'moment'
// import attentionbell from "../assets/sound/attentionbell.wav"
import classicTone from '../assets/sound/classic-tone.mp3'
import businessTone from '../assets/sound/business_ring_iphone.mp3'
import Alert from '../component/alert/alert';
import ReactStars from "react-rating-stars-component";
import PaginationComponenent from '../component/Pagination';
import { LIMIT } from '../utils/Constants';
import Avatar from 'react-avatar';
import AppLoader from '../utils/AppLoader';
import { msToHMSShort } from '../utils/validation';
import { Tooltip } from 'react-tooltip';
import dangerSign from '../assets/img/danger-sign.svg'
import missedCall from '../assets/img/missed-call.svg'
class StoreManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callerList: [],
      curDateTime: new Date().toLocaleString(),
      tab: 0,
      callNotification: false,
      callDetails: [],
      latestCall: {},
      logoutModal: false,
      historypageNumber: 1,
      callPageNumber: 1,
      callMute: false,
      isLoading: false,
      isSrmIpAgent: 0
    }
  }
  audio = new Audio(classicTone);
  componentDidUpdate() {
    const { callNotification } = this.state;
    if (callNotification) {
      setTimeout(() => {
        this.setState({ callNotification: false, callMute: false })
        this.audio.pause();
        this.audio.currentTime = 0;
      }, 20000)//20 sec
    }
  }

  componentDidMount() {
    const { getLoginDetails = {} } = this.props
    const { tokenDetails = {} } = getLoginDetails
    const isSrmIpAgent = tokenDetails?.userDetails?.VendorAgent?.isSrmIpAgent
    let params = {
      limit: LIMIT,
      pageNumber: this.state.callPageNumber - 1,
      isSrmIpAgent: isSrmIpAgent
    }
    const callBack = (response) => {
      // this.props.appendQueueList(response?.response?.calls)
      this.setState({ callerList: response?.response, isSrmIpAgent: isSrmIpAgent })
    }
    this.props.customerCallListAction(params, callBack)
    if (window.location.hostname === 'localhost') {
      this.socketURL = SOCKET_URL;
    } else {
      this.socketURL = SOCKET_URL;
    }
    const socket = io(this.socketURL, { path: SOCKET_IO_PATH, transports: ['websocket'] });
    this.socket = socket;

    socket.on(`call_request/${this.props.getLoginDetails?.tokenDetails?.user?.id}`, (response) => {
      // const array = this.props.getQueueList
      // array.push(response?.data)
      // this.props.appendQueueList(array)
      this.handleCallQueue()
      this.setState({ callNotification: true, callDetails: [...this.state.callDetails, response.data] })
      this.audio.play();
      // setTimeout(() => {
      //   this.setState({ callNotification: false, callMute: false })
      //   this.audio.pause();
      //   this.audio.currentTime = 0;
      // }, 20000)//20 sec
    })
    socket.on(`call_decline/${this.props.isCustomer === 'customer' ? 'customer' : 'store'}`, (response) => {
      let { callDetails } = this.state;
      const roomId = response?.roomId
      callDetails?.splice(roomId, 1)
      const callerList = this.props.getQueueList;
      this.handleCallQueue()
      callerList?.findIndex(o => o?.User?.id === response?.data?.User?.id)
      // if (id !== -1) {
      // callerList.splice(id, 1);
      this.setState({ callNotification: false, callMute: false })
      // this.props.appendQueueList(callerList)
      this.audio.pause();
      this.audio.currentTime = 0;
      // }
    })

    socket.on('remove_calls', (response) => {
      let { callDetails } = this.state;
      const roomId = response?.roomId
      callDetails?.splice(roomId, 1)
      this.audio.pause();
      this.audio.currentTime = 0;
      const callerList = this.props.getQueueList
      let RemainsCallerList = [];
      callerList?.filter((item) => {
        if (response.roomId !== item.roomId) {
          return RemainsCallerList.push(item)
        }
        return ''
      })
      this.setState({ callNotification: false, callMute: false })
      this.handleCallQueue()
      // this.props.appendQueueList(RemainsCallerList)
    })
  }

  componentWillUnmount() {
    if (this.socket) this.socket.disconnect();
  }
  openLink = (link) => {
    window.open(link, "_blank")
  }

  joinNow = (call, index, notification) => {
    let { callDetails } = this.state;
    const callerList = this.props.getCustomerCallList?.customerCallList?.calls
    let params = {
      roomId: notification ? call?.roomId : callerList[index]?.roomId,
      callStatus: 2,
      isActive: true,
      callStartTime: new Date().getTime(),
      isSrmIpAgent: this.state.isSrmIpAgent
    }
    let result = callerList?.find(item => item?.roomId == params?.roomId)
    localStorage.setItem("srm_ip_room", result?.SrmIpRoomServiceMapping?.SrmIpRoom?.encryptRoomId)
    const acceptCallback = (response) => {
      if (response.response_code === 0) {
        const roomId = response?.response?.roomId
        this.handleCallQueue()
        // array.splice(index, 1)
        // this.props.appendQueueList(array)
        callDetails?.splice(roomId, 1)
        this.setState({ callNotification: false })
        this.audio.pause();
        this.audio.currentTime = 0;
        if (response?.response?.isCallFromKiosk) {
          this.openLink('/kiosk_srm/manager/' + roomId)
        } else {
          this.openLink('/manager/' + roomId)
        }
      } else {
        this.handleCallQueue()
        this.setState({ callNotification: false })
        this.audio.pause();
        this.audio.currentTime = 0;
      }
    }
    this.props.updateAppointment(params, acceptCallback)
    if (callerList.length >= 1) {
      return this.removeCalls(call)
    }
  }

  removeCalls = (call) => {
    let params = {
      roomId: call?.roomId,
      name: call?.User?.fullName,
      date: new Date(),
      isSrmIpAgent: this.state.isSrmIpAgent
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ url: `remove_calls`, response: params, triggerFrom: "front End" })
    };
    fetch(`${this.socketURL}/api/socket`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data)
      });

  }

  rejectNow = (call, index, notification) => {
    let { callDetails } = this.state;
    const callerList = this.props.getCustomerCallList?.customerCallList?.calls
    let params = {
      roomId: notification ? call.roomId : callerList[index]?.roomId,
      callStatus: 3,
      isActive: false,
      callEndTime: new Date().getTime(),
      isSrmIpAgent: this.state.isSrmIpAgent
    }


    const declinecallback = (response) => {
      this.setState({ isLoading: false })
      if (response.response_code === 0) {
        const roomId = response?.response?.roomId
        const id = callerList.findIndex(o => o?.User?.id === response?.response?.User?.id)
        if (id !== -1) {
          // callerList.splice(id, 1);
        }
        callDetails?.splice(roomId, 1)
        this.handleCallQueue()
        this.audio.pause();
        this.audio.currentTime = 0;
        this.setState({ callNotification: false })
        // this.props.appendQueueList(callerList)
      } else {
        this.handleCallQueue()
        this.audio.pause();
        this.audio.currentTime = 0;
        this.setState({ callNotification: false })
      }
    }
    this.setState({ isLoading: true })
    this.props.updateAppointment(params, declinecallback)
  }


  callMute = () => {
    this.setState({ callMute: !this.state.callMute })
    if (!this.state.callMute) {
      this.audio.pause();
      this.audio.currentTime = 0;
    } else {
      this.audio.play();
    }
  }


  handleCallQueue = () => {
    let params = {
      limit: LIMIT,
      pageNumber: this.state.callPageNumber - 1,
      isSrmIpAgent: this.state.isSrmIpAgent
    }
    this.setState({ tab: 0, isLoading: true })
    const callBack = (response) => {
      this.setState({ callerList: response.response, isLoading: false })
    }
    this.props.customerCallListAction(params, callBack)
  }

  //Function trigger the call history api and affected the isloading and tab state set the respones in the reducer
  handleHistory = () => {
    let params = {
      pageNumber: this.state.historypageNumber - 1,
      limit: LIMIT,
      isSrmIpAgent: this.state.isSrmIpAgent
    }
    this.setState({ tab: 1, isLoading: true })
    const historyCallback = (response) => {
      this.props.callHistoryList(response)
      this.setState({ isLoading: false })
    }
    this.props.agentHistory(params, historyCallback)
  }

  logout = () => {
    this.setState({ logoutModal: false })
    localStorage.clear()
    this.props.history.push('/')
  }


  onChangeHistoryHandle = (pageNumbers) => {
    let params = {
      pageNumber: (pageNumbers - 1),
      limit: LIMIT,
      isSrmIpAgent: this.state.isSrmIpAgent
    }
    const historyCallback = (response) => {
      this.props.callHistoryList(response, "It is from the Pagination")
      this.setState({ isLoading: false })
    }
    this.props.agentHistory(params, historyCallback)
    this.setState({ historypageNumber: pageNumbers, isLoading: true })
  }


  onChangeCallQueue = (pageNumber) => {
    let params = {
      pageNumber: (pageNumber - 1),
      limit: LIMIT,
      isSrmIpAgent: this.state.isSrmIpAgent
    }
    const callBack = (response) => {
      this.setState({ callerList: response.response, isLoading: false })
    }
    this.props.customerCallListAction(params, callBack)
    this.setState({ callPageNumber: pageNumber, isLoading: true })
  }

  updateComments = (item) => {
  }

  findDuration = (callEndTime, callStartTime) => {
    let time = (callEndTime - callStartTime).toString();
    const hours = new Date(time)
    const minutes = Math.floor(time / (1000 * 60) % 60);
    const seconds = Math.floor(time / 1000 % 60)
    const result = `${hours.getHours()}h ${minutes}m ${seconds}s`
    return result
  }

  render() {
    const { tab, callNotification, historypageNumber, callPageNumber, isLoading } = this.state;
    const { getHistotyDetails, getCustomerCallList } = this.props
    const callerList = getCustomerCallList?.customerCallList?.calls
    const historysValue = getHistotyDetails?.callHistory?.historys
    const historyTotalItems = getHistotyDetails?.callHistory?.totalItems
    const { getLoginDetails = {} } = this.props
    const { tokenDetails = {}, agentName, vendorLogo, isFeedback } = getLoginDetails
    const { appointmentLoading } = this.props.updateAppointmentLoader
    return (
      <React.Fragment>
        {
          isLoading && <AppLoader />
        }
        <div className="store-content">
          <div className='dash-heading-part px-5 py-2'>
            <div>
              <img src={vendorLogo ? vendorLogo : require('../assets/img/proglint Transparent.png')} className="dashboard-logo" alt="logo" />
            </div>
            {/* <div className="dropdown proflie-dropdown">
            <button className="btn d-flex align-items-center" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <p className="loginName">{agentName}</p>
              <Avatar name={agentName} round={true} color={'black'} size='50px' onClick={() => this.setState({ profile: true })} />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a className="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-fill mx-2 my-2" viewBox="0 0 16 16">
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              </svg>Profile</a></li>
              <li><a className="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-square mx-2 my-2 mb-2" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
              </svg>Edit</a></li>
              <hr className="m-0"></hr>
              <li onClick={() => this.setState({ logoutModal: true })} ><a className="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-box-arrow-left mx-2 my-2" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z" />
                <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
              </svg>Logout</a></li>
            </ul>
          </div> */}
            <div className='dropdown ms-4'>
              <p className="loginName">{agentName}</p>
              <Avatar name={agentName} round={true} color={'black'} size='35px' onClick={() => this.setState({ logoutModal: true })} />
            </div>
          </div>
          <div className='row row-part'>
            <div className='col-md-4 ps-md-5 py-md-4 join-card-left'>
              <div className='card-shadow-content temp-card-img p-md-4'>
                <img src={require('../assets/img/agent_dashboard.png')} className="join-bg" alt="join-call" style={{ width: '80%' }} />
              </div>
            </div>
            <div className='col-md-8 pe-md-5 py-md-4'>
              <div className='card-shadow-content p-4 p-md-5 mt-4 mt-md-0'>
                <div className='row text-center'>
                  <div className='col-6 menu-division'>
                    <div className='attended-calls' onClick={this.handleCallQueue}>
                      <p className={`${tab === 0 ? "call-history-menu select-call-queue" : "call-history-menu"} `}>{Constants.CALL_IN_QUEUE}</p>
                    </div>
                  </div>
                  <div className='col-6 menu-division'>
                    <div className='attended-calls' onClick={this.handleHistory}>
                      <p className={`${tab === 1 ? "call-history-menu select-call-queue" : "call-history-menu"} `}>{Constants.HISTORY}</p>
                    </div>
                  </div>
                </div>
                <div className='feedback-contents content-scroll'>
                  {/* {(appointmentLoading) && <AppLoader />} */}
                  {
                    tab === 0 ?
                      <div className="join-call-card-content-height">
                        {
                          callerList?.length > 0 ? callerList?.map((call, index) => {
                            return (
                              <div className="card join-card-content" key={index}>
                                <div className="d-flex justify-content-between">
                                  <div className="call-card-p d-flex gap-4">
                                    {call?.callPriority === 2 && <img className='emergency-call' src={dangerSign}></img>}
                                    <div>
                                      <p className="m-0 calls-found">{call?.User?.fullName ? call?.User?.fullName : (call?.SrmIpRoomServiceMapping?.SrmIpRoom?.patientRoomName)}</p>
                                      <span className=''>{moment(call?.createdAt).format("DD/MM/YYYY hh:mm:ss:A")}</span>
                                    </div>
                                  </div>
                                  <div className="d-flex align-self-center">
                                    <div className="camera-icon mx-3 attenListCallTooltip" >
                                      <div target='_blank' onClick={() => this.joinNow(call, index, false)}  >
                                        <i className="fa-solid fa-phone mic-icon-end" style={{ color: "white" }}></i>
                                      </div>
                                      <Tooltip anchorSelect=".attenListCallTooltip" place="top">
                                        Attend Call
                                      </Tooltip>
                                    </div>
                                    <div className="camera-icon mx-3 rejectListCallTooltip" onClick={() => this.rejectNow(call, index, false)} style={{ backgroundColor: "#FF2E4A" }}>
                                      <i className="fa-solid fa-phone call-icon-end"></i>
                                      <Tooltip anchorSelect=".rejectListCallTooltip" place="top">
                                        Reject Call
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                          ) : <div className="card join-card-content">
                            <div className="d-flex justify-content-center p-2">
                              <div className="call-card-p">
                                <p className="m-0 calls-found">{Constants.NO_DATA_FOUND}</p>
                              </div>
                            </div>
                          </div>
                        }
                        <div className='page-content'>
                          {
                            getCustomerCallList?.customerCallList?.totalItems > (LIMIT - 1) && <PaginationComponenent currentPage={callPageNumber} countValue={getCustomerCallList?.customerCallList?.totalItems} onChangeHandle={this.onChangeCallQueue} />
                          }
                        </div>
                      </div> :
                      <>
                        {
                          historysValue?.length > 0 ? historysValue?.map((item, index) => {
                            return (
                              <div className="accordion mt-3" id="accordionExample" key={index}>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingTwo">
                                    <div className='card-header ratings-head px-3 py-2'>
                                      <div className='col-11 p-0'>
                                        <div className='name-date'>
                                          <p className={`${item?.callStatus === 2 ? 'user-name-part mb-1' : "user-name-part mb-1"}`}>{item?.User ? item?.User?.fullName : (item?.SrmIpRoomServiceMapping?.SrmIpRoom?.patientRoomName)}</p>
                                          <div className='ratings-duration-part'>
                                            <div className=''>
                                              <ReactStars
                                                count={5}
                                                edit={false}
                                                size={25}
                                                value={item?.ratings ? item?.ratings : 0}
                                                isHalf={true}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                activeColor="#ffd700"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className='number-time mt-2'>
                                          {/* <p className='mb-1 call-number'>{Constants.COUNTRY_CODE}{item?.User?.phone}</p> */}
                                          {item?.callDuration ? <p className='d-flex justify-content-end  date-content mb-1'>{"Call Duration: "}{msToHMSShort(item?.callDuration)}</p> : <div className='d-flex gap-2 align-items-center'><img src={missedCall} className='missed-call'></img> <span className='fs-14'>Missed call</span></div>}
                                          {item?.updatedAt && <p className='d-flex justify-content-end  date-content mb-1'>{moment(item?.createdAt).format("DD/MM/YYYY hh:mm:ss:A")} - {moment(item?.updatedAt).format("hh:mm:ss:A")}</p>}
                                        </div>
                                      </div>
                                      <div className='col-1 d-flex p-0'>
                                        <button
                                          className="accordion-button collapsed history-accordion ms-2"
                                          type="button" data-bs-toggle="collapse"
                                          data-bs-target={`#collapse${index}`}
                                          aria-expanded="false"
                                          aria-controls="collapseTwo">
                                        </button>
                                      </div>
                                    </div>
                                  </h2>
                                  <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body comment-content">
                                      <div className='card-body accordion-body-content' id="user-content">
                                        <p className='comments-part comment-heading mt-2'>{Constants.COMMENTS}</p>
                                        {!item?.feedback && <p className='comments-part mt-2'>No Data Found</p>}
                                        {item?.feedback && <p className='comments-part mt-2'>{item?.feedback}</p>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }) : <div className="card join-card-content">
                            <div className="d-flex justify-content-center p-2">
                              <div className="call-card-p">
                                <p className="m-0 calls-found">{Constants.NO_DATA_FOUND}</p>
                              </div>
                            </div>
                          </div>
                        }
                        <div className='page-content'>
                          {
                            historyTotalItems > (LIMIT - 1) && <PaginationComponenent currentPage={historypageNumber} countValue={getHistotyDetails?.callHistory?.totalItems} onChangeHandle={this.onChangeHistoryHandle} />
                          }
                        </div>
                      </>
                  }
                  {this.state.logoutModal && <Alert
                    id="Alert"
                    description={CONSTANTS.LOG_OUT.desc}
                    proceed={CONSTANTS.LOG_OUT.proceed}
                    cancel={CONSTANTS.LOG_OUT.cancel}
                    onSuccess={() => this.logout()}
                    onFailure={() => this.setState({ logoutModal: false })}
                  />}
                  {callNotification && <CallNotification rejectNow={(e) => this.rejectNow(e, '', true)} joinNow={(e) => this.joinNow(e, '', true)} callMute={() => this.callMute()} mute={this.state.callMute} callDetails={this.state.callDetails} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export const mapStateToProps = (state) => {
  return {
    getQueueList: state?.CHAT_LIST?.queueList,
    getCustomerCallList: Actions.getCustomerCallListDetails(state),
    getHistotyDetails: Actions.getHistotyDetails(state),
    getLoginDetails: Actions.getLoginDetails(state),
    updateAppointmentLoader: state?.UPDATE_APPOINTMENT,
  }
};
export default withRouter(connect(mapStateToProps, {
  customerCallListAction: Actions.customerCallListAction,
  updateAppointment: Actions.updateAppointmentAction,
  agentHistory: Actions.callHistoryAction,
  appendQueueList: Actions.addChatQueueList,
  callHistoryList: Actions.callHistoryListAction,
})(StoreManager))
