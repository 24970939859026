import { takeLatest, put, all } from "redux-saga/effects";
import Actions from "../actions";
import { END_POINTS } from "../../utils/service/EndPoints";
import { doAgentGET, doAgentPost } from "../../utils/service/index";
import { ADD_CHAT_LIST, CHAT_LIST } from "../actions/ChatListAction";
export function* chatListSaga({ params, callback }) {
    try {
        const response = yield doAgentGET(END_POINTS.CHAT_LIST_API, params);
        if (response) {
            yield put(Actions.chatListSuccess(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.chatListFailure(e));
        callback && callback(e);
    }
}

export function* addChatListSaga({ params, callback }) {
    try {
        const response = yield doAgentPost(END_POINTS.ADD_CHAT_LIST_API, params);
        if (response) {
            yield put(Actions.addChatListSuccess(response));
            callback && callback(response);
        }
    } catch (e) {
        yield put(Actions.addChatListFailure(e));
        callback && callback(e);
    }
}
export default function* chatListListener() {
    yield all([
        takeLatest(CHAT_LIST, chatListSaga),
        takeLatest(ADD_CHAT_LIST, addChatListSaga)
    ]);
}
