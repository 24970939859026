import { Component } from "react";
import Avatar from 'react-avatar';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Actions from "../redux/actions";
import { CONSTANTS } from "../utils/Constants";
import RoutePath from "../utils/routes/RoutePath";
import Alert from "./alert/alert";
import ResponsiveNavBar from "./ResponsiveNavBar";
import { stopSocketConnect } from "../socket";
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoutModal: false,
            profile: false,
        }
    }
    logout = () => {
        this.setState({ logoutModal: false })
        localStorage.clear()
        const route = this.props.match.path.split('/')
        switch (route[1]) {
            case "admin":
                localStorage.clear();
                stopSocketConnect()
                if (localStorage.length === 0) {
                    this.props.history.push(RoutePath.ADMIN_LOGIN_SCREEN_PATH)
                }
                break;
            case "vendor":
                localStorage.clear();
                stopSocketConnect()
                if (localStorage.length === 0) {
                    this.props.history.push(RoutePath.VENDOR_LOGIN_SCREEN_PATH)
                }
                break;
            default:
                localStorage.clear();
                stopSocketConnect()
                if (localStorage.length === 0) {
                    this.props.history.push("/")
                }
                break;
        }
    }
    render() {
        const { getLoginDetails = {} } = this.props
        const { adminTokenDetails = {}, vendorName, agentName } = getLoginDetails
        const route = this.props.match.path.split('/')
        const fullName = route[1] === 'admin' ? (adminTokenDetails?.user?.fullName.replace("_", " ")) : route[1] === 'vendor' ? vendorName : agentName
        return (
            <div className="headerMain">
                <div className="heading-part-dash px-3 px-md-5 headerMain">
                    <i className="fa-solid fa-bars d-block d-md-none menu-icon" data-bs-toggle="modal" data-bs-target="#exampleModal"></i>
                    {/* responsive sidebar popup */}
                    <ResponsiveNavBar route={route} />
                    <h3 className="agent-head m-0">{route[2]?.charAt(0)?.toUpperCase() + route[2]?.slice(1)}</h3>
                    <div className="dropdown proflie-dropdown">
                        <button className="btn d-flex align-items-center" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <p className="loginName">{fullName}</p>
                            <Avatar name={fullName} round={true} color={'black'} size='30px' onClick={() => this.setState({ profile: true })} />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {/* <li><a className="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-fill mx-2 my-2" viewBox="0 0 16 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                            </svg>Profile</a></li>
                            <li><a className="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-square mx-2 my-2 mb-2" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>Edit</a></li>
                            <hr className="m-0"></hr> */}
                            <li onClick={() => this.setState({ logoutModal: true })} ><a className="dropdown-item" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-box-arrow-left mx-2 my-2" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z" />
                                <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
                            </svg>Logout</a></li>
                        </ul>
                    </div>
                </div>
                {this.state.logoutModal && <Alert
                    id="Alert"
                    description={CONSTANTS.LOG_OUT.desc}
                    proceed={CONSTANTS.LOG_OUT.proceed}
                    cancel={CONSTANTS.LOG_OUT.cancel}
                    onSuccess={() => this.logout()}
                    onFailure={() => this.setState({ logoutModal: false })}
                />}
            </div>

        )
    }
}

export const mapStateToProps = (state) => {
    return {
        getLoginDetails: Actions.getLoginDetails(state)
    }
};
export default withRouter(connect(mapStateToProps, {
})(Header));