import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import AddVendorScreen from '../component/AddVendorScreen'

class AddVendorAgent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fullName: '',
            email: '',
            agentType: '',
            password: '',
            phone: '',
            companyName: '',
            accountNumber: '',
            gstIn: "",
            regNo: '',
            pan: '',
            address: '',
            subscriptionId: '',
            accountType: '',
            ifsc: '',
            agentId: '',
            error: {},
            message: {},
            emptyFieldError: [],
            errorData: ''
        }
    }
    render() {
        return (
            <div className="dashboard-screen-part">
                <div className="d-flex p-0">
                    <SideBar />
                    <div className="card-right p-0">
                        <Header />
                        <AddVendorScreen isEdit={this.props.match.params.id ? true : false} />
                    </div>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {

    }
};
export default withRouter(connect(mapStateToProps, {
})(AddVendorAgent));