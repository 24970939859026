import React, { Component } from 'react'
import Multiselect from 'multiselect-react-dropdown';
import { connect } from 'react-redux'
import Actions from '../redux/actions'
class MultiSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: [],
      isLoading: false
    }
  }
  componentDidMount = () => {
    this.props.adminAgentList('', (response) => {
      const options = response?.response?.map(obj => ({ label: obj?.fullName + " - (" + obj?.phone + ")", value: obj.id, data: obj }))
      this.setState({ options })
    }, this.props.isAdmin)
  }
  render() {
    return (
      <Multiselect
        options={this.state.options} // Options to display in the dropdown
        onRemove={(value) => this.props.onRemove(value || {})} // Function will trigger on remove event
        displayValue="label" // Property name to display in the dropdown options
        isObject={true}
        placeholder={this.props.placeholder}
        selectedValues={this.props.value}
        onSelect={(value) => this.props.onSelect(value || {})}
      />
    )
  }
}
const mapStateToProps = (state) => {
  return {

  }
}
export default connect(mapStateToProps, {
  adminAgentList: Actions.agentListAction
})(MultiSelect)


