import { doAction, doResponseAction } from ".";

const CHAT_LIST = "CHAT_LIST";
const CHAT_LIST_SUCCESS = "CHAT_LIST_SUCCESS";
const CHAT_LIST_FAILURE = "CHAT_LIST_FAILURE";

const ADD_CHAT_LIST = "ADD_CHAT_LIST";
const ADD_CHAT_LIST_SUCCESS = "ADD_CHAT_LIST_SUCCESS";
const ADD_CHAT_LIST_FAILURE = "ADD_CHAT_LIST_FAILURE";
const APPEND_CHAT = "APPEND_CHAT";
const SOCKET_APPEND_CHAT = "SOCKET_APPEND_CHAT";

const CHAT_QUEUE_LIST='CHAT_QUEUE_LIST';
const ADD_CHAT_QUEUE_LIST='ADD_CHAT_QUEUE_LIST';

export { CHAT_LIST, CHAT_LIST_SUCCESS, CHAT_LIST_FAILURE,ADD_CHAT_LIST,ADD_CHAT_LIST_SUCCESS,ADD_CHAT_LIST_FAILURE,APPEND_CHAT,SOCKET_APPEND_CHAT,CHAT_QUEUE_LIST,ADD_CHAT_QUEUE_LIST};

export const chatListAction = (params, callback) => doAction(CHAT_LIST, params, callback);
export const chatListSuccess = (data) => doResponseAction(CHAT_LIST_SUCCESS, data);
export const chatListFailure = (data) => doResponseAction(CHAT_LIST_FAILURE, data);

export const appendChatAction = (data) => doResponseAction(APPEND_CHAT, data);
export const socketAppendChatAction = (data) => doResponseAction(SOCKET_APPEND_CHAT, data);

export const chatQueueListAction = (data) => doResponseAction(CHAT_QUEUE_LIST, data);
export const addChatQueueList = (data) => doResponseAction(ADD_CHAT_QUEUE_LIST, data);


export const addChatListAction = (params, callback) => doAction(ADD_CHAT_LIST, params, callback);
export const addChatListSuccess = (data) => doResponseAction(ADD_CHAT_LIST_SUCCESS, data);
export const addChatListFailure = (data) => doResponseAction(ADD_CHAT_LIST_FAILURE, data);
