export const END_POINTS = {
  LOGIN_API: "oauth/login",
  FORGET_PASSWORD_API: "oauth/token",
  CUSTOMER_LIST_API: "customer",
  VENDOR_FEEDBACK_TEXT: "vendor/feedback/text",
  VENDOR_FEEDBACK_VOICE: "vendor/feedback/voice",
  GET_VENDOR_DETAILS: "customer/getVendorDetails",
  GET_AR_PRODUCT_VALIDATION: "3dButton/arValidation",

  //SRM
  GET_SRM_IP_ROOM_SERVICE: "customer/srm_ip_room_service/list",

  //chat
  CHAT_LIST_API: "chat/list",
  ADD_CHAT_LIST_API: "chat/add",

  //customer
  FEEDBACK_API: "customer/feedback",
  AGE_VERIFICATION: "/customer/ageVeification",
  VIRTUAL_BACKGROUND: "/customer/virtualBackground",
  GET_PAYMENT_LIST_DETAILS: "/customer/paymentType",
  CUSTOMER_DECLINE_API: "customer/appointment/update",

  //agent
  CUSTOMER_CALL_LIST_API: "agent/list",
  UPDATE_APPOINTMENT: "agent/list/update",
  CALL_HISTORY: "agent/history/details",
  AGENT_SCREEN_SHARE: "agent/screenshare",
  AGENT_SCREEN_SHARE_UPDATE: "agent/screenshare/update",
  AGENT_VIRTUAL_BACKGROUND: "agent/virtualBackground/list",

  //admin
  ADMIN_DASHBOARD: "admin/dashboard/details",

  ADMIN_VENDOR_LIST: "admin/vendor/list",
  ADMIN_VENDOR_ACTIVE_LIST: "admin/vendor/activeList",
  ADMIN_VENDOR_DELETE_AGENT: "admin/vendor/delAgent",
  ADMIN_VENDOR_UPDATE: "admin/vendor/update",
  ADMIN_VENDOR_DETAILS: "admin/vendor/details",
  SET_NEW_PASSWORD: "admin/vendor/setPassword",

  ADMIN_AGENT_CALL_HISTORY: "admin/agent/history/details",
  AGENT_LIST_DETAILS: "admin/agent/list",
  ADD_AGENT: "admin/agent/create",
  UPDATE_AGENT: "admin/agent/update",
  DELETE_AGENT: "admin/agent/delete",
  GET_AGENT: "admin/agent/details",

  GET_SUBSCRIPTION_LIST: "admin/subscription/list",
  GET_SUBSCRIPTION_ACTIVE_LIST: "admin/subscription/activeList",
  ADD_SUBSCRIPTION: "admin/subscription/add",
  UPDATE_SUBSCRIPTION: "admin/subscription/update",
  GET_SUBSCRIPTION_DETAILS: "admin/subscription/details",

  GET_ADDON_LIST: "admin/addon/list",
  GET_ADDON_ACTIVE_LIST: "admin/addon/activeList",
  ADD_ADDON: "admin/addon/add",
  UPDATE_ADDON: "admin/addon/update",
  GET_ADDON_DETAILS: "admin/addon/details",

  GET_ADMIN_AR_PRODUCT_LIST: "admin/arProduct/list",
  POST_ADMIN_AR_PRODUCT_ADD: "admin/arProduct/add",
  GET_ADMIN_AR_PRODUCT_DETAILS: "admin/arProduct/details",
  POST_ADMIN_AR_PRODUCT_UPDATE: "admin/arProduct/update",
  GET_ALL_VENDOR_LIST: "admin/arProduct/vendor/list",

  GET_ADMIN_VB_IMAGE_LIST: "",
  POST_ADMIN_VB_IMAGE_ADD: "",
  GET_ADMIN_VB_IMAGE_DETAILS: "",
  POST_ADMIN_VB_IMAGE_UPDATE: "",

  //vendor
  VENDOR_DASHBOARD: "vendor/agent/dashboard",

  VENDOR_AGENT_CALL_HISTORY: "vendor/agent/history/details",
  VENDOR_AGENT_LIST_DETAILS: "vendor/agent/list",
  GET_VENDOR_STATUS_URL: "vendor/agent/status/get",
  VENDOR_STATUS_URL: "vendor/agent/status/add",
  VENDOR_STATUS_URL_UPDATE: "vendor/agent/status/update",
  VENDOR_ADD_AGENT: "vendor/agent/create",
  VENDOR_UPDATE_AGENT: "vendor/agent/update",
  VENDOR_DELETE_AGENT: "vendor/agent/delete",
  VENDOR_GET_AGENT: "vendor/agent/details",
  VENDOR_GET_AGENT_VIEW_DETAILS: "vendor/agent/view/details",
  UPDATE_VENDOR_PROFILE: "vendor/agent/profile/update",
  VENDOR_AGENT_DETAILS: "vendor/agent/details",
  GET_VENDOR_ADDON_LIST: "vendor/agent/addon/list",
  GET_VENDOR_ADDON_DETAILS: "vendor/agent/addon/details",
  PAYMENT_ADDON: "vendor/agent/addon",
  VENDOR_SUBSCRIPTION_LIST: "vendor/agent/activeList",
  GET_VENDOR_AR_PRODUCT_LIST: "vendor/agent/arProduct/list",
  POST_VENDOR_AR_PRODUCT_ADD: "vendor/agent/arProduct/add",
  GET_VENDOR_AR_PRODUCT_DETAILS: "vendor/agent/arProduct/details",
  POST_VENDOR_AR_PRODUCT_UPDATE: "vendor/agent/arProduct/update",
  RENEW_VENDOR_SUBSCRIPTION: "vendor/agent/renewSubscription",
  VENDOR_DOWNLOAD_FEEDBACK: "vendor/agent/feedback/list",

  GET_VENDOR_VB_IMAGE_LIST: "vendor/agent/virtualBackground/list",
  POST_VENDOR_VB_IMAGE_ADD: "vendor/agent/virtualBackground/add",
  GET_VENDOR_VB_IMAGE_DETAILS: "vendor/agent/virtualBackground/details",
  POST_VENDOR_VB_IMAGE_UPDATE: "vendor/agent/virtualBackground/update",

  GET_ROOM_LIST: "vendor/agent/srm_ip_room/list",
  GET_VENDOR_ROOM_DETAILS: "vendor/agent/srm_ip_room/details",
  ADD_VENDOR_ROOM: "vendor/agent/srm_ip_room/create",
  UPDATE_VENDOR_ROOM: "vendor/agent/srm_ip_room/update",

  GET_VENDOR_SERVICE_LIST: "vendor/agent/srm_ip_service/list",
  POST_VENDOR_SERVICE_ADD: "vendor/agent/srm_ip_service/create",
  GET_VENDOR_SERVICE_DETAIL: "vendor/agent/srm_ip_service/get",
  POST_VENDOR_SERVICE_UPDATE: "vendor/agent/srm_ip_service/update",

  GET_VENDOR_ROOM_SERVICE_LIST: "vendor/agent/srm_ip_room_service/mapping/list",
  POST_VENDOR_ROOM_SERVICE_ADD:
    "vendor/agent/srm_ip_room_service/mapping/create",
  GET_VENDOR_ROOM_SERVICE_DETAIL:
    "vendor/agent/srm_ip_room_service/mapping/get",
  POST_VENDOR_ROOM_SERVICE_UPDATE:
    "vendor/agent/srm_ip_room_service/mapping/update",
  GET_VENDOR_SRM_IP_AGENT_LIST: "vendor/agent/srmip/list",


  BABY_CRY_LIST: "babycry/list",

};
