import { doAction, doResponseAction } from ".";

const GET_ADDON_LIST = 'GET_ADDON_LIST';
const GET_ADDON_LIST_SUCCESS = 'GET_ADDON_LIST_SUCCESS';
const GET_ADDON_LIST_FAILURE = 'GET_ADDON_LIST_FAILURE';

const ADD_ADDON = 'ADD_ADDON';
const ADD_ADDON_SUCCESS = 'ADD_ADDON_SUCCESS';
const ADD_ADDON_FAILURE = 'ADD_ADDON_FAILURE';

const UPDATE_ADDON = 'UPDATE_ADDON';
const UPDATE_ADDON_SUCCESS = 'UPDATE_ADDON_SUCCESS';
const UPDATE_ADDON_FAILURE = 'UPDATE_ADDON_FAILURE';

const PAYMENT = 'PAYMENT';
const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
const PAYMENT_FAILURE = 'PAYMENT_FAILURE';

const GET_ADDON_DETAILS = 'GET_ADDON_DETAILS';
const GET_ADDON_DETAILS_SUCCESS = 'GET_ADDON_DETAILS_SUCCESS';
const GET_ADDON_DETAILS_FAILURE = 'GET_ADDON_DETAILS_FAILURE';

const GET_ADDON_ACTIVE_LIST = 'GET_ADDON_ACTIVE_LIST';
const GET_ADDON_ACTIVE_LIST_SUCCESS = 'GET_ADDON_ACTIVE_LIST_SUCCESS';
const GET_ADDON_ACTIVE_LIST_FAILURE = 'GET_ADDON_ACTIVE_LIST_FAILURE';
export {
    GET_ADDON_LIST,
    GET_ADDON_LIST_SUCCESS,
    GET_ADDON_LIST_FAILURE,
    ADD_ADDON,
    ADD_ADDON_SUCCESS,
    ADD_ADDON_FAILURE,
    UPDATE_ADDON,
    UPDATE_ADDON_SUCCESS,
    UPDATE_ADDON_FAILURE,
    PAYMENT,
    PAYMENT_SUCCESS,
    PAYMENT_FAILURE,
    GET_ADDON_DETAILS,
    GET_ADDON_DETAILS_SUCCESS,
    GET_ADDON_DETAILS_FAILURE,
    GET_ADDON_ACTIVE_LIST,
    GET_ADDON_ACTIVE_LIST_SUCCESS,
    GET_ADDON_ACTIVE_LIST_FAILURE
}

export const getAddOn = store => store['ADDON_DETAILS']
export const getAddOnList = (params, callback, isAdmin) => doAction(GET_ADDON_LIST, params, callback, isAdmin);
export const getAddOnListSuccess = data => doResponseAction(GET_ADDON_LIST_SUCCESS, data);
export const getAddOnListFailure = data => doResponseAction(GET_ADDON_LIST_FAILURE, data);


export const addAddOn = (params, callback, isAdmin) => doAction(ADD_ADDON, params, callback, isAdmin);
export const addAddOnSuccess = data => doResponseAction(ADD_ADDON_SUCCESS, data);
export const addAddOnFailure = data => doResponseAction(ADD_ADDON_FAILURE, data);

export const updateAddOn = (params, callback, isAdmin) => doAction(UPDATE_ADDON, params, callback, isAdmin);
export const updateAddOnSuccess = data => doResponseAction(UPDATE_ADDON_SUCCESS, data);
export const updateAddOnFailure = data => doResponseAction(UPDATE_ADDON_FAILURE, data);

export const getAddOnDetails = (params, callback, isAdmin) => doAction(GET_ADDON_DETAILS, params, callback, isAdmin);
export const getAddOnDetailsSuccess = data => doResponseAction(GET_ADDON_DETAILS_SUCCESS, data);
export const getAddOnDetailsFailure = data => doResponseAction(GET_ADDON_DETAILS_FAILURE, data);

export const payment = (params, callback, isAdmin) => doAction(PAYMENT, params, callback, isAdmin);
export const paymentSuccess = data => doResponseAction(PAYMENT_SUCCESS, data);
export const paymentFailure = data => doResponseAction(PAYMENT_FAILURE, data);

export const getAddOnActiveList = (params, callback, isAdmin) => doAction(GET_ADDON_ACTIVE_LIST, params, callback, isAdmin);
export const getAddOnActiveListSuccess = data => doResponseAction(GET_ADDON_ACTIVE_LIST_SUCCESS, data);
export const getAddOnActiveListFailure = data => doResponseAction(GET_ADDON_ACTIVE_LIST_FAILURE, data);